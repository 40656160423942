import {BaseService} from '@esgi/core/service';
import {TestHistoryService} from './test-history-service';
import {HeaderService} from './header-service';
import {HierarchySnapshot} from '../../../hierarchy/core/models';
import {SubjectType, TestType} from '@esgi/core/enums';
import {TestHistoryAllStudentsService} from 'modules/reports/test-history/services/test-history-all-students-service';
import {BehaviorSubject, mergeMap} from 'rxjs';
import {mapToEnum} from 'shared/utils';
import {tap} from 'rxjs/operators';

export class TestHistoryRootService extends BaseService {
	// hot fix (ESGI-31185)
	public busy = new BehaviorSubject(false);
	public testHistoryService: TestHistoryService;
	public testHistoryAllStudentsService: TestHistoryAllStudentsService;
	public headerService: HeaderService;

	constructor(
		private hierarchy: HierarchySnapshot,
		private subjectID: number,
		private subjectType: SubjectType,
		public testId: number,
		private testType: TestType,
		private studentId?: number,
	) {
		super();
		this.testHistoryAllStudentsService = new TestHistoryAllStudentsService(hierarchy, testId, 0, 0);
		this.testHistoryService = new TestHistoryService(hierarchy, subjectID, subjectType, testId, testType, studentId);
		this.headerService = new HeaderService(this.testHistoryService, this.testHistoryAllStudentsService);
	}

	public init() {
		this.testHistoryService.reportData.subscribe((reportData) => {
			this.headerService.visibleDownload.next(!!reportData?.testSessions.length);
		});
		this.testHistoryAllStudentsService.reportData.subscribe((reportData) => {
			this.headerService.visibleDownloadAllStudents.next(
				!!reportData?.studentsTested
				&& this.testHistoryService.selectedStudent.value[0]?.studentID === -1,
			);
		});
		this.testHistoryService.selectedSubject.subscribe((newVal) => {
			this.headerService.selectedSubjectId.next(newVal[0]?.subjectID);
			this.headerService.selectedSubjectType.next(newVal[0]?.subjectType);
			this.testHistoryAllStudentsService.selectedSubject.next(newVal);
		});
		this.testHistoryService.selectedTest.subscribe((newVal) => {
			if (this.testHistoryService.selectedTest.value.length > 0) {
				this.testHistoryAllStudentsService.selectedTestId.next(newVal[0]?.testID);
				this.testHistoryAllStudentsService.selectedTest.next(newVal);
			}
		});
		this.testHistoryService.selectedClass.subscribe((newVal) => {
			if (this.testHistoryService.classes.value.length > 0) {
				this.headerService.selectedClassId.next(newVal[0]?.classID);
				this.testHistoryAllStudentsService.selectedClassId.next(newVal[0]?.classID);
				this.testHistoryAllStudentsService.selectedClass.next(newVal);
			}
		});
		this.testHistoryService.selectedGroup.subscribe((newVal) => {
			this.headerService.selectedGroupId.next(newVal[0]?.groupID);
			this.testHistoryAllStudentsService.selectedGroupId.next(newVal[0]?.groupID);
			this.testHistoryAllStudentsService.selectedGroup.next(newVal);
		});
		this.testHistoryService.selectedSpecialistGroup.subscribe((newVal) => {
			this.headerService.selectedSpecialistGroupId.next(newVal[0]?.specialistGroupID);
			this.testHistoryAllStudentsService.selectedSpecialistGroup.next(newVal);
		});
		this.testHistoryService.selectedStudent.subscribe((newVal) => {
			this.headerService.selectedStudentId.next(newVal[0]?.studentID);
			this.headerService.visibleDownloadAllStudents.next(
				!!this.testHistoryAllStudentsService.reportData.value?.studentsTested
				&& newVal[0]?.studentID === -1,
			);
		});
		this.testHistoryService.teacherName.subscribe((newVal) => {
			this.testHistoryAllStudentsService.teacherName.next(newVal);
		});
		this.testHistoryService.dateFrom.subscribe((newVal) => {
			this.testHistoryAllStudentsService.dateFrom.next(newVal);
		});
		this.testHistoryService.dateTo.subscribe((newVal) => {
			this.testHistoryAllStudentsService.dateTo.next(newVal);
		});
		this.testHistoryService.students.subscribe((newVal) => {
			this.testHistoryAllStudentsService.students.next(newVal);
		});

		this.busy.next(true);
		if (mapToEnum(this.testType, TestType) === TestType.Rubric) {
			return this.testHistoryService.init().pipe(tap(() => this.busy.next(false)));
		} else {
			return this.testHistoryAllStudentsService.init().pipe(mergeMap(() =>
				this.testHistoryService.init().pipe(tap(() => this.busy.next(false))),
			));
		}
	}
}
