import {useUser} from '@esgi/core/authentication';
import {Hierarchy, HierarchyMode} from '../types';
import {useMemo} from 'react';
import {buildHierarchyInstance} from '../helpers/build-hierarchy-instance';

type Parameters = {
	hierarchy: Hierarchy;
};

export function useHierarchyInstance({hierarchy}: Parameters) {
	const currentUser = useUser();

	return useMemo(
		() =>
			buildHierarchyInstance({
				currentUser,
				hierarchy,
			}),
		[currentUser, hierarchy],
	);
}
