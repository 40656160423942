import {useCallback, useState} from 'react';
import {AddEditStudent, TransferStudent} from '@esgi/main/features/school-admin/student-profile-drawer';
import {BottomPanel} from './bottom-panel';
import {RowDef} from '../../../types';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';
import {useDataPageContext} from '../../../../layout/context';
import {Portal} from '@esgi/ui';
import {SchoolReportPanel} from '../../../../_kit/school-reports-panel';
import {useData} from '../../../use-data';
import {Page} from '@esgi/main/features/admins/data';

interface Props {
	addButtonDisabled: boolean;
	onOpenAddDrawer: VoidFunction;
}

export function Content({addButtonDisabled, onOpenAddDrawer}: Props) {
	const {api} = Page.useContext<RowDef>();
	const {schoolName} = useData();

	const [editStudentID, setEditStudentID] = useState<number>(null);
	const [transferStudentID, setTransferStudentID] = useState<number>(null);
	const {pageContentContainerRef} = useDataPageContext();

	const handleOpenTransferStudent = useCallback(() => {
		if (!editStudentID) {
			return;
		}

		setEditStudentID(null);
		setTransferStudentID(editStudentID);
	}, [editStudentID]);

	const handleStudentTransferred = useCallback(() => {
		setEditStudentID(transferStudentID);
	}, [transferStudentID]);

	return (
		<>
			<Page.Header.Base
				addButtonDisabled={addButtonDisabled}
				addButtonDisabledTooltipMessage='To add a new Student, please contact our Customer Support team at support@esgisoftware.com'
				onAddButtonClick={onOpenAddDrawer}
			/>
			<Page.Body.Base />

			<SingleItemExpandablePanel onEditClicked={setEditStudentID} />
			<SchoolReportPanel api={api} schoolName={schoolName} />
			<BottomPanel />

			{editStudentID && (
				<Portal container={pageContentContainerRef.current}>
					<AddEditStudent
						studentID={String(editStudentID)}
						onOpenTransferStudent={handleOpenTransferStudent}
						onClose={() => setEditStudentID(null)}
					/>
				</Portal>
			)}

			{transferStudentID && (
				<Portal container={pageContentContainerRef.current}>
					<TransferStudent
						studentID={transferStudentID}
						onTransferred={handleStudentTransferred}
						onClose={() => setTransferStudentID(null)}
					/>
				</Portal>
			)}
		</>
	);
}
