import {ControlValue, FormControl, FormGroup, ValidatorArgs, Validators} from '@esgillc/ui-kit/form';
import {Observable, tap} from 'rxjs';

export type LoginInfoFormType = FormGroup<{ password: FormControl<string>, login: FormControl<string> },
	ControlValue<{ password: FormControl<string>, login: FormControl<string> }>>;

export const createLoginInfoForm = (): LoginInfoFormType => {
	return new FormGroup({
		login: new FormControl('', {validators: [Validators.required()]}),
		password: new FormControl('', {validators: [Validators.required(), Validators.length.min(8)]}),
	});
};

export const usernameAvailableCustomValidator = (initialUsername: string, isUsernameAvailable: Observable<boolean>) => {
	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(tap((validatorArgs) => {
			isUsernameAvailable.subscribe(available => {
				if (!available && initialUsername !== validatorArgs.field.currentValue){
					validatorArgs.errors.push('login-in-use');
				}
			});
		}));
	};
};
