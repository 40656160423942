import {ActionPanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {Text} from '@esgi/ui/typography';
import {AddToFolderButton, Page, RunReportButton} from '@esgi/main/features/admins/data';

type Props = {
	onFolderAddClick: VoidFunction;
};

export function BottomPanel({onFolderAddClick}: Props) {
	const {api, entityName, tableRows} = Page.useContext<RowDef>();

	return (
		<ActionPanel.Root api={api} showOnMinSelectedItems={2} showOnMaxSelectedItems={tableRows.length - 1}>
			<ActionPanel.Header>
				<ActionPanel.ContentBox>
					<ActionPanel.CloseButton />

					<ActionPanel.MainBox>
						<ActionPanel.CounterBox />
						<Text size='xSmall' font='mono' color='primary'>
							{entityName} Selected
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<ActionPanel.ShowOnlySelectedCheckbox />
						<ActionPanel.ActionButtonDivider />

						<AddToFolderButton onClick={onFolderAddClick} />

						<RunReportButton
							title='School Reports'
							disabledMessage='Multiple schools must be added to a folder from which reports can be run'
						/>
					</ActionPanel.ActionButtonsBox>
				</ActionPanel.ContentBox>
			</ActionPanel.Header>
		</ActionPanel.Root>
	);
}
