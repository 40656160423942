import {FolderType} from '@esgi/main/features/admins/data';
import {AdditionalFolderData} from './types';

export const additionalFolderData: AdditionalFolderData = {
	[FolderType.Schools]: {
		hierarchyKey: 'schoolsFolderID',
		title: 'School Group Reports',
		noStudentsSinglePrefix: 'class',
		noStudentsMultiplePrefix: 'classes',
	},
	[FolderType.Teacher]: {
		hierarchyKey: 'teachersFolderID',
		title: 'Teacher Group Reports',
		noStudentsSinglePrefix: 'class',
		noStudentsMultiplePrefix: 'classes',
	},
	[FolderType.Specialist]: {
		hierarchyKey: 'specialistsFolderID',
		title: 'Specialist Group Reports',
		noStudentsSinglePrefix: 'group',
		noStudentsMultiplePrefix: 'groups',
	},
};
