import {RefObject} from 'react';
import {BehaviorSubject, forkJoin, from} from 'rxjs';
import {tap, switchMap} from 'rxjs/operators';
import {getUser, UserType} from '@esgi/core/authentication';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {BaseTabService} from '../../../base-tab-service';
import {createSchoolAdminForm} from '../../../../../../forms/location/school-admin';

import {
	TabsApi,
	Teacher,
	ClassItem,
	GroupsItem,
	PreSelected,
	UserRawResponse,
	StudentProfileTab,
	StudentProfileMode,
	ClassesResponse, ClassesResponseItem, ProfileInitData,
} from 'modules/forms/students-form/types';
import {ObservableBuilder} from '@esgi/api';
import {StudentChangedEvent, StudentSaveAndAddEvent} from 'modules/forms/students-form/events';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';

class LocationModel {
	constructor(
		public studentID: number,
		public groupIDs: number[],
		public classIDs: number[],
		public teacher: number,
		public globalSchoolYearID: number,
		public schoolID: number,
	) {
	}
}

interface InitArgs {
	initData: ProfileInitData,
	mode: StudentProfileMode,
	studentID: number,
	preSelected: PreSelected | null,
	tabsApi: RefObject<TabsApi>
}

export class SchoolAdminService extends BaseTabService {
	public preSelected = new BehaviorSubject<PreSelected>(null);
	public teachers = new BehaviorSubject<Teacher[]>(null);
	public classes = new BehaviorSubject<ClassItem[]>(null);
	public groups = new BehaviorSubject<(GroupsItem & { isDisabled?: boolean })[]>(null);
	public selectedClasses = new BehaviorSubject<ClassItem[]>([]);

	public form = createSchoolAdminForm();

	protected tab = StudentProfileTab.Location;
	private currentUser = getUser();

	public init({initData, mode, studentID, preSelected, tabsApi}: InitArgs) {
		this.serviceLoading.next(true);
		this.studentID = studentID;
		this.preSelected.next(preSelected);
		this.initData.next(initData);
		return forkJoin([this.getTeachers(), this.setClassesAndGroups()]).pipe(tap(([teachersResponse]) => {
			const selectedTeacherID = initData?.location?.teacherID || this.preSelected.value?.userID;
			const teacher = teachersResponse.users.find(t => t.userID === selectedTeacherID);
			this.form.value = {
				classIDs: initData?.location?.classIDs || [],
				groupIDs: initData?.location?.groupIDs || [],
				teacher: teacher ? [{...teacher, fullName: `${teacher.firstName} ${teacher.lastName}`}] : [],
			};

			this.selectedClasses.next(this.form.controls.classIDs.value.map(id => this.classes.value?.find(cl => id === cl.id)));

			if (mode !== StudentProfileMode.view) {
				this.disableGroups();
				this.form.controls.teacher.onChanged.subscribe((ch) => {
					if (ch.reason === 'value') {
						this.form.controls.classIDs.value = [];
						this.form.controls.groupIDs.value = [];
						this.setClassesAndGroups(ch.currState.value[0]?.userID).subscribe();
					}
				});

				this.form.controls.classIDs.onChanged.subscribe((v) => {
					this.disableGroups();
					if (v.currState.value) {
						this.selectedClasses.next(v.currState.value.map(id => this.classes.value?.find(cl => id === cl.id)));
					} else {
						this.selectedClasses.next([]);
					}
				});

				this.initTabsApi(tabsApi);
			} else {
				this.form.status = ElementStatus.disabled;
			}
		})).pipe(tap({
			complete: () => this.serviceLoading.next(false),
		}));
	}

	public save = (isSaveAndAdd?: boolean) => {
		const {
			groupIDs,
			classIDs,
			teacher,
		} = this.form.value;

		const model = {
			studentID: this.studentID,
			groupIDs: groupIDs.filter(group => !this.groups.value.find(item => Number(item.id) === group)?.isDisabled),
			classIDs,
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
			teacherID: Number(teacher[0]?.userID),
			schoolID: this.initData.value?.location?.schoolID,
			specialistGroupIDs: this.initData.value?.location?.specialistGroupIDs,
			specialistID: this.initData.value?.location?.specialistID,
		};

		return this.form.validate().pipe(switchMap(v => {
			if (v.valid) {
				return this.httpClient.ESGIApi
					.post(this.controller,
						'profile/location/save', model)
					.pipe(tap(() => {
						const {
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
						} = this.initData.value?.general;
						if(!model.classIDs.length && this.initData.value.location.classIDs.length) {
							showSnackbarNotification('You have removed this student from all classes.');
						}
						this.initData.next({...this.initData.value, location: {
							...this.initData.value.location,
							classIDs, groupIDs, teacherID: teacher[0]?.userID,
						}});
						const event = new StudentChangedEvent(
							this.studentID,
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
							this.initData.value?.location?.schoolID,
							this.form.controls.teacher.value[0].userID,
							model.classIDs,
							model.groupIDs,
							null,
						);
						this.eventBus.dispatch(StudentChangedEvent, event);
						this.eventBus.dispatch(StudentSaveAndAddEvent, new StudentSaveAndAddEvent(isSaveAndAdd));
					}));
			}
			return from([]);
		}));
	};

	private getTeachers() {
		const globalSchoolYearID = this.currentUser.globalSchoolYearID;
		const schoolID = this.initData.value?.location?.schoolID || this.currentUser?.schoolID;
		return this.httpClient.ESGIApi
			.get<{ users: UserRawResponse[] }>(this.controller, 'location/users', {
				userType: UserType.T,
				schoolID,
				globalSchoolYearID,
			})
			.pipe(tap(res => {
				this.teachers.next(res.users.map(user => ({...user, fullName: `${user.firstName} ${user.lastName}`})));
			}));
	}

	private setClassesAndGroups = (selectedTeacherID?: number): ObservableBuilder<ClassesResponse> => {
		const userID = selectedTeacherID || this.initData.value?.location?.teacherID|| this.currentUser.userID;
		const queryParams = new URLSearchParams({
			...(userID ? {userID: userID.toString()} : {}),
			globalSchoolYearId: this.currentUser.globalSchoolYearID?.toString(),
		});

		return this.httpClient.ESGIApi
			.get<ClassesResponse>(this.controller, `location/classes?${queryParams.toString()}`).pipe(tap(res => {
				this.classes.next(res.classes.map(({classID, name}) => ({id: classID, name, teacherID: userID})));
				this.groups.next(res.classes.reduce((acc: GroupsItem[], item: ClassesResponseItem) => {
					return [...acc, ...item.groups.map(item => ({
						id: item.groupID,
						name: item.name,
						classID: item.classID,
					}))];
				}, []));
				this.disableGroups();
			}));
	};

	private disableGroups = () => {
		this.groups.next(this.groups.value.map(group => ({
			...group,
			isDisabled: !this.form.controls.classIDs.value.includes(Number(group.classID)),
		})));
	};
}
