import {useMemo} from 'react';
import {ColDef} from 'ag-grid-community';

import {School, User} from '@esgi/main/libs/admin-store';

import {CellType, EntitiesFilter, entitiesFilterParams, NumberFilter, numberFilterParams} from '@esgi/ui/ag-grid';

import {RowDef} from './types';
import {UserTypeOptions} from './constants';

export function useColumnDefinitions(rows: RowDef[], schools: School[], specialists: User[]) {
	return useMemo<ColDef<RowDef>[]>(
		() => [
			{
				field: 'name',
				headerName: 'Specialist Group',
				lockVisible: true,
				headerComponentParams: {
					description: 'Displays the name of a Specialist Group (Required)',
				},
				filter: false,
				sortable: true,
				type: CellType.Primary,
				sort: 'asc',
			},
			{
				field: 'school',
				headerName: 'School',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(schools),
				headerComponentParams: {
					description: `Displays a school's name`,
				},
				sortable: true,
			},
			{
				field: 'specialist',
				headerName: 'Specialist',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(specialists),
				headerComponentParams: {
					description: 'Displays the name of a Specialist',
				},
				sortable: true,
			},
			{
				field: 'type',
				headerName: 'Type',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(Object.values(UserTypeOptions)),
				headerComponentParams: {
					description: 'Displays the type of a Specialist: School or District',
				},
				sortable: true,
			},
			{
				field: 'students',
				headerName: 'Students',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows, 'students'),
				headerComponentParams: {
					description: 'Displays the number of students associated with the Specialist',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
		],
		[rows, schools, specialists],
	);
}
