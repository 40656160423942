import {userStorage, UserType} from '@esgi/core/authentication';
import {dispatchAppEvent} from '@esgillc/events';
import {BehaviorSubject, forkJoin, map, switchMap, tap} from 'rxjs';
import {
	AdminProfileModel, BaseResponse,
	DefaultProfileControllers,
	DropdownInModel,
	TrackInfoResponse,
	UserInfoResponse,
} from '../../types';
import {UserInfoChangedEvent, UserInfoTrackChangedEvent, UserInfoTrackCreatedEvent} from '../../events';
import {Titles} from '../../constants';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {BaseProfileService} from '../../base-profile-service';
import {DateTools} from '../../utils';
import {usernameAvailableCustomValidator} from '../../forms/login-info';

export class ProfileService extends BaseProfileService {
	protected defaultController: DefaultProfileControllers;
	private isUsernameAvailable = new BehaviorSubject<boolean>(true);
	private initialUsername: string;

	public init(userID: number, defaultController: DefaultProfileControllers, isUserInfoDisabled: boolean) {
		this.userID = userID;
		this.defaultController = defaultController;
		this.trackInfoForm.controls.type.status = ElementStatus.disabled;

		if(isUserInfoDisabled){
			this.userInfoForm.controls.title.status = ElementStatus.disabled;
			this.userInfoForm.controls.firstName.status = ElementStatus.disabled;
			this.userInfoForm.controls.lastName.status = ElementStatus.disabled;
			this.userInfoForm.controls.state.status = ElementStatus.disabled;
			this.userInfoForm.controls.country.status = ElementStatus.disabled;
		}

		this.eventBus.subscribe(UserInfoTrackChangedEvent, this.trackChanged);
		this.eventBus.subscribe(UserInfoTrackCreatedEvent, this.trackCreated);
		return this.initProfile(this.defaultController);
	}

	public update() {
		const model = new AdminProfileModel(
			this.loginInfoForm.controls.login.value,
			this.userInfoForm.controls.email.value,
			this.userInfoForm.controls.title.value[0].value,
			this.userInfoForm.controls.firstName.value,
			this.currentUser.globalSchoolYearID,
			this.userInfoForm.controls.lastName.value,
			this.loginInfoForm.controls.password.value === this.initialPassword ? null : this.loginInfoForm.controls.password.value,
			this.userInfoForm.controls.state.value[0]?.id,
			this.trackInfoForm.controls.track.value[0]?.trackID,
			this.userID,
		);

		return this.httpClient.ESGIApi.post<BaseResponse>(this.defaultController, 'update', model)
			.pipe(tap((response: BaseResponse) => {
				if (!response.isSuccess) {
					const usernameError = response.errors.find(error => error.type === 'username_is_already_taken');
					if (usernameError) {
						this.isUsernameAvailable.next(false);
						this.loginInfoForm.controls.login.validateWithEmitters();
					}
				} else {
					const {
						districtID,
						globalSchoolYearID,
						name,
						schoolYearType,
						schoolYearTypeID,
						trackID,
						trackDates,
					} = this.trackInfoForm.controls.track.value[0];

					dispatchAppEvent(UserInfoTrackChangedEvent, new UserInfoTrackChangedEvent(
						districtID,
						globalSchoolYearID,
						name,
						schoolYearTypeID,
						schoolYearType,
						trackID,
						trackDates,
					));
					dispatchAppEvent(UserInfoChangedEvent, new UserInfoChangedEvent(model.userID, model.firstName, model.lastName, UserType.D));
					if (model.userID === this.currentUser.userID) {
						userStorage.update({
							firstName: model.firstName,
							lastName: model.lastName,
							stateID: model.stateID,
						});
					}
				}
			}));
	}

	public validateForms() {
		return forkJoin([
			this.userInfoForm,
			this.loginInfoForm,
		].map(f => f.validate())).pipe(map((r) => r.map(v => v.valid).every(Boolean)));
	}

	protected initProfile(controller: string) {
		const stream = this.httpClient.ESGIApi.get<UserInfoResponse>(controller, 'init', {
			userID: this.currentUser.userID,
		})
			.pipe(tap((response) => {
				const countries = response.countries.map(({countryID, name}) => new DropdownInModel(countryID, name));
				const states = response.states.map(({stateID, name}) => new DropdownInModel(stateID, name));
				this.countries.next(countries);
				this.states.next(states);

				const {
					email,
					lastName,
					firstName,
					userName,
					countryID,
					stateID,
					title,
					districtID,
					districtName,
					schoolName,
					expirationDate,
					agreementLevelCode,
					canAllowRenewByCC,
					notRenewable,
					isLinked,
				} = response.user;

				this.loginInfoForm.value = {
					login: userName,
					password: this.initialPassword,
				};

				this.initialUsername = userName;
				this.loginInfoForm.controls.login.validators.push(usernameAvailableCustomValidator(this.initialUsername, this.isUsernameAvailable));
				this.loginInfoForm.controls.login.onChanged.subscribe((ch) => {
					if (ch.prevState.value !== ch.currState.value) {
						this.loginInfoForm.controls.login.status = ElementStatus.untouched;
						this.isUsernameAvailable.next(true);
					}
				});

				const currentUser = userStorage.get();
				let notRenew = notRenewable;
				if (currentUser.userType === UserType.D || currentUser.userType === UserType.C) {
					notRenew = true;
				}

				this.userInfoForm.value = {
					firstName,
					lastName,
					email,
					state: [states.find(x => x.id === stateID)],
					country: [countries.find(x => x.id === countryID)],
					title: [Titles.find(x => x.value === title)],
					gradeLevels: [],
				};
				this.userInfo.next({
					schoolName,
					initialLogin: userName,
					expirationDate: expirationDate ? DateTools.toUIString(DateTools.toDate(expirationDate)) : 'not set',
					agreementLevelCode,
					canAllowRenewByCC,
					notRenewable: notRenew,
					isLinked,
					isPreassess: currentUser.userType === UserType.PA,
				});

				this.district.next({
					id: districtID,
					name: districtName,
				});
			}));
		if(this.currentUser.userType !== UserType.C){
			stream.pipe(
				switchMap(() => {
					return this.httpClient.ESGIApi.get<TrackInfoResponse>(DefaultProfileControllers.Common, 'get-track-info', {
						userID: this.userID,
						districtID: this.currentUser.districtID,
					});
				})).pipe(tap(response => {
				const selectedTrack = response.tracks.find(t => t.trackID === response.selectedTrackID) || response.tracks[0];
				const selectedType = response.schoolYearTypes.find(x => x.id === selectedTrack?.schoolYearTypeID);
				this.trackInfoForm.value = {
					track: [selectedTrack],
					type: [selectedType],
				};
				this.trackInfoForm.controls.track.onChanged.subscribe(v => {
					const selectedTrack = this.tracks.value.find(x => x.trackID === v.currState.value[0].trackID);
					const selectedSchoolYearType = this.schoolYearTypes.value.find(x => x.id === selectedTrack.schoolYearTypeID);
					this.trackInfoForm.controls.type.value = [selectedSchoolYearType];
					this.periods.next(this.getPeriods());
				});

				this.tracks.next(response.tracks);
				this.schoolYearTypes.next(response.schoolYearTypes);
				this.periods.next(this.getPeriods());
			}),
			);
		}
		return stream;
	}
}



