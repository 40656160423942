import {Student} from '@esgi/main/libs/school-admin-store';
import {Alert} from '@esgi/ui/alert';
import {useCallback} from 'react';
import {useRemoveStudent} from '../../../utils';
import {RemoveSingleEntityAlert} from '@esgi/main/features/admins/data';
import {getFullName} from '@esgi/main/kits/common';

type Props = {
	onRemoved: VoidFunction;
	onClose: VoidFunction;
	potentialRemovedStudent: Student;
};

export function DeleteStudentAlert({onClose, onRemoved, potentialRemovedStudent}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isStudentDataLoaded, removeStudent, isStudentRemoving] = useRemoveStudent();

	const onRemoveStudent = useCallback(() => {
		removeStudent({
			id: potentialRemovedStudent.id,
			firstName: potentialRemovedStudent.firstName,
			lastName: potentialRemovedStudent.lastName,
			onRemoved: () => {
				onClose();
				onRemoved();
			},
		});
	}, [onClose, onRemoved, potentialRemovedStudent, removeStudent]);

	return (
		<RemoveSingleEntityAlert
			skeleton={!isStudentDataLoaded}
			alertRef={alertRef}
			closeAlert={closeAlert}
			title='Delete Student?'
			entityName={getFullName({
				firstName: potentialRemovedStudent.firstName,
				lastName: potentialRemovedStudent.lastName,
			})}
			bodyMessage={[
				'Are you sure you wish to delete the following student from the system?',
				'Doing so will also remove all associated data which is not recoverable.',
			]}
			isRemoving={isStudentRemoving}
			onRemove={onRemoveStudent}
		/>
	);
}
