import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../../types';
import {ExpandedPanel} from './expanded-panel';
import {QuickPanel} from './quick-panel';
import {useMemo} from 'react';
import {UserType} from '@esgi/core/authentication';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {TestResultsStudent} from '@esgi/main/kits/admin';
import {useTestResultsSubject} from '../../../../../../_kit';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	deselectedAllItems: VoidFunction;
};

export function ContentWrapper({deselectedAllItems, isPanelExpanded, selectedRow}: Props) {
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({specialistGroupsIDs}) => specialistGroupsIDs.includes(selectedRow.id))
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {
		isReady: isTestResultsSubjectReady,
		subjects: testResultsSubjects,
		reportSubject,
	} = useTestResultsSubject({
		userID: selectedRow.specialist.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter: 'userIDAsSpecialist',
	});

	const hierarchy = useMemo<Hierarchy>(() => {
		const h: Hierarchy = {
			specialistsID: selectedRow.specialist.id,
			specialistGroup: {
				id: selectedRow.id,
				name: selectedRow.name,
				specialistID: selectedRow.specialist.id,
				type: UserType.ISS,
			},
		};

		return h;
	}, [selectedRow]);

	const skeleton = !isTestResultsSubjectReady || !isStudentsLoaded;

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				deselectedAllItems={deselectedAllItems}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={skeleton}
			/>

			<ExpandedPanel
				selectedRow={selectedRow}
				hierarchy={hierarchy}
				skeleton={skeleton}
				students={filteredStudents}
				subjects={testResultsSubjects}
			/>
		</>
	);
}
