import * as React from 'react';
import type {SVGProps} from 'react';

export function PreAssess(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M15.2 13.2C15.2 13.6416 15.5584 14 16 14C16.4416 14 16.8 13.6416 16.8 13.2V10.8H19.2C19.6416 10.8 20 10.4416 20 10C20 9.5584 19.6416 9.2 19.2 9.2H16.8V6.8C16.8 6.3584 16.4416 6 16 6C15.5584 6 15.2 6.3584 15.2 6.8V9.2H12.8C12.3584 9.2 12 9.5584 12 10C12 10.4416 12.3584 10.8 12.8 10.8H15.2V13.2Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.19393 13.2061C9.05606 13.0689 8.861 13 8.66667 13C7.43393 13 6.5796 13.3095 5.9394 13.9621C5.3058 14.6097 5 15.4669 5 16.6667C5 16.861 5.06894 17.0561 5.20607 17.1939L11.8061 23.7939C11.9439 23.9311 12.139 24 12.3333 24H15.2667C15.6715 24 16 23.6715 16 23.2667V20.3333C16 20.139 15.9311 19.9439 15.7939 19.8061L9.19393 13.2061ZM6.99393 14.9712C7.288 14.6705 7.70673 14.5195 8.3682 14.4806C8.58967 14.7021 8.76331 14.8737 8.89603 15.005C8.97811 15.0861 9.04474 15.152 9.09714 15.2044C8.77052 15.5314 8.39427 15.9058 8.02409 16.2741L8.0202 16.2779C7.73344 16.5633 7.45043 16.8448 7.19707 17.0979C7.1412 17.0417 7.07093 16.9729 6.98279 16.8865C6.85577 16.7621 6.6915 16.6011 6.47987 16.3895C6.5202 15.7405 6.69326 15.2777 6.99393 14.9712ZM8.2362 18.1363L10.1319 16.2458L14.5333 20.6545V22.5333H12.6545L8.2362 18.1363Z'
				fill='#333333'
			/>
			<path
				d='M19.8181 20.6255C20.4804 19.8234 21.6447 19.7822 22.3561 20.5356L23.8376 22.3435C23.9382 22.4501 24.1026 22.4455 24.1978 22.3334L27.6942 18.2694C27.968 17.9382 28.4445 17.9082 28.7533 18.2027C29.0563 18.4917 29.084 18.985 28.8154 19.31L25.3057 23.39C24.6393 24.1746 23.4884 24.2068 22.7839 23.4606L21.3024 21.6528C21.2008 21.5451 21.0345 21.551 20.9398 21.6656L19.3061 23.7236C19.0351 24.0519 18.5643 24.0851 18.2545 23.7979C17.9448 23.5107 17.9134 23.0118 18.1844 22.6835L19.8181 20.6255Z'
				fill='#333333'
			/>
		</svg>
	);
}
