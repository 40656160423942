import {ReportDialog, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {useSchoolSpecialists} from '@esgi/main/libs/school-admin-store';
import {useMemo, useState} from 'react';
import {TabID} from './types';
import {Teacher} from './tabs-content/teacher';
import {Specialists} from './tabs-content/specialists';

type Props = {
	onClose: VoidFunction;
	subject: ReportsSubjectInfo;
};

export function SchoolReportsDialog({onClose, subject}: Props) {
	const [activeTab, setActiveTab] = useState(TabID.Teacher);

	const [{data: schoolSpecialists, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();

	const withSpecialistsTab = useMemo(
		() => (isSchoolSpecialistsLoaded ? Boolean(schoolSpecialists.length) : true),
		[isSchoolSpecialistsLoaded, schoolSpecialists],
	);

	return (
		<ReportDialog header='School' onClose={onClose} subject={subject}>
			{withSpecialistsTab ? (
				<ReportDialog.Tabs.Root<TabID> activeTab={activeTab} setActiveTab={setActiveTab}>
					<ReportDialog.Tabs.Title size='small' color='highContrast'>
						Select a group for which you would like to run the report
					</ReportDialog.Tabs.Title>
					<ReportDialog.Tabs.Bar />

					<ReportDialog.Tabs.Content tabID={TabID.Teacher} tabContent='Teachers'>
						<Teacher />
					</ReportDialog.Tabs.Content>

					<ReportDialog.Tabs.Content tabID={TabID.Specialists} tabContent='Specialists'>
						<Specialists />
					</ReportDialog.Tabs.Content>
				</ReportDialog.Tabs.Root>
			) : (
				<Teacher />
			)}
		</ReportDialog>
	);
}
