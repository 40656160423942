import {useEffect, useMemo} from 'react';
import {isUndefined, unique} from 'underscore';
import {UserType} from '@esgi/core/authentication';
import {
	useSchools,
	useDistrictSpecialists,
	useSchoolSpecialists,
	useSpecialistGroups,
	usePreAssessSpecialists,
	School,
	User,
} from '@esgi/main/libs/admin-store';
import {useDisposable} from '@esgi/core/service';
import {V2DistrictAdminsPagesDataAnalyticsController} from '@esgi/contracts/esgi';

import {RowDef} from './types';
import {UserTypeOptions} from './constants';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';

export type Out = {
	ready: boolean;
	rows: RowDef[];
	schools: School[];
	specialists: User[];
};

export function useData() {
	const [{data: allSchools, loaded: isAllSchoolsLoaded}] = useSchools();
	const [{data: districtSpecialists, loaded: isDistrictSpecialistsLoaded}] = useDistrictSpecialists();
	const [{data: schoolSpecialists, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();
	const [{data: preAssessSpecialists, loaded: isPreAssessSpecialistsLoaded}] = usePreAssessSpecialists();
	const [{data: specialistGroups, loaded: groupsLoaded}] = useSpecialistGroups();

	const analyticController = useDisposable(V2DistrictAdminsPagesDataAnalyticsController);

	const [analyticsData, fetchAnalyticsData] = useCancelableRequest(analyticController.specialistGroups);

	useEffect(() => {
		if (groupsLoaded) {
			fetchAnalyticsData({specialistGroupIDs: specialistGroups.map((g) => g.id)});
		}
	}, [fetchAnalyticsData, groupsLoaded, specialistGroups]);

	return useMemo<Out>(() => {
		const isDataLoaded =
			isAllSchoolsLoaded &&
			isDistrictSpecialistsLoaded &&
			isSchoolSpecialistsLoaded &&
			isPreAssessSpecialistsLoaded &&
			groupsLoaded &&
			isAsyncSucceed(analyticsData);

		if (!isDataLoaded) {
			return {
				ready: false,
				rows: [],
				schools: [],
				specialists: [],
			};
		}

		const allSpecialists = [...districtSpecialists, ...schoolSpecialists, ...preAssessSpecialists];
		const rows = specialistGroups.map<RowDef>((group) => {
			const school = allSchools.find((s) => group.schoolID === s.id);
			const specialist = allSpecialists.find((s) => s.id === group.specialistID);
			const analytics = analyticsData.data.value.specialistGroupsAnalytics.find((g) => g.groupID === group.id);

			if (isUndefined(specialist)) {
				throw new Error('specialist is undefined');
			}

			return {
				id: group.id,
				name: group.name,
				school: school ?? null,
				specialist,
				type:
					group.userType === UserType.ISD
						? UserTypeOptions.ISD
						: group.userType === UserType.ISS
							? UserTypeOptions.ISS
							: UserTypeOptions.PA,
				students: analytics?.studentsCount ?? 0,
			};
		});

		return {
			ready: true,
			rows,
			schools: unique(rows.map((r) => r.school)).filter((item): item is School => Boolean(item)),
			specialists: unique(rows.map((r) => r.specialist)),
		};
	}, [
		allSchools,
		analyticsData,
		districtSpecialists,
		groupsLoaded,
		isAllSchoolsLoaded,
		isDistrictSpecialistsLoaded,
		isPreAssessSpecialistsLoaded,
		isSchoolSpecialistsLoaded,
		preAssessSpecialists,
		schoolSpecialists,
		specialistGroups,
	]);
}
