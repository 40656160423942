import {isEqual} from 'underscore';
import {BehaviorSubject, catchError, of, switchMap, tap, throwError} from 'rxjs';
import {ElementStatus} from '@esgi/ui/form';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {V2SchoolAdminsStudentsController} from '@esgi/contracts/esgi';
import {studentsStore, Student as StudentStore} from '@esgi/main/libs/school-admin-store';
import {School} from '@esgi/contracts/esgi/types/esgi.schools/schools/get/by-id/school';
import {ClassModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/modules/forms/student/class-model';
import {UserModel} from '@esgi/contracts/esgi/types/esgi.accounts/queries/esgi.apigateway/modules/forms/student/users/user-model';
import {UpdateRequest} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/modules/forms/students/update-request';
import {Response} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/modules/students/profile/update/init/response';
import {SchoolSpecialist} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.districtadmin/schools-specialist/get-by-school-id/school-specialist';
import {Dictionary, DictionaryItem, ImageData, Information, Location, NewPhoto, Profile, ProfileState, StudentProfileMode, StudentSpecialProgram} from './types';
import {createLocationForm, teacherClassAssignValidator} from './forms/location';
import {createProfileTabForm, studentIDNValidator} from './forms/profile';
import {createInformationForm} from './forms/information';
import {extractBase64String} from './utils';
import {dictionary} from './constants';
import {dispatchAppEvent} from '@esgillc/events';
import {StudentCreatedEvent} from '../../../events';

export class StudentProfileService extends BaseService {
	public studentID = new BehaviorSubject<string>(null);
	public initData = new BehaviorSubject<Response>(null);
	public initDictionaryData = new BehaviorSubject<Dictionary>(null);
	public newPhoto = new BehaviorSubject<NewPhoto>(null);
	public mode = new BehaviorSubject<StudentProfileMode>(null);
	public canExportID = new BehaviorSubject<boolean>(true);
	public duplicateError = new BehaviorSubject<string | null>(null);

	public school = new BehaviorSubject<School>(null);
	public teacher = new BehaviorSubject<UserModel>(null);
	public teachers = new BehaviorSubject<UserModel[]>(null);
	public classes = new BehaviorSubject<ClassModel[]>(null);
	public schoolSpecialists = new BehaviorSubject<SchoolSpecialist[]>(null);

	public isBusy = new BehaviorSubject(false);

	public profile = createProfileTabForm();
	public location = createLocationForm();
	public information = createInformationForm();

	private currentUser = userStorage.get();
	private initialState: ProfileState = null;
	private controller = new V2SchoolAdminsStudentsController();

	constructor() {
		super();

		this.completeOnDestroy(this.location.controls.teacherID.onChanged).subscribe((changed) => {
			if (changed.reason === 'status') {
				return;
			}

			this.location.controls.teacherEntityIDs.value = {groupIDs: [], classIDs: []};

			if (!this.teachers.value || !changed.currState.value) {
				this.classes.next(null);
				this.teacher.next(null);
				return;
			}

			this.classes.next(null);
			this.setSelectedTeacher();
			this.getClasses().subscribe();
		});
	}

	public init(studentID?: string) {
		this.studentID.next(studentID);

		if (studentID) {
			this.mode.next(StudentProfileMode.edit);
		} else {
			this.mode.next(StudentProfileMode.add);
		}

		return this.controller.init({
			teacherID: undefined,
		}).pipe(tap((response) => {
			const languagesObjectLink = response.value.languages;

			languagesObjectLink.sort((a, b) => (
				a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase())
			));

			const indexOfOtherOption = languagesObjectLink.findIndex(({name}) => (
				name.toLocaleLowerCase() === 'other'
			));

			if (indexOfOtherOption !== -1) {
				const otherOption = languagesObjectLink.splice(indexOfOtherOption, 1)[0];
				languagesObjectLink.splice(languagesObjectLink.length, 1, otherOption);
			}

			this.initDictionaryData.next({...dictionary, ...response.value});
		}), switchMap(() => {
			if (studentID) {
				return this.controller.updateInit({studentID: Number(this.studentID.value)}).pipe(tap((response) => {
					this.initData.next(response.value);
					this.newPhoto.next(response.value?.profile.photo?.imageUrl ? response.value?.profile.photo : null);
				}));
			}

			return of(null);
		})).pipe(tap({
			next: () => {
				this.profileInit();

				this.getTeachers().subscribe(() => {
					this.setSelectedTeacher();
					this.getSchool().subscribe();
					this.getClasses().subscribe();
					this.getSchoolSpecialists().subscribe();
				});
			},
			complete: () => {
				this.profile.status = ElementStatus.untouched;
				this.setStudentProfileFormStatuses();
			},
		}));
	}

	public profileInit() {
		if (this.mode.value === StudentProfileMode.edit) {
			const {profile, location, information} = this.initData.value;

			this.profile.value = {
				studentIDN: profile?.studentIDN?.toString(),
				lastName: profile?.lastName,
				firstName: profile?.firstName,
				birthDate: profile?.birthDate ? [new Date(profile.birthDate)] : [],
				exportIDN: profile?.exportIDN,
				gradeLevel: [profile?.gradeLevelID.toString()],
				language: [profile?.languageID.toString()],
				gender: [profile?.gender],
			};

			this.location.value = {
				schoolID: location?.schoolID?.toString(),
				teacherID: location?.teacherID?.toString() ?? null,
				teacherEntityIDs: {
					classIDs: location?.classIDs.map(cl => cl.toString()) ?? [],
					groupIDs: location?.groupIDs.map(g => g.toString()) ?? [],
				},
				specialistGroupIDs: location?.specialistGroupIDs.map(s => s.toString()) ?? [],
			};

			const parseBackendNameValue = (dictionaryItems: DictionaryItem<any>[], backendValue: string | number) => {
				if (!backendValue) {
					return null;
				}

				return dictionaryItems?.find(item => item.valueName === String(backendValue))?.id?.toString();
			};

			const parsedRace = parseBackendNameValue(dictionary.races, information?.race);
			const parsedLunchStatus = parseBackendNameValue(dictionary.lunchStatuses, information?.lunchStatus);

			const parsedSpecialPrograms = dictionary.specialPrograms.filter(item => (
				information.studentSpecialPrograms.includes(item.valueName as unknown as StudentSpecialProgram)
			)).map(item => item.id);

			this.information.value = {
				race: parsedRace ? [parsedRace] : [],
				lunchStatus: parsedLunchStatus ? [parsedLunchStatus] : [],
				studentSpecialPrograms: parsedSpecialPrograms || [],
				comments: information?.comments || '',
			};
		}

		const {profile, location, information} = this.createDataState();

		this.initialState = {
			...profile,
			...location,
			...information,
		};

		this.handleStudentIDNValidation();
		this.handleStudentClassAssignValidation();
	}

	public getSpecialistsIDsBySpecialistGroupIDs = (specialistGroupIDs: number[]) => {
		const specialistGroupsIDsSet = new Set(specialistGroupIDs);

		const specialistsIDs = this.schoolSpecialists.value.reduce((ids, specialist) => {
			if (specialist.groups.some(group => specialistGroupsIDsSet.has(group.groupID))) {
				ids.push(specialist.id);
			}
			return ids;
		}, []);

		return specialistsIDs;
	};

	public save(saveAnyway?: boolean) {
		this.isBusy.next(true);

		const {profile, location, information} = this.createDataState();

		const hadImage = this.initData.value?.profile?.photo?.imageUrl;

		const defaultPhoto = this.mode.value === StudentProfileMode.add ? null : {
			crop: this.initData.value.profile?.photo?.crop || null,
			imageBase64: null,
			imageCropBase64: null,
			remove: false,
			contentType: 'string',
		};

		const newPhoto = this.newPhoto.value ? {
			crop: this.newPhoto.value.crop,
			imageBase64: this.newPhoto.value.imageUrl === this.initData.value?.profile.photo?.imageUrl ? null : extractBase64String(this.newPhoto.value.imageUrl),
			imageCropBase64: this.newPhoto.value.imageCropUrl === this.initData.value?.profile.photo?.imageCropUrl ? null : extractBase64String(this.newPhoto.value.imageCropUrl),
			remove: false,
		} : defaultPhoto;

		const model = {
			profile: {...profile, birthDate: profile.birthDate || null},
			location: {
				...location,
				schoolID: location.schoolID ?? this.currentUser.schoolID,
			},
			information,
			studentID: this.studentID.value ? Number(this.studentID.value) : null,
			createAnyway: false,
			newPhoto: hadImage && !this.newPhoto.value ? {
				imageBase64: null,
				imageCropBase64: null,
				crop: {x: 0, y: 0, zoom: 1},
				remove: true,
			} : newPhoto,
		};

		const isSaveAnyway = saveAnyway
			|| (this.initData.value?.profile?.firstName === model.profile.firstName
				&& this.initData.value?.profile?.lastName === model.profile.lastName);

		if (isSaveAnyway) {
			model.createAnyway = true;
		}

		return this.controller[this.studentID.value ? 'update' : 'create'](
			model as UpdateRequest
		).pipe(tap((response) => {
			if (response.isSuccess) {


				const student: Omit<StudentStore, 'id'> = {
					firstName: model.profile.firstName,
					lastName: model.profile.lastName,
					schoolID: model.location.schoolID,
					teacherID: model.location.teacherID,
					classesIDs: model.location.classIDs,
					groupsIDs: model.location.groupIDs,
					photoUrl: model.newPhoto?.imageCropBase64 ?? null,
					specialistGroupsIDs: model.location.specialistGroupIDs,
					specialistsIDs: this.getSpecialistsIDsBySpecialistGroupIDs(model.location.specialistGroupIDs),
					gradeLevelID: model.profile.gradeLevelID,
				};

				if (this.mode.value === StudentProfileMode.add) {
					const newStudentID: number = (response as any).value.studentID
					this.studentID.next(newStudentID.toString());
					studentsStore().add({
						...student,
						id: newStudentID,
					});
					dispatchAppEvent(StudentCreatedEvent, {data: {id: newStudentID}});
					this.isBusy.next(false);
					return;
				}

				const numberedStudentID = Number(this.studentID.value);

				studentsStore().update((item: StudentStore): StudentStore => {
					if (item.id === numberedStudentID) {
						return {
							...item,
							...student,
						};
					}

					return item;
				});
				this.isBusy.next(false);
				return;
			}

			if (response.errors?.length && response.errors[0].type === 'duplicate') {
				this.duplicateError.next(response.errors[0].description);
				this.isBusy.next(false);
				return;
			}

			if (response.isSuccess && !response.errors?.length) {
				this.duplicateError.next(null);
				this.isBusy.next(false);
			}
		}), catchError((error) => {
			this.isBusy.next(false);
			return throwError(error);
		}));
	}

	public setSelectedTeacher() {
		if (!this.teachers.value || !this.location.value.teacherID) {
			return;
		}

		const selectedTeacher = this.teachers.value.find((item) => (
			String(item.userID) === String(this.location.value.teacherID)
		));

		this.teacher.next(selectedTeacher);
	}

	public getSchool() {
		return this.controller.school().pipe(tap((response) => {
			this.school.next(response.value.school);
		}));
	}

	public getSchoolSpecialists() {
		return this.controller.schoolsSpecialists().pipe(tap((response) => {
			this.schoolSpecialists.next(response.value.users);
		}));
	}

	public getTeachers() {
		return this.controller.teachers().pipe(tap((response) => {
			this.teachers.next(response.users);
		}));
	}

	public getClasses() {
		const teacherID = this.location.value.teacherID;
		if (!teacherID) {
			return of(null);
		}

		return this.controller.classes({teacherID: Number(teacherID)}).pipe(tap((response) => {
			this.classes.next(response.classes);
		}));
	}

	public saveImage(imageFileData: ImageData) {
		const {cropParameters, croppedImage, image} = imageFileData;

		this.newPhoto.next({
			imageUrl: image,
			imageCropUrl: croppedImage,
			crop: cropParameters,
			remove: false,
		});
	}

	public deletePhoto() {
		this.newPhoto.next(null);
	}

	public checkFormIsTouched() {
		const {profile, location, information} = this.createDataState();

		const currentState = {
			...profile,
			...location,
			...information,
		};

		return !isEqual(this.initialState, currentState);
	}

	public setStudentProfileFormStatuses() {
		if (this.mode.value === StudentProfileMode.edit) {
			if (!this.currentUser?.canEditStudents) {
				this.profile.controls.firstName.status = ElementStatus.disabled;
				this.profile.controls.lastName.status = ElementStatus.disabled;
				this.profile.controls.gender.status = ElementStatus.disabled;
				this.profile.controls.gradeLevel.status = ElementStatus.disabled;
				this.profile.controls.studentIDN.status = ElementStatus.disabled;
				this.profile.controls.exportIDN.status = ElementStatus.disabled;
			}

			if (!this.currentUser?.canEditStudentLanguage) {
				this.profile.controls.language.status = ElementStatus.disabled;
			}

			if (!this.currentUser?.canEditStudentMetaFields) {
				this.profile.controls.birthDate.status = ElementStatus.disabled;
				this.information.controls.race.status = ElementStatus.disabled;
				this.information.controls.lunchStatus.status = ElementStatus.disabled;
				this.information.controls.studentSpecialPrograms.status = ElementStatus.disabled;
			}
		}
	}

	private createDataState() {
		const profile = new Profile(this.profile.value, this.newPhoto.value);
		const location = new Location(this.location.value);
		const information = new Information(this.information.value);

		return {
			profile,
			location,
			information,
		};
	}

	private handleStudentIDNValidation() {
		this.profile.controls.studentIDN.validators.push(
			studentIDNValidator(this.controller, Number(this.studentID.value), this.currentUser?.globalSchoolYearID),
		);
	}

	private handleStudentClassAssignValidation() {
		this.location.controls.teacherEntityIDs.validators.push(
			teacherClassAssignValidator(this.location.controls.teacherID)
		);
	}
}

