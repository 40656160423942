import {User} from '@esgi/main/libs/admin-store';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {useCallback, useEffect, useMemo} from 'react';
import {OnTeacherRemoved} from '../types';
import {useRemoveTeacher} from '../../../utils';
import {RemoveSingleEntityAlert} from '@esgi/main/features/admins/data';
import {getFullName} from '@esgi/main/kits/common';

type Props = {
	onRemoved: OnTeacherRemoved;
	onClose: VoidFunction;
	potentialRemovedTeacher: User;
};

export function DeleteTeacherAlert({onClose, onRemoved, potentialRemovedTeacher}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const api = useEsgiApiGateway();

	const [initFoldersData, fetchUserFoldersData] = useCancelableRequest(
		api.v2.districtAdmins.modules.folders.initDelete,
	);

	const [isTeacherDataLoaded, removeTeacher, isTeacherRemoving] = useRemoveTeacher();

	const isUserFoldersDataLoaded = isAsyncSucceed(initFoldersData);

	useEffect(() => {
		fetchUserFoldersData({userID: potentialRemovedTeacher.id});
	}, []);

	const additionalMessage = useMemo(() => {
		if (!isUserFoldersDataLoaded || !initFoldersData.data.value.foldersWillBeDeleted.length) {
			return '';
		}

		return 'This action will remove the teacher from the associated folder(s) and delete any folder(s) for which this teacher was the only member.';
	}, [initFoldersData, isUserFoldersDataLoaded]);

	const onRemoveTeacher = useCallback(() => {
		removeTeacher({
			id: potentialRemovedTeacher.id,
			firstName: potentialRemovedTeacher.firstName,
			lastName: potentialRemovedTeacher.lastName,
			onRemoved: () => {
				const removedFoldersIDs: number[] = isUserFoldersDataLoaded
					? initFoldersData.data.value.foldersWillBeDeleted.map(({id}) => id)
					: [];

				onClose();
				onRemoved({
					removedFoldersIDs,
				});
			},
		});
	}, [initFoldersData, isUserFoldersDataLoaded, onClose, onRemoved, potentialRemovedTeacher, removeTeacher]);

	return (
		<RemoveSingleEntityAlert
			skeleton={!isTeacherDataLoaded || !isUserFoldersDataLoaded}
			alertRef={alertRef}
			closeAlert={closeAlert}
			title='Delete Teacher?'
			entityName={getFullName({
				firstName: potentialRemovedTeacher.firstName,
				lastName: potentialRemovedTeacher.lastName,
			})}
			bodyMessage={`Are you sure you want to delete the teacher? ${additionalMessage}`}
			isRemoving={isTeacherRemoving}
			onRemove={onRemoveTeacher}
		/>
	);
}
