import {useCallback, useMemo} from 'react';
import {
	SubjectModel,
	Entities,
	DistrictSubject,
	SchoolSubject,
	TeacherSubject,
} from '@esgi/main/kits/subject-selection-panel';
import {MappedSubjectsData} from '../types/subjects-panel';

type Parameters = {
	districtSubjects: DistrictSubject[];
	schoolSubjects: SchoolSubject[];
	teacherSubjects: TeacherSubject[];
};

export function useSubjects({districtSubjects, schoolSubjects, teacherSubjects}: Parameters) {
	const {firstSelectedSubjectID, subjectsEntities, hasSubjects} = useMemo<MappedSubjectsData>(() => {
		const entities: Entities = {
			districtSubjects: [...districtSubjects],
			schoolSubjects: [...schoolSubjects],
			teacherSubjects: [...teacherSubjects],
		};

		const firstSelectedSubjectID: SubjectModel['id'] | null =
			entities.districtSubjects[0]?.id ?? entities.schoolSubjects[0]?.id ?? entities.teacherSubjects[0]?.id ?? null;

		return {
			firstSelectedSubjectID,
			subjectsEntities: entities,
			hasSubjects: Object.values(entities).some((entity) => entity.length),
		};
	}, [districtSubjects, schoolSubjects, teacherSubjects]);

	const getSubjectByID = useCallback(
		(id: SubjectModel['id']) =>
			[
				...subjectsEntities.districtSubjects,
				...subjectsEntities.schoolSubjects,
				...subjectsEntities.teacherSubjects,
			].find((subject) => subject.id === id) ?? null,
		[subjectsEntities],
	);

	return {
		subjectsEntities,
		firstSelectedSubjectID,
		hasSubjects,
		getSubjectByID,
	};
}
