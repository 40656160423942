import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody, AlertFooter, alertRootCss} from './index.styled';
import {Button, isAsyncPending, isAsyncSucceed, isUndefined, useStreamEffect} from '@esgi/ui';
import {FolderManagement, FolderType} from '../kit';
import {nameFolderErrors} from './constants';
import {useCallback, useEffect, useState} from 'react';
import {Form, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {FormElement} from './components/form-element';
import {ErrorTooltip} from './components/error-tooltip';
import {Input} from '@esgi/ui/form-controls';
import {isNull} from 'underscore';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = {
	folderID: number;
	folderType: FolderType;
	onClose: VoidFunction;
};

export function RenameFolder({onClose, folderID, folderType}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [showUnsavedChangesConfirmation, setShowUnsavedChangesConfirmation] = useState(false);

	const [isFolderUpdating, setIsFolderUpdating] = useState(false);
	const [initialFolderName, setInitialFolderName] = useState<string | null>(null);

	const [foldersData, fetchFolders] = FolderManagement.useFetchFolders();
	const [folderData, fetchFolderByID] = FolderManagement.useFetchFolderByID({type: folderType});

	const updateFolder = FolderManagement.useUpdateFolder({type: folderType, onFolderUpdated: closeAlert});

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isFormValid, setIsFormValid] = useState(true);

	const openUnsavedChangesConfirmation = useCallback(() => {
		setShowUnsavedChangesConfirmation(true);
	}, []);

	const closeUnsavedChangesConfirmation = useCallback(() => {
		setShowUnsavedChangesConfirmation(false);
	}, []);

	const onAlertClose = useCallback(() => {
		if (isFormTouched) {
			openUnsavedChangesConfirmation();

			return;
		}

		closeAlert();
	}, [closeAlert, isFormTouched, openUnsavedChangesConfirmation]);

	const form = useForm(
		() =>
			new FormGroup({
				name: new FormControl('', {
					validators: [Validators.required(), Validators.length.max(FolderManagement.folderNameMaxLength)],
				}),
			}),
	);

	useEffect(() => {
		fetchFolders(folderType);
		fetchFolderByID(folderID);
	}, []);

	useEffect(() => {
		if (isAsyncSucceed(foldersData)) {
			const allFolders = foldersData.data.folders;

			const currentFolder = allFolders.find(({id}) => id === folderID);

			if (isUndefined(currentFolder)) {
				throw new Error('Folder is not found');
			}

			const folderName = currentFolder.name;

			setInitialFolderName(folderName);
			form.controls.name.value = folderName;

			form.controls.name.validators.push(
				Validators.isDublicateValue(foldersData.data.folders.filter(({id}) => id !== folderID).map(({name}) => name)),
			);
		}
	}, [foldersData]);

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {name},
			},
		}) => {
			if (!isNull(initialFolderName)) {
				const isFormTouched = name !== initialFolderName;

				setIsFormTouched(isFormTouched);

				form.validate(true).subscribe((validators) => {
					setIsFormValid(!validators.length);
				});
			}
		},
	);

	const isRenameDisable = !isFormTouched || !isFormValid || isFolderUpdating;

	const onFolderRename = () => {
		if (isRenameDisable) {
			return;
		}

		if (isAsyncSucceed(folderData)) {
			setIsFolderUpdating(true);

			updateFolder({
				id: folderID,
				name: form.value.name,
				entityIDs: folderData.data.ids,
			});
		}
	};

	return (
		<>
			<Alert
				modalManagerRef={alertRef}
				css={alertRootCss}
				skeleton={isAsyncPending(foldersData) || isAsyncPending(folderData)}
			>
				<Alert.Header withBacklight={false} onCloseIconClick={onAlertClose}>
					<Text size='medium'>Rename Folder</Text>
				</Alert.Header>

				<AlertBody>
					<Form controller={form}>
						<FormElement control={form.controls.name}>
							<Input.Base placeholder='Folder Name' dataCy='folder-name' />

							{nameFolderErrors.map(({showOnError, message}) => (
								<ErrorTooltip showOnError={showOnError} message={message} key={showOnError} />
							))}
						</FormElement>
					</Form>
				</AlertBody>

				<AlertFooter>
					<GridBox gap='3' flow='column'>
						<Button color='tertiary' onClick={onAlertClose}>
							<Text size='medium' bold color='base'>
								Cancel
							</Text>
						</Button>

						<Button color='secondary' disabled={isRenameDisable} onClick={onFolderRename}>
							<Text size='medium' bold color='base'>
								Rename
							</Text>
						</Button>
					</GridBox>
				</AlertFooter>
			</Alert>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeAlert} />
			)}
		</>
	);
}
