import {FormElement} from '@esgi/ui/form';
import {Input} from '@esgi/ui/form-controls';
import {ErrorTooltip} from '../error-tooltip';
import {GroupNameControl} from './types';
import {errorItems} from './constants';

type Props = {
	groupNameControl: GroupNameControl;
};

export function GroupNameInput({groupNameControl}: Props) {
	return (
		<FormElement control={groupNameControl}>
			<Input.CleanableIndicator placeholder='Group Name' dataCy='group-name-input' />

			{errorItems.map(({showOnError, message}) => (
				<ErrorTooltip showOnError={showOnError} message={message} key={showOnError} />
			))}
		</FormElement>
	);
}
