import {GridApi, ColDef, ColGroupDef} from 'ag-grid-community';
import {isColDef} from '@esgi/ui/ag-grid';
import {isNull} from 'underscore';
import {isNumericColumn} from './is-numeric-column';

type Parameters = {
	api: GridApi;
	columns: (ColDef | ColGroupDef)[];
};

export function getAdaptedColDefs({api, columns}: Parameters): (ColDef | ColGroupDef)[] {
	const passedColsFields = columns
		.map((colDef) => (isColDef(colDef) ? colDef.field?.valueOf() ?? null : null))
		.filter((item): item is string => !isNull(item));

	const lastNonNumberedColumnDef: ColDef | null =
		api
			.getAllDisplayedColumns()
			.map((column) => column.getColDef())
			.filter((colDef): colDef is ColDef => isColDef(colDef))
			.filter((colDef) => {
				const field = colDef.field?.valueOf() ?? null;

				if (isNull(field)) {
					return false;
				}

				return passedColsFields.includes(field) && !isNumericColumn(colDef);
			})
			.at(-1) ?? null;

	return columns.map((colDef) => {
		if (isColDef(colDef)) {
			const {width, minWidth, maxWidth, flex, ...restColDef} = colDef;

			const commonColDef: ColDef = {
				...restColDef,
				resizable: false,
			};

			if (isNumericColumn(colDef)) {
				return {
					...commonColDef,
					width: 92,
				};
			}

			return {
				...commonColDef,
				minWidth: 132,
				maxWidth: 244,
				flex: 1,
				// ...(lastNonNumberedColumnDef?.field === colDef.field ? {flex: 1} : {maxWidth: 244}),
			};
		}

		return colDef;
	});
}
