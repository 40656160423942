import {useColumnDefinitions} from './use-column-definitions';
import {useData} from './use-data';
import {BaseContent} from './variant/base';
import {useCallback, useState} from 'react';
import {GroupDrawer, useDataPageState} from '@esgi/main/features/school-admin/data';
import {Page} from '@esgi/main/features/admins/data';

export default function () {
	const entityName = 'Groups';
	const {ready, rows, classes, teachers} = useData();

	const colDefs = useColumnDefinitions(rows, teachers, classes);
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);
	const [isOpenAddGroupDrawer, setIsOpenAddGroupDrawer] = useState(false);
	const closeAddGroupDrawer = useCallback(() => {
		setIsOpenAddGroupDrawer(false);
	}, []);

	const openAddGroupDrawer = useCallback(() => {
		setIsOpenAddGroupDrawer(true);
	}, []);

	return (
		<>
			<Page.Root
				columnDefs={colDefs}
				entityName={entityName}
				tableRows={rows}
				isDataLoaded={ready && isInitialStateReady}
				initialState={initialState}
				onApplyTableCustomization={onApplyTableCustomization}
			>
				<BaseContent onOpenAddDrawer={openAddGroupDrawer} />
			</Page.Root>

			{isOpenAddGroupDrawer && <GroupDrawer.Add onClose={closeAddGroupDrawer} />}
		</>
	);
}
