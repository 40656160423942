import {useUser} from '@esgi/core/authentication';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useMemo} from 'react';
import {RowDef} from '../../../../../types';

type Parameters = {
	selectedRow: RowDef;
};

export function useHierarchy({selectedRow}: Parameters) {
	const user = useUser();

	return useMemo<Hierarchy>(() => {
		const schoolID = user?.schoolID ?? 0;
		const studentTeacher = selectedRow.teacher;
		const studentClass = selectedRow.class[0];
		const studentGroup = selectedRow.group[0];

		const h: Hierarchy = {
			...(studentTeacher && {
				student: {
					schoolID,
					id: selectedRow.id,
					firstName: selectedRow.firstName,
					lastName: selectedRow.lastName,
					gradeLevelID: selectedRow.gradeLevelID,
					teacherID: studentTeacher.id,
					classesIDs: selectedRow.class.map(({id}) => id),
					groupsIDs: selectedRow.group.map(({id}) => id),
					specialistGroupsIDs: selectedRow.specialistGroups.map(({id}) => id),
				},
				teacher: {
					id: studentTeacher.id,
					firstName: studentTeacher.firstName,
					lastName: studentTeacher.lastName,
					schoolID,
				},
			}),
			...(studentClass && {
				teacherClass: {
					id: studentClass.id,
					name: studentClass.name,
					teacherID: studentClass.teacherID,
				},
			}),
			...(studentGroup && {
				teacherGroup: {
					id: studentGroup.id,
					name: studentGroup.name,
					classID: studentGroup.classID,
				},
			}),
		};

		return h;
	}, [selectedRow, user]);
}
