import {FolderNoSelectedItemsPanel} from '../../../../_kit';
import {RowDef} from '../../../types';
import {useDataPageContext} from '../../../../layout/context';
import {useCallback, useMemo, useState} from 'react';
import {
	AddEntitiesIntoFolder,
	Folder,
	FolderType,
	RemoveEntitiesFromFolder,
	RemoveFolder,
	Page,
} from '@esgi/main/features/admins/data';
import {addButtonDisabledTooltipMessage} from '../../constants';
import {BottomPanel} from './bottom-panel';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';
import {folderReports} from './constants';

type Props = {
	allFolderRows: RowDef[];
	activeFolder: Folder;
	userCanAddAndDeleteTeachers: boolean;
	onOpenAddDrawer: VoidFunction;
};

export function Content({activeFolder, allFolderRows, userCanAddAndDeleteTeachers, onOpenAddDrawer}: Props) {
	const {onDeselectFolder, entityFolders} = useDataPageContext();
	const {selectedItems, isDataLoaded} = Page.useContext<RowDef>();

	const [showAddEntitiesIntoFolder, setShowAddEntitiesIntoFolder] = useState(false);
	const [showRemoveEntitiesFromFolder, setShowRemoveEntitiesFromFolder] = useState(false);

	const openAddEntitiesIntoFolder = useCallback(() => {
		setShowAddEntitiesIntoFolder(true);
	}, []);

	const closeAddEntitiesIntoFolder = useCallback(() => {
		setShowAddEntitiesIntoFolder(false);
	}, []);

	const openRemoveEntitiesFromFolder = useCallback(() => {
		setShowRemoveEntitiesFromFolder(true);
	}, []);

	const closeRemoveEntitiesFromFolder = useCallback(() => {
		setShowRemoveEntitiesFromFolder(false);
	}, []);

	const studentsCountInFolder = useMemo(
		() => allFolderRows.reduce((currentCount, {students}) => currentCount + students, 0),
		[allFolderRows],
	);

	return (
		<>
			<Page.Header.Base
				onAddButtonClick={onOpenAddDrawer}
				addButtonDisabled={!userCanAddAndDeleteTeachers}
				addButtonDisabledTooltipMessage={addButtonDisabledTooltipMessage}
			/>
			<Page.SubHeader.Folder onClose={onDeselectFolder} folderName={activeFolder.name} />
			<Page.Body.Base />

			<FolderNoSelectedItemsPanel
				folderID={activeFolder.id}
				folderName={activeFolder.name}
				isShowPanel={isDataLoaded && !selectedItems.length}
				folderType={FolderType.Teacher}
				reports={folderReports}
				rowsCountInFolder={activeFolder.count}
				studentsCountInFolder={studentsCountInFolder}
			/>

			<SingleItemExpandablePanel
				activeFolder={activeFolder}
				onFolderAddClick={openAddEntitiesIntoFolder}
				onRemoveFromFolderClick={openRemoveEntitiesFromFolder}
				userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
				onDeselectFolder={onDeselectFolder}
			/>

			<BottomPanel
				onFolderAddClick={openAddEntitiesIntoFolder}
				onRemoveFromFolderClick={openRemoveEntitiesFromFolder}
			/>

			{showAddEntitiesIntoFolder && (
				<AddEntitiesIntoFolder
					addEntityIDs={selectedItems.map(({id}) => id)}
					folders={(entityFolders ?? []).map(({id, name}) => ({id, name}))}
					folderType={activeFolder.type}
					initialFolderID={activeFolder.id}
					onCloseAlert={closeAddEntitiesIntoFolder}
				/>
			)}

			{showRemoveEntitiesFromFolder &&
				(allFolderRows.length !== selectedItems.length ? (
					<RemoveEntitiesFromFolder
						allEntityIDs={allFolderRows.map(({id}) => id)}
						potentialRemovedEntityIDs={selectedItems.map(({id}) => id)}
						folderID={activeFolder.id}
						folderType={activeFolder.type}
						folderName={activeFolder.name}
						onClose={closeRemoveEntitiesFromFolder}
					/>
				) : (
					<RemoveFolder
						folderID={activeFolder.id}
						folderName={activeFolder.name}
						folderType={activeFolder.type}
						onClose={closeRemoveEntitiesFromFolder}
						onFolderRemoved={onDeselectFolder}
					/>
				))}
		</>
	);
}
