import {HierarchySnapshot} from 'modules/hierarchy/core/models';

export class GrowthReportOptions {
	districtId: number;
	globalSchoolYearId: number;
	subjectType: string;
	subjectId: number;
	trackId: number;
	showNumberOfStudents: string;
	hierarchy: HierarchySnapshot;
}

export class Request extends GrowthReportOptions {
	cacheKey: string;
	sortModel: SortModel;
}

export class SortModel {
	fieldName: string;
	direction: string;
}

export interface DataRowResultElement {
	firstPercent: number;
	growthPercent: number;
	numberOfTestedStudents: number;
	periodsPercent: (number | null)[];
}

export interface DataRow {
	averageOfGrowthScores: number;
	firstName: string;
	lastName: string;
	numberOfStudents: number;
	results: DataRowResultElement[];
	schoolName: string
	testedAverageOfGrowthScores: number;
}

export interface InitResponse {
	cacheKey: string;
	isTruncated: boolean;
	report: Report;
	showNumberOfStudents: boolean;
	sortModel: SortModel;
	tracks: Track[];

}

export interface Report {
	cacheKey: string;
	currentNumberPeriods: number;
	date: string;
	districtID: number;
	districtName: string;
	subjectName: string;
	rows: DataRow[];
	sortRules: SortRule[];
	tests: Test[];
	track: Track;
	tracks: Track[];
}

export interface Track {
	trackID: number;
	name: string;
	dates: TrackDates[];
}

export interface TrackDates {
	dateFrom: string;
	dateTo: string;
}

export interface SortRule {
	fieldName: string;
	relatedSorts: SortModel[];
}

export interface Test {
	testID: number;
	questionsCount: number;
	testName: string;
}

export type Subject = {
	id: number;
	type: string;
	name: string;
}