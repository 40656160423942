import {V2SchoolAdminsActionPanelController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {groupsStore, Student, studentsStore, useGroups, useStudents} from '@esgi/main/libs/school-admin-store';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {RemoveTeacherGroupEvent} from '../../events';

type Out = [boolean, Dispatch<OnRemoveParameters>, boolean];

type OnRemoveParameters = {
	id: number;
	name: string;
	onRemoved: VoidFunction;
};

export function useRemoveTeacherGroup(): Out {
	const [{loaded: isGroupsLoaded}] = useGroups();
	const [{loaded: isStudentsLoaded}] = useStudents();

	const controller = useDisposable(V2SchoolAdminsActionPanelController);
	const [response, request] = useCancelableRequest(controller.groupsDelete);

	const [isRemoving, setIsRemoving] = useState(false);
	const [requestParameters, setRequestParameters] = useState<OnRemoveParameters | null>(null);

	useEffect(() => {
		if (isAsyncSucceed(response) && !isNull(requestParameters)) {
			groupsStore().removeById?.(requestParameters.id);

			studentsStore().update?.((item: Student): Student => {
				if (item.groupsIDs.includes(requestParameters.id)) {
					return {
						...item,
						groupsIDs: item.groupsIDs.filter((id) => id !== requestParameters.id),
					};
				}

				return item;
			});

			dispatchAppEvent(
				RemoveTeacherGroupEvent,
				new RemoveTeacherGroupEvent({
					id: requestParameters.id,
					name: requestParameters.name,
				}),
			);

			requestParameters.onRemoved();

			setRequestParameters(null);
			setIsRemoving(false);
		}
	}, [requestParameters, response]);

	const onRemove = useCallback(
		({id, name, onRemoved}: OnRemoveParameters) => {
			setIsRemoving(true);

			request({
				iDs: [id],
			});

			setRequestParameters({
				id,
				name,
				onRemoved,
			});
		},
		[request],
	);

	const isDataLoaded = isGroupsLoaded && isStudentsLoaded;

	return [isDataLoaded, onRemove, isAsyncPending(response) || isRemoving];
}
