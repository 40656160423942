import {ReportDialog as Component} from './component';
import {ReportSection} from './reports-section';
import {Root as TabsRoot} from './tabs/components/root';
import {Bar as TabsBar} from './tabs/components/bar';
import {TabContent} from './tabs/components/tab-content';
import {Title as TabsTitle} from './tabs/components/title';

export const ReportDialog = Object.assign(Component, {
	Section: ReportSection,
	Tabs: {
		Root: TabsRoot,
		Title: TabsTitle,
		Bar: TabsBar,
		Content: TabContent,
	},
});

export {Reports} from './reports';
