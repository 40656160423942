import {useMemo} from 'react';
import {RowDef} from '../../../../../types';
import {QuickPanel} from './quick-panel';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useSelectedSchoolData} from '../../../../use-selected-school-data';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {TestResults} from '@esgi/main/kits/admin';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
};

export function ContentWrapper({isPanelExpanded, onFolderAddClick, selectedRow}: Props) {
	const {testResultsAdditionalInfo, reportSubject, skeleton, students, subjects} = useSelectedSchoolData({selectedRow});

	const hierarchy = useMemo<Hierarchy>(() => {
		const h: Hierarchy = {
			schoolID: selectedRow.id,
		};

		return h;
	}, [selectedRow]);

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={skeleton}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={skeleton}
					allSubjects={subjects}
					students={students}
					hierarchy={hierarchy}
					additionalInfo={testResultsAdditionalInfo}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
