import {Tooltip} from '@esgi/ui';
import {Counter} from './counter.styled';
import {NavigationItem as Item} from './navigation-item.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	name: string;
	count: number | null;
	isActive: boolean;
	onClick: VoidFunction;
};

export function NavigationItem({name, count, isActive, onClick}: Props) {
	return (
		<Tooltip>
			<Tooltip.Trigger>
				<Item active={isActive} dataCy={`navigation-item-district-admin-data-${name.toLowerCase()}`} onClick={onClick}>
					<Text size='large' color='currentColor'>
						{name}
					</Text>
					<Counter dataCy='counter'>
						<Text size='small' font='mono' color='currentColor'>
							{count ?? '-'}
						</Text>
					</Counter>
				</Item>
			</Tooltip.Trigger>
			<Tooltip.Content variant='default'>
				<Text size='medium' font='mono'>
					{name}
				</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
