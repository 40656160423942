import {useMemo} from 'react';
import {RowDef} from '../../../../../types';
import {ExpandedPanel} from './expanded-panel';
import {QuickPanel} from './quick-panel';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {TestResultsStudent} from '@esgi/main/kits/admin';
import {isNull} from 'underscore';
import {useTestResultsSubject} from '../../../../../../_kit';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
};

export function ContentWrapper({isPanelExpanded, selectedRow}: Props) {
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({classesIDs}) => classesIDs.includes(selectedRow.id))
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {
		isReady: isTestResultsSubjectReady,
		subjects: testResultsSubjects,
		reportSubject,
	} = useTestResultsSubject({
		...(!isNull(selectedRow.teacher) && {
			userID: selectedRow.teacher.id,
			additionalStoreSubjectsFilter: 'checkOnAllowedToAllTeachers',
		}),
		students: filteredStudents,
	});

	const hierarchy = useMemo<Hierarchy>(() => {
		const classTeacher = selectedRow.teacher;

		const h: Hierarchy = {
			...(classTeacher && {
				teacher: {
					id: classTeacher.id,
					firstName: classTeacher.firstName,
					lastName: classTeacher.lastName,
					schoolID: classTeacher.schoolID,
				},
				teacherClass: {
					id: selectedRow.id,
					name: selectedRow.name,
					teacherID: classTeacher.id,
				},
			}),
			schoolID: selectedRow.teacher?.schoolID ?? 0,
		};

		return h;
	}, [selectedRow]);

	const skeleton = !isTestResultsSubjectReady || !isStudentsLoaded;

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={skeleton}
			/>

			<ExpandedPanel
				selectedRow={selectedRow}
				hierarchy={hierarchy}
				skeleton={skeleton}
				students={filteredStudents}
				subjects={testResultsSubjects}
			/>
		</>
	);
}
