import {Box} from '@esgi/ui/layout';
import {useTabsContext} from '../context';
import {ComponentPropsWithoutRef, ForwardedRef, forwardRef, useEffect} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {TabInfo} from '../types';

type Props<Tab extends string> = BaseComponentProps & ComponentPropsWithoutRef<typeof Box> & TabInfo<Tab>;

function TabContentInner<Tab extends string>(
	{dataCy = 'tab-content', css = {}, tabID, tabContent, children, ...boxProps}: Props<Tab>,
	ref: ForwardedRef<HTMLDivElement>,
) {
	const {addTab, removeTab, updateTab, activeTab} = useTabsContext<Tab>();

	useEffect(() => {
		addTab({
			tabID,
			tabContent,
		});

		return () => removeTab(tabID);
	}, []);

	useEffect(() => {
		updateTab({
			tabID,
			data: {
				tabContent,
			},
		});
	}, [tabContent, tabID, updateTab]);

	if (activeTab !== tabID) {
		return null;
	}

	return (
		<Box dataCy={dataCy} css={css} ref={ref} {...boxProps}>
			{children}
		</Box>
	);
}

export const TabContent = forwardRef(TabContentInner);
