import {useDatePickerWidget} from './use-date-picker-widget';
import {DatePickerData} from '../types';
import {useMemo} from 'react';

type Parameters = {
	initialData: [DatePickerData | null, DatePickerData | null];
};

export function useDatePickerWidgetMultiple({initialData: [w1, w2]}: Parameters) {
	const {
		isTouched: isFirstWidgetTouched,
		isValid: isFirstWidgetValid,
		...firstWidget
	} = useDatePickerWidget({
		value: w1,
	});

	const {
		isTouched: isSecondWidgetTouched,
		isValid: isSecondWidgetValid,
		...secondWidget
	} = useDatePickerWidget({
		value: w2,
	});

	return useMemo(
		() => ({
			firstWidget,
			secondWidget,
			isDatesTouched: isFirstWidgetTouched || isSecondWidgetTouched,
			isDatesValid: isFirstWidgetValid && isSecondWidgetValid,
		}),
		[firstWidget, isFirstWidgetTouched, isFirstWidgetValid, isSecondWidgetTouched, isSecondWidgetValid, secondWidget],
	);
}
