import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {SubjectType} from '@esgi/core/enums';
import {useEventEffect} from '@esgillc/events';
import {ViewParentLetterEvent} from 'modules/reports/parent-letter/events';
import {InitModel} from 'modules/reports/parent-letter/models';
import {useEffect, useMemo, useState} from 'react';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import ParentLetterLauncher from 'modules/reports/parent-letter/launcher';
import {isNull} from 'underscore';

type Props = {
	hierarchy: HierarchyInstance;
	subjectID: number;
	subjectType: SubjectType;
	open: boolean;
	onOpen: VoidFunction;
	onClose: VoidFunction;
};

export function ParentLetter({hierarchy, open, onOpen, onClose, subjectID, subjectType}: Props) {
	const [selectedItems, setSelectedItems] = useState<InitModel | null>(null);

	const changesCollector = useMemo(
		() =>
			new ChangesCollector(
				{
					id: subjectID,
					type: subjectType,
				},
				hierarchy.snapshot,
			),
		[subjectID, subjectType, hierarchy],
	);

	useEffect(() => {
		setSelectedItems((currentState) =>
			open && isNull(currentState)
				? new InitModel(
						changesCollector.groupID.initialValue,
						changesCollector.studentID.initialValue,
						subjectID,
						subjectType,
					)
				: currentState,
		);
	}, [changesCollector, open, selectedItems, subjectID, subjectType]);

	useEventEffect(ViewParentLetterEvent, (args) => {
		const {selectedGroupItemID, selectedStudentID, subjectType, subjectID} = args.initModel;
		setSelectedItems(new InitModel(selectedGroupItemID, selectedStudentID, subjectID, subjectType));
		onOpen();
	});

	function handleReportClose() {
		setSelectedItems(null);
		onClose();
	}

	if (!isNull(selectedItems)) {
		return (
			<ParentLetterLauncher
				initModel={selectedItems}
				changesCollector={changesCollector}
				onClosed={handleReportClose}
				hierarchy={hierarchy.snapshot}
			/>
		);
	}

	return null;
}
