import {RefObject} from 'react';
import {BehaviorSubject, from} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {getUser} from '@esgi/core/authentication';
import {ElementStatus} from '@esgillc/ui-kit/form';
import {createTeacherForm} from '../../../../../../forms/location/teacher';
import {BaseTabService} from '../../../base-tab-service';
import {
	ClassesResponse,
	ClassesResponseItem,
	ClassItem,
	GroupsItem,
	PreSelected, ProfileInitData,
	StudentProfileMode,
	StudentProfileTab,
	TabsApi,
} from 'modules/forms/students-form/types';
import {ObservableBuilder} from '@esgi/api';
import {StudentChangedEvent, StudentSaveAndAddEvent} from 'modules/forms/students-form/events';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';

class LocationChangedEvent {
	constructor(
		private studentID: number,
		public groupIDs: number[],
		public classIDs: number[],
		public globalSchoolYearID: number,
	) {
	}
}

interface InitArgs {
	initData: ProfileInitData,
	mode: StudentProfileMode,
	studentID: number,
	preSelected: PreSelected | null,
	tabsApi: RefObject<TabsApi>
}

export class TeacherService extends BaseTabService {
	public preSelected = new BehaviorSubject<PreSelected>(null);
	public classes = new BehaviorSubject<ClassItem[]>(null);
	public groups = new BehaviorSubject<(GroupsItem & { isDisabled?: boolean })[]>(null);
	public selectedClasses = new BehaviorSubject<ClassItem[]>([]);

	public form = createTeacherForm();

	protected tab = StudentProfileTab.Location;
	private currentUser = getUser();

	public init({initData, mode, studentID, preSelected, tabsApi}: InitArgs) {
		this.studentID = studentID;
		this.preSelected.next(preSelected);
		this.initData.next(initData);

		this.form.value = {
			classIDs: initData?.location?.classIDs || [],
			groupIDs: initData?.location?.groupIDs || [],
		};
		if (mode !== StudentProfileMode.view) {
			this.form.controls.classIDs.onChanged.subscribe((v) => {
				this.disableGroups();

				if (v.currState.value) {
					this.selectedClasses.next(v.currState.value.map(id => this.classes.value?.find(cl => id === cl.id)));
				} else {
					this.selectedClasses.next([]);
				}
			});
			this.initTabsApi(tabsApi);
		} else {
			this.form.status = ElementStatus.disabled;
		}

		return this.setClassesAndGroups();
	}

	public save = (isSaveAndAdd?: boolean) => {
		const {
			groupIDs,
			classIDs,
		} = this.form.value;

		const eventModel = new LocationChangedEvent(
			this.studentID,
			groupIDs.filter(group => !this.groups.value.find(item => Number(item.id) === group)?.isDisabled),
			classIDs,
			this.currentUser.globalSchoolYearID);

		const model = {
			studentID: this.studentID,
			groupIDs: groupIDs.filter(group => !this.groups.value.find(item => Number(item.id) === group)?.isDisabled),
			classIDs,
			globalSchoolYearID: this.currentUser.globalSchoolYearID,
			teacherID: this.initData.value?.location?.teacherID || this.currentUser.userID,
			schoolID: this.initData.value?.location?.schoolID,
			specialistGroupIDs: this.initData.value?.location?.specialistGroupIDs,
			specialistID: this.initData.value?.location?.specialistID,
		};

		return this.form.validate().pipe(switchMap(v => {
			if (v.valid) {
				return this.httpClient.ESGIApi.post(this.controller, 'profile/location/save', model)
					.pipe(tap(() => {
						if(!model.classIDs.length && this.initData.value.location.classIDs.length) {
							showSnackbarNotification('You have removed this student from all classes.');
						}
						this.initData.next({...this.initData.value, location: {
							...this.initData.value.location,
							classIDs, groupIDs,
						}});
						const {
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
						} = this.initData.value.general;
						const event = new StudentChangedEvent(
							this.studentID,
							firstName,
							lastName,
							gender,
							gradeLevelID,
							languageID,
							studentIDN,
							this.initData.value?.location?.schoolID,
							this.initData.value?.location?.teacherID || this.currentUser.userID,
							model.classIDs,
							model.groupIDs,
							null,
						);
						this.eventBus.dispatch(StudentChangedEvent, event);
						this.eventBus.dispatch(StudentSaveAndAddEvent, new StudentSaveAndAddEvent(isSaveAndAdd));
					}));
			}
			return from([]);
		}));
	};

	private getClasses = (userID?: number) => {
		return this.httpClient.ESGIApi
			.get<ClassesResponse>(this.controller,
				`location/classes?userID=${userID}&globalSchoolYearId=${this.currentUser.globalSchoolYearID}`);
	};


	private setClassesAndGroups = (selectedTeacherID?: number): ObservableBuilder<ClassesResponse> => {
		this.serviceLoading.next(true);

		const userID = selectedTeacherID || this.initData?.value.location?.teacherID || this.currentUser.userID;
		return this.getClasses(userID).pipe(tap(res => {
			this.classes.next(res.classes.map(item => ({id: item.classID, name: item.name, teacherID: userID})));
			this.groups.next(res.classes.reduce((acc: GroupsItem[], item: ClassesResponseItem) => {
				return [...acc, ...item.groups.map(item => ({
					id: item.groupID,
					name: item.name,
					classID: item.classID,
				}))];
			}, []));
			this.disableGroups();
		}), tap({
			complete: () => {
				this.selectedClasses.next(this.form.controls.classIDs.value.map(id => this.classes.value?.find(cl => id === cl.id)));
				this.serviceLoading.next(false);
			},
		}));
	};

	private disableGroups = () => {
		if (this.groups.value) {
			this.groups.next(this.groups.value.map(group => ({
				...group,
				isDisabled: !this.form.controls.classIDs.value.includes(Number(group.classID)),
			})));
		}
	};
}


