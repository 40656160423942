import {useEffect, useMemo} from 'react';
import {unique} from 'underscore';

import {useSchools, useTeachers, useGroups, useClasses, Class, User, School} from '@esgi/main/libs/admin-store';
import {useDisposable} from '@esgi/core/service';
import {V2DistrictAdminsPagesDataAnalyticsController} from '@esgi/contracts/esgi';
import {RowDef} from './types';
import {isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';

export type Out = {
	ready: boolean;
	rows: RowDef[];
	schools: School[];
	classes: Class[];
	teachers: User[];
};

export function useData() {
	const [{data: allSchools, loaded: isAllSchoolsLoaded}] = useSchools();
	const [{data: allTeachers, loaded: isAllTeachersLoaded}] = useTeachers();
	const [{data: groups, loaded: groupsLoaded}] = useGroups();
	const [{data: allClasses, loaded: isAllClasses}] = useClasses();

	const analyticController = useDisposable(V2DistrictAdminsPagesDataAnalyticsController);

	const [analyticsData, fetchAnalyticsData] = useCancelableRequest(analyticController.groups);

	useEffect(() => {
		if (groupsLoaded) {
			fetchAnalyticsData({groupIDs: groups.map((g) => g.id)});
		}
	}, [fetchAnalyticsData, groupsLoaded, groups]);

	return useMemo<Out>(() => {
		const isDataLoaded =
			isAllSchoolsLoaded && isAllTeachersLoaded && groupsLoaded && isAllClasses && isAsyncSucceed(analyticsData);

		if (!isDataLoaded) {
			return {
				ready: false,
				classes: [],
				rows: [],
				schools: [],
				teachers: [],
			};
		}

		const rows = groups.map<RowDef>((group) => {
			const teacher = allTeachers.find((t) => t.id === group.teacherID);
			const school = allSchools.find((s) => s.id === group.schoolID);
			const classEntity = allClasses.find((c) => c.id === group.classID);
			const analytic = analyticsData.data.value.groupsAnalytics.find((a) => a.groupID === group.id);

			if (isUndefined(school)) {
				throw new Error('school is undefined');
			}

			if (isUndefined(teacher)) {
				throw new Error('teacher is undefined');
			}

			if (isUndefined(classEntity)) {
				throw new Error('classEntity is undefined');
			}

			return {
				id: group.id,
				name: group.name,
				school,
				teacher,
				class: classEntity,
				students: analytic?.studentsCount ?? 0,
			};
		});
		return {
			ready: true,
			rows,
			classes: unique(rows.map((r) => r.class)),
			schools: unique(rows.map((r) => r.school)),
			teachers: unique(rows.map((r) => r.teacher)),
		};
	}, [
		allClasses,
		allSchools,
		allTeachers,
		analyticsData,
		groups,
		groupsLoaded,
		isAllClasses,
		isAllSchoolsLoaded,
		isAllTeachersLoaded,
	]);
}
