import {RowDef} from '../../../../../types';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {getFullName} from '@esgi/main/kits/common';

type Props = {
	student: RowDef;
	onClose: VoidFunction;
	hierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function ReportsFeature({student, onClose, hierarchy, subject}: Props) {
	return (
		<ReportDialog
			header={getFullName({firstName: student.firstName, lastName: student.lastName})}
			onClose={onClose}
			subject={subject}
		>
			<ReportDialog.Section title='Student Reports'>
				<Reports.ParentLetter hierarchy={hierarchy} />
				<Reports.StudentDetail hierarchy={hierarchy} />
				<Reports.StudentProgress hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
