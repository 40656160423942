import {FolderAdd, PieChartIcon, Teacher} from '@esgi/ui/icons';
import {ActionPanel, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {useNavigateToSpecialistsGroupsPage, useNavigateToStudentsPage} from '@esgi/main/features/school-admin/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {getFullName} from '@esgi/main/kits/common';
import {ReportsFeature} from '../../../../../../components/reports';
import {useCallback} from 'react';
import {AddToFolderButton, ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	hierarchy: Hierarchy;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	hierarchy,
	reportSubject,
	skeleton,
}: Props) {
	const goToSpecialistsGroupsPage = useNavigateToSpecialistsGroupsPage();
	const goToStudentsPage = useNavigateToStudentsPage();

	const navigateToSpecialistGroups = useCallback(() => {
		goToSpecialistsGroupsPage({
			specialist: [selectedRow.id],
		});
	}, [goToSpecialistsGroupsPage, selectedRow]);

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			specialist: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	return (
		<SingleSelectedExpandablePanel.QuickPanel>
			<SingleSelectedExpandablePanel.QuickPanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<Teacher />
						<Text size='large' color='highContrast'>
							{getFullName({
								firstName: selectedRow.firstName,
								lastName: selectedRow.lastName,
							})}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<AddToFolderButton onClick={onFolderAddClick}>
							<FolderAdd />
						</AddToFolderButton>
						<ActionPanel.ActionButtonDivider />

						<RunReportButton
							subject={reportSubject}
							disabled={!selectedRow.students}
							disabledMessage={
								selectedRow.students
									? null
									: 'No students have been added to your class. Please add some before running the report.'
							}
							skeleton={skeleton}
						>
							{({onReportsClose, subject}) => (
								<ReportsFeature
									onClose={onReportsClose}
									specialist={selectedRow}
									hierarchy={hierarchy}

									subject={subject}
								/>
							)}
						</RunReportButton>
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</SingleSelectedExpandablePanel.QuickPanel.Header>

			{!isPanelExpanded && (
				<AdditionalHeaderContent>
					<ActionPanel.Navigation.Container>
						<ActionPanel.Navigation.Card
							onClick={navigateToSpecialistGroups}
							title='Specialist Groups'
							count={selectedRow.specialistGroups}
						/>
						<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
					</ActionPanel.Navigation.Container>

					<SingleSelectedExpandablePanel.OpenPanelButton
						Icon={PieChartIcon}
						transcript='View Tests Result'
						warningDescription='No students have been added to your specialist. Please add some before running the report.'
						canExpandPanel={!!selectedRow.students}
					/>
				</AdditionalHeaderContent>
			)}
		</SingleSelectedExpandablePanel.QuickPanel>
	);
}
