import {FolderAdd, FolderRemove, School} from '@esgi/ui/icons';
import {ActionPanel, NegativeButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button} from '@esgi/ui';
import {ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';
import {SchoolReportsDialog} from '../../../../../../components/school-reports-dialog';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	isPanelExpanded: boolean;
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	hierarchy: Hierarchy;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	onRemoveFromFolderClick,
	hierarchy,
	reportSubject,
	skeleton,
}: Props) {
	return (
		<SingleSelectedExpandablePanel.QuickPanel>
			<SingleSelectedExpandablePanel.QuickPanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<School />
						<Text size='large' color='highContrast' data-cy='selected-item-name'>
							{selectedRow.name}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<Button.Icon withBackgroundHover onClick={onFolderAddClick}>
							<FolderAdd />
						</Button.Icon>

						<NegativeButton onClick={onRemoveFromFolderClick}>
							<FolderRemove />
						</NegativeButton>

						<RunReportButton skeleton={skeleton} subject={reportSubject}>
							{({onReportsClose, subject}) => (
								<SchoolReportsDialog
									school={selectedRow}
									onClose={onReportsClose}
									baseHierarchy={hierarchy}
									subject={subject}
								/>
							)}
						</RunReportButton>
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</SingleSelectedExpandablePanel.QuickPanel.Header>

			{!isPanelExpanded && (
				<AdditionalHeaderContent>
					<OpenPanelButton canExpandPanel={!!selectedRow.students} />
				</AdditionalHeaderContent>
			)}
		</SingleSelectedExpandablePanel.QuickPanel>
	);
}
