import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {SubjectType} from '@esgi/core/enums';
import {useEventEffect} from '@esgillc/events';
import {ViewFlashCardsSettingsEvent} from 'modules/reports/parent-letter/events';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {useEffect, useState} from 'react';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import {FlashCardsWrapper} from 'shared/modules/reports/flash-cards/settings-form/wrapper';
import {isNull, noop} from 'underscore';

type Props = {
	hierarchy: HierarchyInstance;
	subjectID: number;
	subjectType: SubjectType;
	open: boolean;
	onOpen: VoidFunction;
	onClose: VoidFunction;
};

export function FlashCards({hierarchy, onOpen, onClose, open, subjectID, subjectType}: Props) {
	const [initModel, setInitModel] = useState<Models.InitModel | null>(null);
	const [changesCollector, setChangesCollector] = useState<ChangesCollector | null>(null);

	useEffect(() => {
		if (open) {
			setChangesCollector(
				new ChangesCollector(
					{
						id: subjectID,
						type: subjectType,
					},
					hierarchy.snapshot,
				),
			);

			return;
		}

		setChangesCollector(null);
	}, [hierarchy, open, subjectID, subjectType]);

	useEventEffect(ViewFlashCardsSettingsEvent, (args) => {
		setInitModel(args.initModel);
		onOpen();
	});

	function handleReportClose() {
		setInitModel(null);
		setChangesCollector(null);
		onClose();
	}

	if (!isNull(changesCollector)) {
		return (
			<FlashCardsWrapper
				hierarchySnapshot={hierarchy.snapshot}
				initModel={initModel}
				changesCollector={changesCollector}
				parentLetterOnClosed={noop}
				onCloseClicked={handleReportClose}
				subjectID={subjectID}
				subjectType={subjectType}
			/>
		);
	}

	return null;
}
