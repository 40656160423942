import {ToggleGroup} from '@esgi/ui/controls';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const Content = styled(ToggleGroup.Content, {
	backgroundColor: '$background',

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
			},
		},
	},
});

export const Item = styled(ToggleGroup.Item, {
	paddingTop: 9,
	paddingBottom: 9,
});
