import {Edit, Expand} from '@esgi/ui/icons';
import {ActionPanel, DeleteButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button} from '@esgi/ui';
import {Dispatch, useCallback, useMemo} from 'react';
import {
	AdditionalHeaderContent,
	AdditionalInfo,
	AvatarRoot,
	HeaderContent,
	IconButtonsBox,
	StudentInfoBox,
} from './index.styled';
import {Avatar} from '@esgi/ui/avatar';
import {useUser} from '@esgi/core/authentication';
import {getFullName, getInitials, OneLinedText} from '@esgi/main/kits/common';
import {TitleWithTranscription} from '../title-with-transcription';
import {ReportsFeature} from '../reports-dialog';
import {isNull, noop} from 'underscore';
import {ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RemoveStudent} from '@esgi/main/features/school-admin/common';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	onEditClicked: Dispatch<number>;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	hierarchy: Hierarchy;
};

export function QuickPanel({selectedRow, isPanelExpanded, onEditClicked, hierarchy, reportSubject, skeleton}: Props) {
	const currentUser = useUser();

	const removeStudentManager = RemoveStudent.useManagerRef();

	const reportDisableReason = useMemo(() => {
		if (!selectedRow.teacher && !selectedRow.specialist.length) {
			return 'The student is not assigned to any user';
		}

		return null;
	}, [selectedRow]);

	const handleEditClick = useCallback(() => {
		onEditClicked(selectedRow.id);
	}, [onEditClicked, selectedRow]);

	const onOpenRemoveStudent = useCallback(() => {
		removeStudentManager.current?.runRemove({
			studentID: selectedRow.id,
		});
	}, [removeStudentManager, selectedRow]);

	const studentInitials = useMemo(() => {
		return getInitials({
			firstName: selectedRow.firstName,
			lastName: selectedRow.lastName,
		});
	}, [selectedRow]);

	const studentFullName = useMemo(() => {
		return getFullName({
			firstName: selectedRow.firstName,
			lastName: selectedRow.lastName,
		});
	}, [selectedRow]);

	const teacherFullName = useMemo(() => {
		if (!selectedRow.teacher) {
			return '-';
		}
		return getFullName({
			firstName: selectedRow.teacher.firstName,
			lastName: selectedRow.teacher.lastName,
		});
	}, [selectedRow]);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<StudentInfoBox>
							<AvatarRoot size='sm'>
								<Avatar.Image src={undefined} />
								<Avatar.Fallback>
									<Text color='primary' size='large'>
										{studentInitials}
									</Text>
								</Avatar.Fallback>
							</AvatarRoot>
							<OneLinedText size='large' color='highContrast'>
								{studentFullName}
							</OneLinedText>

							<AdditionalInfo>
								<TitleWithTranscription title='Teacher' transcript={teacherFullName} />
							</AdditionalInfo>
						</StudentInfoBox>

						<ActionPanel.ActionButtonsBox>
							<IconButtonsBox>
								<DeleteButton
									whenShowTooltip='disabled'
									tooltipMessage='To delete a student, please contact our Customer Support team at support@esgisoftware.com.'
									disabled={!currentUser?.canEditStudents}
									onClick={onOpenRemoveStudent}
									withBackgroundHover
								/>
								<ActionPanel.ActionButtonDivider />

								<Button.Icon withBackgroundHover onClick={handleEditClick}>
									<Edit />
								</Button.Icon>
							</IconButtonsBox>

							<RunReportButton disabledMessage={reportDisableReason} skeleton={skeleton} subject={reportSubject}>
								{({onReportsClose, subject}) => (
									<ReportsFeature
										onClose={onReportsClose}
										student={selectedRow}
										hierarchy={hierarchy}
										subject={subject}
									/>
								)}
							</RunReportButton>
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<SingleSelectedExpandablePanel.OpenPanelButton
							Icon={Expand}
							transcript='View Student’s Tests'
							disabled={isNull(selectedRow.teacher) && !selectedRow.specialist.length}
						/>
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			<RemoveStudent managerRef={removeStudentManager} onRemoved={noop} />
		</>
	);
}
