import {Edit, PieChartIcon, StudentsList} from '@esgi/ui/icons';
import {ActionPanel, DeleteButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button, Portal, useFlag} from '@esgi/ui';
import {ClassDrawer, useNavigateToGroupsPage, useNavigateToStudentsPage} from '@esgi/main/features/school-admin/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {useDataPageContext} from '../../../../../../../layout/context';
import {ClassesReportsDialog} from '../reports-dialog';
import {useCallback} from 'react';
import {ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RemoveTeacherClass} from '@esgi/main/features/school-admin/common';
import {noop} from 'underscore';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	hierarchy: Hierarchy;
};

export function QuickPanel({selectedRow, isPanelExpanded, hierarchy, reportSubject, skeleton}: Props) {
	const {rootPageContentContainerRef} = useDataPageContext();

	const removeTeacherClassManager = RemoveTeacherClass.useManagerRef();

	const [showEdit, openEdit, closeEdit] = useFlag();

	const goToGroupsPage = useNavigateToGroupsPage();
	const goToStudentsPage = useNavigateToStudentsPage();

	const navigateToGroups = useCallback(() => {
		goToGroupsPage({
			class: [selectedRow.id],
		});
	}, [goToGroupsPage, selectedRow]);

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			class: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	const handleOpenRemoveEntity = useCallback(() => {
		removeTeacherClassManager.current?.runRemove({
			teacherClassID: selectedRow.id,
		});
	}, [removeTeacherClassManager, selectedRow]);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<StudentsList />
							<Text size='large' color='highContrast'>
								{selectedRow.name}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteButton onClick={handleOpenRemoveEntity} />
							<ActionPanel.ActionButtonDivider />

							<Button.Icon onClick={openEdit}>
								<Edit />
							</Button.Icon>

							<RunReportButton
								disabled={!selectedRow.students}
								disabledMessage={
									selectedRow.students
										? null
										: 'No students have been added to your class. Please add some before running the report.'
								}
								subject={reportSubject}
								skeleton={skeleton}
							>
								{({onReportsClose, subject}) => (
									<ClassesReportsDialog
										onClose={onReportsClose}
										selectedClass={selectedRow}
										hierarchy={hierarchy}
										subject={subject}
									/>
								)}
							</RunReportButton>
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<ActionPanel.Navigation.Container>
							<ActionPanel.Navigation.Card
								onClick={navigateToGroups}
								title='Groups'
								count={selectedRow.groups.length}
							/>
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
						</ActionPanel.Navigation.Container>

						<SingleSelectedExpandablePanel.OpenPanelButton
							Icon={PieChartIcon}
							transcript='View Tests Results'
							warningDescription='No students have been added to your class. Please add some before running the report.'
							canExpandPanel={!!selectedRow.students}
						/>
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showEdit && (
				<Portal container={rootPageContentContainerRef.current}>
					<ClassDrawer.Edit
						onClose={closeEdit}
						activeClassID={selectedRow.id}
						primaryTeacherID={selectedRow.teacher.id}
					/>
				</Portal>
			)}

			<RemoveTeacherClass managerRef={removeTeacherClassManager} onRemoved={noop} />
		</>
	);
}
