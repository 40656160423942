import React from 'react';
import {ChartControlsWrapper, ExpandIconContainer} from './index.styled';
import {ChartIcon} from '../chart-icon';
import {Button, ExpandChart, Tooltip} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';
import {ChartIconType} from '../../types';
import {Checkbox} from '../../../../kit';
import {EntityType} from '../../../../types';
import {Text} from '@esgi/ui/typography';
import {TestType} from '@esgi/main/libs/core';

type ChartControlsProps = {
	entityType: EntityType,
	showBaseline: boolean,
	toggleBaseLine: VoidFunction,
	showOnlyTestedStudents: boolean,
	toggleShowOnlyTestedStudent: VoidFunction,
	showExpandedView: VoidFunction,
	testType: TestType,
}

export function ChartControls({entityType, showBaseline, toggleBaseLine, showOnlyTestedStudents, toggleShowOnlyTestedStudent, showExpandedView, testType}: ChartControlsProps) {
	return(
		<ChartControlsWrapper align='center' justify='between'>
			{entityType === EntityType.Student ? (
				<Checkbox
					checked={showBaseline}
					onCheckedChange={toggleBaseLine}
					label='Show Baseline'
					selected={showBaseline}
					dataCy='test-history-show-baseline-checkbox'
				/>
			) : (
				<Checkbox
					checked={showOnlyTestedStudents}
					onCheckedChange={toggleShowOnlyTestedStudent}
					label='Include only students who have been tested'
					selected={showOnlyTestedStudents}
					dataCy='test-history-include-tested-students'
				/>
			) }

			<FlexBox>
				{entityType === EntityType.ClassGroup && <ChartIcon type={ChartIconType.CORRECT} color='primaryVivid' width={24}/>}
				{entityType === EntityType.Student && <>
					{testType === TestType.YN && <ChartIcon type={ChartIconType.RUNSUBSET} color='primaryVivid'/>}
					{testType === TestType.YN && <ChartIcon type={ChartIconType.INCORRECT} color='negative'/>}
					<ChartIcon type={ChartIconType.RETEST} color='base'/>
					<ChartIcon type={ChartIconType.BASELINE} color='secondary' width={24}/>
        		</>}

				<ExpandIconContainer onClick={showExpandedView} dataCy='test-history-expand'>
					<Tooltip>
						<Tooltip.Trigger>
							<Button.Icon>
								<ExpandChart/>
							</Button.Icon>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text>View More Test History Details</Text>
						</Tooltip.Content>
					</Tooltip>
				</ExpandIconContainer>
			</FlexBox>
		</ChartControlsWrapper>
	);
}