import * as React from 'react';
import type {SVGProps} from 'react';

export function Growth(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 24 24' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.83276 7.33309C6.83276 7.01126 7.09392 6.75006 7.4161 6.75006L12.6664 6.75C12.9884 6.75 13.2494 7.01103 13.2494 7.33303C13.2494 7.65503 12.9884 7.91606 12.6664 7.91607L8.58276 7.91613V16.0786H9.74943V15.4956C9.74943 14.5295 10.5328 13.7465 11.4994 13.7465C12.466 13.7465 13.2494 14.5295 13.2494 15.4956V16.6667C13.2494 16.988 12.9874 17.2476 12.6661 17.2447C12.3441 17.2447 12.0828 16.9835 12.0828 16.6616V15.4956C12.0828 15.1737 11.8214 14.9125 11.4994 14.9125C11.1774 14.9125 10.9161 15.1737 10.9161 15.4956V16.6616C10.9161 16.9835 10.6548 17.2447 10.3328 17.2447L7.41667 17.2501H5.66667C5.3445 17.2501 5.08333 16.9889 5.08333 16.6667V10.2501C4.76117 10.2501 4.5 9.98889 4.5 9.66673C4.5 9.34456 4.76117 9.08339 5.08333 9.08339H7.41585C7.416 8.6943 7.4161 8.30521 7.4161 7.91613C7.09392 7.91613 6.83276 7.65493 6.83276 7.33309ZM6.25 10.2501H7.41525L7.41483 10.9331C7.41374 12.6499 7.41266 14.3667 7.41473 16.0834H6.25V10.2501Z'
				fill='#333333'
			/>
			<path
				d='M10.9167 9.66673C10.9167 9.98889 10.6555 10.2501 10.3333 10.2501C10.0112 10.2501 9.75 9.98889 9.75 9.66673C9.75 9.34456 10.0112 9.08339 10.3333 9.08339C10.6555 9.08339 10.9167 9.34456 10.9167 9.66673Z'
				fill='#333333'
			/>
			<path
				d='M10.3333 12.5834C10.6555 12.5834 10.9167 12.3222 10.9167 12.0001C10.9167 11.6779 10.6555 11.4167 10.3333 11.4167C10.0112 11.4167 9.75 11.6779 9.75 12.0001C9.75 12.3222 10.0112 12.5834 10.3333 12.5834Z'
				fill='#333333'
			/>
			<path
				d='M15.2671 9.96421C14.7336 9.39914 13.8603 9.43007 13.3636 10.0316L12.1383 11.5751C11.935 11.8213 11.9586 12.1955 12.1909 12.4109C12.4232 12.6263 12.7763 12.6014 12.9796 12.3552L14.2049 10.8117C14.2758 10.7258 14.4006 10.7213 14.4768 10.8021L15.5879 12.1579C16.1163 12.7176 16.9795 12.6934 17.4793 12.105L20.1115 9.04496C20.313 8.80124 20.2922 8.43126 20.065 8.21453C19.8334 7.99363 19.476 8.01613 19.2707 8.26455L16.6484 11.3125C16.577 11.3966 16.4536 11.4 16.3782 11.3201L15.2671 9.96421Z'
				fill='#333333'
			/>
		</svg>
	);
}
