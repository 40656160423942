import {useSubjectsFromStore} from './hooks/use-subjects-from-store';
import {useSubjectsBySchoolID} from './hooks/use-subjects-by-school-id';
import {useSubjectsByUserID} from './hooks/use-subjects-by-user-id';

type Parameters = {
	teacherID: number | null;
	schoolID: number | null;
	availableGradeLevel: number;
};

export function useSubjects({teacherID, schoolID, availableGradeLevel}: Parameters) {
	const [isSubjectsFromStoreReady, districtSubjects] = useSubjectsFromStore({availableGradeLevel, schoolID});
	const [isSubjectsBySchoolIDReady, schoolSubjects] = useSubjectsBySchoolID({schoolID, availableGradeLevel});
	const [isSubjectsByUserIDReady, teacherSubjects] = useSubjectsByUserID({teacherID});

	return {
		isSubjectsLoaded: isSubjectsFromStoreReady && isSubjectsBySchoolIDReady && isSubjectsByUserIDReady,
		districtSubjects,
		schoolSubjects,
		teacherSubjects,
	};
}
