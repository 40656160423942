import {isNull} from 'underscore';
import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {UserAccountService} from './user-account-service';
import {PrimaryAccountContent} from './components/primary-account-content';
import {PrimaryAccountPanel, SecondaryProfilePanel} from './components/panels';
import {SecondaryProfileContent} from './components/secondary-profile-content';

type Props = {
	onClose: VoidFunction;
	isTablet: boolean;
};

export function UserAccountDrawer(props: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, props.onClose);
	const dataService = useService(UserAccountService);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		dataService.init().subscribe(() => setLoaded(true));
	}, []);

	const schoolYearTypes = useBehaviorSubject(dataService.schoolYearTypes$);

	const user = useBehaviorSubject(dataService.user$);
	const secondaryProfile = useBehaviorSubject(dataService.secondaryProfile$);
	const countries = useBehaviorSubject(dataService.countries$);
	const states = useBehaviorSubject(dataService.states$);
	const activeUserTrackId = useBehaviorSubject(dataService.activeUserTrackId$);
	const tracks = useBehaviorSubject(dataService.tracks$);
	const isUserDataLoading = useBehaviorSubject(dataService.isUserDataLoading$);

	const content = () => {
		if (!isNull(user) && isNull(secondaryProfile) && !isNull(schoolYearTypes)) {
			return (
				<>
					<PrimaryAccountContent
						onClose={close}
						dataService={dataService}
						schoolYearTypes={schoolYearTypes}
						user={user}
						activeUserTrackId={activeUserTrackId}
						tracks={tracks}
					/>
					<PrimaryAccountPanel
						dataService={dataService}
						user={user}
						countries={countries}
						states={states}
						tracks={tracks}
						schoolYearTypes={schoolYearTypes}
						isUserDataLoading={isUserDataLoading}
						activeUserTrackId={activeUserTrackId}
						isTablet={props.isTablet}
					/>
				</>
			);
		}

		if (!isNull(user) && !isNull(secondaryProfile) && !isNull(activeUserTrackId)) {
			return (
				<>
					<SecondaryProfileContent activeUserTrackId={activeUserTrackId} user={user} tracks={tracks} onClose={close} />
					<SecondaryProfilePanel
						dataService={dataService}
						user={user}
						secondaryProfile={secondaryProfile}
						countries={countries}
						states={states}
						activeUserTrackId={activeUserTrackId}
						tracks={tracks}
						isTablet={props.isTablet}
					/>
				</>
			);
		}
	};

	return (
		<Drawer drawerRef={drawerRef} onClickOutside={close}>
			{content()}
		</Drawer>
	);
}
