import {Drawer, OneLinedText, UnsavedChangesConfirmation} from '@esgi/main/kits/common';
import {Close, Edit as EditIcon} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {TabID} from '../../types';
import {Divider, Header, HeaderButtons, HeaderTitle} from '../../components/header.styled';
import {ContextMenu} from './components/context-menu';
import {Body} from '../../components/body';
import {FormRoot} from '../../components/form.styled';
import {User, Class} from '@esgi/main/libs/school-admin-store';
import {useDrawerState} from '../use-drawer-state';
import {StudentsTabContent} from '../../components/students-tab-content';
import {ClassInformationTabContent} from './components/class-information-tab-content';
import {Skeleton} from '../../components/skeleton';
import {useSave} from './use-save';
import {useEditForm} from './form-data/use-edit-form';
import {useMemo} from 'react';

type Props = {
	activeClassID: Class['id'];
	primaryTeacherID: User['id'];
	onClose: VoidFunction;
};

export function Edit({onClose, activeClassID, primaryTeacherID}: Props) {
	const {
		drawerRef,
		onDrawerClose,
		closeDrawer,
		closeUnsavedChangesConfirmation,
		showUnsavedChangesConfirmation,
		classes,
		activeTab,
		setActiveTab,
		setIsFormTouched,
		setIsFormValid,
		isFormTouched,
		isFormValid,
		teachers,
		withoutResetSingleStudentAlert,
		setWithoutResetSingleStudentAlert,
		students,
		skeleton,
	} = useDrawerState({onClose});

	const teacherClasses = useMemo(
		() => classes.filter(({teacherID}) => teacherID === primaryTeacherID),
		[classes, primaryTeacherID],
	);

	const {form, initialClassName, initialStudentsIDs} = useEditForm({
		activeClassID,
		teacherClasses,
		setIsFormTouched,
		setIsFormValid,
		students,
		skeleton,
	});

	const {saveData, isDataSaving} = useSave({
		closeDrawer,
		form,
		classID: activeClassID,
		initialStudentsIDs,
	});

	return (
		<>
			<Drawer drawerRef={drawerRef} onClickOutside={onDrawerClose}>
				{skeleton ? (
					<Skeleton onClose={closeDrawer} />
				) : (
					<>
						<Header>
							<HeaderTitle>
								<EditIcon />
								<OneLinedText size='small' color='highContrast'>
									{initialClassName}
								</OneLinedText>
							</HeaderTitle>
							<HeaderButtons>
								<ContextMenu closeDrawer={closeDrawer} classID={activeClassID} />

								<Button color='primary' onClick={saveData} disabled={!isFormValid || !isFormTouched || isDataSaving}>
									<Text size='large'>Save</Text>
								</Button>

								<Button.Icon withBackgroundHover onClick={onDrawerClose}>
									<Close />
								</Button.Icon>
							</HeaderButtons>

							<Divider />
						</Header>

						<Body activeTabID={activeTab} setActiveTabID={setActiveTab}>
							<FormRoot controller={form}>
								{activeTab === TabID.ClassInformation && (
									<ClassInformationTabContent
										classNameControl={form.controls.className}
										primaryTeacherID={primaryTeacherID}
										teachers={teachers}
									/>
								)}

								{activeTab === TabID.Students && (
									<StudentsTabContent
										studentsIDsControl={form.controls.studentsIDs}
										withoutResetSingleStudentAlert={withoutResetSingleStudentAlert}
										setWithoutResetSingleStudentAlert={setWithoutResetSingleStudentAlert}
										primaryTeacherID={primaryTeacherID}
										allStudents={students}
										currentClass={{
											id: activeClassID,
											name: initialClassName,
										}}
									/>
								)}
							</FormRoot>
						</Body>
					</>
				)}
			</Drawer>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeDrawer} />
			)}
		</>
	);
}
