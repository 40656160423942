import {ContentBox, IconBox} from './index.styled';
import {Skeletonable} from '@esgi/ui';
import {More} from '@esgi/ui/icons';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = Skeletonable & {
	placeholder: string;
	valueTranscript: string;
	disabled?: boolean;
	onClick: VoidFunction;
	dataCy?: string;
};

export function SelectDrawerableBase({placeholder, onClick, skeleton, disabled, valueTranscript, dataCy}: Props) {
	const isDisabled = skeleton || disabled;

	const handleClick = () => {
		if (isDisabled) {
			return;
		}

		onClick();
	};

	return (
		<ContentBox skeleton={Boolean(skeleton)} isDisabled={Boolean(isDisabled)} onClick={handleClick} dataCy={dataCy}>
			<GridBox>
				<Text size='xSmall' font='mono' data-placeholder=''>
					{placeholder}
				</Text>
				<OneLinedText size='medium' bold data-transcript=''>
					{valueTranscript}
				</OneLinedText>
			</GridBox>

			{!isDisabled && (
				<IconBox>
					<More />
				</IconBox>
			)}
		</ContentBox>
	);
}
