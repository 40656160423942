import {ReportDialog, Reports} from '@esgi/main/features/admins/data';
import {Hierarchy, HierarchyMode} from '@esgi/main/features/admins/utils';
import {useMemo} from 'react';

export function Teacher() {
	const hierarchy = useMemo(() => {
		const h: Hierarchy = {
			mode: HierarchyMode.Classic,
		};

		return h;
	}, []);

	return (
		<ReportDialog.Section title='School Reports'>
			<Reports.GradeScale
				name='School Grades'
				description='Apply customizable grade scales to school scores'
				hierarchy={hierarchy}
			/>
			<Reports.TotalStudents
				name='School Students'
				description='Review and compare student scores for a subject tab at the School level'
				hierarchy={hierarchy}
			/>
			<Reports.Totals
				name='School Totals'
				description='Review and compare school scores for a subject tab'
				hierarchy={hierarchy}
			/>
			<Reports.ItemAnalysis hierarchy={hierarchy} />
			<Reports.PieCharts hierarchy={hierarchy} />
		</ReportDialog.Section>
	);
}
