import {RowDef} from '../../types';
import {DistrictReportPanel} from '../../../_kit';
import {BottomPanel} from './components/bottom-panel';
import {SingleItemExpandablePanel} from './components/single-item-expandable-panel';
import {useDataPageContext} from '../../../layout/context';
import {Page} from '@esgi/main/features/admins/data';

type Props = {
	onOpenAddDrawer: VoidFunction;
};

export function BaseContent({onOpenAddDrawer}: Props) {
	const {tableRows, selectedItems} = Page.useContext<RowDef>();
	const {districtName} = useDataPageContext();

	return (
		<>
			<Page.Header.Base onAddButtonClick={onOpenAddDrawer} />
			<Page.Body.Base />

			<DistrictReportPanel totalItems={tableRows.length} selectedItems={selectedItems.length} title={districtName} />
			<SingleItemExpandablePanel />
			<BottomPanel />
		</>
	);
}
