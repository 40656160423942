import {Body} from './index.styled';
import {AnalyticsData, RubricCriterion, StudentResult, StudentsResult, TestSession} from '../../../../types/test-panel';
import {LastDateAnalytic} from './components/last-date-analytic';
import {AverageSessionAnalytic} from './components/average-session-analytic';
import {isNull} from 'underscore';
import {Text} from '@esgi/ui/typography';
import {TotalPossibleAnalytic} from './components/total-possible-analytic';
import {LastSessionBarRubric} from './components/last-session-bar-rubric';
import {LastSessionBar} from './components/last-session-bar';
import {ClassAverageBar} from './components/class-average-bar';
import {TestType} from '@esgi/main/kits/common';
import {TestCard} from '@esgi/main/kits/test-results-panel';

type Props = {
	testType: TestType;
	rubricCriteria: RubricCriterion[];
	totalPossible: number;
	testSessions: TestSession[];
	analyticsData: AnalyticsData | null;
	studentResult: StudentResult | null;
	studentsResult: StudentsResult | null;
};

export function CardBody({
	testType,
	totalPossible,
	rubricCriteria,
	testSessions,
	analyticsData,
	studentResult,
	studentsResult,
}: Props) {
	const hasStudentResult = !isNull(studentResult);
	const hasStudentsResult = !isNull(studentsResult);
	const isRubricTypeTest = testType === TestType.Rubric;

	return (
		<Body>
			<TestCard.Statistic.Charts.ChartsBox>
				{isRubricTypeTest ? (
					<LastSessionBarRubric
						rubricCriteria={rubricCriteria}
						totalPossible={totalPossible}
						rubricAnswers={hasStudentResult ? studentResult.lastTestSessionRubricAnswers : null}
						correctAnswers={hasStudentResult ? studentResult.correctAnswers : null}
						resultDelta={hasStudentResult ? studentResult.delta : null}
					/>
				) : (
					<LastSessionBar
						totalPossible={totalPossible}
						result={hasStudentResult ? studentResult.result : null}
						resultDelta={hasStudentResult ? studentResult.delta : null}
						correctAnswers={hasStudentResult ? studentResult.correctAnswers : null}
						testType={testType}
					/>
				)}

				<TestCard.Statistic.Charts.Divider />

				<ClassAverageBar
					result={hasStudentsResult ? studentsResult.result : null}
					resultDelta={hasStudentsResult ? studentsResult.delta : null}
					testType={testType}
					totalPossible={totalPossible}
				/>
			</TestCard.Statistic.Charts.ChartsBox>

			<TestCard.Statistic.Charts.AnalyticsBox>
				<TotalPossibleAnalytic
					testType={testType}
					totalPossible={totalPossible}
					analyticsDataTotal={!isNull(analyticsData) ? analyticsData.total : null}
					rubricCriteriaCount={rubricCriteria.length}
				/>
				<LastDateAnalytic testDate={testSessions[0]?.testDate ?? null} />
				<AverageSessionAnalytic
					averageSessionDuration={!isNull(analyticsData) ? analyticsData.averageSessionDuration : null}
				/>
				<TestCard.Statistic.InfoWithTranscript transcript='Sessions'>
					<Text size='medium' font='mono' bold color='base'>
						{testSessions.length}
					</Text>
				</TestCard.Statistic.InfoWithTranscript>
			</TestCard.Statistic.Charts.AnalyticsBox>
		</Body>
	);
}
