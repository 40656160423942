import {useSubjectsFromStore} from './hooks/use-subjects-from-store';
import {useSubjectsByUserID} from './hooks/use-subjects-by-user-id';
import {TestResultsSubject} from '@esgi/main/kits/admin';
import {useMemo} from 'react';
import {useSubjectsByDistrictID} from './hooks/use-subjects-by-district-id';
import {AdditionalStoreSubjectsFilter} from './types';
import {isUndefined} from '@esgi/ui';
import {ReportsSubjectInfo} from '@esgi/main/features/admins/data';

type Student<P = unknown> = P & {gradeLevelID: number};

type Parameters = {students: Student[]} & Partial<{
	userID: number;
	additionalStoreSubjectsFilter: AdditionalStoreSubjectsFilter | null;
}>;

type Out = {
	isReady: boolean;
	subjects: TestResultsSubject[];
	reportSubject: ReportsSubjectInfo | null;
};

export function useTestResultsSubject({userID, students, additionalStoreSubjectsFilter}: Parameters): Out {
	const targetGradeLevels = useMemo(() => {
		const gradeLevels = new Set<number>();

		students.forEach(({gradeLevelID}) => gradeLevels.add(gradeLevelID));

		return [...gradeLevels];
	}, [students]);

	const [isSubjectsFromStoreReady, subjectsFromStore] = useSubjectsFromStore({
		targetGradeLevels,
		additionalStoreSubjectsFilter: additionalStoreSubjectsFilter ?? null,
		userID: userID ?? null,
	});

	const [isDistrictSubjectsByIDReady, districtSubjectsByID] = useSubjectsByDistrictID({
		targetGradeLevels,
		userID: userID ?? null,
		isUserIDAsSpecialist: additionalStoreSubjectsFilter === 'userIDAsSpecialist',
	});

	const [isSubjectsByUserIDReady, subjectsByUserBy] = useSubjectsByUserID({userID: userID ?? null});

	return useMemo<Out>(() => {
		const subjects = [...subjectsFromStore, ...districtSubjectsByID, ...subjectsByUserBy];

		const firstSubject = subjects[0];

		const reportSubject: ReportsSubjectInfo | null = isUndefined(firstSubject)
			? null
			: {
					id: firstSubject.id,
					level: firstSubject.level,
					type: firstSubject.type,
					name: firstSubject.name,
				};

		return {
			isReady: isSubjectsFromStoreReady && isDistrictSubjectsByIDReady && isSubjectsByUserIDReady,
			subjects,
			reportSubject,
		};
	}, [
		districtSubjectsByID,
		isDistrictSubjectsByIDReady,
		isSubjectsByUserIDReady,
		isSubjectsFromStoreReady,
		subjectsByUserBy,
		subjectsFromStore,
	]);
}
