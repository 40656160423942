import {useMemo, Suspense, useEffect} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {HierarchyInstance, SelectedSnapshot} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {SsoTracker} from '@esgi/core/tracker';
import {userStorage} from '@esgi/core/authentication';
import {lazyComponent} from '@esgi/core/react';

const OldStudentDetailLauncher = lazyComponent(() => import('modules/reports/student-detail-report'));

interface Props {
	subjectId: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
	onClose: VoidFunction;
}

export function StudentDetailLauncher(props: Props) {
	const snapshot = props.hierarchy.snapshot;
	const {classID, studentID, groupID, teacherID} = new SelectedSnapshot(snapshot);
	const changesCollector = useMemo(() => new ChangesCollector({
		id: props.subjectId,
		type: props.subjectType,
	}, snapshot), []);

	useEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: 'StudentDetailReport',
		});
	}, []);

	const options = useMemo(() => ({
		classId: classID,
		groupId: groupID,
		studentId: studentID,
		globalSchoolYearId: userStorage.get().globalSchoolYearID,
		subjectId: props.subjectId,
		subjectType: props.subjectType, userId: teacherID,
	}), [classID, groupID, studentID, props.subjectId, props.subjectType, teacherID]);

	return <Suspense fallback={<></>}>
		<OldStudentDetailLauncher options={options}
		                          onClose={props.onClose}
		                          changesCollector={changesCollector}
		                          hierarchy={props.hierarchy}
		/>
	</Suspense>;
}
