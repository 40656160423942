import {
	HierarchyInstance,
} from 'modules/hierarchy/core/models';
import React, {Suspense, useMemo} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {ReportsSubjectInfo} from '../types';

const GrowthReportModal = lazyComponent(() => import('modules/reports/growth-report'));

type Props = {
	subjectType: SubjectType,
	subjectID: number,
	hierarchy: HierarchyInstance,
	onClose: VoidFunction,
	subjects?: ReportsSubjectInfo[],
}

export function GrowthReportLauncher(props: Props) {
	const subjects = useMemo(() => {
		return props.subjects?.map((subject) => ({
			id: subject.id,
			name: subject.name,
			type: subject.type,
		}))
	}, [props.subjects]);

	return <Suspense fallback={<></>}>
		<GrowthReportModal hierarchy={props.hierarchy}
		                   onCloseClicked={props.onClose}
		                   showNumberOfStudents=''
		                   trackId={0}
		                   subjectId={props.subjectID}
		                   subjectType={SubjectType[props.subjectType]}
						   subjects={subjects}
		/>
	</Suspense>;
}