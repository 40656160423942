import {useMemo} from 'react';
import {TestResultsStudent} from '@esgi/main/kits/admin';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {RowDef} from '../types';
import {useTestResultsSubject} from '../../_kit';

type Parameters = {
	selectedRow: RowDef;
};

export function useSelectedSpecialistData({selectedRow}: Parameters) {
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({specialistsIDs}) => specialistsIDs.includes(selectedRow.id))
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {
		isReady: isTestResultsSubjectReady,
		subjects: testResultsSubjects,
		reportSubject,
	} = useTestResultsSubject({
		userID: selectedRow.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter: 'userIDAsSpecialist',
	});

	return {
		subjects: testResultsSubjects,
		students: filteredStudents,
		isSelectedSpecialistDataReady: isTestResultsSubjectReady && isStudentsLoaded,
		reportSubject,
	};
}
