import * as React from 'react';
import type {SVGProps} from 'react';

export function FolderAdd(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			data-cy='folder-add-icon'
			{...props}
		>
			<path
				d='M10.6667 8C9.19378 8 8 9.19378 8 10.6667V20.4444C8 22.408 9.592 24 11.5556 24H20.4444C22.408 24 24 22.408 24 20.4444V19.3889C24 18.898 23.602 18.5 23.1111 18.5C22.6202 18.5 22.2222 18.898 22.2222 19.3889V20.4444C22.2222 21.4267 21.4267 22.2222 20.4444 22.2222H11.5556C10.5733 22.2222 9.77778 21.4267 9.77778 20.4444V10.6667C9.77778 10.176 10.176 9.77778 10.6667 9.77778H13.3747C14.0458 9.77778 14.2618 9.99556 14.7916 11.056C14.8878 11.2493 14.9372 11.3327 15.0168 11.4672L15.0689 11.5556H12.3528C11.8613 11.5556 11.4639 11.9538 11.4639 12.4444C11.4639 12.9351 11.8613 13.3333 12.3528 13.3333L16.472 13.3333C17.1916 13.3333 17.6613 12.5758 17.3149 11.9451C17.1954 11.7276 17.0919 11.5455 16.9937 11.373C16.802 11.0359 16.631 10.7352 16.4022 10.2773C15.5996 8.67111 14.9258 8 13.3747 8H10.6667Z'
				fill='#333333'
			/>
			<path
				d='M22.2 15.1C22.2 15.5968 22.6032 16 23.1 16C23.5968 16 24 15.5968 24 15.1V13.4H25.7C26.1968 13.4 26.6 12.9968 26.6 12.5C26.6 12.0032 26.1968 11.6 25.7 11.6H24V9.9C24 9.4032 23.5968 9 23.1 9C22.6032 9 22.2 9.4032 22.2 9.9V11.6H20.5C20.0032 11.6 19.6 12.0032 19.6 12.5C19.6 12.9968 20.0032 13.4 20.5 13.4H22.2V15.1Z'
				fill='#333333'
			/>
		</svg>
	);
}
