import {Dispatch, PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {TabsContext, TabsContextValue} from '../context';
import {TabInfo, UpdateTab} from '../types';
import {GridBox} from '@esgi/ui/layout';

type Props<Tab extends string> = PropsWithChildren<{
	activeTab: Tab;
	setActiveTab: Dispatch<Tab>;
}>;

export function Root<Tab extends string>({activeTab, setActiveTab, children}: Props<Tab>) {
	const [availableTabs, setAvailableTabs] = useState<TabInfo<Tab>[]>([]);

	const addTab = useCallback((newTab: TabInfo<Tab>) => {
		setAvailableTabs((currentTabs) => [...currentTabs, newTab]);
	}, []);

	const removeTab = useCallback((tabID: Tab) => {
		setAvailableTabs((currentTabs) => currentTabs.filter((tab) => tab.tabID !== tabID));
	}, []);

	const updateTab = useCallback<UpdateTab<Tab>>(({tabID, data}) => {
		setAvailableTabs((currentTabs) =>
			currentTabs.map((currentTab) => (currentTab.tabID === tabID ? {...currentTab, ...data} : currentTab)),
		);
	}, []);

	const context = useMemo<TabsContextValue<Tab>>(
		() => ({
			activeTab,
			setActiveTab,
			addTab,
			removeTab,
			updateTab,
			availableTabs,
		}),
		[activeTab, addTab, availableTabs, removeTab, setActiveTab, updateTab],
	);

	return (
		<TabsContext.Provider value={context}>
			<GridBox gapY='5'>{children}</GridBox>
		</TabsContext.Provider>
	);
}
