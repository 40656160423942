import {Delete, Edit, Folder as FolderIcon, MoreV} from '@esgi/ui/icons';
import {ActionButton, ContextMenuItem, Item, ItemNameText} from './index.styled';
import {Counter} from '../../../counter.styled';
import {Text} from '@esgi/ui/typography';
import {ContextMenu, Tooltip} from '@esgi/ui';
import {MouseEvent, useCallback, useLayoutEffect, useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {useDataPageContext} from '../../../../../../context';
import {isNull} from 'underscore';
import {Folder, RemoveFolder, RenameFolder} from '@esgi/main/features/admins/data';

export function FolderNavigationItem(folder: Folder) {
	const {activeFolder, onSetActiveFolder, onDeselectFolder} = useDataPageContext();

	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [isContextmenuOpen, setIsContextMenuOpen] = useState(false);

	const [isTextTruncated, setIsTextTruncated] = useState(false);
	const [isContextMenuActive, setIsContextMenuActive] = useState(false);

	const [openRemoveFolderAlert, setOpenRemoveFolderAlert] = useState(false);
	const [openRenameFolderAlert, setOpenRenameFolderAlert] = useState(false);

	const textRef = useRef<HTMLElement>(null);

	useResizeObserver(textRef, ({target: {scrollWidth, clientWidth, scrollHeight, clientHeight}}) => {
		setIsTextTruncated(clientWidth < scrollWidth || clientHeight < scrollHeight);
	});

	useLayoutEffect(() => {
		if (isTooltipOpen && (!isTextTruncated || isContextMenuActive)) {
			setIsTooltipOpen(false);
		}
	}, [isContextMenuActive, isTextTruncated, isTooltipOpen]);

	const handleRenameClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		setIsContextMenuOpen(false);
		setOpenRenameFolderAlert(true);
	}, []);

	const handleRemoveClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();

		setIsContextMenuOpen(false);
		setOpenRemoveFolderAlert(true);
	}, []);

	const onFolderRemoved = useCallback(() => {
		if (!isNull(activeFolder)) {
			onDeselectFolder();
		}
	}, [activeFolder, onDeselectFolder]);

	const closeRemoveFolderAlert = useCallback(() => {
		setOpenRemoveFolderAlert(false);
	}, []);

	const closeRenameFolderAlert = useCallback(() => {
		setOpenRenameFolderAlert(false);
	}, []);

	const handleContextMenuActive = useCallback(() => {
		setIsContextMenuActive(true);
	}, []);

	const handleContextMenuInActive = useCallback(() => {
		setIsContextMenuActive(false);
	}, []);

	return (
		<>
			<Tooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
				<Tooltip.Trigger>
					<Item
						active={isNull(activeFolder) ? false : activeFolder.id === folder.id}
						onClick={() => onSetActiveFolder(folder)}
						disableHover={isContextMenuActive}
						dataCy='folder-navigation-item'
					>
						<FolderIcon />
						<ItemNameText size='large' color='currentColor' ref={textRef}>
							{folder.name}
						</ItemNameText>
						<Counter dataCy='counter'>
							<Text size='small' font='mono' color='currentColor'>
								{folder.count}
							</Text>
						</Counter>

						<ContextMenu.Root open={isContextmenuOpen} onOpenChange={setIsContextMenuOpen}>
							<ContextMenu.Trigger>
								<ActionButton
									withBackgroundHover
									onMouseEnter={handleContextMenuActive}
									onMouseLeave={handleContextMenuInActive}
								>
									<MoreV />
								</ActionButton>
							</ContextMenu.Trigger>

							<ContextMenu.Content>
								<ContextMenu.Group>
									<ContextMenuItem
										onClick={handleRenameClick}
										onMouseEnter={handleContextMenuActive}
										onMouseLeave={handleContextMenuInActive}
									>
										<Edit />
										<Text size='medium'>Rename</Text>
									</ContextMenuItem>
									<ContextMenuItem
										onClick={handleRemoveClick}
										onMouseEnter={handleContextMenuActive}
										onMouseLeave={handleContextMenuInActive}
									>
										<Delete />
										<Text size='medium'>Remove</Text>
									</ContextMenuItem>
								</ContextMenu.Group>
							</ContextMenu.Content>
						</ContextMenu.Root>
					</Item>
				</Tooltip.Trigger>
				<Tooltip.Content variant='default'>{folder.name}</Tooltip.Content>
			</Tooltip>

			{openRemoveFolderAlert && (
				<RemoveFolder
					folderID={folder.id}
					folderName={folder.name}
					folderType={folder.type}
					onClose={closeRemoveFolderAlert}
					onFolderRemoved={onFolderRemoved}
				/>
			)}

			{openRenameFolderAlert && (
				<RenameFolder folderID={folder.id} folderType={folder.type} onClose={closeRenameFolderAlert} />
			)}
		</>
	);
}
