import * as React from 'react';
import type {SVGProps} from 'react';

export function Filenametemplate(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
			<path
				d='M27 12C27 11.5858 27.3358 11.25 27.75 11.25C28.1642 11.25 28.5 11.5858 28.5 12V20.5C28.5 20.9142 28.1642 21.25 27.75 21.25C27.3358 21.25 27 20.9142 27 20.5V12Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.5555 7C9.5919 7 7.99998 8.62034 7.99998 10.619V21.381C7.99998 23.3797 9.5919 25 11.5555 25H20.4444C22.4081 25 24 23.3797 24 21.381V10.619C24 8.62034 22.4081 7 20.4444 7H11.5555ZM11.5555 8.80952H20.4444C21.4263 8.80952 22.2222 9.61965 22.2222 10.619V21.381C22.2222 22.3804 21.4263 23.1905 20.4444 23.1905H11.5555C10.5737 23.1905 9.77776 22.3804 9.77776 21.381V10.619C9.77776 9.61965 10.5737 8.80952 11.5555 8.80952Z'
				fill='#333333'
			/>
			<path
				d='M12.4444 12.881C12.4444 12.3815 12.8427 11.9762 13.3333 11.9762C14.4098 11.9762 15.3475 12.4792 16 13.2483C16.6524 12.4792 17.5902 11.9762 18.6666 11.9762C19.1573 11.9762 19.5555 12.3815 19.5555 12.881C19.5555 13.3804 19.1573 13.7857 18.6666 13.7857C17.6844 13.7857 17 15.0002 17 16C17 16.9998 17.6844 18.2142 18.6666 18.2142C19.1573 18.2142 19.5555 18.6196 19.5555 19.119C19.5555 19.6184 19.1573 20.0238 18.6666 20.0238C17.5902 20.0238 16.6524 19.5207 16 18.7517C15.3475 19.5207 14.4098 20.0238 13.3333 20.0238C12.8427 20.0238 12.4444 19.6184 12.4444 19.119C12.4444 18.6196 12.8427 18.2142 13.3333 18.2142C14.3155 18.2142 15 16.9998 15 16C15 15.0002 14.3155 13.7857 13.3333 13.7857C12.8427 13.7857 12.4444 13.3804 12.4444 12.881Z'
				fill='#333333'
			/>
			<path
				d='M3.5 12C3.5 11.5858 3.83579 11.25 4.25 11.25C4.66421 11.25 5 11.5858 5 12V20.5C5 20.9142 4.66421 21.25 4.25 21.25C3.83579 21.25 3.5 20.9142 3.5 20.5V12Z'
				fill='#333333'
			/>
		</svg>
	);
}
