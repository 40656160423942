import {Drawer, getFullName} from '@esgi/main/kits/common';
import {GroupNameInput} from '../../../components/group-name-input';
import {Input} from '@esgi/ui/controls';
import {User} from '@esgi/main/libs/school-admin-store';
import {GroupNameControl} from '../../../components/group-name-input/types';

type Props = {
	groupNameControl: GroupNameControl;
	currentSpecialist: User;
};

export function GroupInformationContent({groupNameControl, currentSpecialist}: Props) {
	const specialistFullName = getFullName({
		firstName: currentSpecialist.firstName,
		lastName: currentSpecialist.lastName,
	});
	return (
		<>
			<Drawer.ContentBlock title='Specialist' withDivider>
				<Input placeholder='School Specialist' value={specialistFullName} disabled dataCy='school-specialist-input' />
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Specialist Group Information' withDivider>
				<GroupNameInput groupNameControl={groupNameControl} />
			</Drawer.ContentBlock>
		</>
	);
}
