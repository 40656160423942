import {FolderNoSelectedItemsPanel} from '../../../../_kit';
import {RowDef} from '../../../types';
import {useDataPageContext} from '../../../../layout/context';
import {
	AddEntitiesIntoFolder,
	Folder,
	FolderManagement,
	FolderType,
	RemoveEntitiesFromFolder,
	RemoveFolder,
	Page,
} from '@esgi/main/features/admins/data';
import {BottomPanel} from './bottom-panel';
import {useFlag} from '@esgi/ui';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';
import {folderReports} from './constants';
import {useMemo} from 'react';

type Props = {
	allFolderRows: RowDef[];
	activeFolder: Folder;
};

export function Content({activeFolder, allFolderRows}: Props) {
	const {onDeselectFolder, entityFolders} = useDataPageContext();
	const {selectedItems, api, isDataLoaded} = Page.useContext<RowDef>();

	FolderManagement.useUpdateFolder({type: activeFolder.type});

	const [showAddEntitiesIntoFolder, openAddEntitiesIntoFolder, closeAddEntitiesIntoFolder] = useFlag();
	const [showRemoveEntitiesFromFolder, openRemoveEntitiesFromFolder, closeRemoveEntitiesFromFolder] = useFlag();

	const studentsCountInFolder = useMemo(
		() => allFolderRows.reduce((currentCount, {students}) => currentCount + students, 0),
		[allFolderRows],
	);

	return (
		<>
			<Page.Header.Base />
			<Page.SubHeader.Folder onClose={onDeselectFolder} folderName={activeFolder.name} />
			<Page.Body.Base />

			<FolderNoSelectedItemsPanel
				folderID={activeFolder.id}
				folderName={activeFolder.name}
				isShowPanel={isDataLoaded && !selectedItems.length}
				folderType={FolderType.Schools}
				reports={folderReports}
				rowsCountInFolder={activeFolder.count}
				studentsCountInFolder={studentsCountInFolder}
			/>

			<SingleItemExpandablePanel
				gridApi={api}
				onFolderAddClick={openAddEntitiesIntoFolder}
				activeFolder={activeFolder}
				onRemoveFromFolderClick={openRemoveEntitiesFromFolder}
			/>

			<BottomPanel
				onFolderAddClick={openAddEntitiesIntoFolder}
				onRemoveFromFolderClick={openRemoveEntitiesFromFolder}
			/>

			{showAddEntitiesIntoFolder && (
				<AddEntitiesIntoFolder
					addEntityIDs={selectedItems.map(({id}) => id)}
					folders={(entityFolders ?? []).map(({id, name}) => ({id, name}))}
					initialFolderID={activeFolder.id}
					folderType={activeFolder.type}
					onCloseAlert={closeAddEntitiesIntoFolder}
				/>
			)}

			{showRemoveEntitiesFromFolder &&
				(allFolderRows.length !== selectedItems.length ? (
					<RemoveEntitiesFromFolder
						allEntityIDs={allFolderRows.map(({id}) => id)}
						potentialRemovedEntityIDs={selectedItems.map(({id}) => id)}
						folderID={activeFolder.id}
						folderType={activeFolder.type}
						folderName={activeFolder.name}
						onClose={closeRemoveEntitiesFromFolder}
					/>
				) : (
					<RemoveFolder
						folderID={activeFolder.id}
						folderName={activeFolder.name}
						folderType={activeFolder.type}
						onClose={closeRemoveEntitiesFromFolder}
						onFolderRemoved={onDeselectFolder}
					/>
				))}
		</>
	);
}
