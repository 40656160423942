import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {Content, Item} from './index.styled';
import {useTabsContext} from '../../context';
import {ComponentPropsWithoutRef, ForwardedRef, forwardRef, useCallback} from 'react';
import {useReportsDialogContext} from '../../../context';

type Props = Omit<ComponentPropsWithoutRef<typeof ToggleGroup.Root>, 'onValueChange' | 'value' | 'orientation'>;

function BarInner<Tab extends string>(
	{dataCy = 'tabs-bar', css = {}, ...toggleGroupProps}: Props,
	ref: ForwardedRef<HTMLDivElement>,
) {
	const {skeleton} = useReportsDialogContext();
	const {activeTab, setActiveTab, availableTabs} = useTabsContext<Tab>();

	const onValueChange = useCallback(
		(newTab: Tab) => {
			setActiveTab(newTab);
		},
		[setActiveTab],
	);

	return (
		<ToggleGroup.Root
			dataCy={dataCy}
			css={css}
			value={activeTab}
			ref={ref}
			onValueChange={onValueChange}
			orientation='horizontal'
			{...toggleGroupProps}
		>
			{!!availableTabs.length && (
				<Content dataCy={`${dataCy}-content`} skeleton={skeleton}>
					{availableTabs.map(({tabID, tabContent}) => (
						<Item value={tabID} disabled={activeTab === tabID} applyDisabledStyles={false} key={tabID}>
							<Text size='medium' bold>
								{tabContent}
							</Text>
						</Item>
					))}
				</Content>
			)}
		</ToggleGroup.Root>
	);
}

export const Bar = forwardRef(BarInner);
