import {Subject} from '@esgi/main/libs/admin-store';
import {useRearrangeTests} from './hooks/use-rearrange-tests';
import {useDeleteTest} from './hooks/use-delete-test';
import {useTestsPanelTests} from '../../../hooks/use-tests-panel-tests';
import {TestsPanel} from '../../components/tests-panel';
import {AddTestsDrawer, TestPanelMode} from '@esgi/main/features/admins/subject-management';
import {useFlag} from '@esgi/ui';
import {Dispatch, useCallback, useEffect, useMemo, useState} from 'react';
import {storageToAddTestsTestType} from '../mapped-enums';
import {useAddTests} from './hooks/use-add-tests';
import {SettingsPanel} from '../../components/settings-panel';
import {useInitData} from './hooks/use-init-data';
import {useSubjectData} from './hooks/use-subject-data';
import {useDeleteSubject} from './hooks/use-delete-subject';

type Props = {
	subjectFromStore: Subject;
	allDistrictSubjectsNames: string[];
	onSubjectRemoved: VoidFunction;
	onIsFormTouchedValueChanged: Dispatch<boolean>;
};

export function Edit({
	subjectFromStore,
	allDistrictSubjectsNames,
	onSubjectRemoved,
	onIsFormTouchedValueChanged,
}: Props) {
	const [testsPanelMode, setTestsPanelMode] = useState(TestPanelMode.View);

	const [isAddTestsDrawerOpen, openAddTestsDrawer, closeAddTestsDrawer] = useFlag();

	const {
		allTrackDates,
		districtSpecialists,
		gradeLevels,
		isDataLoaded,
		teachersSchools,
		trackID,
		initSubjectData,
		schoolSpecialists,
		schoolSpecialistsSchools,
	} = useInitData({subjectID: subjectFromStore.id});

	const {
		subjectName,
		setSubjectName,
		retestSubjectType,
		setRetestSubjectType,
		isShuffleQuestions,
		setIsShuffleQuestions,
		publishPeriodValue,
		setPublishPeriodValue,
		setSelectedGradeLevels,
		selectedGradeLevels,
		publishForTeacherData,
		setPublishForTeacherData,
		isPublishForTeacherActive,
		setIsPublishForTeacherActive,
		publishForSpecialistsData,
		setPublishForSpecialistsData,
		isPublishForSpecialistsActive,
		setIsPublishForSpecialistsActive,
		onUpdateSubject,
		isDataSaving,
		isFormTouched,
		isActive,
		setIsActive,
	} = useSubjectData({
		allTrackDates,
		districtSpecialists,
		initSubjectData,
		isDataLoaded,
		schoolSpecialists,
		schoolSpecialistsSchools,
		teachersSchools,
		subjectID: subjectFromStore.id,
		trackID,
	});


	const {handleRemoveSubject, isSubjectRemoving} = useDeleteSubject({onSubjectRemoved, subjectFromStore});

	const forbiddenSubjectsNames = useMemo(
		() =>
			allDistrictSubjectsNames.filter((name) => name.toLocaleLowerCase() !== subjectFromStore.name.toLocaleLowerCase()),
		[allDistrictSubjectsNames, subjectFromStore.name],
	);

	const tests = useTestsPanelTests({subjectFromStore});
	const onRearrangeTests = useRearrangeTests({subjectFromStore});
	const onTestDeleteClick = useDeleteTest({subjectFromStore});
	const onAddTests = useAddTests({subjectFromStore});

	const onCloseAddTestsDrawer = useCallback(() => {
		closeAddTestsDrawer();
		setTestsPanelMode(TestPanelMode.View);
	}, [closeAddTestsDrawer]);

	useEffect(() => {
		onIsFormTouchedValueChanged(isFormTouched);

		return () => {
			onIsFormTouchedValueChanged(false);
		};
	}, [isFormTouched, onIsFormTouchedValueChanged]);

	return (
		<>
			<SettingsPanel
				skeleton={!isDataLoaded || isSubjectRemoving}
				subjectName={subjectFromStore.name}
				saveButtonText='Save Changes'
				subjectNameValue={subjectName}
				setSubjectNameValue={setSubjectName}
				forbiddenSubjectsNames={forbiddenSubjectsNames}
				isActive={isActive}
				setIsActive={setIsActive}
				retestSubjectType={retestSubjectType}
				setRetestSubjectType={setRetestSubjectType}
				isShuffleQuestions={isShuffleQuestions}
				setIsShuffleQuestions={setIsShuffleQuestions}
				trackID={trackID}
				gradeLevels={gradeLevels}
				selectedGradeLevels={selectedGradeLevels}
				setSelectedGradeLevels={setSelectedGradeLevels}
				allTrackDates={allTrackDates}
				publishPeriodValue={publishPeriodValue}
				setPublishPeriodValue={setPublishPeriodValue}
				isPublishForTeacherActive={isPublishForTeacherActive}
				setIsPublishForTeacherActive={setIsPublishForTeacherActive}
				publishForTeacherData={publishForTeacherData}
				setPublishForTeacherData={setPublishForTeacherData}
				isPublishForSpecialistsActive={isPublishForSpecialistsActive}
				setIsPublishForSpecialistsActive={setIsPublishForSpecialistsActive}
				publishForSpecialistsData={publishForSpecialistsData}
				setPublishForSpecialistsData={setPublishForSpecialistsData}
				onSaveSubject={onUpdateSubject}
				isDataSaving={isSubjectRemoving || isDataSaving}
				onRemoveSubject={handleRemoveSubject}
				isFormTouched={isFormTouched}
			/>

			<TestsPanel
				tests={tests}
				onRearrangeTests={onRearrangeTests}
				onTestDeleteClick={onTestDeleteClick}
				onAddButtonClick={openAddTestsDrawer}
				testsPanelMode={testsPanelMode}
				setTestsPanelMode={setTestsPanelMode}
			/>

			{isAddTestsDrawerOpen && (
				<AddTestsDrawer
					onClose={onCloseAddTestsDrawer}
					subject={{
						id: subjectFromStore.id,
						name: subjectFromStore.name,
						subjectType: storageToAddTestsTestType[subjectFromStore.subjectType],
					}}
					onAddTests={onAddTests}
				/>
			)}
		</>
	);
}
