import {Group} from '@esgi/main/libs/admin-store';
import {useDrawerState} from '../use-drawer-state';
import {useData} from './hooks/use-data';
import {useEditForm} from './form-data/use-edit-form';
import {Drawer, OneLinedText, UnsavedChangesConfirmation} from '@esgi/main/kits/common';
import {Divider, Header, HeaderButtons, HeaderTitle} from '../../components/header.styled';
import {Close, Edit as EditIcon} from '@esgi/ui/icons';
import {isNull} from 'underscore';
import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Body} from '../../components/body';
import {FormRoot} from '../../components/form.styled';
import {TabID} from '../../types';
import {StudentsTabContent} from '../../components/students-tab-content';
import {GroupInformationTabContent} from './components/group-information-tab-content';
import {useSave} from './hooks/use-save';
import {ContextMenu} from './components/context-menu';
import {DrawerSkeleton} from '@esgi/main/features/admins/data';

type Props = {
	groupID: Group['id'];
	onClose: VoidFunction;
};

export function Edit({groupID, onClose}: Props) {
	const {
		drawerRef,
		onDrawerClose,
		closeDrawer,
		closeUnsavedChangesConfirmation,
		showUnsavedChangesConfirmation,
		activeTab,
		setActiveTab,
		setIsFormTouched,
		setIsFormValid,
		isFormTouched,
		isFormValid,
		withoutResetSingleStudentAlert,
		setWithoutResetSingleStudentAlert,
		skeleton,
		schools,
		teachers,
		classes,
		groups,
		students,
	} = useDrawerState({onClose});

	const {school, primaryTeacher, currentGroup, allGroupsInCurrentClass, currentClass, studentsIDsInGroup} = useData({
		classes,
		groupID,
		groups,
		skeleton,
		teachers,
		schools,
		studentsList: students,
	});

	const {form} = useEditForm({
		allGroupsInCurrentClass,
		currentGroup,
		setIsFormTouched,
		setIsFormValid,
		skeleton,
		studentsIDsInGroup,
	});

	const {isDataSaving, onEditGroup} = useSave({
		closeDrawer,
		currentGroup,
		form,
		initialStudentsIDs: studentsIDsInGroup,
	});

	return (
		<>
			<Drawer drawerRef={drawerRef} onClickOutside={onDrawerClose}>
				{skeleton || isNull(currentGroup) || isNull(primaryTeacher) || isNull(currentClass) || isNull(school) ? (
					<DrawerSkeleton onClose={closeDrawer} />
				) : (
					<>
						<Header>
							<HeaderTitle>
								<EditIcon />
								<OneLinedText size='small' color='highContrast'>
									{currentGroup.name}
								</OneLinedText>
							</HeaderTitle>
							<HeaderButtons>
								<ContextMenu closeDrawer={closeDrawer} currentGroup={currentGroup} />

								<Button color='primary' onClick={onEditGroup} disabled={!isFormValid || !isFormTouched || isDataSaving}>
									<Text size='large'>Save</Text>
								</Button>

								<Button.Icon withBackgroundHover onClick={onDrawerClose}>
									<Close />
								</Button.Icon>
							</HeaderButtons>

							<Divider />
						</Header>

						<Body activeTabID={activeTab} setActiveTabID={setActiveTab}>
							<FormRoot controller={form}>
								{activeTab === TabID.GroupInformation && (
									<GroupInformationTabContent
										form={form}
										currentClass={currentClass}
										primaryTeacher={primaryTeacher}
										school={school}
									/>
								)}

								{activeTab === TabID.Students && (
									<StudentsTabContent
										studentsIDsControl={form.controls.studentsIDs}
										withoutResetSingleStudentAlert={withoutResetSingleStudentAlert}
										setWithoutResetSingleStudentAlert={setWithoutResetSingleStudentAlert}
										allStudents={students}
										currentGroup={currentGroup}
										primaryTeacherID={primaryTeacher.id}
									/>
								)}
							</FormRoot>
						</Body>
					</>
				)}
			</Drawer>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeDrawer} />
			)}
		</>
	);
}
