import {useState} from 'react';
import {SingleSelectedExpandablePanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {Folder} from '@esgi/main/features/school-admin/data';
import {ContentWrapper} from './components/content-wrapper';
import {Page} from '@esgi/main/features/admins/data';

type Props = {
	onFolderAddClick: VoidFunction;
	userCanAddAndDeleteTeachers: boolean;
	activeFolder: Folder;
	onDeselectFolder: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
};

export function SingleItemExpandablePanel({
	onFolderAddClick,
	userCanAddAndDeleteTeachers,
	activeFolder,
	onDeselectFolder,
	onRemoveFromFolderClick,
}: Props) {
	const {api} = Page.useContext<RowDef>();

	const [isPanelExpanded, setIsPanelExpanded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<RowDef | null>(null);

	return (
		<SingleSelectedExpandablePanel.Root<RowDef>
			gridApi={api}
			onSingleSelectedItemChange={setSelectedRow}
			onPanelExpandableValueChange={setIsPanelExpanded}
		>
			{selectedRow && (
				<ContentWrapper
					selectedRow={selectedRow}
					isPanelExpanded={isPanelExpanded}
					onFolderAddClick={onFolderAddClick}
					gridApi={api}
					userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
					activeFolder={activeFolder}
					onDeselectFolder={onDeselectFolder}
					onRemoveFromFolderClick={onRemoveFromFolderClick}
				/>
			)}
		</SingleSelectedExpandablePanel.Root>
	);
}
