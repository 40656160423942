import {RowDef} from './types';
import {useData} from './use-data';
import {useColumnDefinitions} from './use-column-definitions';
import {useDataPageContext} from '../layout/context';
import {isNull} from 'underscore';
import {Base} from './variant/base';
import {Folder} from './variant/folder';
import {useUser} from '@esgi/core/authentication';
import {useCallback, useState} from 'react';
import {AddTeacher, EditTeacher} from '@esgi/main/features/district-admin/data';
import {useFlag} from '@esgi/ui';

export default function () {
	const user = useUser();

	const [teacherEditID, setTeacherEditID] = useState<RowDef['id'] | null>(null);

	const {activeFolder} = useDataPageContext();
	const {ready, rows, schools} = useData();

	const colDefs = useColumnDefinitions(rows, schools);

	const [isOpenAddDrawer, onOpenAddDrawer, onCloseAddDrawer] = useFlag();

	const onOpenEditDrawer = useCallback((entity: RowDef) => {
		setTeacherEditID(entity.id);
	}, []);

	const onCloseEditDrawer = useCallback(() => {
		setTeacherEditID(null);
	}, []);

	const userCanAddAndDeleteTeachers = Boolean(user?.canAddAndDeleteTeachers);

	return (
		<>
			{!isNull(activeFolder) ? (
				<Folder
					columnDefs={colDefs}
					allTableRows={rows}
					activeFolder={activeFolder}
					isDataLoaded={ready}
					userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
					onOpenAddDrawer={onOpenAddDrawer}
				/>
			) : (
				<Base
					columnDefs={colDefs}
					allTableRows={rows}
					isDataLoaded={ready}
					userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
					editButtonDisabled={!user?.canViewAndModifyTeachers}
					onOpenAddDrawer={onOpenAddDrawer}
					onEditClick={onOpenEditDrawer}
				/>
			)}

			{isOpenAddDrawer && <AddTeacher onClose={onCloseAddDrawer} />}
			{!isNull(teacherEditID) && <EditTeacher teacherID={teacherEditID} onClose={onCloseEditDrawer} />}
		</>
	);
}
