import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {sectionContainerInlinePadding} from '../test-section-root/index.styled';
import {CSS} from '@stitches/react';
import {Button} from '@esgi/ui';

const sectionsRowGap = 20;

export const ContentBox = styled(GridBox, {
	rowGap: sectionsRowGap,
	height: '100%',
	gridTemplateRows: 'repeat(2, auto) 1fr',
});

export const Divider = styled(Box, {
	height: 1,
	marginLeft: -sectionContainerInlinePadding,
	marginRight: -sectionContainerInlinePadding,
	backgroundColor: '$background',
});

export const TestSectionTemplateContent = styled(GridBox, {
	rowGap: sectionsRowGap,
	overflow: 'hidden',
	gridTemplateRows: 'auto 1fr',

	variants: {
		isTabletScreen: {
			true: {},

			false: {
				height: '100%',
			},
		},
	},
});

export const WidgetFiltersBox = styled(GridBox, {
	rowGap: sectionsRowGap,
});

export const WidgetsContainer = styled(Box, {
	marginRight: '-24px',
	marginLeft: '-24px',
	overflow: 'hidden',
});

export const FiltersTabsContainer = styled(GridBox, {
	gap: '$3',
	gridTemplateColumns: 'repeat(2, max-content)',
});

export const FiltersSelectsContainer = styled(GridBox, {
	gap: '$3',
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	gridTemplateColumns: 'repeat(2, minmax(max-content, 174px))',
});

export const FiltersContainer = styled(GridBox, {
	gap: '$3',
	gridTemplateColumns: 'max-content 1fr',
	overflow: 'hidden',

	variants: {
		isSmallScreen: {
			true: {
				gap: '$2',

				[`& > ${FiltersTabsContainer}, & > ${FiltersSelectsContainer}`]: {
					gap: '$2',
				},
			},
		},

		isTabletScreen: {
			true: {
				gridTemplateColumns: 'none',
				gap: '$2',

				[`& > ${FiltersTabsContainer}, & > ${FiltersSelectsContainer}`]: {
					gap: '$2',
				},

				[`& > ${FiltersTabsContainer}`]: {
					gridTemplateColumns: 'max-content 1fr',
				},

				[`& > ${FiltersSelectsContainer}`]: {
					gridTemplateColumns: '1fr 1fr',
					justifyContent: 'unset',
				},
			},
		},
	},
});

export const cardViewItemCSS: CSS = {
	padding: 0,
	width: 34,
	height: 34,
};

export const NoTestsBox = styled(FlexBox, {
	flexDirection: 'column',
	gap: '$2',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
});

export const ButtonAdd = styled(Button, {
	width: '166px',
	height: '32px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
