import {Drawer, getFullName} from '@esgi/main/kits/common';
import {FormElementSelect} from '../../../../components/form-element-select';
import {Class, User} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';
import {FormSelectItem} from '../../../../components/form-element-select/types';
import {teacherClassesSelectErrorItems, teacherSelectErrorItems} from './constants';
import {AddGroupForm} from '../../form-data/types';
import {GroupNameInput} from '../../../../components/group-name-input';

type Props = {
	form: AddGroupForm;
	activePrimaryTeachersList: User[];
	activeClassesList: Class[];
	classListUniqueKey: string;
};

export function GroupInformationTabContent({
	form,
	activePrimaryTeachersList,
	activeClassesList,
	classListUniqueKey,
}: Props) {
	const teachersSelectItems = useMemo(() => (
		activePrimaryTeachersList.map<FormSelectItem<string>>(({id, firstName, lastName}) => ({
			value: String(id),
			label: getFullName({firstName, lastName}),
		}))
	), [activePrimaryTeachersList]);

	const classesSelectItems = useMemo(() => (
		activeClassesList.map<FormSelectItem<string>>(({id, name}) => ({
			value: String(id),
			label: name,
		}))
	), [activeClassesList]);

	return (
		<>
			<Drawer.ContentBlock title='Location' withDivider>
				<FormElementSelect
					placeholder='Primary Teacher'
					control={form.controls.primaryTeacherID}
					items={teachersSelectItems}
					errorItems={teacherSelectErrorItems}
					dataCy='teacher-select'
				/>

				<FormElementSelect
					placeholder='Class'
					control={form.controls.teacherClass}
					items={classesSelectItems}
					errorItems={teacherClassesSelectErrorItems}
					key={classListUniqueKey}
					dataCy='class-select'
				/>
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Group Information' withDivider>
				<GroupNameInput groupNameControl={form.controls.groupName} />
			</Drawer.ContentBlock>
		</>
	);
}
