import {TestResults, TestResultsAdditionalEntity, TestResultsStudent, TestResultsSubject} from '@esgi/main/kits/admin';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {RowDef} from '../../../../../types';
import {useMemo} from 'react';
import {useUser} from '@esgi/core/authentication';
import {isNull} from 'underscore';
import {getFullName} from '@esgi/main/kits/common';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	selectedRow: RowDef;
	skeleton: boolean;
	hierarchy: Hierarchy;
	students: TestResultsStudent[];
	subjects: TestResultsSubject[];
};

export function ExpandedPanel({selectedRow, hierarchy, students, subjects, skeleton}: Props) {
	const user = useUser();

	const additionalInfo = useMemo<TestResultsAdditionalEntity>(
		() => ({
			class: {
				id: selectedRow.id,
				name: selectedRow.name,
			},
			...(!isNull(user) && {
				id: user.schoolID,
				name: '',
			}),
			...(!isNull(selectedRow.teacher) && {
				teacher: {
					id: selectedRow.teacher.id,
					name: getFullName({
						firstName: selectedRow.teacher.firstName,
						lastName: selectedRow.teacher.lastName,
					}),
				},
			}),
		}),
		[selectedRow, user],
	);

	return (
		<SingleSelectedExpandablePanel.ExpandedPanel>
			<TestResults
				skeleton={skeleton}
				allSubjects={subjects}
				students={students}
				hierarchy={hierarchy}
				additionalInfo={additionalInfo}
			/>
		</SingleSelectedExpandablePanel.ExpandedPanel>
	);
}
