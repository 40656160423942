import {School, useFlag} from '@esgi/ui';
import {ActionPanel} from '../action-panel';
import {Text} from '@esgi/ui/typography';
import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {HeaderContent} from './index.styled';
import {useEffect} from 'react';
import {DistrictReportsButton} from '../district-reports-button';

type Props = {
	title: string | null;
	totalItems: number;
	selectedItems: number;
	isAllSelectedAsDistrictReport?: boolean;
};

export function DistrictReportPanel({title, totalItems, selectedItems, isAllSelectedAsDistrictReport}: Props) {
	const [showPanel, openPanel, closePanel] = useFlag();

	const managerRef = CollapsiblePanel.useRef();
	const closeActionPanel = CollapsiblePanel.useClose(managerRef, () => {
		closePanel();
	});

	useEffect(() => {
		if (!selectedItems || (isAllSelectedAsDistrictReport ? totalItems !== 1 && selectedItems === totalItems : false)) {
			openPanel();
			return;
		}

		closeActionPanel();
	}, [closeActionPanel, isAllSelectedAsDistrictReport, openPanel, selectedItems, totalItems]);

	if (!showPanel) {
		return null;
	}

	return (
		<CollapsiblePanel.Root managerRef={managerRef}>
			<CollapsiblePanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<School />
						<Text size='large' color='highContrast'>
							{title}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<DistrictReportsButton />
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</CollapsiblePanel.Header>
		</CollapsiblePanel.Root>
	);
}
