import 'overlayscrollbars/overlayscrollbars.css';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useMatchMedia} from '@esgi/ui';
import {Outlet, useNavigate} from 'react-router-dom';
import {TooltipProvider} from '@esgi/ui/tooltip';
import {ThemeProvider} from '@esgi/ui/theme';
import {UserType, useUser} from '@esgi/core/authentication';
import {lazyComponent} from '@esgi/core/react';
import {ContactUs, useContactUs} from '@esgi/features/support';
import {PageContainer, ContentContainer} from './index.styled';
import {ScreenSize, RootPageContext, NotificationAlert} from '@esgi/main/kits/common';
import {VersionToggleBanner} from './components/version-toggle-banner';
import {NavigationPanelRoot} from '../navigator/teacher';
import {UserFeaturesProvider} from '../user-features-context';
import {Provider} from 'react-redux';
import {reduxStore, ClearStorageEvent} from '@esgi/main/libs/store';
import {dispatchAppEvent} from '@esgillc/events';

const TeacherDrawers = lazyComponent(() => import('../navigator/teacher/drawers'));

export default function () {
	const navigate = useNavigate();
	const isPortraitTablet = useMatchMedia('portrait-tablet');
	const isLandscapeTablet = useMatchMedia('landscape-tablet');
	const isSmallLaptop = useMatchMedia('small-laptop');

	const screenSize = isPortraitTablet
		? ScreenSize.PortraitTablet
		: isPortraitTablet || isLandscapeTablet
			? ScreenSize.Tablet
			: isPortraitTablet || isLandscapeTablet || isSmallLaptop
				? ScreenSize.Small
				: null;

	const isTablet = screenSize === ScreenSize.Tablet || screenSize === ScreenSize.PortraitTablet;

	const {isVisible, handleClose} = useContactUs();

	const rootPageContentContainerRef = useRef<HTMLDivElement>(null);

	const user = useUser();

	useEffect(() => {
		if (!user?.userID) {
			navigate('/login', {
				replace: true,
			});

			return;
		}
	}, [navigate, user]);

	const onSchoolYearChanged = useCallback(() => {
		dispatchAppEvent(ClearStorageEvent);
	}, []);

	const navigationPanel = useMemo(() => {
		switch (user?.userType) {
			case UserType.T:
				return <NavigationPanelRoot onSchoolYearChanged={onSchoolYearChanged} />;
		}
	}, [onSchoolYearChanged, user]);

	const outletContext = useMemo<RootPageContext>(
		() => ({screenSize, rootPageContentContainerRef}),
		[screenSize, rootPageContentContainerRef],
	);

	return (
		<ThemeProvider>
			<TooltipProvider>
				<UserFeaturesProvider>
					<Provider store={reduxStore}>
						{isVisible && <ContactUs ticketSubmitted={handleClose} onClose={handleClose} />}
						<VersionToggleBanner />
						<PageContainer direction={isTablet ? 'column' : 'row'} withVersionBannerOffset>
							{navigationPanel}
							<ContentContainer key={user?.globalSchoolYearID} ref={rootPageContentContainerRef}>
								<Outlet context={outletContext} />

								<TeacherDrawers screenSize={screenSize} isTablet={isTablet} />
							</ContentContainer>
						</PageContainer>

						<NotificationAlert />
					</Provider>
				</UserFeaturesProvider>
			</TooltipProvider>
		</ThemeProvider>
	);
}
