import {Drawer, getFullName} from '@esgi/main/kits/common';
import {School, User} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {classNameErrorItems} from './constants';
import {FormElement, IFormControl} from '@esgi/ui/form';
import {ErrorTooltip} from '../../../../components/error-tooltip';
import {Input as FormInput} from '@esgi/ui/form-controls';
import {Input} from '@esgi/ui/controls';
import {isUndefined} from '@esgi/ui';

type Props = {
	schools: School[];
	teachers: User[];
	primaryTeacherID: User['id'];
	schoolID: School['id'];
	classNameControl: IFormControl<string>;
};

export function ClassInformationTabContent({classNameControl, schools, teachers, primaryTeacherID, schoolID}: Props) {
	const {schoolName, teacherFullName} = useMemo(() => {
		const currentSchool = schools.find(({id}) => id === schoolID);
		const currentTeacher = teachers.find(({id}) => id === primaryTeacherID);

		const schoolName = currentSchool?.name ?? '-';
		const teacherFullName = isUndefined(currentTeacher)
			? '-'
			: getFullName({
					firstName: currentTeacher.firstName,
					lastName: currentTeacher.lastName,
			  });

		return {
			schoolName,
			teacherFullName,
		};
	}, [primaryTeacherID, schoolID, schools, teachers]);

	return (
		<>
			<Drawer.ContentBlock title='Location' withDivider>
				<Input placeholder='School' value={schoolName} disabled dataCy='school-input'/>
				<Input placeholder='Primary Teacher' value={teacherFullName} disabled dataCy='teacher-input' />
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Class Information' withDivider>
				<FormElement control={classNameControl}>
					<FormInput.CleanableIndicator placeholder='Class Name' dataCy='class-name-input' />

					{classNameErrorItems.map(({showOnError, message}) => (
						<ErrorTooltip showOnError={showOnError} message={message} key={showOnError} />
					))}
				</FormElement>
			</Drawer.ContentBlock>
		</>
	);
}
