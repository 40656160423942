import {isNaN, isNull} from 'underscore';
import {useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {DatePickerWidget, PeriodType as PeriodTypeForDatePicker, useDatePickerWidget} from '@esgi/main/kits/admin';
import {HighlightState} from './types';
import {InputsBox} from '../../../../index.styled';
import {DynamicComparePeriodsFormProps} from '../../types';
import {InputPercantage} from './components/input-percantage';
import {isUndefined} from '@esgi/ui';
import {enumRemap, mapToEnum} from 'shared/utils';
import {
	Candles,
	Checkbox,
	CheckboxesBox,
	Color,
	ColorPicker,
	Widget,
	WidgetCard,
	WidgetPeriod,
} from '@esgi/main/features/admins/dashboard';

type Props = DynamicComparePeriodsFormProps<{
	periodData: WidgetPeriod | undefined;
	highlight: Candles | null;
}>;

export function SinglePeriod({periodData, highlight, setIsFormValid, setIsFormTouched, dynamicFormDataRef}: Props) {
	const [isHighlight, setIsHighlight] = useState(!isNull(highlight));

	const {
		state,
		handleChange,
		isTouched: isDateTouched,
		isValid,
	} = useDatePickerWidget({
		value: isUndefined(periodData)
			? null
			: {
					datePeriod: mapToEnum(periodData.datePeriod, PeriodTypeForDatePicker),
					dateRange: periodData.dateRange,
					trackDatesID: periodData.trackDatesID,
				},
	});

	const [highlightState, setHighlightState] = useState<HighlightState>({
		value: highlight?.highlightIfAbove ? String(highlight.highlightIfAbove) : '50',
		color: highlight?.color ?? Color.Green,
		validationMessage: null,
	});

	useEffect(() => {
		const isHighlightCheckboxTouched = isHighlight !== !isNull(highlight);

		if (isHighlight) {
			const highlightValueTouched =
				Number(highlightState.value) !== highlight?.highlightIfAbove || highlightState.color !== highlight.color;
			const highlightValueValid = isNull(highlightState.validationMessage);

			setIsFormTouched(isDateTouched || isHighlightCheckboxTouched || highlightValueTouched);
			setIsFormValid(highlightValueValid && isValid);
			return;
		}

		setIsFormTouched(isDateTouched || isHighlightCheckboxTouched);
		setIsFormValid(isValid);
	}, [isDateTouched, highlight, highlightState, isHighlight, periodData, setIsFormTouched, setIsFormValid, isValid]);

	const handleHighlightColorChange = useCallback((color: Color | null) => {
		if (isNull(color)) {
			return;
		}

		setHighlightState((currentState) => ({...currentState, color}));
	}, []);

	useImperativeHandle(dynamicFormDataRef, () => ({
		getPeriodsFormData: () => {
			const highlightNumberedValue = Number(highlightState.value);

			const highlight: Candles | null = isNaN(highlightNumberedValue)
				? null
				: {color: highlightState.color, highlightIfAbove: highlightNumberedValue};

			return {
				candles: isHighlight ? highlight : null,
				periods: [
					{
						color: Color.None,
						trackDatesID: state.trackDatesID,
						dateRange: state.dateRange,
						datePeriod: enumRemap(state.datePeriod, PeriodTypeForDatePicker),
					},
				],
			};
		},
	}));

	return (
		<InputsBox>
			<DatePickerWidget
				datePeriod={state.datePeriod}
				dateRange={state.dateRange}
				trackDatesID={state.trackDatesID}
				onChange={handleChange}
			/>
			<CheckboxesBox>
				<Checkbox
					label='Highlight Candles'
					checked={isHighlight}
					onCheckedChange={(value) => setIsHighlight(value === 'indeterminate' ? false : value)}
				/>
			</CheckboxesBox>

			<WidgetCard.EditMode.PeriodColorBox>
				<ColorPicker
					items={Widget.colorItems}
					value={isHighlight ? highlightState.color : null}
					setValue={handleHighlightColorChange}
					disabled={!isHighlight}
				/>
				<InputPercantage
					disabled={!isHighlight}
					highlightState={highlightState}
					setHighlightState={setHighlightState}
				/>
			</WidgetCard.EditMode.PeriodColorBox>
		</InputsBox>
	);
}
