import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {
	CheckboxAndNameBox,
	Container,
	MainContentBox,
	MainSettingsBox,
	PublishOptionsSection,
	TitleBox,
	TooltipContent,
} from './index.styled';
import {
	ExpandableSelectableBlockTab,
	GradeLevel,
	SelectableListItemModel,
	SettingPanel,
	SubjectPublishValue,
	TrackDate,
} from '@esgi/main/features/admins/subject-management';
import {GridBox} from '@esgi/ui/layout';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {PanelDivider} from '../../../../components/panels.styled';
import {RadioGroup} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {DatePeriod, Level} from '@esgi/ui/icons';
import {Dispatch, useCallback, useEffect, useMemo, useState} from 'react';
import {nonActiveSubjectTipText, notAvailableDeleteMessage, retestItems, retestModesTipText} from './constants';
import {PublishForSpecialistsTabID, RetestSubjectType} from '../../types';
import {useFormValid} from './hooks/use-form-valid';
import {useUpdateSubjectName} from './hooks/use-update-subject-name';
import {isUndefined, Tooltip} from '@esgi/ui';

type Props = {
	subjectName: string;
	skeleton: boolean;
	saveButtonText: string;
	isActive: boolean;
	setIsActive: Dispatch<boolean>;
	subjectNameValue: string;
	setSubjectNameValue: Dispatch<string>;
	forbiddenSubjectsNames: string[];
	retestSubjectType: RetestSubjectType;
	setRetestSubjectType: Dispatch<RetestSubjectType>;
	isShuffleQuestions: boolean;
	setIsShuffleQuestions: Dispatch<boolean>;
	trackID: number | null;
	allTrackDates: TrackDate[];
	publishPeriodValue: SubjectPublishValue;
	setPublishPeriodValue: Dispatch<SubjectPublishValue>;
	gradeLevels: GradeLevel[];
	selectedGradeLevels: GradeLevel['id'][];
	setSelectedGradeLevels: Dispatch<GradeLevel['id'][]>;
	isPublishForAllTeachers: boolean;
	setIsPublishForAllTeachers: Dispatch<boolean>;
	isPublishForSpecialistsActive: boolean;
	setIsPublishForSpecialistsActive: Dispatch<boolean>;
	publishForSpecialistsData: ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[];
	setPublishForSpecialistsData: Dispatch<ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[]>;
	onSaveSubject: VoidFunction;
	isDataSaving: boolean;
	onRemoveSubject?: VoidFunction;
	isFormTouched?: boolean;
};

export function SettingsPanel({
	skeleton,
	subjectName,
	saveButtonText,
	subjectNameValue,
	setSubjectNameValue,
	forbiddenSubjectsNames,
	isActive,
	setIsActive,
	retestSubjectType,
	setRetestSubjectType,
	isShuffleQuestions,
	setIsShuffleQuestions,
	trackID,
	gradeLevels,
	selectedGradeLevels,
	setSelectedGradeLevels,
	allTrackDates,
	publishPeriodValue,
	setPublishPeriodValue,
	isPublishForAllTeachers,
	setIsPublishForAllTeachers,
	isPublishForSpecialistsActive,
	setIsPublishForSpecialistsActive,
	publishForSpecialistsData,
	setPublishForSpecialistsData,
	onSaveSubject,
	isDataSaving,
	onRemoveSubject,
	isFormTouched,
}: Props) {
	const {onSubjectNameUpdate, subjectNameError, subjectNameStatus} = useUpdateSubjectName({
		setValue: setSubjectNameValue,
		forbiddenSubjectsNames,
	});

	const [selectedGradeLevelsValue, setSelectedGradeLevelsValue] = useState<string[]>(selectedGradeLevels.map(String));

	useEffect(() => {
		setSelectedGradeLevelsValue((currentState) => {
			if (
				currentState.length !== selectedGradeLevels.length ||
				selectedGradeLevels.some((gradeLevel) => !currentState.includes(String(gradeLevel)))
			) {
				return selectedGradeLevels.map(String);
			}

			return currentState;
		});
	}, [selectedGradeLevels]);

	const onSetRetestSubjectType = useCallback(
		(value: string) => {
			setRetestSubjectType(value as RetestSubjectType);
		},
		[setRetestSubjectType],
	);

	const handleSelectedGradeLevelsChange = useCallback(
		(gradeLevels: string[]) => {
			setSelectedGradeLevels(gradeLevels.map(Number));
		},
		[setSelectedGradeLevels],
	);

	const gradeLevelsItems = useMemo(
		() => gradeLevels.map<SelectableListItemModel<string>>(({id, name}) => ({value: String(id), label: name})),
		[gradeLevels],
	);

	const isFormValid = useFormValid({
		isDataLoaded: !skeleton,
		subjectNameError,
		publishPeriodValue,
		selectedGradeLevels,
		isPublishForSpecialistsActive,
		publishForSpecialistsData,
		value: subjectNameValue,
	});

	return (
		<Container dataCy='subject-settings-panel'>
			<SettingPanel.Root
				subjectLevel={SubjectLevel.School}
				subjectName={subjectName}
				subjectType={SubjectType.Deployed}
				skeleton={skeleton}
			>
				<TitleBox>
					<GridBox display='inlineGrid' flow='column' align='center' gap='2'>
						<SettingPanel.SubjectName />
						<SettingPanel.Title size='small' color='mediumContrast'>
							Subject Tab Settings
						</SettingPanel.Title>
					</GridBox>

					<GridBox display='inlineGrid' flow='column' align='center' gap='4'>
						{!isUndefined(onRemoveSubject) && (
							<SettingPanel.RemoveButton
								whenShowTooltip='disabled'
								tooltipMessage={notAvailableDeleteMessage}
								disabled={isActive}
								onDeleted={onRemoveSubject}
							/>
						)}

						<SettingPanel.SaveChangesButton
							buttonText={saveButtonText}
							disabled={
								!isFormValid || (!isUndefined(isFormTouched) && !isFormTouched) || isDataSaving
							}
							onClick={onSaveSubject}
						/>
					</GridBox>
				</TitleBox>

				<PanelDivider />

				<OverlayScrollbarsComponent
					defer
					options={{
						scrollbars: {autoHide: 'leave'},
					}}
					style={{height: 'calc(100% + 0px)'}}
				>
					<MainContentBox>
						<MainSettingsBox>
							<CheckboxAndNameBox>
								<SettingPanel.SectionBlock.EntireBlock>
									<Tooltip open={isActive ? false : undefined}>
										<Tooltip.Trigger>
											<SettingPanel.Checkbox
												checked={isActive}
												onCheckedChange={setIsActive}
												label='Active'
												dataCy='active-checkbox'
											/>
										</Tooltip.Trigger>
										<TooltipContent variant='default'>
											<Text size='large'>{nonActiveSubjectTipText}</Text>
										</TooltipContent>
									</Tooltip>
								</SettingPanel.SectionBlock.EntireBlock>

								<SettingPanel.InputCleanableIndicator
									value={subjectNameValue}
									setValue={onSubjectNameUpdate}
									placeholder='Tab Name'
									status={subjectNameStatus}
									errorMessage={subjectNameError ?? undefined}
									required
									dataCy='tab-name-input'
								/>
							</CheckboxAndNameBox>

							<SettingPanel.SectionBlock.EntireBlock
								sectionTitle='Retest Modes'
								tipText={retestModesTipText}
								dataCy='retest-modes'
							>
								<RadioGroup.Root
									value={retestSubjectType}
									onValueChange={onSetRetestSubjectType}
								>
									<RadioGroup.Content>
										{retestItems.map(({value, label}) => (
											<RadioGroup.Item key={value} value={value}>
												<Text size='small' font='mono' color='currentColor'>
													{label}
												</Text>
											</RadioGroup.Item>
										))}
									</RadioGroup.Content>
								</RadioGroup.Root>
							</SettingPanel.SectionBlock.EntireBlock>

							<SettingPanel.SectionBlock.EntireBlock sectionTitle='Settings' dataCy='shuffle-questions'>
								<SettingPanel.Checkbox
									checked={isShuffleQuestions}
									onCheckedChange={setIsShuffleQuestions}
									label='Shuffle Questions'
								/>
							</SettingPanel.SectionBlock.EntireBlock>
						</MainSettingsBox>

						<PublishOptionsSection sectionTitle='Publish Options'>
							<SettingPanel.PublishPeriodDrawerable
								EntityIcon={DatePeriod}
								drawerTitle='Publish Period'
								placeholder='Publish Period'
								trackID={trackID}
								allTrackDates={allTrackDates}
								value={publishPeriodValue}
								setValue={setPublishPeriodValue}
							/>

							<SettingPanel.SelectDrawerable
								EntityIcon={Level}
								allItems={gradeLevelsItems}
								drawerTitle='Select Grade Levels'
								onSaveSelectedEntity={handleSelectedGradeLevelsChange}
								placeholder='Grade Level'
								selectedValueTranscript='Grade Levels'
								value={selectedGradeLevelsValue}
								dataCy='grade-level'
							/>

							<SettingPanel.SectionBlock.EntireBlock>
								<SettingPanel.Checkbox
									checked={isPublishForAllTeachers}
									onCheckedChange={setIsPublishForAllTeachers}
									label='Publish For All Teachers'
									dataCy='publish-for-all-teachers'
								/>
							</SettingPanel.SectionBlock.EntireBlock>

							<SettingPanel.ExpandableSelectableBlock
								value={publishForSpecialistsData}
								setValue={setPublishForSpecialistsData}
								sectionName='Publish For Specialists'
								isSectionActive={isPublishForSpecialistsActive}
								onSectionActiveChanged={setIsPublishForSpecialistsActive}
								withoutIdenticalTabsItems
								dataCy='publish-for-specialists'
							/>
						</PublishOptionsSection>
					</MainContentBox>
				</OverlayScrollbarsComponent>
			</SettingPanel.Root>
		</Container>
	);
}
