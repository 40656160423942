import {V2DistrictAdminsActionPanelController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {
	Class,
	classesStore,
	Group,
	groupsStore,
	Student,
	studentsStore,
	teachersStore,
	useClasses,
	useGroups,
	useStudents,
	useTeachers,
} from '@esgi/main/libs/admin-store';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {RemoveTeacherEvent} from '../../events';

type Out = [boolean, Dispatch<OnRemoveParameters>, boolean];

type OnRemoveParameters = {
	id: number;
	firstName: string;
	lastName: string;
	onRemoved: VoidFunction;
};

export function useRemoveTeacher(): Out {
	const [{loaded: isTeachersLoaded}] = useTeachers();
	const [{loaded: isClassesLoaded}] = useClasses();
	const [{loaded: isGroupsLoaded}] = useGroups();
	const [{loaded: isStudentsLoaded}] = useStudents();

	const controller = useDisposable(V2DistrictAdminsActionPanelController);

	const [response, request] = useCancelableRequest(controller.teachersDelete);

	const [isRemoving, setIsRemoving] = useState(false);
	const [requestParameters, setRequestParameters] = useState<OnRemoveParameters | null>(null);

	useEffect(() => {
		if (isAsyncSucceed(response) && !isNull(requestParameters)) {
			const removedClassesIDsFromStorage: number[] = [];
			const removedGroupsIDsFromStorage: number[] = [];

			classesStore().remove?.(({teacherID, id}: Class): boolean => {
				if (teacherID === requestParameters.id) {
					removedClassesIDsFromStorage.push(id);

					return true;
				}

				return false;
			});

			groupsStore().remove?.(({teacherID, id}: Group): boolean => {
				if (teacherID === requestParameters.id) {
					removedGroupsIDsFromStorage.push(id);

					return true;
				}

				return false;
			});

			teachersStore().removeById?.(requestParameters.id);

			studentsStore().update?.((item: Student): Student => {
				if (item.teacherID === requestParameters.id) {
					return {
						...item,
						teacherID: null,
						classesIDs: item.classesIDs.filter((classID) => !removedClassesIDsFromStorage.includes(classID)),
						groupsIDs: item.groupsIDs.filter((groupID) => !removedGroupsIDsFromStorage.includes(groupID)),
					};
				}

				return item;
			});

			dispatchAppEvent(
				RemoveTeacherEvent,
				new RemoveTeacherEvent({
					id: requestParameters.id,
					firstName: requestParameters.firstName,
					lastName: requestParameters.lastName,
				}),
			);

			requestParameters.onRemoved();
			setRequestParameters(null);
			setIsRemoving(false);
		}
	}, [requestParameters, response]);

	const onRemove = useCallback(
		({id, firstName, lastName, onRemoved}: OnRemoveParameters) => {
			setIsRemoving(true);

			request({
				iDs: [id],
			});

			setRequestParameters({
				id,
				firstName,
				lastName,
				onRemoved,
			});
		},
		[request],
	);

	const isDataLoaded = isTeachersLoaded && isClassesLoaded && isGroupsLoaded && isStudentsLoaded;

	return [isDataLoaded, onRemove, isAsyncPending(response) || isRemoving];
}
