import {isNull} from 'underscore';
import {useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {
	DatePickerWidget,
	useDatePickerWidgetMultiple,
	PeriodType as PeriodTypeForDatePicker,
	DatePickerData,
} from '@esgi/main/kits/admin';
import {InputsBox} from '../../../../index.styled';
import {DynamicComparePeriodsFormProps} from '../../types';
import {enumRemap, mapToEnum} from 'shared/utils';
import {Color, ColorPicker, Widget, WidgetCard, WidgetPeriod} from '@esgi/main/features/admins/dashboard';

type Props = DynamicComparePeriodsFormProps<{
	periodsData: [WidgetPeriod | null, WidgetPeriod | null];
}>;

export function MultiplePeriods({periodsData, setIsFormValid, setIsFormTouched, dynamicFormDataRef}: Props) {
	const {firstWidget, secondWidget, isDatesTouched, isDatesValid} = useDatePickerWidgetMultiple({
		initialData: periodsData.map<DatePickerData | null>((periodData) =>
			isNull(periodData)
				? null
				: {
						datePeriod: mapToEnum(periodData.datePeriod, PeriodTypeForDatePicker),
						dateRange: periodData.dateRange,
						trackDatesID: periodData.trackDatesID,
					},
		) as [DatePickerData | null, DatePickerData | null],
	});

	const [colorsState, setColorsState] = useState<[Color, Color]>(
		() =>
			periodsData.map((periodsData, index) => {
				if (isNull(periodsData) || periodsData.color === Color.None) {
					return Widget.colorItems[index]?.value ?? Color.None;
				}

				return periodsData.color;
			}) as [Color, Color],
	);

	const aggregatedData = useMemo(
		() =>
			[firstWidget, secondWidget].map((widget, index) => ({
				widget: widget,
				color: colorsState[index]!,
			})),
		[colorsState, firstWidget, secondWidget],
	);

	useEffect(() => {
		const isSomeDataTouched = colorsState.some((color, index) => {
			const isColorTouched = color !== periodsData[index]?.color;

			return isDatesTouched || isColorTouched;
		});

		setIsFormTouched(isSomeDataTouched);
	}, [periodsData, isDatesTouched, colorsState, setIsFormTouched]);

	useEffect(() => {
		setIsFormValid(isDatesValid);
	}, [isDatesValid, setIsFormValid]);

	const onChartColorChanged = useCallback(({value, index}: {value: Color | null; index: 0 | 1}) => {
		if (isNull(value)) {
			return;
		}

		setColorsState((currentState) => {
			const copiedState: [Color, Color] = [...currentState];

			copiedState[index] = value;

			return copiedState;
		});
	}, []);

	useImperativeHandle(dynamicFormDataRef, () => ({
		getPeriodsFormData: () => {
			return {
				candles: null,
				periods: aggregatedData.map(({color, widget: {state}}) => ({
					trackDatesID: state.trackDatesID,
					dateRange: state.dateRange,
					datePeriod: enumRemap(state.datePeriod, PeriodTypeForDatePicker),
					color,
				})),
			};
		},
	}));

	const excludedColors = useMemo(() => colorsState.map((color) => color), [colorsState]);

	return (
		<InputsBox>
			{aggregatedData.map(({color, widget}, index) => {
				return (
					<WidgetCard.EditMode.PeriodColorBox key={index}>
						<ColorPicker
							items={Widget.colorItems.filter(({value}) => (value === color ? true : !excludedColors.includes(value)))}
							value={color}
							setValue={(color) => onChartColorChanged({value: color, index: index as 0 | 1})}
						/>

						<DatePickerWidget
							datePeriod={widget.state.datePeriod}
							dateRange={widget.state.dateRange}
							trackDatesID={widget.state.trackDatesID}
							onChange={widget.handleChange}
						/>
					</WidgetCard.EditMode.PeriodColorBox>
				);
			})}
		</InputsBox>
	);
}
