import {ActionPanel, SchoolReportsButton} from '../../../../_kit';
import {RowDef} from '../../../types';
import {Text} from '@esgi/ui/typography';
import {AddToFolderButton, Page, RunReportButton} from '@esgi/main/features/admins/data';

type Props = {
	onFolderAddClick: VoidFunction;
};

export function BottomPanel({onFolderAddClick}: Props) {
	const {api, entityName} = Page.useContext<RowDef>();
	const totalRows = api?.getDisplayedRowCount();
	const selectedRows = api?.getSelectedRows();

	return (
		<ActionPanel.Root api={api} showOnMinSelectedItems={2}>
			<ActionPanel.Header>
				<ActionPanel.ContentBox>
					<ActionPanel.CloseButton />

					<ActionPanel.MainBox>
						<ActionPanel.CounterBox />

						<Text size='xSmall' font='mono' color='primary'>
							{entityName} Selected
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<ActionPanel.ShowOnlySelectedCheckbox />
						<ActionPanel.ActionButtonDivider />

						<AddToFolderButton onClick={onFolderAddClick} />

						{selectedRows?.length === totalRows ? (
							<SchoolReportsButton />
						) : (
							<RunReportButton
								title='Run Reports'
								disabledMessage='Multiple teachers must be added to a folder from which reports can be run.'
							/>
						)}
					</ActionPanel.ActionButtonsBox>
				</ActionPanel.ContentBox>
			</ActionPanel.Header>
		</ActionPanel.Root>
	);
}
