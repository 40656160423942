import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../../types';
import {NoStudentsAlert} from '../../../../../../_kit';
import {getFullName} from '@esgi/main/kits/common';
import {ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';

type Props = {
	teacher: RowDef;
	onClose: VoidFunction;
	hierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function ReportsFeature({teacher, onClose, hierarchy, subject}: Props) {
	if (!teacher.students) {
		return <NoStudentsAlert onClose={onClose} />;
	}

	return (
		<ReportDialog
			header={getFullName({firstName: teacher.firstName, lastName: teacher.lastName})}
			onClose={onClose}
			subject={subject}
		>
			<ReportDialog.Section title='Teacher Reports'>
				<Reports.ItemAnalysis hierarchy={hierarchy} description='Analyze data based on least and most known items' />
				<Reports.PieCharts hierarchy={hierarchy} description='Access a printable report of homepage pie charts' />
				<Reports.UntestedStudents
					hierarchy={hierarchy}
					description='Identify untested students to administer assessments'
				/>
			</ReportDialog.Section>

			<ReportDialog.Section title='Student Activities'>
				<Reports.Bingo hierarchy={hierarchy} />
				<Reports.WritingPractice hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
