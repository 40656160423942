import {School, useFlag} from '@esgi/ui';
import {ActionPanel} from '../action-panel';
import {Text} from '@esgi/ui/typography';
import {GridApi} from 'ag-grid-community';
import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {HeaderContent} from './index.styled';
import {useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {SchoolReportsButton} from '../school-reports-button';

type Props<T extends Record<string, unknown>> = {
	api: GridApi<T> | null;
	schoolName: string;
};

export function SchoolReportPanel<T extends Record<string, unknown>>({schoolName, api}: Props<T>) {
	const [showPanel, openPanel, closePanel] = useFlag();

	const [selectedItemsCount, setSelectedItems] = useState(0);

	const managerRef = CollapsiblePanel.useRef();
	const closeActionPanel = CollapsiblePanel.useClose(managerRef, () => {
		closePanel();
	});

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const handler = () => {
			const selectedRows = api.getSelectedRows();
			setSelectedItems(selectedRows?.length || 0);
		};

		api.addEventListener('selectionChanged', handler);

		return () => api.removeEventListener('selectionChanged', handler);
	}, [api]);

	useEffect(() => {
		if (!selectedItemsCount) {
			openPanel();
			return;
		}

		closeActionPanel();
	}, [closeActionPanel, openPanel, selectedItemsCount]);

	if (!showPanel) {
		return null;
	}

	return (
		<CollapsiblePanel.Root managerRef={managerRef}>
			<CollapsiblePanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<School />
						<Text size='large' color='highContrast'>
							{schoolName}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<SchoolReportsButton />
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</CollapsiblePanel.Header>
		</CollapsiblePanel.Root>
	);
}
