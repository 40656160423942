import {Modal} from '@esgi/deprecated/ui-kit/modal';
import React, {useEffect, useState} from 'react';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Primary} from '@esgi/deprecated/elements/buttons';
import {GrowthReportPrintService, Options} from 'modules/reports/growth-report/services/growth-report-print-service';
import {Printer} from 'modules/reports/growth-report/print-module/print-module';
import {ReportModal, ReportModalCloseIcon, ReportModalHeader, ReportModalTitle} from '@esgi/deprecated/ui-kit/report/modal';
import GrowthReport from 'modules/reports/growth-report/growth-report';
import {GrowthReportService} from 'modules/reports/growth-report/services/growth-report-service';
import {useServiceFactory} from '@esgi/core/service';
import {Loader, ServiceLoader} from '@esgillc/ui-kit/loader';
import styles from './growth-report.module.less';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {GrowthReportOptions, Subject} from 'modules/reports/growth-report/types';
import {useUser} from '@esgi/core/authentication';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';

interface GrowthReportModalProps {
	showNumberOfStudents: string;
	subjectId: number;
	subjectType: string;
	trackId: number;
	onCloseClicked: () => void;
	hierarchy: HierarchyInstance;
	subjects?: Subject[]
}

export default function GrowthReportModal (props: GrowthReportModalProps) {
	const modalManager = useModal();
	const currentUser = useUser();
	const close = useCloseModal(modalManager, props.onCloseClicked);
	const growthReportService = useServiceFactory(() => new GrowthReportService(props.subjects));
	const isLoading = useBehaviorSubject(growthReportService.isLoading);

	useEffect(() => {
		let options: GrowthReportOptions = new GrowthReportOptions();
		options.districtId = currentUser.districtID
		options.globalSchoolYearId = currentUser.globalSchoolYearID
		options.showNumberOfStudents = props.showNumberOfStudents
		options.subjectId = props.subjectId
		options.subjectType = props.subjectType
		options.trackId = props.trackId
		options.hierarchy = props.hierarchy.snapshot;
		growthReportService.initOptions(options);
	}, [])

	const printClicked = () => {
		let options = new Options();
		options.width = 950;
		options.thead = document.getElementById('growth-report-table-head');
		options.tbody = document.getElementById('growth-report-table-body');

		let generator = new GrowthReportPrintService(options);
		let printer = new Printer(false);

		printer.print(generator);
	}

	const downloadExcelClicked = () => {
		growthReportService.downloadExcel();
	}

	return <>
		{isLoading && <Loader show={isLoading} fullscreen/>}
		{!isLoading && <ReportModal modalManagerRef={modalManager}>
			<Modal modalManagerRef={modalManager}>
				<ReportModalHeader>
					<ReportModalTitle>Growth Report</ReportModalTitle>
					<div>
						<Primary className={styles.whiteTransparentBtn} title='PRINT' onClick={() => {
							printClicked();
						}}><i className='fa fa-print' aria-hidden='true'/></Primary>
						<Primary className={styles.whiteTransparentBtn} title='DOWNLOAD EXCEL' onClick={() => {
							downloadExcelClicked();
						}}><i className='fa fa-cloud-download' aria-hidden='true'/></Primary>
					</div>
					<ReportModalCloseIcon onClick={close}/>
				</ReportModalHeader>
				<Modal.Body>
					<div>
						<GrowthReport growthReportService={growthReportService}/>
					</div>
					<ServiceLoader trackingService={growthReportService} fullscreen/>
				</Modal.Body>
				<Modal.Footer />
			</Modal>
		</ReportModal>}
	</>
}
