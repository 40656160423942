import {TestsPanel} from '@esgi/main/features/admins/subject-management';
import {PanelContainer, PanelDivider} from '../../components/panels.styled';
import {SettingsPanel} from './settings-panel';
import {SelectedSubject} from '../../types';

type Props = {
	subject: SelectedSubject;
};

export function Teacher({subject}: Props) {
	return (
		<>
			<SettingsPanel subject={subject} />

			<PanelContainer dataCy='subject-tests-panel'>
				<TestsPanel.Root tests={subject.tests}>
					<TestsPanel.SectionTitle.Root>
						<TestsPanel.SectionTitle.Title size='small' color='mediumContrast'>
							Subject Tab Tests
						</TestsPanel.SectionTitle.Title>
					</TestsPanel.SectionTitle.Root>

					<PanelDivider />

					<TestsPanel.Content.ViewMode />
				</TestsPanel.Root>
			</PanelContainer>
		</>
	);
}
