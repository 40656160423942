import {styled} from '@esgi/ui/theme';
import {Drawer} from '@esgi/main/kits/common';
import {expandablePanelWidth} from '../constants';

export const IconWrapper = styled('span', {
	lineHeight: '12px',
	'& > svg': {
		width: 24,
		height: 24,
		fill: '$neutral56',

		'& > path': {
			fill: '$neutral56',
		},
	},
});

export const DrawerPanel = styled(Drawer.Panel, {
	variants: {
		isExpandable: {
			true: {
				marginLeft: expandablePanelWidth,
				width: `calc(100% - ${expandablePanelWidth}px)`,
				gridTemplateAreas: `
				"header"
				"content"
			`,
				gridTemplateColumns: 'auto',
				gridTemplateRows: 'auto 1fr',
			},
			false: {},
		},
	},
});
