import {Checkbox, Dropdown, Option} from '@esgillc/ui-kit/control';
import styles from './report-options.module.less';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {Subject, Track} from 'modules/reports/growth-report/types';
import {GrowthReportService} from 'modules/reports/growth-report/services/growth-report-service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';

interface ReportOptionsProps {
	growthReportService: GrowthReportService;
}

export default function ReportOptions(props: ReportOptionsProps) {
	const {growthReportService} = props;
	const showMarkingPeriods = useBehaviorSubject(growthReportService.showMarkingPeriods);
	const showNumberOfStudents = useBehaviorSubject(growthReportService.showNumberOfStudents);
	const selectedTrack = useBehaviorSubject(growthReportService.selectedTrack);
	const tracks = useBehaviorSubject(growthReportService.tracks);
	const selectedSubjectId = useBehaviorSubject(growthReportService.subjectId);
	const subjectType = useBehaviorSubject(growthReportService.subjectType);

	const showNumberOfStudentsClicked = (e) => {
		growthReportService.changeShowNumberOfStudents(e.target.checked)
	}

	const showMarkingPeriodsClicked = (e) => {
		growthReportService.changeShowMarkingPeriods(e.target.checked)
	}

	const onTrackSelect = (track: Track) => {
		growthReportService.changeSelectedTrack(tracks.find(s => s.trackID === track.trackID));
	}

	const onSubjectSelect = (subject: Subject) => {
		growthReportService.setSubject(subject);
	}
	
	return (
		<div className={styles.reportInfo}>
			<div className={styles.reportOptions}>
				<Checkbox checked={showMarkingPeriods} onChange={(e) => showMarkingPeriodsClicked(e)}>
					<span className={styles.lbl}>Show Marking Periods</span>
					<OnHoverTooltip message={<div>
						<span className={styles.textAlignJustify}>The average scores in the columns of this report will be based on the marking periods in the track chosen here (for students tested at least twice).</span><br/>
						<span>For more information: <a
							href='https://support.esgisoftware.com/hc/en-us/articles/115001522066'
							target='_blank' rel='noreferrer'>Support on Growth Report</a></span>
					</div>}>
							<span className={styles.helperContainer}>
								<i className='fa fa-question-circle' aria-hidden='true'/>
							</span>
					</OnHoverTooltip>
				</Checkbox>
				{showMarkingPeriods && <Dropdown value={[selectedTrack]} optionName='name' autoWidth disabled={!showMarkingPeriods} setValue={((track) => onTrackSelect(track[0]))}>
					{tracks?.map(track => <Option key={`track-growth-report-option-${track?.trackID}`} value={track}>{track?.name}</Option>)}
				</Dropdown>}
				{growthReportService.allSubjects?.length > 1 && <div className={styles.subjectsContainer}>
					<span className={styles.lbl}>Subject Tab:</span>
					<Dropdown value={[growthReportService.allSubjects.find(t => t.id === selectedSubjectId && t.type === subjectType)]} optionName='name' autoWidth setValue={((subject) => onSubjectSelect(subject[0]))}>
						{growthReportService.allSubjects.map(subject => <Option key={`subject-growth-report-option-${subject?.id}-${subject?.type}`} value={subject}>{subject?.name}</Option>)}
					</Dropdown>
				</div>}
				<Checkbox checked={showNumberOfStudents} onChange={(e) => showNumberOfStudentsClicked(e)}>
					<span className={styles.lbl}>Show Number of Students</span>
					<OnHoverTooltip message={<div>
						<span className={styles.textAlignJustify}>Checking this box will add two more columns:</span><br/>
						<span className={styles.textAlignJustify}>Students (Number of students assigned ot the teacher)</span><br/>
						<span className={styles.textAlignJustify}>S2 (Number of students who have at least two test sessions)</span><br/>
						<span>For more information: <a
							href='https://support.esgisoftware.com/hc/en-us/articles/115001522066'
							target='_blank' rel='noreferrer'>Support on Growth Report</a></span>
					</div>}>
							<span className={styles.helperContainer}>
								<i className='fa fa-question-circle' aria-hidden='true'/>
							</span>
					</OnHoverTooltip>
				</Checkbox>
			</div>
		</div>

	)
}
