import {useSubjects, Subject, isDeployedSubject, isStockSubject} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';

type Out = [Subject[], Subject | null, boolean];

export function useFilteredStoreSubjects(): Out {
	const [{data: subjects, loaded: isSubjectsLoaded}] = useSubjects();

	return useMemo<Out>(() => {
		if (!isSubjectsLoaded) {
			return [[], null, false];
		}

		const filteredSubjects = subjects.filter((subject) => {
			if (isDeployedSubject(subject)) {
				const {hidden, isPublished, tests} = subject;

				return !hidden && isPublished && tests.length;
			}

			if (isStockSubject(subject)) {
				const {hidden, tests} = subject;

				return !hidden && tests.length;
			}

			return false;
		});

		const firstSubject = filteredSubjects[0];

		return [filteredSubjects, firstSubject ?? null, true];
	}, [isSubjectsLoaded, subjects]);
}
