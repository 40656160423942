import {Content} from './components/content';
import {entityName} from '../constants';
import {BasePageProps} from '../types';
import {Dispatch} from 'react';
import {RowDef} from '../../types';
import {useDataPageState, Page} from '@esgi/main/features/admins/data';

type Props = BasePageProps<{
	editButtonDisabled: boolean;
	onEditClick: Dispatch<RowDef>;
}>;

export function Base({
	allTableRows,
	isDataLoaded,
	columnDefs,
	userCanAddAndDeleteTeachers,
	editButtonDisabled,
	onOpenAddDrawer,
	onEditClick,
}: Props) {
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	return (
		<Page.Root
			columnDefs={columnDefs}
			entityName={entityName}
			initialState={initialState}
			tableRows={allTableRows}
			isDataLoaded={isDataLoaded && isInitialStateReady}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<Content
				userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
				editButtonDisabled={editButtonDisabled}
				onOpenAddDrawer={onOpenAddDrawer}
				onEditClick={onEditClick}
			/>
		</Page.Root>
	);
}
