import {isUndefined} from '@esgi/ui';
import {Hierarchy, HierarchyMode} from '../types';

export function getHierarchyMode({
	preAssessID,
	specialistsID,
	specialistsFolderID,
	specialistGroup,
	specialistType,
	preAssessGroupID,
}: Hierarchy): HierarchyMode {
	if (
		!isUndefined(specialistsID) ||
		!isUndefined(specialistsFolderID) ||
		!isUndefined(specialistGroup) ||
		!isUndefined(specialistType)
	) {
		return HierarchyMode.Specialist;
	}

	if (!isUndefined(preAssessID) || !isUndefined(preAssessGroupID)) {
		return HierarchyMode.PreAssess;
	}

	return HierarchyMode.Classic;
}
