import {ExpandedTestCard} from '../../../expanded-test-card';
import {AdaptedTestCard} from '../../types';
import {EmptyContent} from '../../../empty-content';
import {CardsContent} from './index.styled';
import {OnMoveTestToSubject, OnRemoveTest, SelectedStudent} from '../../../../types/section';
import {SchoolSubject, SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {TestPanel} from '@esgi/main/kits/test-results-panel';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	selectedSubject: SubjectModel;
	selectedStudent: SelectedStudent;
	schoolSubjects: SchoolSubject[];
	onMoveToTest: OnMoveTestToSubject;
	onRemoveTest: OnRemoveTest;
	hierarchy: Hierarchy;
};

export function TestCardsContent({
	selectedSubject,
	selectedStudent,
	schoolSubjects,
	onMoveToTest,
	onRemoveTest,
	hierarchy,
}: Props) {
	const {filteredCards, allTestCards} = TestPanel.useTestPanelContext<AdaptedTestCard>();

	const isEmptyDataContent = !allTestCards.length || !filteredCards.length;

	const renderContent = () => {
		if (!allTestCards.length) {
			return <EmptyContent message='No tests are available in the selected subject' />;
		}

		if (!filteredCards.length) {
			return <EmptyContent message='No tests match the selected parameters' />;
		}

		return (
			<>
				{filteredCards.map(({testInfo, lastTestSessions, analyticsData, studentResult, studentsResult}) => (
					<ExpandedTestCard
						testInfo={testInfo}
						lastTestSessions={lastTestSessions}
						analyticsData={analyticsData}
						studentResult={studentResult}
						studentsResult={studentsResult}
						selectedSubject={selectedSubject}
						selectedStudent={selectedStudent}
						schoolSubjects={schoolSubjects}
						onMoveToTest={({previousSubjectID, newSubjectID}) => {
							onMoveToTest({
								previousSubjectID,
								newSubjectID,
								testID: testInfo.id,
								subjectType: selectedSubject.type,
							});
						}}
						onRemoveTest={() => {
							onRemoveTest({
								subjectID: selectedSubject.id,
								subjectType: selectedSubject.type,
								testID: testInfo.id,
							});
						}}
						hierarchy={hierarchy}
						key={testInfo.id}
					/>
				))}
			</>
		);
	};

	return <CardsContent isEmptyContent={isEmptyDataContent}>{renderContent()}</CardsContent>;
}
