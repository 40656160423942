import {useCallback, useState} from 'react';
import {SpecialistGroupDrawer, useDataPageState} from '@esgi/main/features/school-admin/data';
import {useColumnDefinitions} from './use-column-definitions';
import {entityName} from './constants';
import {Base} from './variant/base';
import {useData} from './use-data';
import {Page} from '@esgi/main/features/admins/data';

export default function () {
	const {ready, rows, specialists} = useData();
	const colDefs = useColumnDefinitions(rows, specialists);

	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	const [isOpenAddSpecialistGroupDrawer, setIsOpenAddSpecialistGroupDrawer] = useState(false);

	const openAddSpecialistGroup = useCallback(() => {
		setIsOpenAddSpecialistGroupDrawer(true);
	}, []);

	const closeAddSpecialistGroup = useCallback(() => {
		setIsOpenAddSpecialistGroupDrawer(false);
	}, []);

	return (
		<Page.Root
			columnDefs={colDefs}
			entityName={entityName}
			tableRows={rows}
			isDataLoaded={ready && isInitialStateReady}
			initialState={initialState}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<Base onOpenAddDrawer={openAddSpecialistGroup} />

			{isOpenAddSpecialistGroupDrawer && <SpecialistGroupDrawer.Add onClose={closeAddSpecialistGroup} />}
		</Page.Root>
	);
}
