import {Drawer, getFullName} from '@esgi/main/kits/common';
import {User} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';
import {classNameErrorItems} from './constants';
import {FormElement, IFormControl} from '@esgi/ui/form';
import {ErrorTooltip} from '../../../../components/error-tooltip';
import {Input as FormInput} from '@esgi/ui/form-controls';
import {Input} from '@esgi/ui/controls';
import {isUndefined} from '@esgi/ui';

type Props = {
	teachers: User[];
	primaryTeacherID: User['id'];
	classNameControl: IFormControl<string>;
};

export function ClassInformationTabContent({
	classNameControl,
	teachers,
	primaryTeacherID,
}: Props) {
	const {teacherFullName} = useMemo(() => {
		const currentTeacher = teachers.find(({id}) => id === primaryTeacherID);

		const teacherFullName = isUndefined(currentTeacher)
			? '-'
			: getFullName({
				firstName: currentTeacher.firstName,
				lastName: currentTeacher.lastName,
			  });

		return {
			teacherFullName,
		};
	}, [primaryTeacherID, teachers]);

	return (
		<>
			<Drawer.ContentBlock title='Primary Teacher' withDivider>
				<Input placeholder='Primary Teacher' value={teacherFullName} disabled dataCy='teacher-input' />
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Class Information' withDivider>
				<FormElement control={classNameControl}>
					<FormInput.CleanableIndicator placeholder='Class Name' dataCy='class-name-input' />

					{classNameErrorItems.map(({showOnError, message}) => (
						<ErrorTooltip
							showOnError={showOnError}
							message={message}
							key={showOnError}
						/>
					))}
				</FormElement>
			</Drawer.ContentBlock>
		</>
	);
}
