import {TestResults, TestResultsAdditionalEntity, TestResultsStudent, TestResultsSubject} from '@esgi/main/kits/admin';
import {RowDef} from '../../../../../types';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	selectedRow: RowDef;
	skeleton: boolean;
	hierarchy: Hierarchy;
	students: TestResultsStudent[];
	subjects: TestResultsSubject[];
};

export function ExpandedPanel({selectedRow, hierarchy, skeleton, students, subjects}: Props) {
	const additionalInfo = useMemo<TestResultsAdditionalEntity>(
		() => ({
			...(selectedRow.school && {
				school: {
					id: selectedRow.school.id,
					name: selectedRow.school.name,
				},
			}),
			specialistGroup: {
				id: selectedRow.id,
				name: selectedRow.name,
			},
		}),
		[selectedRow],
	);

	return (
		<SingleSelectedExpandablePanel.ExpandedPanel>
			<TestResults
				skeleton={skeleton}
				allSubjects={subjects}
				students={students}
				hierarchy={hierarchy}
				additionalInfo={additionalInfo}
			/>
		</SingleSelectedExpandablePanel.ExpandedPanel>
	);
}
