import {RowDef} from '../../../../../types';
import {QuickPanel} from './quick-panel';
import {TestResults} from '@esgi/main/kits/admin';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useSpecialistGroups} from '@esgi/main/libs/school-admin-store';
import {UserType} from '@esgi/core/authentication';
import {useSelectedSpecialistData} from '../../../../use-selected-specialist-data';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
};

export function ContentWrapper({isPanelExpanded, onFolderAddClick, selectedRow}: Props) {
	const {isSelectedSpecialistDataReady, students, subjects, reportSubject} = useSelectedSpecialistData({selectedRow});

	const [{data: specialistsGroups, loaded: isSpecialistsGroupsLoaded}] = useSpecialistGroups();

	const hierarchy = useMemo<Hierarchy>(() => {
		const specialistGroup = specialistsGroups.find(({specialistID}) => specialistID === selectedRow.id);

		const h: Hierarchy = {
			specialistsID: selectedRow.id,
			...(specialistGroup && {
				specialistGroup: {
					id: specialistGroup.id,
					name: specialistGroup.name,
					specialistID: specialistGroup.specialistID,
					type: UserType.ISS,
				},
			}),
		};

		return h;
	}, [selectedRow, specialistsGroups]);

	const skeleton = !isSelectedSpecialistDataReady || !isSpecialistsGroupsLoaded;

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={skeleton}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={skeleton}
					allSubjects={subjects}
					students={students}
					hierarchy={hierarchy}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
