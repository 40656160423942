import {Drawer, getFullName} from '@esgi/main/kits/common';
import {EditGroupForm} from '../form-data/types';
import {GroupNameInput} from '../../../components/group-name-input';
import {Input} from '@esgi/ui/controls';
import {Class, User} from '@esgi/main/libs/school-admin-store';

type Props = {
	form: EditGroupForm;
	currentClass: Class;
	primaryTeacher: User;
};

export function GroupInformationTabContent({form, currentClass, primaryTeacher}: Props) {
	return (
		<>
			<Drawer.ContentBlock title='Location' withDivider>
				<Input
					placeholder='Primary Teacher'
					value={getFullName({
						firstName: primaryTeacher.firstName,
						lastName: primaryTeacher.lastName,
					})}
					disabled
					dataCy='teacher-input'
				/>
				<Input placeholder='Class' value={currentClass.name} disabled dataCy='class-input' />
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Group Information' withDivider>
				<GroupNameInput groupNameControl={form.controls.groupName} />
			</Drawer.ContentBlock>
		</>
	);
}
