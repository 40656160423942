import {RowDef} from '../../../../../types';
import {QuickPanel} from './quick-panel';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {TestResults} from '@esgi/main/kits/admin';
import {useSelectedSpecialistData} from '../../../../use-selected-specialist-data';
import {Folder} from '@esgi/main/features/admins/data';

type Props = {
	isPanelExpanded: boolean;
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	activeFolder: Folder;
};

export function ContentWrapper({
	isPanelExpanded,
	onFolderAddClick,
	onRemoveFromFolderClick,
	selectedRow,
	activeFolder,
}: Props) {
	const {activitiesHierarchy, hierarchy, skeleton, students, subjects, reportSubject} = useSelectedSpecialistData({
		selectedRow,
		activeFolder,
	});

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				onRemoveFromFolderClick={onRemoveFromFolderClick}
				activitiesHierarchy={activitiesHierarchy}
				reportSubject={reportSubject}
				reportsHierarchy={hierarchy}
				skeleton={skeleton}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={skeleton}
					allSubjects={subjects}
					students={students}
					hierarchy={hierarchy}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
