import {Alert} from '@esgi/ui/alert';
import {SpecialistGroup} from '@esgi/main/libs/school-admin-store';
import {useCallback} from 'react';
import {useRemoveSpecialistGroup} from '../../../utils';
import {RemoveSingleEntityAlert} from '@esgi/main/features/admins/data';

type Props = {
	onRemoved: VoidFunction;
	onClose: VoidFunction;
	potentialRemovedSpecialistGroup: SpecialistGroup;
};

export function DeleteSpecialistGroupAlert({onClose, onRemoved, potentialRemovedSpecialistGroup}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isSpecialistGroupDataLoaded, removeSpecialistGroup, isSpecialistGroupRemoving] = useRemoveSpecialistGroup();

	const onRemoveSpecialistGroup = useCallback(() => {
		removeSpecialistGroup({
			id: potentialRemovedSpecialistGroup.id,
			name: potentialRemovedSpecialistGroup.name,
			onRemoved: () => {
				onClose();
				onRemoved();
			},
		});
	}, [onClose, onRemoved, potentialRemovedSpecialistGroup, removeSpecialistGroup]);

	return (
		<RemoveSingleEntityAlert
			skeleton={!isSpecialistGroupDataLoaded}
			alertRef={alertRef}
			closeAlert={closeAlert}
			title='Delete Specialist Group?'
			entityName={potentialRemovedSpecialistGroup.name}
			bodyMessage={[
				'Are you sure you want to delete the following specialist group?',
				'This will NOT delete students and their data. The students still will be available and can be added to another specialist group.',
			]}
			isRemoving={isSpecialistGroupRemoving}
			onRemove={onRemoveSpecialistGroup}
		/>
	);
}
