import {useEffect, useState} from 'react';
import {Buttons} from '@esgillc/ui-kit/button';
import {useService} from '@esgi/core/service';
import {Loader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {TrackForm} from '../../components/track-form';
import {ProfileLoginInfo} from '../../components/login-info';
import {ProfileUserInfo} from '../../components/user-info';
import styles from './styles.module.less';
import {ProfileService} from './service';
import {DefaultProfileControllers} from '../../types';

type Props = {
	userID: number;
	onClose: () => void;
	title: string;
	defaultController: DefaultProfileControllers;
	canSeeTracks?: boolean;
	canEditTracks?: boolean;
	isSchoolNameHidden?: boolean;
	isExpirationDateHidden?: boolean;
	isUserInfoDisabled?: boolean
}

export default function Profile({
	userID,
	onClose,
	title,
	defaultController,
	canSeeTracks = false,
	canEditTracks = false,
	isSchoolNameHidden = false,
	isUserInfoDisabled = false,
}: Props) {
	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClose);
	const service = useService(ProfileService);

	const states = useBehaviorSubject(service.states);
	const countries = useBehaviorSubject(service.countries);
	const tracks = useBehaviorSubject(service.tracks);
	const periods = useBehaviorSubject(service.periods);
	const schoolYearTypes = useBehaviorSubject(service.schoolYearTypes);

	const {agreementLevelCode, expirationDate, schoolName, isPreassess, notRenewable} = useBehaviorSubject(service.userInfo);
	const district = useBehaviorSubject(service.district);

	const [isLoading, setLoading] = useState(false);
	const [isInitializing, setInitializing] = useState(false);

	useEffect(() => {
		setInitializing(true);
		service.init(userID, defaultController, isUserInfoDisabled).subscribe(() => {
			setInitializing(false);
			service.userInfoForm.controls.country.onChanged.subscribe((v) => {
				if (v.prevState.value[0]?.id !== v.currState.value[0].id) {
					setLoading(true);
					service.getStatesByCountryID(v.currState.value[0].id).subscribe({
						complete: () => {
							setLoading(false);
						},
					});
				}
			});
		});
	}, []);

	const onSubmit = () => {
		setLoading(true);
		service.validateForms()
			.subscribe(isValid => {
				if (isValid) {
					service.update().subscribe({
						next: (value) => {
							if (value.isSuccess){
								handleClose();
							}
						},
						complete: () => {
							setLoading(false);
						},
					});
				} else {
					setLoading(false);
				}
			});
	};

	return <>
		<Loader show={isLoading || isInitializing} fullscreen className={styles.loader}/>
		{!isInitializing && <Modal modalManagerRef={modalRef}>
			<Modal.Header>
				<Title>{title}</Title>
				<CloseIcon onClick={handleClose}/>
			</Modal.Header>

			<Modal.Body className={styles.body}>
				<div className={styles.row}>
					<div>
						<div className={styles.labelRow}>
							<label className={styles.label}>District</label>
							<label className={styles.labelValue}>{district.name}</label>
						</div>
						{!isSchoolNameHidden && <div className={styles.labelRow}>
							<label className={styles.label}>School</label>
							<label className={styles.labelValue}>{schoolName}</label>
						</div>}
					</div>
					<div className={styles.expirationInfo}>
						Expiration Date {expirationDate} {!notRenewable && <Buttons.Contained onClick={() => {
							location.href = `/renewal?page=activationcode`;
					}}>RENEW</Buttons.Contained>}
					</div>
				</div>

				<ProfileUserInfo
					countries={countries}
					states={states}
					form={service.userInfoForm}
				/>

				{canSeeTracks && <TrackForm
					form={service.trackInfoForm}
					tracks={tracks}
					districtID={district.id}
					schoolYearTypes={schoolYearTypes}
					periods={periods}
					isAbleToAddEdit={canEditTracks || !isPreassess && agreementLevelCode === 'D'}
				/>}

				<ProfileLoginInfo
					form={service.loginInfoForm}
				/>
			</Modal.Body>

			<Modal.Footer>
				<Buttons.Gray onClick={handleClose}>CANCEL</Buttons.Gray>
				<Buttons.Contained onClick={onSubmit}>SAVE</Buttons.Contained>
			</Modal.Footer>
		</Modal>}
	</>;
}
