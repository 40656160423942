import {TestResultsAdditionalEntity, TestResultsStudent} from '@esgi/main/kits/admin';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';
import {RowDef} from '../types';
import {getFullName} from '@esgi/main/kits/common';
import {useUser} from '../../../../../../../../libs/core/src/authentication';
import {useTestResultsSubject} from '../../_kit';
import {isNull} from 'underscore';

type Parameters = {
	selectedRow: RowDef;
};

export function useSelectedTeacherData({selectedRow}: Parameters) {
	const user = useUser();

	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({teacherID}) => selectedRow.id === teacherID)
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {
		isReady: isTestResultsSubjectReady,
		subjects: testResultsSubjects,
		reportSubject,
	} = useTestResultsSubject({
		userID: selectedRow.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter: 'checkOnAllowedToAllTeachers',
	});

	const testResultsAdditionalInfo = useMemo<TestResultsAdditionalEntity>(
		() => ({
			...(!isNull(user) && {
				school: {
					id: user.schoolID,
					name: '',
				},
			}),
			teacher: {
				id: selectedRow.id,
				name: getFullName({
					firstName: selectedRow.firstName,
					lastName: selectedRow.lastName,
				}),
			},
		}),
		[selectedRow, user],
	);

	return {
		subjects: testResultsSubjects,
		students: filteredStudents,
		isSelectedTeacherDataReady: isTestResultsSubjectReady && isStudentsLoaded,
		testResultsAdditionalInfo,
		reportSubject,
	};
}
