import {RowDef} from '../types';
import {NoStudentsAlert} from '../../_kit';
import {ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {getFullName} from '@esgi/main/kits/common';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {UserType} from '@esgi/core/authentication';

type Props = {
	specialist: RowDef;
	onClose: VoidFunction;
	reportsHierarchy: Hierarchy;
	activitiesHierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function ReportsFeature({specialist, onClose, activitiesHierarchy, reportsHierarchy, subject}: Props) {
	if (!specialist.students) {
		return <NoStudentsAlert onClose={onClose} prefix={`${specialist.firstName} ${specialist.lastName} group`} />;
	}

	const specialistType = specialist.type.id;
	const isSpecialist = specialistType === UserType.ISS || specialistType === UserType.ISD;

	return (
		<ReportDialog
			onClose={onClose}
			header={getFullName({
				firstName: specialist.firstName,
				lastName: specialist.lastName,
			})}
			subject={subject}
		>
			<ReportDialog.Section title='Specialist Reports'>
				<Reports.ItemAnalysis
					hierarchy={reportsHierarchy}
					description='Analyze data based on least and most known items'
				/>
				<Reports.PieCharts
					hierarchy={reportsHierarchy}
					description='Access a printable report of homepage pie charts'
				/>
				<Reports.UntestedStudents
					hierarchy={reportsHierarchy}
					description='Identify untested students to administer assessments'
				/>
			</ReportDialog.Section>

			<ReportDialog.Section title='Student Activities'>
				<Reports.Bingo hierarchy={activitiesHierarchy} />
				{isSpecialist && <Reports.WritingPractice hierarchy={activitiesHierarchy} />}
			</ReportDialog.Section>
		</ReportDialog>
	);
}
