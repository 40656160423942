import {useSpecialistGroups, SpecialistGroup} from '@esgi/main/libs/school-admin-store';
import {isUndefined, useFlag} from '@esgi/ui';
import {useCallback, useImperativeHandle, useState} from 'react';
import {DeleteSpecialistGroupAlert} from './components/delete-specialist-group-alert';
import {isNull} from 'underscore';
import {RemoveSpecialistGroupManagerRef} from './types';
import {WarningAlert} from '@esgi/main/features/admins/data';

type Props = {
	managerRef: RemoveSpecialistGroupManagerRef;
	onRemoved: VoidFunction;
};

export function RemoveSpecialistGroupImpl({onRemoved, managerRef}: Props) {
	const [{data: specialistGroups}] = useSpecialistGroups();

	const [potentialRemovedSpecialistGroup, setPotentialRemovedSpecialistGroup] = useState<SpecialistGroup | null>(null);

	const [showImpossibleDeleteAlert, openImpossibleDeleteAlert, closeImpossibleDeleteAlert] = useFlag();

	const closeDeleteAlert = useCallback(() => {
		setPotentialRemovedSpecialistGroup(null);
	}, []);

	useImperativeHandle(managerRef, () => ({
		runRemove: ({specialistGroupID}) => {
			const specialistGroup = specialistGroups.find(({id}) => id === specialistGroupID);

			if (isUndefined(specialistGroup)) {
				throw new Error('specialistGroup is not found in admin store');
			}

			const specialistGroupsList = specialistGroups.filter(
				({specialistID}) => specialistID === specialistGroup.specialistID,
			);

			if (specialistGroupsList.length === 1) {
				openImpossibleDeleteAlert();
				return;
			}

			setPotentialRemovedSpecialistGroup(specialistGroup);
		},
	}));

	return (
		<>
			{!isNull(potentialRemovedSpecialistGroup) && (
				<DeleteSpecialistGroupAlert
					onClose={closeDeleteAlert}
					potentialRemovedSpecialistGroup={potentialRemovedSpecialistGroup}
					onRemoved={onRemoved}
				/>
			)}

			{showImpossibleDeleteAlert && (
				<WarningAlert
					description='Sorry, you must have at least one group in each school year.'
					onClose={closeImpossibleDeleteAlert}
				/>
			)}
		</>
	);
}
