import {Class, useClasses} from '@esgi/main/libs/admin-store';
import {RemoveTeacherClassManagerRef} from './types';
import {useCallback, useImperativeHandle, useState} from 'react';
import {isUndefined, useFlag} from '@esgi/ui';
import {isNull} from 'underscore';
import {DeleteTeacherClassAlert} from './components/delete-teacher-class-alert';
import {WarningAlert} from '@esgi/main/features/admins/data';

type Props = {
	managerRef: RemoveTeacherClassManagerRef;
	onRemoved: VoidFunction;
};

export function RemoveTeacherClassImpl({managerRef, onRemoved}: Props) {
	const [{data: teachersClassesList}] = useClasses();

	const [showImpossibleDeleteAlert, openImpossibleDeleteAlert, closeImpossibleDeleteAlert] = useFlag();
	const [potentialRemovedTeacherClass, setPotentialRemovedTeacherClass] = useState<Class | null>(null);

	const closeDeleteAlert = useCallback(() => {
		setPotentialRemovedTeacherClass(null);
	}, []);

	useImperativeHandle(managerRef, () => ({
		runRemove: ({teacherClassID}) => {
			const teacherClass = teachersClassesList.find(({id}) => id === teacherClassID);

			if (isUndefined(teacherClass)) {
				throw new Error('teacherClass is not found in admin store');
			}

			const teacherClasses = teachersClassesList.filter(({teacherID}) => teacherID === teacherClass.teacherID);

			if (teacherClasses.length === 1) {
				openImpossibleDeleteAlert();
				return;
			}

			setPotentialRemovedTeacherClass(teacherClass);
		},
	}));

	return (
		<>
			{!isNull(potentialRemovedTeacherClass) && (
				<DeleteTeacherClassAlert
					onClose={closeDeleteAlert}
					onRemoved={onRemoved}
					potentialRemovedTeacherClass={potentialRemovedTeacherClass}
				/>
			)}

			{showImpossibleDeleteAlert && (
				<WarningAlert
					description='Sorry, you must have at least one class in each school year.'
					onClose={closeImpossibleDeleteAlert}
				/>
			)}
		</>
	);
}
