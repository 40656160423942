import './custom-theme.css';
import {SearchInput} from './components/search-input';
import {Theme} from './components/theme';
import {AgGridTable} from './components/ag-grid-table';
import {useRegisterExternalFilter} from './hooks/use-register-external-filter';
import {CellCheckbox} from './components/cell-checkbox';

export const AgGrid = Object.assign(Theme, {
	Table: AgGridTable,
	SearchInput,
	CellCheckbox,
	useRegisterExternalFilter,
});

export {Pagination} from './components/pagination';
export {EntitiesFilter, NumberFilter} from './components/filters';
export {TableCustomizationDrawer} from './components/table-customization-drawer';

export {numberFilterParams} from './components/filters/numbers';
export {entitiesFilterParams} from './components/filters/entities';

export {CellType} from './types';

export {isColDef} from './utils';
