import {GridApi} from 'ag-grid-community';
import {RowDef} from '../../../../../types';
import {Dispatch, useMemo} from 'react';
import {QuickPanel} from './quick-panel';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useSelectedTeacherData} from '../../../../use-selected-teacher-data';
import {TestResults} from '@esgi/main/kits/admin';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';

type Props = {
	gridApi: GridApi<Readonly<RowDef>> | null;
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
	userCanAddAndDeleteTeachers: boolean;
	editButtonDisabled: boolean;
	onEditClick: Dispatch<RowDef>;
};

export function ContentWrapper({
	editButtonDisabled,
	gridApi,
	isPanelExpanded,
	onEditClick,
	onFolderAddClick,
	selectedRow,
	userCanAddAndDeleteTeachers,
}: Props) {
	const {skeleton, students, subjects, testResultsAdditionalInfo, reportSubject} = useSelectedTeacherData({
		selectedRow,
	});

	const hierarchy = useMemo<Hierarchy>(() => {
		const h: Hierarchy = {
			schoolID: selectedRow.school.id,
			teacher: {
				id: selectedRow.id,
				firstName: selectedRow.firstName,
				lastName: selectedRow.lastName,
				schoolID: selectedRow.school.id,
			},
		};

		return h;
	}, [selectedRow]);

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				editButtonDisabled={editButtonDisabled}
				gridApi={gridApi}
				onEditClick={onEditClick}
				userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={skeleton}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={skeleton}
					allSubjects={subjects}
					students={students}
					hierarchy={hierarchy}
					additionalInfo={testResultsAdditionalInfo}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
