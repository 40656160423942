import React, {ChangeEvent, useState} from 'react';
import {CloseIcon, Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {RosterTable} from './componenets/roster-table';
import {FieldsList} from './componenets/fieldsList';
import styles from './styles.module.less';

interface Props {
	onFileUploaded: (e: ChangeEvent<HTMLInputElement>) => void;
	onDownloadTemplateClicked: () => void;
	onExit: () => void;
}

export function UploadRoster({onFileUploaded, onDownloadTemplateClicked, onExit}: Props) {

	const [fileExplorer, setFileExplorer] = useState<HTMLInputElement>(null);
	const openFileExplorer = () => fileExplorer.click();

	const modalRef = useModal();
	const handelClose = useCloseModal(modalRef, onExit);

	return <Modal className={styles.modal} modalManagerRef={modalRef}>
		<Modal.Header className={styles.header}>
			<CloseIcon color={'#666666'} onClick={handelClose}/>
		</Modal.Header>
		<Modal.Body className={styles.body}>
			<div data-cy={'upload-roster'} className={styles.column}>
				<div data-cy={'upload-roster-title'} className={styles.row}>
					<Title className={styles.title}>Go Ahead and Upload a File of Your Own Choosing!</Title>
				</div>

				<div className={styles.row}>
					<FieldsList/>
				</div>

				<div className={styles.row}>
					<div className={styles.fileFormats}> Accepted file formats: csv, doc, xls</div>
				</div>

				<div className={styles.row}>
					<Buttons.Contained onClick={openFileExplorer}>
						UPLOAD ROSTER
						<input
							data-cy={'file-input'}
							type='file'
							ref={setFileExplorer}
							onChange={onFileUploaded}
							onClick={(e: React.MouseEvent<HTMLInputElement>) => (e.target as HTMLInputElement).value = null}
						/>
					</Buttons.Contained>

				</div>
			</div>

			<div className={styles.verticalLine}/>

			<div className={styles.column}>
				<div data-cy={'download-template-title'} className={styles.row}>
					<Title className={styles.title}>Use Our Template <span
						className={styles.optional}>(optional).</span> </Title>
				</div>
				<div className={styles.row}>
					<span className={styles.text}>Fill out this template to ensure your student information is in the correct format.</span>
				</div>

				<div className={styles.row}>
					<RosterTable/>
				</div>

				<div className={styles.row}>
					<Buttons.Contained onClick={onDownloadTemplateClicked}>
						DOWNLOAD TEMPLATE
					</Buttons.Contained>
				</div>
			</div>
		</Modal.Body>
	</Modal>;
}
