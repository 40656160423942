import {Group} from '@esgi/main/libs/school-admin-store';
import {Alert} from '@esgi/ui/alert';
import {useCallback} from 'react';
import {useRemoveTeacherGroup} from '../../../utils';
import {RemoveSingleEntityAlert} from '@esgi/main/features/admins/data';

type Props = {
	onRemoved: VoidFunction;
	onClose: VoidFunction;
	potentialRemovedTeacherGroup: Group;
};

export function DeleteTeacherGroupAlert({onClose, onRemoved, potentialRemovedTeacherGroup}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isTeacherGroupsDataLoaded, removeTeacherGroup, isTeacherGroupRemoving] = useRemoveTeacherGroup();

	const onRemoveTeacherGroup = useCallback(() => {
		removeTeacherGroup({
			id: potentialRemovedTeacherGroup.id,
			name: potentialRemovedTeacherGroup.name,
			onRemoved: () => {
				onClose();
				onRemoved();
			},
		});
	}, [onClose, onRemoved, potentialRemovedTeacherGroup, removeTeacherGroup]);

	return (
		<RemoveSingleEntityAlert
			skeleton={!isTeacherGroupsDataLoaded}
			alertRef={alertRef}
			closeAlert={closeAlert}
			title='Delete Group?'
			entityName={potentialRemovedTeacherGroup.name}
			bodyMessage={[
				'Are you sure you want to delete the following group?',
				'This will NOT delete students and their data. The students still will be available and can be added to another group.',
			]}
			isRemoving={isTeacherGroupRemoving}
			onRemove={onRemoveTeacherGroup}
		/>
	);
}
