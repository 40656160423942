import {useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {useUser} from '@esgi/core/authentication';
import {Folder as FolderIcon} from '@esgi/ui/icons';
import {Folder, FolderType} from '@esgi/main/features/school-admin/data';
import {
	CollapsiblePanel,
	ReportDialog,
	Reports,
	ReportsSubjectInfo,
	RunReportButton,
} from '@esgi/main/features/admins/data';
import {CollapsiblePanelHeader, TitleBox} from './index.styled';
import {additionalFolderData} from './constants';
import {FolderReportModel} from './types';
import {isNull} from 'underscore';
import {useFirstStoreSubject} from '../hooks';

type Props = {
	folderID: Folder['id'];
	folderName: string;
	folderType: FolderType;
	isShowPanel: boolean;
	reports: FolderReportModel[];
	studentsCountInFolder: number;
	rowsCountInFolder: number;
};

export function FolderNoSelectedItemsPanel({
	folderID,
	folderName,
	isShowPanel,
	folderType,
	reports,
	studentsCountInFolder,
	rowsCountInFolder,
}: Props) {
	const [firstStoreSubject, isFirstStoreSubjectLoaded] = useFirstStoreSubject();

	const reportSubject = useMemo<ReportsSubjectInfo | null>(() => {
		if (isNull(firstStoreSubject)) {
			return null;
		}

		return {
			id: firstStoreSubject.id,
			level: firstStoreSubject.level,
			type: firstStoreSubject.subjectType,
			name: firstStoreSubject.name,
		};
	}, [firstStoreSubject]);

	const user = useUser();

	const managerRef = CollapsiblePanel.useRef();

	if (!isShowPanel) {
		return null;
	}

	const {hierarchyKey, title, noStudentsSinglePrefix, noStudentsMultiplePrefix} = additionalFolderData[folderType];

	return (
		<CollapsiblePanel.Root managerRef={managerRef}>
			<CollapsiblePanel.QuickPanel>
				<CollapsiblePanelHeader>
					<TitleBox>
						<FolderIcon />
						<Text size='large' color='highContrast'>
							{folderName}
						</Text>
					</TitleBox>
					<RunReportButton
						title={title}
						subject={reportSubject}
						disabled={!studentsCountInFolder}
						disabledMessage={
							studentsCountInFolder > 0
								? null
								: `No students have been added to ${rowsCountInFolder === 1 ? noStudentsSinglePrefix : noStudentsMultiplePrefix}. Please add some before running the report.`
						}
					>
						{({onReportsClose, subject}) => (
							<ReportDialog
								header={folderName}
								onClose={onReportsClose}
								subject={subject}
								loading={!isFirstStoreSubjectLoaded}
							>
								<ReportDialog.Section title={title}>
									{reports.map(({type, name, description}, index) => {
										const ReportComponent = Reports[type];

										return (
											<ReportComponent
												hierarchy={{
													schoolID: user?.schoolID ?? 0,
													[hierarchyKey]: folderID,
												}}
												name={name}
												description={description}
												key={`${type}-${index}`}
											/>
										);
									})}
								</ReportDialog.Section>
							</ReportDialog>
						)}
					</RunReportButton>
				</CollapsiblePanelHeader>
			</CollapsiblePanel.QuickPanel>
		</CollapsiblePanel.Root>
	);
}
