import {IconComponent, useFlag} from '@esgi/ui';
import {Dispatch, useMemo} from 'react';
import {SelectDrawerableBase} from '../../../kit/select-drawerable-base';
import {usePanelContext} from '../../context';
import {SubjectPublishValue, SubjectPublishType, TrackDate} from './types';
import moment from 'moment';
import {dateFormat} from './constants';
import {PeriodsDrawer} from './periods-drawer';

type Props = {
	placeholder: string;
	drawerTitle: string;
	EntityIcon: IconComponent;
	disabled?: boolean;
	value: SubjectPublishValue;
	setValue: Dispatch<SubjectPublishValue>;
	trackID: number | null;
	allTrackDates: TrackDate[];
};

export function PublishPeriodDrawerable({
	placeholder,
	drawerTitle,
	EntityIcon,
	disabled,
	value,
	setValue,
	trackID,
	allTrackDates,
}: Props) {
	const {skeleton} = usePanelContext();

	const [isDrawerOpen, openDrawer, closeDrawer] = useFlag();

	const valueTranscript = useMemo(() => {
		if (value.type === SubjectPublishType.Off) {
			return '- ';
		}
		if (value.type === SubjectPublishType.Indefinitely) {
			return 'Indefinitely ';
		}

		if (value.type === SubjectPublishType.SchoolYear) {
			return 'Current School Year ';
		}

		if (value.type === SubjectPublishType.MarkingPeriod) {
			return 'Marking Period';
		}

		if (value.type === SubjectPublishType.CustomDateRange) {
			const {dateFrom, dateTo} = value;

			const formattedDateFrom = moment(`${dateFrom}Z`).format(dateFormat);
			const formattedDateTo = moment(`${dateTo}Z`).format(dateFormat);

			return `${formattedDateFrom} - ${formattedDateTo}`;
		}

		throw new Error('value.type is not correspont to SubjectPublishType');
	}, [value]);

	return (
		<>
			<SelectDrawerableBase
				placeholder={placeholder}
				valueTranscript={valueTranscript}
				disabled={Boolean(disabled)}
				onClick={openDrawer}
				skeleton={skeleton}
				dataCy='publish-period'
			/>

			{isDrawerOpen && (
				<PeriodsDrawer
					EntityIcon={EntityIcon}
					title={drawerTitle}
					onClose={closeDrawer}
					value={value}
					onSave={setValue}
					allTrackDates={allTrackDates}
					trackID={trackID}
				/>
			)}
		</>
	);
}
