import {FolderAdd, Teacher} from '@esgi/ui/icons';
import {ActionPanel, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {useFlag} from '@esgi/ui';
import {
	AddToFolderButton,
	ReportsSubjectInfo,
	RunReportButton,
	useNavigateToSpecialistsGroupsPage,
	useNavigateToStudentsPage,
} from '@esgi/main/features/admins/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {getFullName} from '@esgi/main/kits/common';
import {ReportsFeature} from '../../../../../../components/reports';
import {useCallback} from 'react';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
	activitiesHierarchy: Hierarchy;
	reportsHierarchy: Hierarchy;
	skeleton: boolean;
	reportSubject: ReportsSubjectInfo | null;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	activitiesHierarchy,
	reportSubject,
	reportsHierarchy,
	skeleton,
}: Props) {
	const goToSpecialistsGroupsPage = useNavigateToSpecialistsGroupsPage();
	const goToStudentsPage = useNavigateToStudentsPage();

	const navigateToSpecialistGroups = useCallback(() => {
		goToSpecialistsGroupsPage({
			specialist: [selectedRow.id],
		});
	}, [goToSpecialistsGroupsPage, selectedRow]);

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			specialist: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	return (
		<SingleSelectedExpandablePanel.QuickPanel>
			<SingleSelectedExpandablePanel.QuickPanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<Teacher />
						<Text size='large' color='highContrast'>
							{getFullName({
								firstName: selectedRow.firstName,
								lastName: selectedRow.lastName,
							})}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<AddToFolderButton onClick={onFolderAddClick}>
							<FolderAdd />
						</AddToFolderButton>
						<ActionPanel.ActionButtonDivider />

						<RunReportButton skeleton={skeleton} subject={reportSubject}>
							{({onReportsClose, subject}) => (
								<ReportsFeature
									onClose={onReportsClose}
									specialist={selectedRow}
									activitiesHierarchy={activitiesHierarchy}
									reportsHierarchy={reportsHierarchy}
									subject={subject}
								/>
							)}
						</RunReportButton>
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</SingleSelectedExpandablePanel.QuickPanel.Header>

			{!isPanelExpanded && (
				<AdditionalHeaderContent>
					<ActionPanel.Navigation.Container>
						<ActionPanel.Navigation.Card
							onClick={navigateToSpecialistGroups}
							title='Specialist Groups'
							count={selectedRow.specialistGroups}
						/>
						<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
					</ActionPanel.Navigation.Container>

					<OpenPanelButton canExpandPanel={!!selectedRow.students} />
				</AdditionalHeaderContent>
			)}
		</SingleSelectedExpandablePanel.QuickPanel>
	);
}
