import {FolderAdd, School} from '@esgi/ui/icons';
import {ActionPanel, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {useClasses, useGroups, useSpecialistGroups} from '@esgi/main/libs/admin-store';
import {useCallback, useMemo} from 'react';
import {
	AddToFolderButton,
	ReportsSubjectInfo,
	RunReportButton,
	useNavigateToClassesPage,
	useNavigateToGroupsPage,
	useNavigateToSpecialistsGroupsPage,
	useNavigateToSpecialistsPage,
	useNavigateToStudentsPage,
	useNavigateToTeachersPage,
} from '@esgi/main/features/admins/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';
import {SchoolReportsDialog} from '../../../../../../components/school-reports-dialog';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	hierarchy: Hierarchy;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	hierarchy,
	reportSubject,
	skeleton,
}: Props) {
	const [{data: allClasses}] = useClasses();
	const [{data: allGroups, loaded: isAllGroupsLoaded}] = useGroups();
	const [{data: allSpecialistsGroups, loaded: isAllSpecialistsGroups}] = useSpecialistGroups();

	const classes = useMemo(() => allClasses.filter((c) => c.schoolID === selectedRow.id), [selectedRow, allClasses]);
	const groups = useMemo(() => allGroups.filter((c) => c.schoolID === selectedRow.id), [selectedRow, allGroups]);
	const specialistsGroups = useMemo(
		() => allSpecialistsGroups.filter((c) => c.schoolID === selectedRow.id),
		[selectedRow, allSpecialistsGroups],
	);

	const goToTeacherPage = useNavigateToTeachersPage();
	const goToSpecialistsPage = useNavigateToSpecialistsPage();
	const goToClassesPage = useNavigateToClassesPage();
	const goToGroupsPage = useNavigateToGroupsPage();
	const goToSpecialistsGroupsPage = useNavigateToSpecialistsGroupsPage();
	const goToStudentsPage = useNavigateToStudentsPage();

	const navigateToTeachers = useCallback(() => {
		goToTeacherPage({
			school: [selectedRow.id],
		});
	}, [goToTeacherPage, selectedRow]);

	const navigateToSpecialists = useCallback(() => {
		goToSpecialistsPage({
			schools: [selectedRow.id],
		});
	}, [goToSpecialistsPage, selectedRow]);

	const navigateToClasses = useCallback(() => {
		goToClassesPage({
			school: [selectedRow.id],
		});
	}, [goToClassesPage, selectedRow]);

	const navigateToGroups = useCallback(() => {
		goToGroupsPage({
			school: [selectedRow.id],
		});
	}, [goToGroupsPage, selectedRow]);

	const navigateToSpecialistsGroups = useCallback(() => {
		goToSpecialistsGroupsPage({
			school: [selectedRow.id],
		});
	}, [goToSpecialistsGroupsPage, selectedRow]);

	const navigateToStudents = () => {
		goToStudentsPage({
			school: [selectedRow.id],
		});
	};

	return (
		<SingleSelectedExpandablePanel.QuickPanel>
			<SingleSelectedExpandablePanel.QuickPanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<School />
						<Text size='large' color='highContrast' data-cy='selected-item-name'>
							{selectedRow.name}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<AddToFolderButton onClick={onFolderAddClick}>
							<FolderAdd />
						</AddToFolderButton>
						<ActionPanel.ActionButtonDivider />

						<RunReportButton title='School Reports' skeleton={skeleton} subject={reportSubject}>
							{({subject, onReportsClose}) => (
								<SchoolReportsDialog
									school={selectedRow}
									onClose={onReportsClose}
									baseHierarchy={hierarchy}
									subject={subject}
								/>
							)}
						</RunReportButton>
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</SingleSelectedExpandablePanel.QuickPanel.Header>

			{!isPanelExpanded && (
				<AdditionalHeaderContent>
					<ActionPanel.Navigation.Container>
						<ActionPanel.Navigation.Card onClick={navigateToTeachers} title='Teachers' count={selectedRow.teachers} />
						<ActionPanel.Navigation.Card
							onClick={navigateToSpecialists}
							title='Specialists'
							count={selectedRow.specialists}
						/>
						<ActionPanel.Navigation.Card onClick={navigateToClasses} title='Classes' count={classes.length} />
						<ActionPanel.Navigation.Card onClick={navigateToGroups} title='Groups' count={groups.length} />
							<ActionPanel.Navigation.Card
								onClick={navigateToSpecialistsGroups}
								title='Specialist Groups'
								count={specialistsGroups.length}
							/>
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
					</ActionPanel.Navigation.Container>

					<OpenPanelButton canExpandPanel={!!selectedRow.students} />
				</AdditionalHeaderContent>
			)}
		</SingleSelectedExpandablePanel.QuickPanel>
	);
}
