import {useUser} from '@esgi/core/authentication';
import {RowDef} from '../../../../../types';
import {ExpandedPanel} from './expanded-panel';
import {QuickPanel} from './quick-panel';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useMemo} from 'react';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {TestResultsStudent} from '@esgi/main/kits/admin';
import {useTestResultsSubject} from '../../../../../../_kit';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
};

export function ContentWrapper({isPanelExpanded, selectedRow}: Props) {
	const user = useUser();

	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({groupsIDs}) => groupsIDs.includes(selectedRow.id))
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {
		isReady: isTestResultsSubjectReady,
		subjects: testResultsSubjects,
		reportSubject,
	} = useTestResultsSubject({
		userID: selectedRow.teacher.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter: 'checkOnAllowedToAllTeachers',
	});

	const hierarchy = useMemo<Hierarchy>(() => {
		const schoolID = user?.schoolID ?? 0;

		const h: Hierarchy = {
			schoolID,
			teacher: {
				id: selectedRow.teacher.id,
				firstName: selectedRow.teacher.firstName,
				lastName: selectedRow.teacher.lastName,
				schoolID,
			},
			teacherClass: {
				id: selectedRow.class.id,
				name: selectedRow.class.name,
				teacherID: selectedRow.class.teacherID,
			},
			teacherGroup: {
				id: selectedRow.id,
				classID: selectedRow.class.id,
				name: selectedRow.name,
			},
		};

		return h;
	}, [selectedRow, user]);

	const skeleton = !isTestResultsSubjectReady || !isStudentsLoaded;

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={skeleton}
			/>

			<ExpandedPanel
				selectedRow={selectedRow}
				hierarchy={hierarchy}
				skeleton={skeleton}
				students={filteredStudents}
				subjects={testResultsSubjects}
			/>
		</>
	);
}
