import {Button, ContextMenu as ContextMenuUI} from '@esgi/ui';
import {Delete, More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useCallback, useState} from 'react';
import {Class} from '@esgi/main/libs/admin-store';
import {RemoveTeacherClass} from '@esgi/main/features/district-admin/common';

type Props = {
	closeDrawer: VoidFunction;
	classID: Class['id'];
};

export function ContextMenu({closeDrawer, classID}: Props) {
	const removeTeacherClassManager = RemoveTeacherClass.useManagerRef();

	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

	const handleOpenDeleteClassAlert = useCallback(() => {
		removeTeacherClassManager.current?.runRemove({
			teacherClassID: classID,
		});

		setIsContextMenuOpen(false);
	}, [classID, removeTeacherClassManager]);

	return (
		<>
			<ContextMenuUI.Root open={isContextMenuOpen} onOpenChange={setIsContextMenuOpen}>
				<ContextMenuUI.Trigger>
					<Button.Icon withBackgroundHover>
						<More />
					</Button.Icon>
				</ContextMenuUI.Trigger>
				<ContextMenuUI.Content>
					<ContextMenuUI.Group>
						<ContextMenuUI.Item onClick={handleOpenDeleteClassAlert}>
							<Delete />
							<Text size='medium'>Delete</Text>
						</ContextMenuUI.Item>
					</ContextMenuUI.Group>
				</ContextMenuUI.Content>
			</ContextMenuUI.Root>

			<RemoveTeacherClass managerRef={removeTeacherClassManager} onRemoved={closeDrawer} />
		</>
	);
}
