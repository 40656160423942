import {join} from '@esgillc/ui-kit/utils';
import {useMemo, useState} from 'react';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {lazyComponent} from '@esgi/core/react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {SubjectModel} from '../../../../models';
import {AddTestButton} from '../../../add-test';
import {SubjectDroppableInfo} from '../../types';
import {ArrowIcon} from './components/arrow-icon';
import {DeleteButton} from './components/delete-button';
import {SubjectDragTrigger} from './components/drag-trigger';
import {EditButton} from './components/edit-button';
import {HideButton} from './components/hide-button';
import {RemoveSubjectAlert} from './components/remove-subject-alert';
import {Test} from './components/test';
import styles from './styles.module.less';
import {OverflowSpan, useCollapse} from '@esgillc/ui-kit/layout';

const AddTestModal = lazyComponent(() => import('shared/modules/home-add-test/modal/modal'));
const SubjectDetails = lazyComponent(() => import('modules/subject-details'));

export type Props = {
	index: number;
	hierarchy: HierarchySnapshot;
	canEdit: boolean;
	dragging: boolean;
	droppingDisabled: boolean;
	subject: SubjectModel;
	justAdded?: boolean;
	removeSubjectClicked: () => any;
	onHideToggled: (hide: boolean) => void;
	removeTestClicked: (testID: number) => any;
}

const droppableType = 'tests';

export function Subject(props: Props): JSX.Element {
	const {tests, id, subjectType, name, hidden} = props.subject;
	const [showAddTestDialog, setShowAddTestDialog] = useState(false);
	const [showSubjectDetailsDialog, setShowSubjectDetailsDialog] = useState(false);
	const [showRemoveSubjectAlert, setShowRemoveSubjectAlert] = useState(false);

	const [collapseProps, collapsed, toggleCollapse] = useCollapse(!props.justAdded);

	const draggableID = 'subject-' + id;
	const droppableID = useMemo(() => JSON.stringify({
		id,
		subjectType,
		expanded: !collapsed,
	} as SubjectDroppableInfo), [collapsed]);

	function renderModals() {
		return <>
			{showRemoveSubjectAlert && <RemoveSubjectAlert
				onCancel={() => setShowRemoveSubjectAlert(false)}
				onConfirm={() => {
					setShowRemoveSubjectAlert(false);
					props.removeSubjectClicked();
				}}/>}
			{showAddTestDialog &&
				<AddTestModal hidden={hidden}
				              subjectId={id}
				              hierarchy={props.hierarchy}
				              closed={() => setShowAddTestDialog(false)}
				              limitToSelectedSubjectId={true}/>}
			{showSubjectDetailsDialog &&
				<SubjectDetails
					subjectID={id}
					subjectName={name}
					onClosed={() => setShowSubjectDetailsDialog(false)}
					onSaved={() => setShowSubjectDetailsDialog(false)}/>}
		</>;
	}

	return <Draggable draggableId={draggableID} index={props.index} isDragDisabled={!props.canEdit}>
		{(subjectProvided) => (
			<Droppable droppableId={droppableID} type={droppableType} isDropDisabled={props.droppingDisabled}>
				{(provided, snapshot) => (
					<div className={join(styles.subjectRow)}
					     ref={subjectProvided.innerRef} {...subjectProvided.draggableProps}>
						<div className={join(styles.cell, styles.order)}>{props.index + 1}</div>
						<div className={join(styles.subjectFullBody)} {...provided.droppableProps}
						     ref={provided.innerRef}>
							{props.justAdded && <div className={styles.blueBar}/>}
							<div className={styles.subjectRowBody} onClick={() => toggleCollapse(!collapsed)}
							     data-cy='collapse-trigger'>
								<SubjectDragTrigger canDrag={props.canEdit}
								                    dragging={props.dragging}
								                    subjectType={subjectType}
								                    dragHandleProps={subjectProvided.dragHandleProps}/>
								<div className={join(styles.nameCell, snapshot.isDraggingOver && styles.active)}
								     data-cy='subject-name'>
									<ArrowIcon open={!collapsed}/>
									<OverflowSpan>{props.subject.name}</OverflowSpan>
								</div>
								<div className={join(styles.actionCell, styles.showHide)}>
									<HideButton toggled={props.subject.hidden}
									            onClick={() => props.onHideToggled(!props.subject.hidden)}/>
								</div>
								<div className={styles.actionCell}>
									<EditButton onClick={() => setShowSubjectDetailsDialog(true)}
									            canEdit={props.canEdit}
									            subjectType={props.subject.subjectType}/>
								</div>
								<div className={styles.actionCell}>
									<DeleteButton onClick={() => setShowRemoveSubjectAlert(true)}
									              canDelete={props.canEdit}
									              subjectType={props.subject.subjectType}/>
								</div>
							</div>
							{collapsed && <div className={styles.placeholderHidden}>{provided.placeholder}</div>}
							<div {...collapseProps} className={styles.testsContainer}>
								{tests.map((t, i) => <Test
									test={t}
									index={i}
									key={t.id}
									subjectID={id}
									subjectName={name}
									hidden={collapsed}
									canDelete={props.canEdit}
									subjectType={subjectType}
									onRemoveClicked={() => props.removeTestClicked(t.id)}/>)}
								{!collapsed && provided.placeholder}
								{props.canEdit && <div className={styles.addTestRow}>
									<AddTestButton subjectName={name}
									               addTestClicked={() => setShowAddTestDialog(true)}/>
								</div>
								}
							</div>
							{props.droppingDisabled && <OnHoverTooltip
								message={props.droppingDisabled ? 'Test is already in this subject tab' : ''}
								showUnderCursor={true} followForCursor={true} showImmediately={true}>
								<div className={styles.subjectMask}>
									<div className={styles.subMask}/>
								</div>
							</OnHoverTooltip>}
						</div>
						{renderModals()}
					</div>)}
			</Droppable>
		)}
	</Draggable>;
}
