import {Tabs} from '../../../../components/tabs';
import {Dispatch} from 'react';
import {School, User} from '@esgi/main/libs/admin-store';
import {DistrictSpecialistContent} from './components/district-specialist-content';
import {SchoolSpecialistContent} from './components/school-specialist-content';
import {FormGroup, useForm} from '@esgi/ui/form';
import {FormRoot} from '../../../../components/form.styled';
import {Drawer} from '@esgi/main/kits/common';
import {DistrictSpecialistForm, SchoolSpecialistForm} from '../../forms-data/types';
import {GroupNameInput} from '../../../../components/group-name-input';
import {SpecialistTabID} from '../../../types';
import {specialistGroupTabItems} from '../../../constants';

type Props = {
	specialistTabID: SpecialistTabID;
	setSpecialistTabID: Dispatch<SpecialistTabID>;

	districtSpecialists: User[];
	districtSpecialistForm: DistrictSpecialistForm;
	districtSpecialistFormInactiveMessage: string | null;

	schoolsWithSpecialists: School[];
	schoolSpecialistForm: SchoolSpecialistForm;
	activeSchoolSpecialistsList: User[];
	schoolSpecialistUniqueKey: string;
	schoolSpecialistFormInactiveMessage: string | null;
};

export function GroupInformationContent({
	specialistTabID,
	setSpecialistTabID,

	districtSpecialists,
	districtSpecialistForm,
	districtSpecialistFormInactiveMessage,

	schoolsWithSpecialists,
	schoolSpecialistForm,
	activeSchoolSpecialistsList,
	schoolSpecialistUniqueKey,
	schoolSpecialistFormInactiveMessage,
}: Props) {
	const mockForm = useForm(() => new FormGroup({}));

	return (
		<FormRoot controller={mockForm}>
			<Drawer.ContentBlock title='Specialist' withDivider>
				<Tabs<SpecialistTabID>
					activeTabID={specialistTabID}
					setActiveTabID={setSpecialistTabID}
					items={specialistGroupTabItems}
					dataCy='specialist-type-toggle'
				/>

				{specialistTabID === SpecialistTabID.District && (
					<DistrictSpecialistContent
						districtSpecialists={districtSpecialists}
						form={districtSpecialistForm}
						formInactiveMessage={districtSpecialistFormInactiveMessage}
					/>
				)}

				{specialistTabID === SpecialistTabID.School && (
					<SchoolSpecialistContent
						schoolsWithSpecialists={schoolsWithSpecialists}
						form={schoolSpecialistForm}
						activeSchoolSpecialistsList={activeSchoolSpecialistsList}
						schoolSpecialistUniqueKey={schoolSpecialistUniqueKey}
						formInactiveMessage={schoolSpecialistFormInactiveMessage}
					/>
				)}
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Specialist Group Information' withDivider>
				{specialistTabID === SpecialistTabID.District && (
					<GroupNameInput groupNameControl={districtSpecialistForm.controls.groupName} />
				)}
				{specialistTabID === SpecialistTabID.School && (
					<GroupNameInput groupNameControl={schoolSpecialistForm.controls.groupName} />
				)}
			</Drawer.ContentBlock>
		</FormRoot>
	);
}
