import {NameTitle} from '../../types';

export const userNameTitleTranscript: Record<NameTitle, string> = {
	[NameTitle.DR]: 'Dr.',
	[NameTitle.MISS]: 'Miss',
	[NameTitle.MR]: 'Mr.',
	[NameTitle.MRS]: 'Mrs.',
	[NameTitle.MS]: 'Ms.',
};

export const expandablePanelWidth = 54;
