import {Folder} from '@esgi/main/features/admins/data';
import {RowDef} from '../../../../../types';
import {QuickPanel} from './quick-panel';
import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useSelectedSchoolData} from '../../../../use-selected-school-data';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {TestResults} from '@esgi/main/kits/admin';

type Props = {
	isPanelExpanded: boolean;
	selectedRow: RowDef;
	activeFolder: Folder;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
};

export function ContentWrapper({
	activeFolder,
	isPanelExpanded,
	onFolderAddClick,
	onRemoveFromFolderClick,
	selectedRow,
}: Props) {
	const {testResultsAdditionalInfo, reportSubject, skeleton, students, subjects} = useSelectedSchoolData({selectedRow});

	const hierarchy = useMemo<Hierarchy>(() => {
		const h: Hierarchy = {
			schoolID: selectedRow.id,
			schoolsFolderID: activeFolder.id,
		};

		return h;
	}, [selectedRow, activeFolder]);

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				onRemoveFromFolderClick={onRemoveFromFolderClick}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={skeleton}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={skeleton}
					allSubjects={subjects}
					students={students}
					hierarchy={hierarchy}
					additionalInfo={testResultsAdditionalInfo}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
