import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {AlertBody, AlertFooter, AlertHeader, alertRootCss, GroupNameBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Students} from '@esgi/ui/icons';
import {OneLinedText} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {Button, Skeletonable} from '@esgi/ui';
import {ModalManagerRef} from '@esgillc/ui-kit/dist/types/modal';
import {Skeleton} from './components/skeleton';

type Props = Skeletonable<{
	alertRef: ModalManagerRef;
	closeAlert: VoidFunction;
	title: string;
	entityName: string;
	bodyMessage: string | string[];
	onRemove: VoidFunction;
	isRemoving: boolean;
}>;

export function RemoveSingleEntityAlert({
	alertRef,
	closeAlert,
	skeleton,
	title,
	entityName,
	bodyMessage,
	onRemove,
	isRemoving,
}: Props) {
	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative} css={alertRootCss}>
			{skeleton ? (
				<Skeleton closeAlert={closeAlert} />
			) : (
				<>
					<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
						<Text size='small'>{title}</Text>

						<GroupNameBox>
							<Students />
							<OneLinedText size='small' color='highContrast'>
								{entityName}
							</OneLinedText>
						</GroupNameBox>
					</AlertHeader>

					<AlertBody>
						{typeof bodyMessage === 'string' ? (
							<Text size='small' color='highContrast'>
								{bodyMessage}
							</Text>
						) : (
							bodyMessage.map((message, index) => (
								<Text size='small' color='highContrast' key={index}>
									{message}
								</Text>
							))
						)}
					</AlertBody>

					<AlertFooter>
						<GridBox gap='3' flow='column'>
							<Button color='tertiary' onClick={closeAlert}>
								<Text size='medium' bold color='base'>
									Cancel
								</Text>
							</Button>

							<Button color='warn' onClick={onRemove} disabled={isRemoving}>
								<Text size='medium' bold color='negative'>
									Delete
								</Text>
							</Button>
						</GridBox>
					</AlertFooter>
				</>
			)}
		</Alert>
	);
}
