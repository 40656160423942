import {useMemo} from 'react';
import {RowDef} from '../../../../../types';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {isUndefined} from '@esgi/ui';
import {UserType as UserTypeStorage} from '@esgi/main/libs/admin-store';
import {isNull} from 'underscore';

type Parameters = {
	selectedRow: RowDef;
};

export function useHierarchy({selectedRow}: Parameters) {
	return useMemo<Hierarchy>(() => {
		const studentClass = selectedRow.class[0];
		const studentGroup = selectedRow.group[0];

		const h: Hierarchy = {
			schoolID: selectedRow.school?.id ?? 0,
			student: {
				id: selectedRow.id,
				classesIDs: selectedRow.class.map(({id}) => id),
				firstName: selectedRow.firstName,
				lastName: selectedRow.lastName,
				gradeLevelID: selectedRow.gradeLevelID,
				groupsIDs: selectedRow.group.map(({id}) => id),
				schoolID: selectedRow.school?.id ?? 0,
				specialistGroupsIDs: selectedRow.specialistGroups
					.filter(({userType}) => userType === UserTypeStorage.ISD || userType === UserTypeStorage.ISS)
					.map(({id}) => id),
				teacherID: selectedRow.teacher?.id ?? 0,
			},

			...(!isNull(selectedRow.teacher) && {
				teacher: {
					id: selectedRow.teacher.id,
					firstName: selectedRow.teacher.firstName,
					lastName: selectedRow.teacher.lastName,
					schoolID: selectedRow.teacher.schoolID,
				},
			}),

			...(!isUndefined(studentClass) && {
				teacherClass: {
					id: studentClass.id,
					name: studentClass.name,
					teacherID: studentClass.teacherID,
				},
			}),

			...(!isUndefined(studentGroup) && {
				teacherGroup: {
					id: studentGroup.id,
					name: studentGroup.name,
					classID: studentGroup.classID,
				},
			}),
		};

		return h;
	}, [selectedRow]);
}
