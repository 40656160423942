import {RowDef} from '../../../../../types';
import {NoStudentsAlert} from '../../../../../../_kit';
import {ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	group: RowDef;
	onClose: VoidFunction;
	hierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function ReportsFeature({group, onClose, hierarchy, subject}: Props) {
	if (!group.students) {
		return <NoStudentsAlert onClose={onClose} />;
	}

	return (
		<ReportDialog onClose={onClose} header={group.name} subject={subject}>
			<ReportDialog.Section title='Student Reports'>
				<Reports.ParentLetter hierarchy={hierarchy} />
				<Reports.StudentDetail hierarchy={hierarchy} />
				<Reports.StudentProgress hierarchy={hierarchy} />
			</ReportDialog.Section>

			<ReportDialog.Section title='Specialist Group Reports'>
				<Reports.Totals prefix='Specialist Group' hierarchy={hierarchy} />
				<Reports.GradeScale prefix='Specialist Group' hierarchy={hierarchy} />
				<Reports.ItemAnalysis hierarchy={hierarchy} />
				<Reports.PieCharts hierarchy={hierarchy} />
				<Reports.RubricResults hierarchy={hierarchy} />
				<Reports.UntestedStudents hierarchy={hierarchy} />
			</ReportDialog.Section>

			<ReportDialog.Section title='Student Activities'>
				<Reports.Bingo hierarchy={hierarchy} />
				<Reports.Flashcards hierarchy={hierarchy} />
				<Reports.WritingPractice hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
