import {ComponentPropsWithoutRef, forwardRef, useEffect, useState} from 'react';
import {Body, RightSideContainer, SelectedEntityInfo, Separator, TopBarContainer} from './index.styled';
import {AgGrid} from '../../../ag-grid';
import {CounterBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {usePageContentContext} from '../../../../context';
import {Pagination} from '@esgi/ui/ag-grid';
import {isNull} from 'underscore';
import {AppliedFiltersInfo} from '@esgi/main/kits/admin';

type Props = Omit<ComponentPropsWithoutRef<typeof Body>, 'children'>;

export const Base = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'data-page-body-base', css = {}, ...props}, forwardedRef) => {
		const {isDataLoaded, entityName, api} = usePageContentContext();

		const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
		const [rowsCount, setRowsCount] = useState(api?.getDisplayedRowCount() ?? 0);
		const [, setForceUpdate] = useState({});

		useEffect(() => {
			if (isNull(api)) {
				return;
			}

			const viewportChangedHandler = () => {
				setForceUpdate({});
			};

			const updateRowsCount = () => {
				setRowsCount(api.getDisplayedRowCount());
			};

			updateRowsCount();

			api.addEventListener('rowDataUpdated', viewportChangedHandler);
			api.addEventListener('modelUpdated', updateRowsCount);

			return () => {
				api.removeEventListener('rowDataUpdated', viewportChangedHandler);
				api.removeEventListener('modelUpdated', updateRowsCount);
			};
		}, [api]);

		return (
			<Body dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				<TopBarContainer justify='between'>
					<SelectedEntityInfo>
						<CounterBox color='blue' skeleton={!isDataLoaded} dataCy='table-title-counter'>
							<Text size='small' font='mono'>
								{rowsCount}
							</Text>
						</CounterBox>
						<Text size='xSmall' font='mono' color='mediumContrast' data-cy='talbe-title'>
							{entityName}
						</Text>
					</SelectedEntityInfo>
					<RightSideContainer>
						<AppliedFiltersInfo gridApi={api} onAppliedFiltersCountChanged={setAppliedFiltersCount} />
						{Boolean(appliedFiltersCount) && <Separator />}

						{!isNull(api) && <Pagination api={api} />}
					</RightSideContainer>
				</TopBarContainer>

				<AgGrid />
			</Body>
		);
	},
);
