import {Buttons} from '@esgillc/ui-kit/button';
import {useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Alert} from '@esgillc/ui-kit/modal';
import {Text} from '@esgi/ui/typography';

type Props = {
	testName: string,
	subjectName: string,
	onConfirm: () => void,
	onCancel: () => void,
};

export function RemoveTestAlert({testName, subjectName, onConfirm, onCancel}: Props) {
	const modal = useModal();
	const cancel = useCloseModal(modal, onCancel);
	const confirm = useCloseModal(modal, onConfirm);

	return <Alert modalManagerRef={modal}>
		<Alert.Header>
			<Text size='large'>Remove test from subject</Text>
		</Alert.Header>
		<Alert.Body>
			<>Are you sure you want to delete the {testName}  from the subject {subjectName}?</>
		</Alert.Body>
		<Alert.Footer>
			<Buttons.Text onClick={cancel}>
				Cancel
			</Buttons.Text>
			<Buttons.Contained onClick={confirm} data-cy='confirm-remove-test-from-subject'>
				Confirm
			</Buttons.Contained>
		</Alert.Footer>
	</Alert>;
}
