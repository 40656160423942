import {IconComponent} from '@esgi/ui';
import {Item} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {NavLink, To} from 'react-router-dom';
import {Property} from '@stitches/react/types/css';

type Props = {
	to: To;
	Icon: IconComponent;
	label: string;
	linkWidth?: Property.Width;
};

export function NavigationPageItem({to, Icon, label, linkWidth}: Props) {
	return (
		<NavLink
			to={to}
			style={{
				all: 'unset',
				width: linkWidth,
			}}
			data-cy='nav-item-default'
		>
			{({isActive}) => (
				<Item isActive={isActive}>
					<Icon />
					<Text size='small'>{label}</Text>
				</Item>
			)}
		</NavLink>
	);
}
