import {RowDef} from '../types';
import {NoStudentsAlert} from '../../_kit/no-students-alert';
import {ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {getFullName} from '@esgi/main/kits/common';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	specialist: RowDef;
	onClose: VoidFunction;
	hierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function ReportsFeature({specialist, onClose, hierarchy, subject}: Props) {
	if (!specialist.students) {
		return <NoStudentsAlert onClose={onClose} prefix={`${specialist.firstName} ${specialist.lastName} group`} />;
	}

	return (
		<ReportDialog
			header={getFullName({firstName: specialist.firstName, lastName: specialist.lastName})}
			onClose={onClose}
			subject={subject}
		>
			<ReportDialog.Section title='Specialist Group Reports'>
				<Reports.ItemAnalysis hierarchy={hierarchy} />
				<Reports.PieCharts hierarchy={hierarchy} />
				<Reports.UntestedStudents hierarchy={hierarchy} />
			</ReportDialog.Section>

			<ReportDialog.Section title='Student Activities'>
				<Reports.Bingo hierarchy={hierarchy} />
				<Reports.WritingPractice hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
