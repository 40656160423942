import {ReportDialog, Reports} from '@esgi/main/features/admins/data';
import {Hierarchy, HierarchyMode} from '@esgi/main/features/admins/utils';
import {useMemo} from 'react';

export function Specialists() {
	const hierarchy = useMemo(() => {
		const h: Hierarchy = {
			mode: HierarchyMode.Specialist,
		};

		return h;
	}, []);

	return (
		<ReportDialog.Section title='District Reports'>
			<Reports.GradeScale
				name='District Grades'
				description='Apply customizable grade scales to school scores'
				hierarchy={hierarchy}
			/>

			<Reports.Growth
				name='District Growth'
				description='View growth on tests in a Subject Tab'
				hierarchy={hierarchy}
			/>

			<Reports.TotalStudents
				name='District Students'
				description='Review and compare student scores for a subject tab at the District level'
				hierarchy={hierarchy}
			/>

			<Reports.Totals
				name='District Totals'
				description='Review and compare school scores for a subject tab'
				hierarchy={hierarchy}
			/>

			<Reports.ItemAnalysis name='Item Analysis' hierarchy={hierarchy} />

			<Reports.PieCharts name='Pie Charts' hierarchy={hierarchy} />

			<Reports.SpecialistActivityReport
				name='Specialist Activity'
				description='View specialist usage of ESGI'
				hierarchy={hierarchy}
			/>
		</ReportDialog.Section>
	);
}
