import {IconComponent} from '@esgi/ui';
import {useReportsDialogContext} from '../../../context';
import {useCallback} from 'react';
import {Bottom, Header, SkeletonText, Wrapper} from './index.styled';
import {ReportModel} from '../../types';
import {ReportType} from '../../../../reports';

type Props = Omit<Required<ReportModel>, 'prefix'> & {
	Icon: IconComponent;
	reportType: ReportType;
};

export function ReportBase({Icon, hierarchy, description, name, reportType}: Props) {
	const {skeleton, runReport} = useReportsDialogContext();

	const handleClick = useCallback(() => {
		if (skeleton) {
			return;
		}

		runReport(reportType, hierarchy);
	}, [hierarchy, skeleton, reportType, runReport]);

	return (
		<Wrapper onClick={handleClick} dataCy='report-button'>
			<Header>
				<Icon height={24} width={24} />
				<SkeletonText size='large' bold color='base' skeleton={skeleton} data-cy='report-name'>
					{name}
				</SkeletonText>
			</Header>
			<Bottom>
				<SkeletonText size='small' color='mediumContrast' skeleton={skeleton} data-cy='report-description'>
					{description}
				</SkeletonText>
			</Bottom>
		</Wrapper>
	);
}
