import {CSS} from '@stitches/react';
import {ThemeColorKeys} from 'libs/ui/src/types';
import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {Container} from './index.styled';
import {getThemeColor, isUndefined} from '@esgi/ui/utils';
import {ThemeContext, ThemeContextValue} from '../../contexts/theme';

type Props = ComponentPropsWithoutRef<typeof Container> & {
	cellHorizontalPadding?: number;
	agBackgroundColor?: ThemeColorKeys;
	agSelectedRowBackgroundColor?: ThemeColorKeys;
	agRowHoverColor?: ThemeColorKeys;
	withoutFirstColumnPadding?: boolean;
	withoutLastColumnPadding?: boolean;
	agRowHoveredBeforeCSS?: CSS;
	agRowSelectedBeforeCSS?: CSS;
	fullRowHover?: boolean;
};

export const Theme = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'ag-grid-theme',
			css = {},
			children,
			cellHorizontalPadding = 6,
			agBackgroundColor,
			withoutFirstColumnPadding = false,
			withoutLastColumnPadding = false,
			agRowHoverColor = 'secondarySurface',
			agRowHoveredBeforeCSS = {
				borderBottomStyle: 'solid',
				borderBottomColor: '$secondaryMuted',
				borderBottomWidth: 1,
			},
			agSelectedRowBackgroundColor = 'primarySurface',
			agRowSelectedBeforeCSS = {
				borderBottomStyle: 'solid',
				borderBottomColor: '$primaryHighlight',
				borderBottomWidth: 1,
			},
			fullRowHover,
			...props
		},
		forwardedRef,
	) => {
		const themeCSS = useMemo<CSS>(() => {
			return {
				'--ag-background-color': isUndefined(agBackgroundColor) ? 'transparent' : getThemeColor(agBackgroundColor),
				'--ag-cell-horizontal-padding': `${cellHorizontalPadding}px`,

				'--ag-selected-row-background-color': getThemeColor(agSelectedRowBackgroundColor),
				'& .ag-row-selected::before': agRowSelectedBeforeCSS,

				'--ag-row-hover-color': getThemeColor(agRowHoverColor),
				'& .ag-row-hover::before': agRowHoveredBeforeCSS,

				'& .ag-center-cols-container': {
					...(fullRowHover && {
						minWidth: '100%',
					}),
				},
			};
		}, [
			agBackgroundColor,
			agRowHoverColor,
			agRowHoveredBeforeCSS,
			agRowSelectedBeforeCSS,
			agSelectedRowBackgroundColor,
			cellHorizontalPadding,
			fullRowHover,
		]);

		const context = useMemo<ThemeContextValue>(
			() => ({
				cellHorizontalPadding,
				withoutFirstColumnPadding,
				withoutLastColumnPadding,
			}),
			[cellHorizontalPadding, withoutFirstColumnPadding, withoutLastColumnPadding],
		);

		return (
			<ThemeContext.Provider value={context}>
				<Container
					dataCy={dataCy}
					css={{
						...themeCSS,
						...css,
					}}
					withoutFirstColumnPadding={withoutFirstColumnPadding}
					withoutLastColumnPadding={withoutLastColumnPadding}
					ref={forwardedRef}
					{...props}
				>
					{children}
				</Container>
			</ThemeContext.Provider>
		);
	},
);
