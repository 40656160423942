import {V2SchoolAdminsStudentsController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {studentsStore, useStudents} from '@esgi/main/libs/school-admin-store';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {RemoveStudentEvent} from '../../events';

type Out = [boolean, Dispatch<OnRemoveParameters>, boolean];

type OnRemoveParameters = {
	id: number;
	firstName: string;
	lastName: string;
	onRemoved: VoidFunction;
};

export function useRemoveStudent(): Out {
	const [{loaded: isStudentsLoaded}] = useStudents();

	const controller = useDisposable(V2SchoolAdminsStudentsController);

	const [response, request] = useCancelableRequest(controller.makeDelete);

	const [isRemoving, setIsRemoving] = useState(false);
	const [requestParameters, setRequestParameters] = useState<OnRemoveParameters | null>(null);

	useEffect(() => {
		if (isAsyncSucceed(response) && !isNull(requestParameters)) {
			studentsStore().removeById?.(requestParameters.id);

			dispatchAppEvent(
				RemoveStudentEvent,
				new RemoveStudentEvent({
					id: requestParameters.id,
					firstName: requestParameters.firstName,
					lastName: requestParameters.lastName,
				}),
			);

			requestParameters.onRemoved();

			setRequestParameters(null);
			setIsRemoving(false);
		}
	}, [response, requestParameters]);

	const onRemove = useCallback(
		({id, firstName, lastName, onRemoved}: OnRemoveParameters) => {
			setIsRemoving(true);

			request({
				studentID: id,
			});

			setRequestParameters({
				id,
				firstName,
				lastName,
				onRemoved,
			});
		},
		[request],
	);

	const isDataLoaded = isStudentsLoaded;

	return [isDataLoaded, onRemove, isAsyncPending(response) || isRemoving];
}
