import {useDisposable} from '@esgi/core/service';
import {V2SchoolAdminsPagesDataController} from '@esgi/contracts/esgi';
import {useCallback, useEffect, useMemo} from 'react';
import {EntityProperty} from '../types';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {entitiesWithFolders} from '../constants';
import {isEmpty, isNull} from 'underscore';
import {useDataPageContext} from '../../../context';
import {EntityType} from '../../../types';
import {
	ClassCreatedEvent,
	FolderCreatedEvent,
	FolderManagement,
	FolderType,
	FolderUpdatedEvent,
	GroupCreatedEvent,
	SpecialistGroupCreatedEvent,
	TeacherCreatedEvent,
} from '@esgi/main/features/school-admin/data';
import {StudentCreatedEvent} from '@esgi/main/features/school-admin/student-profile-drawer/events';
import {useEventEffect} from '@esgillc/events';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {
	RemoveSpecialistGroupEvent,
	RemoveStudentEvent,
	RemoveTeacherClassEvent,
	RemoveTeacherEvent,
	RemoveTeacherGroupEvent,
} from '@esgi/main/features/school-admin/common/events';

export function useData({activeEntityType}: {activeEntityType: EntityType | null}) {
	const {entityFolders, setEntityFolders} = useDataPageContext();

	const [foldersData, fetchFolders] = FolderManagement.useFetchFolders();

	const controller = useDisposable(V2SchoolAdminsPagesDataController);

	const [initData, fetchInitData] = useCancelableRequest(controller.init);

	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const fetchFoldersData = useCallback(() => {
		const folderType = (() => {
			switch (activeEntityType) {
				case 'teachers':
					return FolderType.Teacher;
				case 'specialists':
					return FolderType.Specialist;

				default:
					return null;
			}
		})();

		if (isNull(folderType)) {
			return;
		}

		fetchFolders(folderType);
	}, [activeEntityType, fetchFolders]);

	useEffect(fetchFoldersData, [activeEntityType]);
	useEffect(fetchInitData, []);

	useEffect(() => {
		if (!isNull(activeEntityType) && entitiesWithFolders.includes(activeEntityType) && isAsyncSucceed(foldersData)) {
			setEntityFolders(foldersData.data.folders);
			return;
		}

		setEntityFolders([]);
	}, [activeEntityType, foldersData, setEntityFolders]);

	useEventEffect(ClassCreatedEvent, fetchInitData);
	useEventEffect(RemoveTeacherClassEvent, fetchInitData);

	useEventEffect(TeacherCreatedEvent, fetchInitData);
	useEventEffect(RemoveTeacherEvent, () => {
		fetchInitData();
		fetchFoldersData();
	});

	useEventEffect(StudentCreatedEvent, fetchInitData);
	useEventEffect(RemoveStudentEvent, fetchInitData);

	useEventEffect(GroupCreatedEvent, fetchInitData);
	useEventEffect(RemoveTeacherGroupEvent, fetchInitData);

	useEventEffect(SpecialistGroupCreatedEvent, fetchInitData);
	useEventEffect(RemoveSpecialistGroupEvent, fetchInitData);

	useEventEffect(FolderCreatedEvent, fetchFoldersData);
	useEventEffect(FolderUpdatedEvent, fetchFoldersData);

	const entities = useMemo<EntityProperty[]>(
		() => [
			{
				name: 'Teachers',
				type: 'teachers',
				count: isAsyncSucceed(initData) ? initData.data.value.teachersCount : null,
			},
			{
				name: 'School Specialists',
				type: 'specialists',
				count: isAsyncSucceed(initData) ? initData.data.value.schoolSpecialistsCount : null,
			},
			{
				name: 'Classes',
				type: 'classes',
				count: isAsyncSucceed(initData) ? initData.data.value.classesCount : null,
			},
			{
				name: 'Groups',
				type: 'groups',
				count: isAsyncSucceed(initData) ? initData.data.value.groupsCount : null,
			},
			{
				name: 'Specialist Groups',
				type: 'specialistGroups',
				count: isAsyncSucceed(initData) ? initData.data.value.specialistGroupsCount : null,
			},
			{
				name: 'Students',
				type: 'students',
				count: isStudentsLoaded
					? students.filter((student) => !!student.teacherID || !isEmpty(student.specialistGroupsIDs)).length
					: null,
			},
		],
		[initData, isStudentsLoaded, students],
	);

	return {
		entities,
		folders: entityFolders,
	};
}
