import {DistrictReportPanel} from '../../../../_kit';
import {RowDef} from '../../../types';
import {useDataPageContext} from '../../../../layout/context';
import {AddEntitiesIntoFolder, FolderType, Page} from '@esgi/main/features/admins/data';
import {BottomPanel} from './bottom-panel';
import {useFlag} from '@esgi/ui';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';

export function Content() {
	const {entityFolders, districtName} = useDataPageContext();
	const {selectedItems, api, tableRows} = Page.useContext<RowDef>();

	const [showAddEntitiesIntoFolder, openAddEntitiesIntoFolder, closeAddEntitiesIntoFolder] = useFlag();

	return (
		<>
			<Page.Header.Base />
			<Page.Body.Base />

			<SingleItemExpandablePanel gridApi={api} onFolderAddClick={openAddEntitiesIntoFolder} />
			<BottomPanel onFolderAddClick={openAddEntitiesIntoFolder} />
			<DistrictReportPanel
				title={districtName}
				isAllSelectedAsDistrictReport
				totalItems={tableRows.length}
				selectedItems={selectedItems.length}
			/>

			{showAddEntitiesIntoFolder && (
				<AddEntitiesIntoFolder
					addEntityIDs={selectedItems.map(({id}) => id)}
					folders={(entityFolders ?? []).map(({id, name}) => ({id, name}))}
					initialFolderID={null}
					onCloseAlert={closeAddEntitiesIntoFolder}
					folderType={FolderType.Schools}
				/>
			)}
		</>
	);
}
