import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {SubjectEntity} from 'api/entities';
import React from 'react';
import {TestType} from '@esgi/core/enums';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import * as TestDetailsEvents from 'shared/modules/test-details/events';
import {EventBusManager} from '@esgillc/events';
import {userStorage} from '@esgi/core/authentication';
import {isEqual} from 'underscore';
import {SubjectModel} from '../../../services/subjects-service/models';
import ReloadPieChart from '../api';
import {PieChartModel} from '../models';
import {CardChart} from './components/chart';
import {CardFooter} from './components/footer';
import {CardHeader} from './components/header';
import {InfoPanel} from './components/info-panel';
import {SelfAssessIcon} from './components/self-assess-icon';
import {IepIcon} from './components/iep-icon';
import ContextMenu from './components/context-menu/context-menu';
import styles from './card.module.less';
import {mapToEnum} from 'shared/utils';
import {TestSavedEvent} from 'modules/assessments';
import {createStateFromPieChartModel, State, stateToPieChartConverter} from './state';
import {calcPercentage, canRemoveTestFromSubject, isStudentLevel} from './utils';
import {RemoveTestAlert} from './components/remove-test-alert';

class Props {
	subject: SubjectModel;
	hierarchy: HierarchyInstance;
	canTest: boolean;
	canDrag: boolean;
	disabled: boolean;
	initModel: PieChartModel;
	reloadPieCharts: () => void;
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	selfAssessEnabled: boolean;
}

export default class Card extends React.Component<Props, State> {
	private readonly eventBus = new EventBusManager();
	private removingInProgress: boolean = false;

	constructor(props: Props) {
		super(props);
		this.state = createStateFromPieChartModel(props.initModel);
	}

	private get currentUser() {
		return userStorage.get();
	}

	public componentDidMount(): void {
		this.eventBus.subscribe(TestDetailsEvents.TestChanged, (args) => {
			if (this.state.testID === args.id) {
				if (this.state.name !== args.newName || this.state.color !== args.color) {
					this.setState({name: args.newName, color: args.color});
				}
			}
		});

		this.eventBus.subscribe(TestSavedEvent, (e: TestSavedEvent) => {
			if (e.testID === this.state.testID) {
				this.runnerFinished();
			}
		});
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (
			this.props.initModel !== prevProps.initModel
			&& !isEqual(this.props.initModel, stateToPieChartConverter(this.state))
		) {
			const state = createStateFromPieChartModel(this.props.initModel);
			state.contextMenuOpened = this.state.contextMenuOpened;
			state.mouseDown = this.state.mouseDown;
			this.setState(state);
		}
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
	}

	public render() {
		const {name, testID, color, testType, studentsTested} = this.state;
		const {hierarchy, testResultsCorrectVerbiage, testResultsIncorrectVerbiage} = this.props;
		const {correctPercent, incorrectPercent} = calcPercentage(this.state.correctAnswers, this.state.totalAnswers);

		return (
			<div className={styles.card} data-name={name} data-id={testID} data-cy='pie-charts-card'>
				<CardHeader
					name={name}
					color={color}
					canDrag={this.props.canDrag}
					openContextMenuClicked={() => this.setState({contextMenuOpened: true})}
				/>
				<div className={styles.cardBody}>
					<CardChart
						testType={testType}
						studentsTested={studentsTested}
						correctPercent={correctPercent}
						incorrectPercent={incorrectPercent}
						testResultsCorrectVerbiage={testResultsCorrectVerbiage}
						testResultsIncorrectVerbiage={testResultsIncorrectVerbiage}
					/>
					<div className={styles.info}>
						{this.state.selfAssessmentTestAllowed && this.props.selfAssessEnabled && <SelfAssessIcon/>}
						{this.state.hasIEPGoal && <IepIcon/>}
						<InfoPanel
							hierarchy={hierarchy}
							pieChart={this.state}
							studentsTested={studentsTested}
							testResultsCorrectVerbiage={testResultsCorrectVerbiage}
						/>
					</div>
				</div>
				<CardFooter
					subject={this.props.subject}
					pieChart={this.state}
					canTest={this.props.canTest}
					selfAssessEnabled={this.props.selfAssessEnabled}
					studentsTested={studentsTested}
					hierarchy={hierarchy.snapshot}
					disabled={this.props.disabled}
					testingFinished={() => this.runnerFinished()}
				/>
				{this.state.contextMenuOpened &&
					<ContextMenu
						onTestInfoChanged={({name, color}) => this.setState({name, color})}
						testType={mapToEnum(this.state.testType, TestType)}
						testId={testID}
						testName={name}
						hierarchy={hierarchy.snapshot}
						subject={this.props.subject}
						clickedOutside={() => this.setState({contextMenuOpened: false})}
						canEdit={this.state.canEdit}
						canRemove={canRemoveTestFromSubject(this.props.subject)}
						testClosed={(totalPossible) => this.testClosed(totalPossible)}
						removeTestFromSubject={() => this.setState({showRemoveTestAlert: true})}
					/>
				}
				{this.state.showRemoveTestAlert && <RemoveTestAlert
					onCancel={this.onRemoveTestCancel}
					onConfirm={this.removeTestFromSubject}
					testName={name}
					subjectName={this.props.subject.name}/>}
			</div>
		);
	}

	public runnerFinished() {
		this.reload().then(() => {
			this.setState(({studentsTested}) => {
				if (studentsTested === 0) {
					return {studentsTested: 1};
				}

				return null;
			});
		});
	}

	public testClosed(totalPossible: number) {
		const {canEdit, testType, totalAnswers, correctAnswers} = this.state;
		if (canEdit && testType === TestType[TestType.Score]) {
			if (totalPossible && totalAnswers !== totalPossible) {
				if (isStudentLevel(this.props.hierarchy)) {
					if (correctAnswers > totalPossible) {
						this.setState({
							totalAnswers: totalPossible,
							correctAnswers: totalPossible,
						});
					} else {
						this.setState({
							totalAnswers: totalPossible,
						});
					}
				} else {
					this.props.reloadPieCharts();
				}
			}
		}
		this.setState({contextMenuOpened: false});
	}

	public reload() {
		const deferred = $.Deferred<any>();
		ReloadPieChart(this.state.testID, this.props.hierarchy.snapshot)
			.subscribe((s) => {
				this.setState(
					() => {
						const {correctAnswers, lastTestDate} = s;
						const totalAnswers = this.state.totalAnswers !== 0
							? this.state.totalAnswers
							: this.state.totalPossible;
						return {correctAnswers, lastTestDate, totalAnswers};
					},
					() => deferred.resolve(s),
				);
			});

		return deferred.promise();
	}

	private onRemoveTestCancel = () => {
		this.setState({contextMenuOpened: false, showRemoveTestAlert: false})
	}

	private removeTestFromSubject = () => {
		this.setState({contextMenuOpened: false, showRemoveTestAlert: false}, () => {
			if (this.removingInProgress) {
				return;
			}

			this.removingInProgress = true;
			SubjectEntity.removeTest(this.props.subject.id, this.props.subject.type, this.state.testID).then(() => {
				showSnackbarNotification(`You've removed ${this.state.name} from ${this.props.subject.name}`);
				this.removingInProgress = false;
			});
		});
	}
}
