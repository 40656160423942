import {ActionPanel, NegativeButton} from '../../../../_kit';
import {RowDef} from '../../../types';
import {Text} from '@esgi/ui/typography';
import {FolderAdd, FolderRemove} from '@esgi/ui/icons';
import {DeleteTeacherButtonMultipleSelect} from '../../../components/delete-teacher-button-multiple-select';
import {Button} from '@esgi/ui';
import {Page, RunReportButton} from '@esgi/main/features/admins/data';

type Props = {
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
};

export function BottomPanel({onFolderAddClick, onRemoveFromFolderClick}: Props) {
	const {entityName, api} = Page.useContext<RowDef>();

	return (
		<ActionPanel.Root api={api} showOnMinSelectedItems={2}>
			<ActionPanel.Header>
				<ActionPanel.ContentBox>
					<ActionPanel.CloseButton />
					<ActionPanel.MainBox>
						<ActionPanel.CounterBox />
						<Text size='xSmall' font='mono' color='primary'>
							{entityName} Selected
						</Text>
					</ActionPanel.MainBox>
					<ActionPanel.ActionButtonsBox>
						<ActionPanel.ShowOnlySelectedCheckbox />
						<ActionPanel.ActionButtonDivider />

						<DeleteTeacherButtonMultipleSelect />
						<ActionPanel.ActionButtonDivider />

						<Button.Icon withBackgroundHover onClick={onFolderAddClick}>
							<FolderAdd />
						</Button.Icon>

						<NegativeButton onClick={onRemoveFromFolderClick}>
							<FolderRemove />
						</NegativeButton>

						<RunReportButton disabled />
					</ActionPanel.ActionButtonsBox>
				</ActionPanel.ContentBox>
			</ActionPanel.Header>
		</ActionPanel.Root>
	);
}
