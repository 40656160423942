import {ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {useFilteredStoreSubjects} from './hooks';
import {isNull} from 'underscore';
import {useMemo} from 'react';
import {DistrictReportsDialog} from './district-reports-dialog';

export function DistrictReportsButton() {
	const [filteredSubjects, firstStoreSubject, isFirstStoreSubjectLoaded] = useFilteredStoreSubjects();

	const reportSubject = useMemo<ReportsSubjectInfo | null>(() => {
		if (isNull(firstStoreSubject)) {
			return null;
		}

		return {
			id: firstStoreSubject.id,
			level: firstStoreSubject.level,
			type: firstStoreSubject.subjectType,
			name: firstStoreSubject.name,
		};
	}, [firstStoreSubject]);

	const reportFilteredSubjects = useMemo<ReportsSubjectInfo[]>(() => {
		return filteredSubjects.map((s) => ({
			id: s.id,
			level: s.level,
			type: s.subjectType,
			name: s.name,
		}));
	}, [filteredSubjects]);

	return (
		<RunReportButton title='District Reports' skeleton={!isFirstStoreSubjectLoaded} subject={reportSubject}>
			{({onReportsClose, subject}) => (
				<DistrictReportsDialog
					onClose={onReportsClose}
					subject={subject}
					reportFilteredSubjects={reportFilteredSubjects}
				/>
			)}
		</RunReportButton>
	);
}
