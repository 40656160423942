import {Dispatch, useCallback, useEffect, useState} from 'react';
import {
	classesStore,
	Group,
	groupsStore,
	Student,
	studentsStore,
	useClasses,
	useGroups,
	useStudents,
} from '@esgi/main/libs/admin-store';
import {V2DistrictAdminsClassesController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {isNull} from 'underscore';
import {useUser} from '@esgi/core/authentication';
import {dispatchAppEvent} from '@esgillc/events';
import {RemoveTeacherClassEvent} from '../../events';

type Out = [boolean, Dispatch<OnRemoveParameters>, boolean];

type OnRemoveParameters = {
	id: number;
	name: string;
	onRemoved: VoidFunction;
};

export function useRemoveTeacherClass(): Out {
	const currentUser = useUser();

	const [{loaded: isClassesLoaded}] = useClasses();
	const [{loaded: isGroupsLoaded}] = useGroups();
	const [{loaded: isStudentsLoaded}] = useStudents();

	const controller = useDisposable(V2DistrictAdminsClassesController);
	const [response, request] = useCancelableRequest(controller.remove);

	const [isRemoving, setIsRemoving] = useState(false);
	const [requestParameters, setRequestParameters] = useState<OnRemoveParameters | null>(null);

	useEffect(() => {
		if (isAsyncSucceed(response) && !isNull(requestParameters)) {
			classesStore().removeById?.(requestParameters.id);

			groupsStore().remove?.((item: Group): boolean => item.classID === requestParameters.id);

			studentsStore().update?.((item: Student): Student => {
				if (item.classesIDs.includes(requestParameters.id)) {
					return {
						...item,
						classesIDs: item.classesIDs.filter((id) => id !== requestParameters.id),
					};
				}

				return item;
			});

			dispatchAppEvent(
				RemoveTeacherClassEvent,
				new RemoveTeacherClassEvent({
					id: requestParameters.id,
					name: requestParameters.name,
				}),
			);

			requestParameters.onRemoved();

			setRequestParameters(null);
			setIsRemoving(false);
		}
	}, [response, requestParameters]);

	const onRemove = useCallback(
		({id, name, onRemoved}: OnRemoveParameters) => {
			if (isNull(currentUser)) {
				return;
			}

			setIsRemoving(true);

			request({
				classID: id,
				globalSchoolYearID: currentUser.globalSchoolYearID,
			});

			setRequestParameters({
				id,
				name,
				onRemoved,
			});
		},
		[currentUser, request],
	);

	const isDataLoaded = isClassesLoaded && isGroupsLoaded && isStudentsLoaded;

	return [isDataLoaded, onRemove, isAsyncPending(response) || isRemoving];
}
