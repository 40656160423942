import {
	SubjectPanel,
	Entities,
	TabID,
	PanelMode,
	SchoolSubject,
	SubjectCheap,
	Select,
	Item,
} from '@esgi/main/features/admins/subject-management';
import {Subject} from '@esgi/ui/icons';
import {Container, TitleContainer} from './index.styled';
import {Dispatch, useCallback, useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {User} from '@esgi/main/libs/school-admin-store';
import {getFullName} from '@esgi/main/kits/common';
import {isNull} from 'underscore';

type Props = {
	skeleton: boolean;
	entities: Entities;
	onActiveTabChanged: Dispatch<TabID | null>;
	selectedSubjectID: number | null;
	setSelectedSubjectID: Dispatch<number | null>;
	subjectsPanelMode: PanelMode;
	onSubjectsPanelModeChange: Dispatch<PanelMode>;
	onRearrangeSchoolSubjects: Dispatch<SchoolSubject[]>;
	isSubjectHasUnsavedChanges: boolean;
	selectedTeacherID: User['id'] | null;
	setSelectedTeacherID: Dispatch<User['id'] | null>;
	teachersList: User[];
	isSelectedTeacherSubjectsLoaded: boolean;
};

export function SubjectsPanel({
	entities,
	skeleton,
	isSelectedTeacherSubjectsLoaded,
	onActiveTabChanged,
	selectedSubjectID,
	setSelectedSubjectID,
	subjectsPanelMode,
	onSubjectsPanelModeChange,
	onRearrangeSchoolSubjects,
	isSubjectHasUnsavedChanges,
	selectedTeacherID,
	setSelectedTeacherID,
	teachersList,
}: Props) {
	const teachersSelectItems = useMemo(
		() =>
			teachersList.map<Item<string>>(({id, firstName, lastName}) => ({
				value: String(id),
				label: getFullName({firstName, lastName}),
			})),
		[teachersList],
	);

	const onActiveTeacherChanged = useCallback(
		(id: string) => {
			setSelectedTeacherID(Number(id));
		},
		[setSelectedTeacherID],
	);

	const isRearrangeMode = subjectsPanelMode === PanelMode.Rearrange;

	return (
		<Container dataCy='subject-manager-panel'>
			<SubjectPanel.Root
				skeleton={skeleton}
				entities={entities}
				initialTab={TabID.School}
				onActiveTabChanged={onActiveTabChanged}
				selectedSubjectID={selectedSubjectID}
				onSelectedSubjectIDChanged={setSelectedSubjectID}
				panelMode={subjectsPanelMode}
				onPanelModeChanged={onSubjectsPanelModeChange}
				isSubjectHasUnsavedChanges={isSubjectHasUnsavedChanges}
			>
				<TitleContainer isRearrangeMode={isRearrangeMode}>
					<SubjectPanel.SectionTitle.Icon fill='mediumContrast' Component={Subject} />
					<SubjectPanel.SectionTitle.Title size='small' color='mediumContrast'>
						Subject Tab Manager
					</SubjectPanel.SectionTitle.Title>
					{isRearrangeMode && (
						<Text size='small' color='primary'>
							Rearrange Mode
						</Text>
					)}
					<SubjectPanel.RearrangeButton />
				</TitleContainer>

				<SubjectPanel.Tabs.Bar />

				<SubjectPanel.Tabs.Content.Root tabID={TabID.School} tabContent={<SubjectCheap.Variant.School />}>
					<SubjectPanel.Tabs.Content.AddSubjectButton />
					<SubjectPanel.Tabs.Content.ViewMode
						hiddenIconText='To activate this tab, select it, then check the Active box in Subject Tab Settings.'
						subjects={entities.schoolSubjects}
					/>
					<SubjectPanel.Tabs.Content.RearrangeMode
						subjects={entities.schoolSubjects}
						onSaveClick={(subjects) => onRearrangeSchoolSubjects(subjects as SchoolSubject[])}
					/>
				</SubjectPanel.Tabs.Content.Root>

				<SubjectPanel.Tabs.Content.Root tabID={TabID.District} tabContent={<SubjectCheap.Variant.District />}>
					<SubjectPanel.Tabs.Content.ViewMode subjects={entities.districtSubjects} />
				</SubjectPanel.Tabs.Content.Root>

				<SubjectPanel.Tabs.Content.Root tabID={TabID.Teacher} tabContent={<SubjectCheap.Variant.Teacher />}>
					<Select
						placeholder='Teacher'
						items={teachersSelectItems}
						activeValue={isNull(selectedTeacherID) ? undefined : String(selectedTeacherID)}
						setActiveValue={onActiveTeacherChanged}
						skeleton={skeleton}
						dataCy='teacher-select'
					/>

					<SubjectPanel.Tabs.Content.ViewMode
						subjects={entities.teacherSubjects}
						skeleton={!isSelectedTeacherSubjectsLoaded}
					/>
				</SubjectPanel.Tabs.Content.Root>
			</SubjectPanel.Root>
		</Container>
	);
}
