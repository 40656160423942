import {Student, useStudents} from '@esgi/main/libs/school-admin-store';
import {RemoveStudentManagerRef} from './types';
import {useCallback, useImperativeHandle, useState} from 'react';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {DeleteStudentAlert} from './components/delete-student-alert';
import {useUser} from '@esgi/core/authentication';

type Props = {
	managerRef: RemoveStudentManagerRef;
	onRemoved: VoidFunction;
};

export function RemoveStudentImpl({managerRef, onRemoved}: Props) {
	const currentUser = useUser();

	const [{data: studentList}] = useStudents();

	const [potentialRemovedStudent, setPotentialRemovedStudent] = useState<Student | null>(null);

	const closeDeleteAlert = useCallback(() => {
		setPotentialRemovedStudent(null);
	}, []);

	useImperativeHandle(managerRef, () => ({
		runRemove: ({studentID}) => {
			if (!currentUser?.canEditStudents) {
				throw new Error(`User hasn't permissions to delete a student`);
			}

			const student = studentList.find(({id}) => id === studentID);

			if (isUndefined(student)) {
				throw new Error('student is not found in admin store');
			}

			setPotentialRemovedStudent(student);
		},
	}));

	if (isNull(potentialRemovedStudent)) {
		return null;
	}

	return (
		<DeleteStudentAlert
			onClose={closeDeleteAlert}
			onRemoved={onRemoved}
			potentialRemovedStudent={potentialRemovedStudent}
		/>
	);
}
