import {ChartIconWrapper} from './index.styled';
import {ChartIconType} from '../../types';
import {Text} from '@esgi/ui/typography';
import {Circle} from '../chart/index.styled';
import {Rect, Path} from '../chart-controls/index.styled';
import {VariantProps} from '@stitches/react';
import {useUser} from '@esgi/core/authentication';
import {useMemo} from 'react';

type ChartIconProps = {
	color: NonNullable<VariantProps<typeof Circle>['stroke']>;
	type: ChartIconType;
	width?: number;
};

export function ChartIcon({type, color, width = 10}: ChartIconProps) {
	const currentUser = useUser();

	const labelText = useMemo<Record<ChartIconType, string>>(
		() => ({
			[ChartIconType.CORRECT]: `Avg. ${currentUser?.testResultsCorrectVerbiage ?? 'Correct'}`,
			[ChartIconType.INCORRECT]: `${currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect'} Only`,
			[ChartIconType.RETEST]: 'Retest All',
			[ChartIconType.BASELINE]: 'Baseline',
			[ChartIconType.RUNSUBSET]: 'Run Subset',
		}),
		[currentUser],
	);

	return (
		<ChartIconWrapper align='center' dataCy={type.toLowerCase()}>
			<svg xmlns='http://www.w3.org/2000/svg' width={width} height='10' viewBox={`0 0 ${width} 10`} fill='none'>
				<Circle cx='5' cy='5' r='3.75' stroke={color} strokeWidth='1.5' />
				{type === ChartIconType.CORRECT && <Rect x='8' y='4.25' width='16' height='1.5' rx='0.75' fill='primaryVivid' />}
				{type === ChartIconType.BASELINE && (
					<Path
						d='M24 5C24 5.41421 23.6776 5.75 23.28 5.75L21.66 5.75C21.2624 5.75 20.94 5.41421 20.94 5C20.94 4.58579 21.2624 4.25 21.66 4.25H23.28C23.6776 4.25 24 4.58579 24 5ZM19.14 5C19.14 5.41421 18.8176 5.75 18.42 5.75H15.18C14.7824 5.75 14.46 5.41421 14.46 5C14.46 4.58579 14.7824 4.25 15.18 4.25H18.42C18.8176 4.25 19.14 4.58579 19.14 5ZM12.66 5C12.66 5.41421 12.3376 5.75 11.94 5.75H8.72C8.32236 5.75 8 5.41421 8 5C8 4.58579 8.32236 4.25 8.72 4.25H11.94C12.3376 4.25 12.66 4.58579 12.66 5Z'
						fill='secondary'
					/>
				)}
			</svg>
			<Text size='xSmall' font='mono' color={color}>
				{labelText[type]}
			</Text>
		</ChartIconWrapper>
	);
}
