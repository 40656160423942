import {QuickPanel} from './quick-panel';
import {TestResults} from '@esgi/main/kits/admin';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {useSelectedSpecialistData} from '../../../../use-selected-specialist-data';
import {RowDef} from '../../../../../types';
import {useSpecialistGroups} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {UserType} from '@esgi/core/authentication';
import {Folder} from '@esgi/main/features/school-admin/data';

type Props = {
	isPanelExpanded: boolean;
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	activeFolder: Folder;
};

export function ContentWrapper({
	isPanelExpanded,
	onFolderAddClick,
	onRemoveFromFolderClick,
	selectedRow,
	activeFolder,
}: Props) {
	const {isSelectedSpecialistDataReady, students, subjects, reportSubject} = useSelectedSpecialistData({selectedRow});

	const [{data: specialistsGroups, loaded: isSpecialistsGroupsLoaded}] = useSpecialistGroups();

	const hierarchy = useMemo<Hierarchy>(() => {
		const specialistGroup = specialistsGroups.find(({specialistID}) => specialistID === selectedRow.id);

		const h: Hierarchy = {
			specialistsID: selectedRow.id,
			specialistsFolderID: activeFolder.id,
			...(specialistGroup && {
				specialistGroup: {
					id: specialistGroup.id,
					name: specialistGroup.name,
					specialistID: specialistGroup.specialistID,
					type: UserType.ISS,
				},
			}),
		};

		return h;
	}, [activeFolder, selectedRow, specialistsGroups]);

	const skeleton = !isSelectedSpecialistDataReady || !isSpecialistsGroupsLoaded;

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				onRemoveFromFolderClick={onRemoveFromFolderClick}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={skeleton}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={skeleton}
					allSubjects={subjects}
					students={students}
					hierarchy={hierarchy}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
