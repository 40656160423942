import {userStorage, UserType} from '@esgi/core/authentication';
import {dispatchAppEvent} from '@esgillc/events';
import {ElementStatus, ValidatorArgs} from '@esgillc/ui-kit/form';
import {BehaviorSubject, forkJoin, map, Observable, switchMap, tap} from 'rxjs';
import {
	createLoginInfoForm,
	LoginInfoFormType,
	usernameAvailableCustomValidator,
} from '../../forms/login-info';
import {
	createSecondaryProfileForm,
	SecondaryProfileFormType,
} from './forms/secondary-profile-form';
import {
	PrimaryProfileUpdateModel,
	UserSecondaryProfileUpdateModel,
} from './types';
import {UserInfoChangedEvent, UserInfoTrackChangedEvent, UserInfoTrackCreatedEvent} from '../../events';
import {
	DefaultProfileControllers,
	DropdownInModel,
	TrackInfoResponse,
	UserInfoResponse,
	BaseResponse,
} from '../../types';
import {GradeLevels, Titles} from '../../constants';
import {BaseProfileService} from '../../base-profile-service';
import {DateTools} from '../../utils';

export class TeacherProfileService extends BaseProfileService {
	public secondaryProfileLoginInfoForm: LoginInfoFormType = createLoginInfoForm();
	public secondaryProfileForm: SecondaryProfileFormType = createSecondaryProfileForm();
	public hasSecondaryProfile = new BehaviorSubject<boolean>(false);
	private isUsernameAvailable = new BehaviorSubject<boolean>(true);
	private initialUsername: string;

	constructor() {
		super();
		this.eventBus.subscribe(UserInfoTrackChangedEvent, this.trackChanged);
		this.eventBus.subscribe(UserInfoTrackCreatedEvent, this.trackCreated);
	}

	public init(userID: number) {
		this.userID = userID;
		this.trackInfoForm.controls.type.status = ElementStatus.disabled;
		return this.initTeacherProfile();
	}

	public validateForms = () => {
		return forkJoin([
			this.userInfoForm,
			this.loginInfoForm,
			this.trackInfoForm,
			this.secondaryProfileForm,
			this.secondaryProfileLoginInfoForm,
		].map(f => f.validate())).pipe(map((r) => r.map(v => v.valid).every(Boolean)));
	};

	public updatePrimaryProfile() {
		const model = new PrimaryProfileUpdateModel(
			this.loginInfoForm.controls.login.value,
			this.userInfoForm.controls.email.value,
			this.userInfoForm.controls.title.value[0].value,
			this.userInfoForm.controls.firstName.value,
			this.currentUser.globalSchoolYearID,
			this.userInfoForm.controls.lastName.value,
			this.loginInfoForm.controls.password.value === this.initialPassword ? null : this.loginInfoForm.controls.password.value,
			this.userInfoForm.controls.state.value[0]?.id,
			this.trackInfoForm.controls.track.value[0]?.trackID,
			this.userID,
			this.userInfoForm.controls.gradeLevels.value.map(g => g.id),
		);
		return this.httpClient.ESGIApi.post<BaseResponse>(DefaultProfileControllers.Teacher, 'update', model)
			.pipe(tap((response: BaseResponse) => {
				if (!response.isSuccess) {
					const usernameError = response.errors.find(error => error.type === 'username_is_already_taken');
					if (usernameError) {
						this.isUsernameAvailable.next(false);
						this.loginInfoForm.controls.login.validateWithEmitters();
					}
				} else {
					const {
						districtID,
						globalSchoolYearID,
						name,
						schoolYearType,
						schoolYearTypeID,
						trackID,
						trackDates,
					} = this.trackInfoForm.controls.track.value[0];
					dispatchAppEvent(UserInfoTrackChangedEvent, new UserInfoTrackChangedEvent(
						districtID,
						globalSchoolYearID,
						name,
						schoolYearTypeID,
						schoolYearType,
						trackID,
						trackDates,
					));
					dispatchAppEvent(UserInfoChangedEvent, new UserInfoChangedEvent(this.userID, model.firstName, model.lastName, UserType.T));

					if (model.userID === this.currentUser.userID) {
						userStorage.update({
							firstName: model.firstName,
							lastName: model.lastName,
							stateID: model.stateID,
						});
					}
				}
			}));
	}

	public updateSecondaryProfile() {
		const model = new UserSecondaryProfileUpdateModel(
			this.secondaryProfileLoginInfoForm.controls.login.value,
			this.secondaryProfileForm.controls.email.value,
			this.secondaryProfileForm.controls.firstName.value,
			this.secondaryProfileForm.controls.lastName.value,
			this.secondaryProfileLoginInfoForm.controls.password.value === this.initialPassword
				? null : this.secondaryProfileLoginInfoForm.controls.password.value,
			this.userID,
			0,
		);
		return this.httpClient.ESGIApi.post(DefaultProfileControllers.Teacher, 'update-secondary-profile', model);
	}

	public onManageAccountSaved = ({states, countries, selectedState, selectedCountry, schoolName, districtName}) => {
		this.userInfoForm.value = {
			...this.userInfoForm.value,
			state: [selectedState],
			country: [selectedCountry],
		};
		this.states.next(states);
		this.countries.next(countries);
		this.userInfo.next({...this.userInfo.value, schoolName});
		this.district.next({...this.district.value, name: districtName});
	};

	private initTeacherProfile() {
		return this.httpClient.ESGIApi.get<UserInfoResponse>(DefaultProfileControllers.Teacher, 'init', {userID: this.userID})
			.pipe(
				tap(response => {
					const countries = response.countries.map(({
						countryID,
						name,
					}) => new DropdownInModel(countryID, name));
					const states = response.states.map(({stateID, name}) => new DropdownInModel(stateID, name));
					this.countries.next(countries);
					this.states.next(states);

					const {
						firstName,
						lastName,
						email,
						countryID,
						stateID,
						title,
						userName,
						districtName,
						districtID,
						schoolName,
						isLinked,
						notRenewable,
						canAllowRenewByCC,
						expirationDate,
						agreementLevelCode,
						canEditGradeLevels,
						gradeLevels,
					} = response.user;

					let selectedGrades: DropdownInModel[] = [];
					if (gradeLevels){
						selectedGrades = GradeLevels
						  .filter(x => gradeLevels.some((g) => g === x.id));
					}

					this.userInfoForm.value = {
						firstName,
						lastName,
						email,
						title: [Titles.find(x => x.value === title)],
						state: [states.find(x => x.id === stateID)],
						country: [countries.find(x => x.id === countryID)],
						gradeLevels: selectedGrades,
					};
					this.userInfoForm.controls.gradeLevels.status = canEditGradeLevels ? ElementStatus.pending : ElementStatus.disabled;
					this.loginInfoForm.value = {
						login: userName,
						password: this.initialPassword,
					};

					this.initialUsername = userName;
					this.loginInfoForm.controls.login.validators.push(usernameAvailableCustomValidator(this.initialUsername, this.isUsernameAvailable));
					this.loginInfoForm.controls.login.onChanged.subscribe((ch) => {
						if (ch.prevState.value !== ch.currState.value) {
							this.loginInfoForm.controls.login.status = ElementStatus.untouched;
							this.isUsernameAvailable.next(true);
						}
					});

					if (response.secondaryProfile) {
						const {firstName, lastName, email, userName} = response.secondaryProfile;
						this.secondaryProfileForm.value = {
							firstName,
							lastName,
							email,
						};
						this.secondaryProfileLoginInfoForm.value = {
							login: userName,
							password: this.initialPassword,
						};
						this.hasSecondaryProfile.next(true);
						this.loginInfoForm.status = ElementStatus.disabled;
						this.userInfoForm.status = ElementStatus.disabled;
						this.trackInfoForm.status = ElementStatus.disabled;
					} else {
						this.secondaryProfileForm.status = ElementStatus.disabled;
						this.secondaryProfileLoginInfoForm.status = ElementStatus.disabled;
					}

					this.district.next({id: districtID, name: districtName});
					this.userInfo.next(
						{
							schoolName,
							isLinked,
							notRenewable,
							canAllowRenewByCC,
							expirationDate: expirationDate ? DateTools.toUIString(DateTools.toDate(expirationDate)) : 'not set',
							agreementLevelCode,
							initialLogin: userName,
							canEditGradeLevels,
							gradeLevels: selectedGrades,
						},
					);
				}),
				switchMap(response => {
					return this.httpClient.ESGIApi.get<TrackInfoResponse>(DefaultProfileControllers.Common, 'get-track-info', {
						userID: this.userID,
						districtID: response.user.districtID,
					});
				}),
			)
			.pipe(tap(response => {
				const selectedTrack = response.tracks.find(x => x.trackID === response.selectedTrackID);
				const selectedType = response.schoolYearTypes.find(x => x.id === selectedTrack.schoolYearTypeID);
				this.trackInfoForm.value = {
					track: [selectedTrack],
					type: [selectedType],
				};
				this.trackInfoForm.controls.track.onChanged.subscribe(v => {
					const selectedTrack = this.tracks.value.find(x => x.trackID === v.currState.value[0].trackID);
					const selectedSchoolYearType = this.schoolYearTypes.value.find(x => x.id === selectedTrack.schoolYearTypeID);
					this.trackInfoForm.controls.type.value = [selectedSchoolYearType];
					this.periods.next(this.getPeriods());
				});

				this.schoolYearTypes.next(response.schoolYearTypes);
				this.tracks.next(response.tracks);
				this.periods.next(this.getPeriods());
			}));
	}
}
