import {Edit, Students} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button, Portal, useFlag} from '@esgi/ui';
import {useNavigateToStudentsPage, RunReportButton, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {ActionPanel, DeleteButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {GroupDrawer} from '@esgi/main/features/district-admin/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {useDataPageContext} from '../../../../../../../layout/context';
import {ReportsDialog} from '../reports-dialog';
import {useCallback} from 'react';
import {OpenPanelButton} from './components/open-panel-button';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RemoveTeacherGroup} from '@esgi/main/features/district-admin/common';
import {noop} from 'underscore';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	hierarchy: Hierarchy;
};

export function QuickPanel({selectedRow, isPanelExpanded, hierarchy, reportSubject, skeleton}: Props) {
	const removeTeacherGroupManager = RemoveTeacherGroup.useManagerRef();

	const {rootPageContentContainerRef} = useDataPageContext();

	const [showEditDrawer, openEditDrawer, closeEditDrawer] = useFlag();

	const goToStudentsPage = useNavigateToStudentsPage();

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			group: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	const onOpenRemoveEntity = useCallback(() => {
		removeTeacherGroupManager.current?.runRemove({
			teacherGroupID: selectedRow.id,
		});
	}, [removeTeacherGroupManager, selectedRow]);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<Students />
							<Text size='large' color='highContrast' data-cy='selected-item-name'>
								{selectedRow.name}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteButton onClick={onOpenRemoveEntity} />
							<ActionPanel.ActionButtonDivider />

							<Button.Icon onClick={openEditDrawer}>
								<Edit />
							</Button.Icon>

							<RunReportButton skeleton={skeleton} subject={reportSubject}>
								{({onReportsClose, subject}) => (
									<ReportsDialog
										onClose={onReportsClose}
										selectedGroup={selectedRow}
										hierarchy={hierarchy}
										subject={subject}
									/>
								)}
							</RunReportButton>
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<ActionPanel.Navigation.Container>
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
						</ActionPanel.Navigation.Container>

						<OpenPanelButton canExpandPanel={!!selectedRow.students} />
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showEditDrawer && (
				<Portal container={rootPageContentContainerRef.current}>
					<GroupDrawer.Edit onClose={closeEditDrawer} groupID={selectedRow.id} />
				</Portal>
			)}

			<RemoveTeacherGroup managerRef={removeTeacherGroupManager} onRemoved={noop} />
		</>
	);
}
