import {useUser} from '@esgi/core/authentication';
import {ReportType, ReportsSubjectInfo} from './types';

import {GradeScalesLauncher} from './launchers/grade-scales';
import {GrowthReportLauncher} from './launchers/growth-report';
import {TotalReportLauncher} from './launchers/total-report';
import {StudentsReportLauncher} from './launchers/students-report';
import ItemAnalysisLauncher from './launchers/item-analysis';
import PieChartsLauncher from './launchers/pie-charts';
import {TeacherActivityLauncher} from './launchers/teacher-activity';
import {StudentProgressLauncher} from './launchers/student-progress';
import {StudentDetailLauncher} from './launchers/student-detail-button';
import {UntestedStudentsButton} from './launchers/untested-students';
import {RubricReportLauncher} from './launchers/rubric';
import {PreAssessActivityLauncher} from './launchers/preassess-activity';
import {SpecialistActivityLauncher} from './launchers/specialist-activity';
import {BingoLauncher} from './launchers/bingo';
import {WritingPracticeLauncher} from './launchers/writing-practice';
import {Hierarchy, useHierarchyInstance} from '@esgi/main/features/admins/utils';
import {ParentLetterAndFlashCards} from './launchers/parent-letter-and-flash-cards';
import {mapToEnum} from 'shared/utils';
import {SubjectType} from '@esgi/core/enums';

type Props = {
	hierarchy: Hierarchy,
	report: ReportType,
	firstSubject: ReportsSubjectInfo,
	onClose: VoidFunction,
	subjects?: ReportsSubjectInfo[],
}

export function OldReport(props: Props) {
	const {
		hierarchy,
		report,
		firstSubject,
		onClose,
		subjects,
	} = props;

	const user = useUser();
	const hierarchyInstance = useHierarchyInstance({hierarchy});

	const mappedSubjectType = mapToEnum(firstSubject.type, SubjectType);

	switch (report) {
		case ReportType.Grade: return <GradeScalesLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.Growth: return <GrowthReportLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose} subjects={subjects}/>;
		case ReportType.TotalStudents: return <StudentsReportLauncher globalSchoolYearId={user?.globalSchoolYearID} hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.Total: return <TotalReportLauncher globalSchoolYearId={user?.globalSchoolYearID} hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.ItemAnalysis: return <ItemAnalysisLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.PieCharts: return <PieChartsLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.TeacherActivity: return <TeacherActivityLauncher teachersGroupID={hierarchy.teachersFolderID} schoolsGroupID={hierarchy.schoolsFolderID} onClose={onClose}/>;
		case ReportType.StudentsProgress: return <StudentProgressLauncher hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.StudentsDetail: return <StudentDetailLauncher hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.UntestedStudents: return <UntestedStudentsButton hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.RubricResults: return <RubricReportLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
		case ReportType.PreAssessActivity: return <PreAssessActivityLauncher onClose={onClose}/>;
		case ReportType.SpecialistActivity: return <SpecialistActivityLauncher groupID={hierarchyInstance.specialist.groupID} onClose={onClose}/>;
		case ReportType.Bingo: return <BingoLauncher subjectID={firstSubject.id} hierarchy={hierarchyInstance} onClose={onClose}/>;
		case ReportType.WritingPractice: return <WritingPracticeLauncher hierarchy={hierarchyInstance} onClose={onClose}/>;
		case ReportType.ParentLetter:
		case ReportType.FlashCards: return <ParentLetterAndFlashCards baseOpendReport={report} hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={mappedSubjectType} onClose={onClose}/>;
	}
}