import * as React from 'react';
import type {SVGProps} from 'react';

export function Specialist(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M21.4845 4.39108C21.6467 3.86964 22.3533 3.86964 22.5155 4.39108L23.2099 6.62239H25.4569C25.982 6.62239 26.2003 7.32397 25.7755 7.64623L23.9576 9.02526L24.652 11.2566C24.8143 11.778 24.2427 12.2116 23.8179 11.8893L22 10.5103L20.1821 11.8893C19.7573 12.2116 19.1857 11.778 19.348 11.2566L20.0424 9.02526L18.2245 7.64623C17.7997 7.32397 18.018 6.62239 18.5431 6.62239H20.7901L21.4845 4.39108Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.69231 12.25C9.69231 10.1792 11.3971 8.5 13.5 8.5C15.6029 8.5 17.3077 10.1792 17.3077 12.25C17.3077 14.3208 15.6029 16 13.5 16C11.3971 16 9.69231 14.3208 9.69231 12.25ZM15.6154 12.25C15.6154 11.0992 14.6683 10.1667 13.5 10.1667C12.3317 10.1667 11.3846 11.0992 11.3846 12.25C11.3846 13.4008 12.3317 14.3333 13.5 14.3333C14.6683 14.3333 15.6154 13.4008 15.6154 12.25Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 21C8 19.2967 9.03942 17.7508 10.5913 17.12C10.8078 17.0317 11.0393 17.05 11.2524 17.1458C12.0275 17.4933 12.7594 17.6667 13.5 17.6667C14.2431 17.6667 14.9725 17.5025 15.7212 17.1717C15.9374 17.0767 16.191 17.0792 16.4087 17.1717C17.9548 17.8283 19 19.3367 19 21V22.6667C19 23.1267 18.6212 23.5 18.1538 23.5H8.84615C8.37882 23.5 8 23.1267 8 22.6667V21ZM13.5 19.3333C12.68 19.3333 11.7161 19.1233 10.9167 18.825C10.2473 19.2108 9.69231 20.0292 9.69231 21V21.8333H17.3077V21C17.3077 20.0825 16.7846 19.2808 16.0209 18.8525C15.2376 19.1375 14.3136 19.3333 13.5 19.3333Z'
				fill='#333333'
			/>
		</svg>
	);
}
