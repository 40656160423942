import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const TabsTitle = styled(Text, {
	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				width: '100%',
				height: 12,
				borderRadius: 6,
			},
		},
	},
});
