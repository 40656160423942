import {useMemo} from 'react';
import {useClasses} from '@esgi/main/libs/admin-store';
import {ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../../types';
import {NoStudentsAlert} from '../../../../../../_kit';
import {getFullName} from '@esgi/main/kits/common';

type Props = {
	teacher: RowDef;
	onClose: VoidFunction;
	teacherLevelHierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function ReportsFeature({teacher, onClose, teacherLevelHierarchy, subject}: Props) {
	const [{data: classes, loaded: classesLoaded}] = useClasses();

	const isFullDataLoaded = classesLoaded;

	const classLevelHierarchy = useMemo<Hierarchy>(() => {
		if (classesLoaded) {
			const firstClass = classes.find((c) => c.teacherID === teacher.id);

			const h: Hierarchy = {
				...teacherLevelHierarchy,
				...(firstClass && {
					teacherClass: {
						id: firstClass.id,
						name: firstClass.name,
						teacherID: firstClass.teacherID,
					},
				}),
			};

			return h;
		}

		return {};
	}, [classesLoaded, classes, teacherLevelHierarchy, teacher]);

	if (!teacher.students) {
		return <NoStudentsAlert onClose={onClose} />;
	}

	return (
		<ReportDialog
			header={getFullName({
				firstName: teacher.firstName,
				lastName: teacher.lastName,
			})}
			loading={!isFullDataLoaded}
			onClose={onClose}
			subject={subject}
		>
			<ReportDialog.Section title='Class Reports'>
				<Reports.ParentLetter hierarchy={classLevelHierarchy} />
				<Reports.StudentDetail hierarchy={classLevelHierarchy} />
				<Reports.StudentProgress hierarchy={classLevelHierarchy} />
				<Reports.Totals prefix='Class' hierarchy={classLevelHierarchy} />
				<Reports.GradeScale prefix='Class' hierarchy={classLevelHierarchy} />
				<Reports.ItemAnalysis hierarchy={teacherLevelHierarchy} />
				<Reports.PieCharts hierarchy={teacherLevelHierarchy} />
				<Reports.RubricResults hierarchy={classLevelHierarchy} />
				<Reports.UntestedStudents hierarchy={teacherLevelHierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
