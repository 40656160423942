import {RowDef} from '../../types';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {ReportDialog, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {TabID} from './types';
import {useState} from 'react';
import {Teacher} from './tabs-content/teacher';
import {Specialists} from './tabs-content/specialists';

type Props = {
	school: RowDef;
	onClose: VoidFunction;
	baseHierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function SchoolReportsDialog({onClose, school, baseHierarchy, subject}: Props) {
	const [activeTab, setActiveTab] = useState(TabID.Teacher);

	return (
		<ReportDialog onClose={onClose} header={school.name} subject={subject}>
			{school.specialists ? (
				<ReportDialog.Tabs.Root<TabID> activeTab={activeTab} setActiveTab={setActiveTab}>
					<ReportDialog.Tabs.Title size='small' color='highContrast'>
						Select a group for which you would like to run the report
					</ReportDialog.Tabs.Title>
					<ReportDialog.Tabs.Bar />

					<ReportDialog.Tabs.Content tabID={TabID.Teacher} tabContent='Teachers'>
						<Teacher baseHierarchy={baseHierarchy} />
					</ReportDialog.Tabs.Content>

					<ReportDialog.Tabs.Content tabID={TabID.Specialists} tabContent='Specialists'>
						<Specialists baseHierarchy={baseHierarchy} />
					</ReportDialog.Tabs.Content>
				</ReportDialog.Tabs.Root>
			) : (
				<Teacher baseHierarchy={baseHierarchy} />
			)}
		</ReportDialog>
	);
}
