import {ColDef} from 'ag-grid-community';
import {useMemo} from 'react';
import {RowDef} from './types';
import {CellType, NumberFilter, numberFilterParams} from '@esgi/ui/ag-grid';

export function useColumnDefinitions(rows: RowDef[]) {
	return useMemo<ColDef<RowDef>[]>(
		() => [
			{
				field: 'firstName',
				headerName: 'First Name',
				lockVisible: true,
				headerComponentParams: {
					description: 'Displays the first name of a School Specialist (Required)',
				},
				sortable: true,
				type: CellType.Primary,
			},
			{
				field: 'lastName',
				headerName: 'Last Name',
				lockVisible: true,
				headerComponentParams: {
					description: 'Displays the last name of a School Specialist (Required)',
				},
				sortable: true,
				type: CellType.Primary,
				sort: 'asc',
			},
			{
				field: 'userName',
				headerName: 'User Name',
				filter: false,
				headerComponentParams: {
					description: 'Displays the user name of a specialist',
				},
				sortable: true,
				sort: 'asc',
			},
			{
				field: 'email',
				headerName: 'Email',
				filter: false,
				headerComponentParams: {
					description: 'Displays the email of a specialist',
				},
				sortable: true,
				sort: 'asc',
			},
			{
				field: 'specialistGroups',
				headerName: 'Specialist Groups',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows, 'specialistGroups'),
				headerComponentParams: {
					description: 'Displays the number of Specialist Groups associated with a School Specialist',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
			{
				field: 'students',
				headerName: 'Students',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows, 'students'),
				headerComponentParams: {
					description: 'Displays the number of students associated with a School Specialist',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
		],
		[rows],
	);
}
