import {isAsyncSucceed} from '@esgi/ui';
import {RowDef} from '../../types';
import {useEffect, useMemo} from 'react';
import {entityName} from '../constants';
import {Content} from './components/content';
import {BasePageProps} from '../types';
import {Folder, FolderManagement, useDataPageState, Page} from '@esgi/main/features/admins/data';

type Props = BasePageProps<{
	activeFolder: Folder;
}>;

export function Folder({
	allTableRows,
	isDataLoaded,
	columnDefs,
	activeFolder,
	userCanAddAndDeleteTeachers,
	onOpenAddDrawer,
}: Props) {
	const [folderData, fetchFolderByID] = FolderManagement.useFetchFolderByID({type: activeFolder.type});
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	const isDataFullLoaded = isDataLoaded && isAsyncSucceed(folderData);

	useEffect(() => {
		fetchFolderByID(activeFolder.id);
	}, [activeFolder]);

	const filteredData = useMemo<RowDef[]>(() => {
		if (isDataFullLoaded) {
			const ids = folderData.data.ids;

			return allTableRows.filter(({id}) => ids.includes(id));
		}

		return [];
	}, [allTableRows, folderData, isDataFullLoaded]);

	return (
		<Page.Root
			columnDefs={columnDefs}
			entityName={entityName}
			tableRows={filteredData}
			isDataLoaded={isDataFullLoaded && isInitialStateReady}
			initialState={initialState}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<Content
				activeFolder={activeFolder}
				userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
				onOpenAddDrawer={onOpenAddDrawer}
				allFolderRows={filteredData}
			/>
		</Page.Root>
	);
}
