import {Drawer, getFullName} from '@esgi/main/kits/common';
import {Tabs} from '../../../components/tabs';
import {SpecialistTabID} from '../../types';
import {specialistGroupTabItems} from '../../constants';
import {GroupNameInput} from '../../../components/group-name-input';
import {Input} from '@esgi/ui/controls';
import {noop} from 'underscore';
import {School, User} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {GroupNameControl} from '../../../components/group-name-input/types';

type Props = {
	specialistTabID: SpecialistTabID;
	groupNameControl: GroupNameControl;
	currentSpecialist: User;
	schools: School[];
};

export function GroupInformationContent({groupNameControl, specialistTabID, currentSpecialist, schools}: Props) {
	const {specialistFullName, schoolName} = useMemo(() => {
		const currentSchool = schools.find(({id}) => id === currentSpecialist.schoolID);

		const schoolName = currentSchool?.name ?? '-';
		const specialistFullName = getFullName({
			firstName: currentSpecialist.firstName,
			lastName: currentSpecialist.lastName,
		});

		return {
			schoolName,
			specialistFullName,
		};
	}, [currentSpecialist, schools]);

	return (
		<>
			<Drawer.ContentBlock title='Specialist' withDivider>
				<Tabs<SpecialistTabID>
					activeTabID={specialistTabID}
					setActiveTabID={noop}
					items={specialistGroupTabItems}
					disabled
					dataCy='specialist-type-toggle'
				/>

				{specialistTabID === SpecialistTabID.District && (
					<Input placeholder='District Specialist' value={specialistFullName} disabled dataCy='district-specialist-input' />
				)}

				{specialistTabID === SpecialistTabID.School && (
					<>
						<Input placeholder='School' value={schoolName} disabled dataCy='school-input' />
						<Input placeholder='School Specialist' value={specialistFullName} disabled dataCy='school-specialist-input' />
					</>
				)}
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Specialist Group Information' withDivider>
				<GroupNameInput groupNameControl={groupNameControl} />
			</Drawer.ContentBlock>
		</>
	);
}
