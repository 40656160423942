import {Folder, FolderType, ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {additionalFolderData} from './constants';
import {NoStudentsAlert} from '../no-students-alert';
import {FolderReportModel} from './types';

type Props = {
	onClose: VoidFunction;
	subject: ReportsSubjectInfo;
	studentsCountInFolder: number;
	folderType: FolderType;
	folderName: string;
	rowsCountInFolder: number;
	reportFilteredSubjects: ReportsSubjectInfo[];
	folderID: Folder['id'];
	reports: FolderReportModel[];
};

export function FolderReports({
	onClose,
	studentsCountInFolder,
	folderType,
	subject,
	folderName,
	rowsCountInFolder,
	reportFilteredSubjects,
	folderID,
	reports,
}: Props) {
	const {hierarchyKey, title, noStudentsSinglePrefix, noStudentsMultiplePrefix} = additionalFolderData[folderType];

	if (!studentsCountInFolder) {
		return (
			<NoStudentsAlert
				onClose={onClose}
				prefix={rowsCountInFolder === 1 ? noStudentsSinglePrefix : noStudentsMultiplePrefix}
			/>
		);
	}

	return (
		<ReportDialog onClose={onClose} header={folderName} subject={subject} subjects={reportFilteredSubjects}>
			<ReportDialog.Section title={title}>
				{reports.map(({type, name, description}, index) => {
					const ReportComponent = Reports[type];

					return (
						<ReportComponent
							hierarchy={{
								[hierarchyKey]: folderID,
							}}
							name={name}
							description={description}
							key={`${type}-${index}`}
						/>
					);
				})}
			</ReportDialog.Section>
		</ReportDialog>
	);
}
