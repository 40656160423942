import {useMemo} from 'react';
import {ColDef} from 'ag-grid-community';

import {CellType, EntitiesFilter, entitiesFilterParams, NumberFilter, numberFilterParams} from '@esgi/ui/ag-grid';

import {RowDef} from './types';
import {User, Class} from '@esgi/main/libs/school-admin-store';

export function useColumnDefinitions(rows: RowDef[], teachers: User[], classes: Class[]) {
	return useMemo<ColDef<RowDef>[]>(
		() => [
			{
				field: 'name',
				headerName: 'Group',
				lockVisible: true,
				headerComponentParams: {
					description: 'Displays a Group name (Required)',
				},
				filter: false,
				sortable: true,
				type: CellType.Primary,
				sort: 'asc',
			},
			{
				field: 'teacher',
				headerName: 'Teacher',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(teachers),
				headerComponentParams: {
					description: 'Displays the name of a teacher',
				},
				sortable: true,
			},
			{
				field: 'class',
				headerName: 'Class',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(classes),
				headerComponentParams: {
					description: 'Displays the name of a class to which a group belongs',
				},
				sortable: true,
			},
			{
				field: 'students',
				headerName: 'Students',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows, 'students'),
				headerComponentParams: {
					description: 'Displays the number of students associated with a group',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
		],
		[rows, teachers, classes],
	);
}
