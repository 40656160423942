import {Dispatch, useState} from 'react';
import {SingleSelectedExpandablePanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {ContentWrapper} from './components/content-wrapper';
import {Page} from '@esgi/main/features/admins/data';

type Props = {
	onEditClicked: Dispatch<number>;
};

export function SingleItemExpandablePanel({onEditClicked}: Props) {
	const {api} = Page.useContext<RowDef>();

	const [isPanelExpanded, setIsPanelExpanded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<RowDef | null>(null);

	return (
		<SingleSelectedExpandablePanel.Root<RowDef>
			gridApi={api}
			onSingleSelectedItemChange={setSelectedRow}
			onPanelExpandableValueChange={setIsPanelExpanded}
		>
			{selectedRow && (
				<ContentWrapper selectedRow={selectedRow} isPanelExpanded={isPanelExpanded} onEditClicked={onEditClicked} />
			)}
		</SingleSelectedExpandablePanel.Root>
	);
}
