import {useMemo} from 'react';
import {useFirstStoreSubject} from './hooks';
import {ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {isNull} from 'underscore';
import {SchoolReportsDialog} from './school-reports-dialog';
import {useStudents} from '@esgi/main/libs/school-admin-store';

export function SchoolReportsButton() {
	const [{data: students, loaded: isStudentLoaded}] = useStudents();

	const [firstStoreSubject, isFirstStoreSubjectLoaded] = useFirstStoreSubject();

	const reportSubject = useMemo<ReportsSubjectInfo | null>(() => {
		if (isNull(firstStoreSubject)) {
			return null;
		}

		return {
			id: firstStoreSubject.id,
			level: firstStoreSubject.level,
			type: firstStoreSubject.subjectType,
			name: firstStoreSubject.name,
		};
	}, [firstStoreSubject]);

	return (
		<RunReportButton
			skeleton={!isFirstStoreSubjectLoaded || !isStudentLoaded}
			subject={reportSubject}
			disabledMessage={
				students.length ? null : 'No students have been added to your class. Please add some before running the report.'
			}
			title='School Reports'
		>
			{({onReportsClose, subject}) => <SchoolReportsDialog onClose={onReportsClose} subject={subject} />}
		</RunReportButton>
	);
}
