import {FolderAdd, FolderRemove, PieChartIcon, Teacher} from '@esgi/ui/icons';
import {ActionPanel, NegativeButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button} from '@esgi/ui';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {getFullName} from '@esgi/main/kits/common';
import {ReportsFeature} from '../../../../../../components/reports';
import {ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	isPanelExpanded: boolean;
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	hierarchy: Hierarchy;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	onRemoveFromFolderClick,
	hierarchy,
	reportSubject,
	skeleton,
}: Props) {
	return (
		<SingleSelectedExpandablePanel.QuickPanel>
			<SingleSelectedExpandablePanel.QuickPanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<Teacher />
						<Text size='large' color='highContrast'>
							{getFullName({
								firstName: selectedRow.firstName,
								lastName: selectedRow.lastName,
							})}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<Button.Icon withBackgroundHover onClick={onFolderAddClick}>
							<FolderAdd />
						</Button.Icon>

						<NegativeButton onClick={onRemoveFromFolderClick}>
							<FolderRemove />
						</NegativeButton>

						<RunReportButton
							disabled={!selectedRow.students}
							disabledMessage={
								selectedRow.students
									? null
									: 'No students have been added to your class. Please add some before running the report.'
							}
							skeleton={skeleton}
							subject={reportSubject}
						>
							{({onReportsClose, subject}) => (
								<ReportsFeature
									onClose={onReportsClose}
									specialist={selectedRow}
									hierarchy={hierarchy}
									subject={subject}
								/>
							)}
						</RunReportButton>
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</SingleSelectedExpandablePanel.QuickPanel.Header>

			{!isPanelExpanded && (
				<AdditionalHeaderContent>
					<SingleSelectedExpandablePanel.OpenPanelButton
						Icon={PieChartIcon}
						transcript='View Tests Results'
						warningDescription='No students have been added to your specialist. Please add some before running the report.'
						canExpandPanel={!!selectedRow.students}
					/>
				</AdditionalHeaderContent>
			)}
		</SingleSelectedExpandablePanel.QuickPanel>
	);
}
