import {ComponentPropsWithoutRef, Dispatch, ForwardedRef, forwardRef, useEffect, useMemo, useState} from 'react';
import {GridApi, ColDef, ColGroupDef, GridState} from 'ag-grid-community';
import {PageContentContext, PageContentContextValue} from '../../context';
import {isNull} from 'underscore';
import {Content} from './index.styled';
import {TableCustomizationDrawer} from '@esgi/ui/ag-grid';

type Props<T extends Record<string, unknown>> = Omit<ComponentPropsWithoutRef<typeof Content>, 'skeleton'> & {
	columnDefs: (ColDef<T> | ColGroupDef<T>)[];
	tableRows: T[];
	entityName: string;
	isDataLoaded: boolean;
	onApplyTableCustomization?: Dispatch<{name: string; isEnabled: boolean}[]>;
	onGridReady?: Dispatch<GridApi<T>>;
	initialState?: GridState | null;
};

function RootImpl<T extends Record<string, unknown>>(
	{
		dataCy = 'data-page-content-root',
		css = {},
		children,
		tableRows,
		columnDefs,
		isDataLoaded,
		entityName,
		onGridReady,
		initialState,
		onApplyTableCustomization,
		...props
	}: Props<T>,
	forwardedRef: ForwardedRef<HTMLDivElement>,
) {
	const [api, setApi] = useState<GridApi<T> | null>(null);

	useEffect(() => {
		if (api) {
			onGridReady?.(api);

			if (initialState) {
				const {columnVisibility} = initialState;
				if (columnVisibility?.hiddenColIds) {
					const columnState = api.getColumnState();
					const updatedColumnState = columnState.map((col) => ({
						...col,
						hide: columnVisibility.hiddenColIds.includes(col.colId),
					}));
					api.applyColumnState({state: updatedColumnState});
				}
			}
		}
	}, [api, initialState, onGridReady]);

	const [selectedItems, setSelectedItems] = useState<T[]>([]);

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const onSelectHandler = () => setSelectedItems(api.getSelectedRows());

		api.addEventListener('selectionChanged', onSelectHandler);

		return () => {
			api.removeEventListener('selectionChanged', onSelectHandler);
		};
	}, [api]);

	const context = useMemo<PageContentContextValue<T>>(
		() => ({
			api,
			setApi,
			selectedItems,
			setSelectedItems,
			entityName,
			columnDefs,
			isDataLoaded,
			tableRows,
			initialState: initialState ?? null,
		}),
		[api, entityName, initialState, isDataLoaded, columnDefs, selectedItems, tableRows],
	);

	return (
		<PageContentContext.Provider value={context}>
			<Content dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</Content>

			{!isNull(api) && (
				<TableCustomizationDrawer
					tableName={entityName}
					api={api}
					onApplyTableCustomization={onApplyTableCustomization}
				/>
			)}
		</PageContentContext.Provider>
	);
}

export const Root = forwardRef(RootImpl) as <T extends Record<string, unknown>>(
	props: Props<T> & {ref?: ForwardedRef<HTMLDivElement>},
) => ReturnType<typeof RootImpl>;
