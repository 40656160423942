import {UserInfo, UserType} from '@esgi/core/authentication';
import {Hierarchy, HierarchyInstance, HierarchySnapshot} from '../types';
import {getHierarchyMode} from './get-hierarchy-mode';
import {StudentSort} from '@esgi/core/enums';
import {isNull} from 'underscore';

type Parameters = {
	currentUser: UserInfo | null;
	hierarchy: Hierarchy;
};

export function buildHierarchyInstance({currentUser, hierarchy}: Parameters): HierarchyInstance {
	const districtID = currentUser?.districtID ?? 0;

	const student = hierarchy.student ?? null;
	const studentID = student?.id ?? 0;
	const studentSort = currentUser?.studentSort ?? StudentSort.FirstName;

	const teacher = hierarchy.teacher ?? null;
	const teacherClass = hierarchy.teacherClass ?? null;
	const teacherGroup = hierarchy.teacherGroup ?? null;
	const teacherID = teacher?.id ?? teacherClass?.teacherID ?? teacherGroup?.id ?? 0;
	const teacherClassID = teacherClass?.id ?? teacherGroup?.classID ?? 0;
	const teacherGroupID = teacherGroup?.id ?? 0;

	const schoolsFolderID = hierarchy.schoolsFolderID ?? 0;
	const teachersFolderID = hierarchy.teachersFolderID ?? 0;

	const specialistGroup = hierarchy.specialistGroup ?? null;
	const specialistsID = hierarchy.specialistsID ?? 0;
	const specialistType = hierarchy.specialistType ?? 0;
	const specialistsFolderID = hierarchy.specialistsFolderID ?? 0;

	const preAssessID = hierarchy.preAssessID ?? 0;
	const preAssessGroupID = hierarchy.preAssessGroupID ?? 0;

	const baseSchoolID = hierarchy.schoolID ?? teacher?.schoolID ?? student?.schoolID ?? null;
	const schoolID = currentUser?.userType === UserType.C ? baseSchoolID ?? currentUser.schoolID : baseSchoolID ?? 0;

	const hierarchyMode = hierarchy.mode ?? getHierarchyMode(hierarchy);

	const snapshot: HierarchySnapshot = {
		classic: {
			studentID,
			groupID: teacherGroupID,
			classID: teacherClassID,
			teacherID,
			schoolID,
			schoolsGroupID: schoolsFolderID,
			teachersGroupID: teachersFolderID,
			districtID,
			selected: {
				level: 0,
				levelID: 0,
			},
		},
		specialist: {
			studentID,
			filter: {
				schoolID,
				teacherID,
			},
			type: specialistType,
			groupID: specialistGroup?.id ?? 0,
			userID: specialistsID,
			districtID,
			groupOfSpecialistsID: specialistsFolderID,
			selected: {
				level: 0,
				levelID: 0,
			},
		},
		preAssess: {
			studentID,
			groupID: preAssessGroupID,
			userID: preAssessID,
			districtID,
			selected: {
				level: 0,
				levelID: 0,
			},
		},
		districtID,
		mode: hierarchyMode,
		studentSort,
	};

	const out = HierarchyInstance.build(hierarchyMode, snapshot.classic, snapshot.specialist, snapshot.preAssess);

	out.studentSort = currentUser?.studentSort ?? StudentSort.FirstName;
	out.snapshot.studentSort = out.studentSort;

	if (!isNull(teacher)) {
		out.teachers.push({
			trackID: 0,
			title: '',
			groups: [],
			firstName: teacher.firstName,
			lastName: teacher.lastName,
			userID: teacher.id,
			schoolID: teacher.schoolID,
		});
	}

	if (!isNull(teacherClass)) {
		out.classes.push({
			classID: teacherClass.id,
			name: teacherClass.name,
			userID: teacherClass.teacherID,
		});
	}

	if (!isNull(teacherGroup)) {
		out.groups.push({
			groupID: teacherGroup.id,
			name: teacherGroup.name,
			classID: teacherGroup.classID,
			orderNum: 0,
		});
	}

	if (!isNull(specialistGroup)) {
		out.specialistGroups.push({
			groupID: specialistGroup.id,
			name: specialistGroup.name,
			type: specialistGroup.type,
			userID: specialistGroup.specialistID,
		});
	}

	if (!isNull(student)) {
		out.students.push({
			studentID: student.id,
			classes: [...student.classesIDs],
			districtID,
			firstName: student.firstName,
			lastName: student.lastName,
			gradeLevelID: student.gradeLevelID,
			groups: [...student.groupsIDs],
			schoolID: student.schoolID,
			specialistGroups: [...student.specialistGroupsIDs],
			teacherID: student.teacherID,
		});
	}

	return out;
}
