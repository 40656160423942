import {Dispatch, useMemo} from 'react';
import {RowDef} from '../../../../../types';
import {QuickPanel} from './quick-panel';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {FullActionPanel} from '../../../../../components/full-action-panel';
import {isNull} from 'underscore';
import {useHierarchy} from '../hooks/use-hierarchy';
import {useSubjects} from '../hooks/use-subjects';
import {ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {isUndefined} from '@esgi/ui';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	onEditClicked: Dispatch<number>;
};

export function ContentWrapper({isPanelExpanded, onEditClicked, selectedRow}: Props) {
	const hierarchy = useHierarchy({selectedRow});

	const {districtSubjects, schoolSubjects, teacherSubjects, isSubjectsLoaded} = useSubjects({
		teacherID: !isNull(selectedRow.teacher) ? selectedRow.teacher.id : null,
		schoolID: selectedRow.school?.id ?? null,
		availableGradeLevel: selectedRow.gradeLevelID,
	});

	const reportSubject = useMemo<ReportsSubjectInfo | null>(() => {
		const firstSubject = districtSubjects[0] ?? schoolSubjects[0] ?? teacherSubjects[0];

		if (isUndefined(firstSubject)) {
			return null;
		}

		return {
			id: firstSubject.id,
			level: firstSubject.level,
			type: firstSubject.type,
			name: firstSubject.name,
		};
	}, [districtSubjects, schoolSubjects, teacherSubjects]);

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onEditClicked={onEditClicked}
				hierarchy={hierarchy}
				reportSubject={reportSubject}
				skeleton={!isSubjectsLoaded}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<FullActionPanel
					student={{
						id: selectedRow.id,
						firstName: selectedRow.firstName,
						lastName: selectedRow.lastName,
						classIDs: selectedRow.class.map(({id}) => id),
						groupsIDs: selectedRow.group.map(({id}) => id),
						avatarUrl: null,
					}}
					isSubjectsLoaded={isSubjectsLoaded}
					districtSubjects={districtSubjects}
					schoolSubjects={schoolSubjects}
					teacherSubjects={teacherSubjects}
					hierarchy={hierarchy}
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
