import {RowDef} from '../../types';
import {ColDef} from 'ag-grid-community';
import {Content} from './components/content';
import {entityName} from '../constants';
import {useDataPageState, Page} from '@esgi/main/features/admins/data';

type Props = {
	allTableRows: RowDef[];
	isDataLoaded: boolean;
	columnDefs: ColDef<RowDef>[];
};

export function Base({allTableRows, isDataLoaded, columnDefs}: Props) {
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	return (
		<Page.Root
			columnDefs={columnDefs}
			entityName={entityName}
			tableRows={allTableRows}
			isDataLoaded={isDataLoaded && isInitialStateReady}
			initialState={initialState}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<Content />
		</Page.Root>
	);
}
