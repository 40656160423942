import {Select as SelectBase} from '@esgi/ui/controls';
import {Item} from '../../types';
import {Text} from '@esgi/ui/typography';
import {Dispatch} from 'react';
import {StyledSelectField} from './index.styled';
import {Skeletonable} from '@esgi/ui';

type Props<T extends string> = Skeletonable & {
	placeholder: string;
	items: Item<T>[];
	activeValue?: T | undefined;
	setActiveValue: Dispatch<T>;
	dataCy?: string;
};

export function Select<T extends string>({items, placeholder, activeValue, setActiveValue, skeleton, dataCy}: Props<T>) {
	return (
		<SelectBase.Root value={activeValue} onValueChange={setActiveValue} dataCy={dataCy}>
			<StyledSelectField placeholder={placeholder} skeleton={skeleton} />
			<SelectBase.Content>
				{items.map(({value, label}) => (
					<SelectBase.Option key={value} value={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</SelectBase.Option>
				))}
			</SelectBase.Content>
		</SelectBase.Root>
	);
}
