import {RowDef} from '../../../../../types';
import {QuickPanel} from './quick-panel';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {TestResults} from '@esgi/main/kits/admin';
import {useSelectedSpecialistData} from '../../../../use-selected-specialist-data';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
};

export function ContentWrapper({isPanelExpanded, onFolderAddClick, selectedRow}: Props) {
	const {activitiesHierarchy, hierarchy, skeleton, students, subjects, reportSubject} = useSelectedSpecialistData({
		selectedRow,
		activeFolder: null,
	});

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				activitiesHierarchy={activitiesHierarchy}
				reportSubject={reportSubject}
				reportsHierarchy={hierarchy}
				skeleton={skeleton}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={skeleton}
					allSubjects={subjects}
					students={students}
					hierarchy={hierarchy}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
