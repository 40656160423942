import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';

export const ParentLettersContainer = styled(Box, {
	width: '100%',
});

export const ParentLetter = styled(Box, {
	padding: '0 30px 30px 30px',
	borderBottom: '3px dashed $neutral92',
});

export const Letter = styled(Box, {
	marginTop: '10px',
	textAlign: 'left',
});

export const AlertBody = styled(GridBox, {
	width: '100%',
	maxHeight: '500px',
	paddingTop: 0,
});

export const GradeScalesBox = styled(FlexBox, {
	flexWrap: 'wrap',
});

export const GradeScaleLegend = styled(FlexBox, {
	margin: '0 20px 5px 0',
});

export const GradeColor = styled(FlexBox, {
	width: '30px',
	height: '22px',
	border: '1px solid $neutral92',
	alignItems: 'center',
	justifyContent: 'center',
});

export const GradeDescription = styled(Box, {
	marginLeft: '10px',
	color: '$neutral48',
});

