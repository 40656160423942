import {useEffect, useMemo} from 'react';
import {FiltersData, RowDef} from './types';
import {isEmpty, unique} from 'underscore';
import {
	useClasses,
	useGroups,
	User,
	useSchoolSpecialists,
	useSpecialistGroups,
	useStudents,
	useTeachers,
} from '@esgi/main/libs/school-admin-store';
import {V2SchoolAdminsUserAccountController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';

type Out = FiltersData & {
	ready: boolean;
	rows: RowDef[];
	schoolName: string;
};

export function useData(): Out {
	const [{data: allTeachers, loaded: allTeachersLoaded}] = useTeachers();
	const [{data: allClasses, loaded: allClassesLoaded}] = useClasses();
	const [{data: allGroups, loaded: allGroupsLoaded}] = useGroups();
	const [{data: schoolSpecialists, loaded: schoolSpecialistsLoaded}] = useSchoolSpecialists();
	const [{data: allSpecialistGroups, loaded: allSpecialistGroupsLoaded}] = useSpecialistGroups();
	const [{data: students, loaded: studentsLoaded}] = useStudents();

	const userAccountController = useDisposable(V2SchoolAdminsUserAccountController);

	const [userControllerData, initUserControllerData] = useCancelableRequest(userAccountController.init);

	useEffect(() => {
		initUserControllerData();
	}, []);

	return useMemo<Out>(() => {
		const isDataLoaded =
			allTeachersLoaded &&
			allClassesLoaded &&
			allGroupsLoaded &&
			schoolSpecialistsLoaded &&
			allSpecialistGroupsLoaded &&
			studentsLoaded &&
			isAsyncSucceed(userControllerData);

		if (!isDataLoaded) {
			return {
				ready: false,
				rows: [],
				schoolName: '',
				availableClasses: [],
				availableGroups: [],
				availableTeachers: [],
				availableSpecialistGroups: [],
				availableSpecialists: [],
			};
		}

		const rows = students.filter((student) => !!student.teacherID || !isEmpty(student.specialistGroupsIDs)).map<RowDef>((student) => {
			const teacher = allTeachers.find((teacher) => teacher.id === student.teacherID);
			const classes = allClasses.filter((c) => student.classesIDs.includes(c.id));
			const groups = allGroups.filter((group) => student.groupsIDs.includes(group.id));

			const specialists = schoolSpecialists.filter((specialist) => student.specialistsIDs.includes(specialist.id));
			const specialistGroups = allSpecialistGroups.filter((group) => student.specialistGroupsIDs.includes(group.id));

			return {
				id: student.id,
				firstName: student.firstName,
				lastName: student.lastName,
				teacher: teacher ?? null,
				class: classes,
				group: groups,
				specialist: specialists,
				specialistGroups: specialistGroups,
				gradeLevelID: student.gradeLevelID,
			};
		});

		return {
			ready: true,
			rows,
			schoolName: userControllerData.data.user.schoolName,
			availableTeachers: unique(rows.map((r) => r.teacher)).filter((item): item is User => !!item),
			availableClasses: unique(rows.map((r) => r.class).flat()),
			availableGroups: unique(rows.map((r) => r.group).flat()),
			availableSpecialists: unique(rows.map((r) => r.specialist).flat()),
			availableSpecialistGroups: unique(rows.map((r) => r.specialistGroups).flat()),
		};
	}, [
		allClasses,
		allClassesLoaded,
		allGroups,
		allGroupsLoaded,
		allSpecialistGroups,
		allSpecialistGroupsLoaded,
		allTeachers,
		allTeachersLoaded,
		schoolSpecialists,
		schoolSpecialistsLoaded,
		students,
		studentsLoaded,
		userControllerData,
	]);
}
