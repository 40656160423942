import {Page, RunReportButton} from '@esgi/main/features/admins/data';
import {ActionPanel, DeleteButton} from '../../../../_kit';
import {RowDef} from '../../../types';
import {Text} from '@esgi/ui/typography';

export function BottomPanel() {
	const {api, entityName} = Page.useContext<RowDef>();

	return (
		<ActionPanel.Root api={api} showOnMinSelectedItems={2}>
			<ActionPanel.Header>
				<ActionPanel.ContentBox>
					<ActionPanel.CloseButton />

					<ActionPanel.MainBox>
						<ActionPanel.CounterBox />
						<Text size='xSmall' font='mono' color='primary'>
							{entityName} Selected
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<ActionPanel.ShowOnlySelectedCheckbox />
						<ActionPanel.ActionButtonDivider />

						<DeleteButton disabled tooltipMessage='Select only one item for deletion.' whenShowTooltip='disabled' />

						<RunReportButton disabled />
					</ActionPanel.ActionButtonsBox>
				</ActionPanel.ContentBox>
			</ActionPanel.Header>
		</ActionPanel.Root>
	);
}
