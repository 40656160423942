import {
	CollapsiblePanel,
	Folder,
	FolderType,
	RunReportButton,
	ReportsSubjectInfo,
} from '@esgi/main/features/admins/data';
import {Folder as FolderIcon} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {CollapsiblePanelHeader, TitleBox} from './index.styled';
import {FolderReportModel} from './types';
import {additionalFolderData} from './constants';
import {useMemo} from 'react';
import {useFilteredStoreSubjects} from '../hooks';
import {isNull} from 'underscore';
import {FolderReports} from './folder-reports';

type Props = {
	folderID: Folder['id'];
	folderName: string;
	folderType: FolderType;
	isShowPanel: boolean;
	reports: FolderReportModel[];
	studentsCountInFolder: number;
	rowsCountInFolder: number;
};

export function FolderNoSelectedItemsPanel({
	folderID,
	folderName,
	isShowPanel,
	folderType,
	reports,
	studentsCountInFolder,
	rowsCountInFolder,
}: Props) {
	const [filteredSubjects, firstStoreSubject, isFirstStoreSubjectLoaded] = useFilteredStoreSubjects();

	const reportSubject = useMemo<ReportsSubjectInfo | null>(() => {
		if (isNull(firstStoreSubject)) {
			return null;
		}

		return {
			id: firstStoreSubject.id,
			level: firstStoreSubject.level,
			type: firstStoreSubject.subjectType,
			name: firstStoreSubject.name,
		};
	}, [firstStoreSubject]);

	const reportFilteredSubjects = useMemo<ReportsSubjectInfo[]>(() => {
		return filteredSubjects.map((s) => ({
			id: s.id,
			level: s.level,
			type: s.subjectType,
			name: s.name,
		}));
	}, [filteredSubjects]);

	const managerRef = CollapsiblePanel.useRef();

	if (!isShowPanel) {
		return null;
	}

	const {title} = additionalFolderData[folderType];

	return (
		<CollapsiblePanel.Root managerRef={managerRef}>
			<CollapsiblePanel.QuickPanel>
				<CollapsiblePanelHeader>
					<TitleBox>
						<FolderIcon />
						<Text size='large' color='highContrast'>
							{folderName}
						</Text>
					</TitleBox>
					<RunReportButton title={title} subject={reportSubject} skeleton={!isFirstStoreSubjectLoaded}>
						{({onReportsClose, subject}) => (
							<FolderReports
								onClose={onReportsClose}
								folderName={folderName}
								subject={subject}
								reportFilteredSubjects={reportFilteredSubjects}
								folderID={folderID}
								folderType={folderType}
								reports={reports}
								rowsCountInFolder={rowsCountInFolder}
								studentsCountInFolder={studentsCountInFolder}
							/>
						)}
					</RunReportButton>
				</CollapsiblePanelHeader>
			</CollapsiblePanel.QuickPanel>
		</CollapsiblePanel.Root>
	);
}
