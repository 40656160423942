import {isUndefined} from '@esgi/ui';
import moment from 'moment';
import {dateFormat} from '../constants';
import {DatePickerData, PeriodType, ValidatorName} from '../types';
import {isNull} from 'underscore';
import {Observable, tap} from 'rxjs';
import {ValidatorArgs} from '@esgi/ui/form';

function isTwoDatesEqual({date1, date2}: {date1: Date | string | undefined; date2: Date | string | undefined}) {
	if (isUndefined(date1) || isUndefined(date2)) {
		return date1 === date2;
	}

	const date1Format = moment(date1).format(dateFormat);
	const date2Format = moment(date2).format(dateFormat);

	return moment(date1Format).diff(moment(date2Format), 'days') === 0;
}

export function isDateTouched(currentDate: DatePickerData, initialDate: DatePickerData | null) {
	return (
		!isTwoDatesEqual({date1: currentDate.dateRange?.dateFrom, date2: initialDate?.dateRange?.dateFrom}) ||
		!isTwoDatesEqual({date1: currentDate.dateRange?.dateTo, date2: initialDate?.dateRange?.dateTo}) ||
		currentDate.trackDatesID !== initialDate?.trackDatesID ||
		currentDate.datePeriod !== initialDate?.datePeriod
	);
}

export function isDateValid(value: DatePickerData) {
	const isDateInvalid =
		value.datePeriod === PeriodType.None ||
		(value.datePeriod === PeriodType.Manual && isNull(value.dateRange)) ||
		(value.datePeriod === PeriodType.PreviousSelectedMarkingPeriod && value.trackDatesID === 0);

	return !isDateInvalid;
}

export function formValueValidation() {
	return function (validationChain: Observable<ValidatorArgs<DatePickerData>>) {
		return validationChain.pipe(
			tap((validatorArgs) => {
				const value = validatorArgs.field.currentValue;

				if (!isDateValid(value)) {
					validatorArgs.errors.push(ValidatorName.DateInvalid);
				}
			}),
		);
	};
}
