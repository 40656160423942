export enum ReportType {
	Grade = 1,
	Growth,
	TotalStudents,
	StudentsProgress,
	StudentsDetail,
	Total,
	ItemAnalysis,
	PieCharts,
	TeacherActivity,
	ParentLetter,
	RubricResults,
	UntestedStudents,
	PreAssessActivity,
	SpecialistActivity,
	Bingo,
	WritingPractice,
	FlashCards,
}

export type ReportsSubjectInfo = {
	id: number;
	level: string;
	type: string;
	name: string;
};
