import {FolderAdd, FolderRemove, Teacher} from '@esgi/ui/icons';
import {ActionPanel, NegativeButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button} from '@esgi/ui';
import {Folder, ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {getFullName} from '@esgi/main/kits/common';
import {DeleteTeacherButtonSingleSelect} from '../../../../../../components/delete-teacher-button-single-select';
import {GridApi} from 'ag-grid-community';
import {ReportsFeature} from '../reports';
import {useCallback} from 'react';
import {useDataPageContext} from '../../../../../../../layout/context';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {OnTeacherRemoved} from '@esgi/main/features/district-admin/common';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	activeFolder: Folder;
	onDeselectFolder: VoidFunction;
	userCanAddAndDeleteTeachers: boolean;
	isPanelExpanded: boolean;
	gridApi: GridApi<Readonly<RowDef>> | null;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	teacherLevelHierarchy: Hierarchy;
};

export function QuickPanel({
	activeFolder,
	onDeselectFolder,
	onFolderAddClick,
	onRemoveFromFolderClick,
	selectedRow,
	userCanAddAndDeleteTeachers,
	isPanelExpanded,
	gridApi,
	reportSubject,
	skeleton,
	teacherLevelHierarchy,
}: Props) {
	const {onDecreaseFolderCount} = useDataPageContext();

	const onTeacherRemoved = useCallback<OnTeacherRemoved>(
		({removedFoldersIDs}) => {
			if (removedFoldersIDs.includes(activeFolder.id)) {
				onDeselectFolder();
				return;
			}

			onDecreaseFolderCount(activeFolder.id);
			gridApi?.deselectAll();
		},
		[activeFolder, gridApi, onDecreaseFolderCount, onDeselectFolder],
	);

	return (
		<SingleSelectedExpandablePanel.QuickPanel>
			<SingleSelectedExpandablePanel.QuickPanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<Teacher />

						<Text size='large' color='highContrast'>
							{getFullName({
								firstName: selectedRow.firstName,
								lastName: selectedRow.lastName,
							})}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<DeleteTeacherButtonSingleSelect
							onRemoved={onTeacherRemoved}
							selectedRow={selectedRow}
							userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
						/>
						<ActionPanel.ActionButtonDivider />

						<Button.Icon withBackgroundHover onClick={onFolderAddClick}>
							<FolderAdd />
						</Button.Icon>

						<NegativeButton onClick={onRemoveFromFolderClick}>
							<FolderRemove />
						</NegativeButton>

						<RunReportButton subject={reportSubject} skeleton={skeleton}>
							{({onReportsClose, subject}) => (
								<ReportsFeature
									onClose={onReportsClose}
									teacher={selectedRow}
									subject={subject}
									teacherLevelHierarchy={teacherLevelHierarchy}
								/>
							)}
						</RunReportButton>
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</SingleSelectedExpandablePanel.QuickPanel.Header>

			{!isPanelExpanded && (
				<AdditionalHeaderContent>
					<OpenPanelButton canExpandPanel={!!selectedRow.students} />
				</AdditionalHeaderContent>
			)}
		</SingleSelectedExpandablePanel.QuickPanel>
	);
}
