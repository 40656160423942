import {BasePageProps} from '../types';
import {entityName} from '../constants';
import {Content} from './components/content';
import {useDataPageState, Page} from '@esgi/main/features/admins/data';

export function Base({allTableRows, isDataLoaded, columnDefs, onOpenAddDrawer}: BasePageProps) {
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	return (
		<Page.Root
			columnDefs={columnDefs}
			entityName={entityName}
			tableRows={allTableRows}
			isDataLoaded={isDataLoaded && isInitialStateReady}
			initialState={initialState}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<Content onOpenAddDrawer={onOpenAddDrawer} />
		</Page.Root>
	);
}
