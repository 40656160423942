import {useMemo, useState} from 'react';
import {usePreAssessSpecialists, useDistrictSpecialists, useSchoolSpecialists} from '@esgi/main/libs/admin-store';
import {ReportDialog, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {TabID} from './types';
import {Teacher} from './tabs-content/teacher';
import {PreAssess} from './tabs-content/pre-assess';
import {Specialists} from './tabs-content/specialists';

type Props = {
	onClose: VoidFunction;
	subject: ReportsSubjectInfo;
	reportFilteredSubjects?: ReportsSubjectInfo[];
};

export function DistrictReportsDialog({onClose, subject, reportFilteredSubjects}: Props) {
	const [activeTab, setActiveTab] = useState(TabID.Teacher);

	const [{data: preAssessSpecialists, loaded: isPreAssessSpecialistsLoaded}] = usePreAssessSpecialists();
	const [{data: districtSpecialists, loaded: isDistrictSpecialistsLoaded}] = useDistrictSpecialists();
	const [{data: schoolSpecialists, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();

	const {withPreAssessTab, withSpecialistsTab} = useMemo(() => {
		if (!isPreAssessSpecialistsLoaded || !isDistrictSpecialistsLoaded || !isSchoolSpecialistsLoaded) {
			return {
				withSpecialistsTab: true,
				withPreAssessTab: true,
			};
		}

		const withSpecialistsTab = isSchoolSpecialistsLoaded
			? Boolean(districtSpecialists.length || schoolSpecialists.length)
			: true;
		const withPreAssessTab = isPreAssessSpecialistsLoaded ? Boolean(preAssessSpecialists.length) : true;

		return {
			withPreAssessTab,
			withSpecialistsTab,
		};
	}, [
		districtSpecialists,
		isDistrictSpecialistsLoaded,
		isPreAssessSpecialistsLoaded,
		isSchoolSpecialistsLoaded,
		preAssessSpecialists,
		schoolSpecialists,
	]);

	return (
		<ReportDialog
			onClose={onClose}
			header='District'
			subject={subject}
			loading={!isPreAssessSpecialistsLoaded || !isDistrictSpecialistsLoaded || !isSchoolSpecialistsLoaded}
			subjects={reportFilteredSubjects}
		>
			{withSpecialistsTab || withPreAssessTab ? (
				<ReportDialog.Tabs.Root<TabID> activeTab={activeTab} setActiveTab={setActiveTab}>
					<ReportDialog.Tabs.Title size='small' color='highContrast'>
						Select a group for which you would like to run the report
					</ReportDialog.Tabs.Title>
					<ReportDialog.Tabs.Bar />

					<ReportDialog.Tabs.Content tabID={TabID.Teacher} tabContent='Teachers'>
						<Teacher />
					</ReportDialog.Tabs.Content>

					{withSpecialistsTab && (
						<ReportDialog.Tabs.Content tabID={TabID.Specialists} tabContent='Specialists'>
							<Specialists />
						</ReportDialog.Tabs.Content>
					)}

					{withPreAssessTab && (
						<ReportDialog.Tabs.Content tabID={TabID.PreAssess} tabContent='Pre-Assess'>
							<PreAssess />
						</ReportDialog.Tabs.Content>
					)}
				</ReportDialog.Tabs.Root>
			) : (
				<Teacher />
			)}
		</ReportDialog>
	);
}
