import {BaseService} from '@esgi/core/service';
import {SubjectBelongsTo} from '../types';
import {subjectsStore, SubjectLevel, SubjectTab, SubjectType} from '@esgi/main/libs/store';
import {BehaviorSubject} from 'rxjs';
import {EventBusManager} from '@esgillc/events';
import {SubjectCreatedEvent as OldSubjectCreatedEvent} from 'api/entities/events/subject';
import {V2PersonalSubjectsController, V2TeachersPagesHomeSectionsSubjectsController} from '@esgi/contracts/esgi';

export class DataSubjectsService extends BaseService {
	public isLoadedData$ = new BehaviorSubject(false);

	public schoolSubjects$ = new BehaviorSubject<SubjectTab[]>([]);
	public districtSubjects$ = new BehaviorSubject<SubjectTab[]>([]);
	public personalSubjects$ = new BehaviorSubject<SubjectTab[]>([]);

	private subjectStorage = subjectsStore();

	private readonly subjectsController = new V2TeachersPagesHomeSectionsSubjectsController();
	private readonly personalSubjectsController = new V2PersonalSubjectsController();

	private readonly eventBus = new EventBusManager();

	constructor() {
		super();

		this.eventBus.subscribe(OldSubjectCreatedEvent, () => this.subjectStorage.invalidateCache());

		this.subjectStorage.get().subscribe(({data: subjects, loaded}) => {
			if(loaded) {
				const schoolSubjects = subjects.filter((s) => s.level === SubjectLevel.School);
				const districtSubjects = subjects.filter((s) => s.level === SubjectLevel.District);
				const personalSubjects = [
					...subjects.filter((s) => s.type === SubjectType.Stock),
					...subjects.filter((s) => s.type === SubjectType.Personal),
				].filter((s) => s.level === SubjectLevel.Teacher);

				this.schoolSubjects$.next(schoolSubjects.filter(x => x.tests.length > 0));
				this.districtSubjects$.next(districtSubjects.filter(x => x.tests.length > 0));
				this.personalSubjects$.next(personalSubjects);

				if (!this.isLoadedData$.value) {
					this.isLoadedData$.next(true);
				}
			}
		});
	}

	public updatePersonalSubjectsOrder(personalSubjects: SubjectTab[]) {
		this.personalSubjects$.next(personalSubjects);

		const subjectsIDs = personalSubjects.map(({id}) => id);
		this.personalSubjectsController.reorder({subjectsIDs}).subscribe();
	}

	public getBelongToValue(subjectId: SubjectTab['id']): SubjectBelongsTo {
		const schoolSubjects = this.schoolSubjects$.value;
		const districtSubjects = this.districtSubjects$.value;
		const personalSubjects = this.personalSubjects$.value;

		const subjectsLists: {subjects: SubjectTab[]; belongsTo: SubjectBelongsTo}[] = [
			{
				subjects: schoolSubjects,
				belongsTo: 'school',
			},
			{
				subjects: districtSubjects,
				belongsTo: 'district',
			},
			{
				subjects: personalSubjects,
				belongsTo: 'personal',
			},
		];

		for (const {subjects, belongsTo} of subjectsLists) {
			if (subjects.find(({id}) => id === subjectId)) {
				return belongsTo;
			}
		}

		throw new Error('subjectId is not belong to any tab');
	}

	public override dispose() {
		this.subjectStorage.dispose();
		this.subjectsController.dispose();
		this.personalSubjectsController.dispose();
	}
}
