import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {
	specialistGroupsStore,
	Student,
	studentsStore,
	useSpecialistGroups,
	useStudents,
} from '@esgi/main/libs/admin-store';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {RemoveSpecialistGroupEvent} from '../../events';

type Out = [boolean, Dispatch<OnRemoveParameters>, boolean];

type OnRemoveParameters = {
	id: number;
	name: string;
	onRemoved: VoidFunction;
};

export function useRemoveSpecialistGroup(): Out {
	const [{loaded: isSpecialistsGroupsLoaded}] = useSpecialistGroups();
	const [{loaded: isStudentsLoaded}] = useStudents();

	const api = useEsgiApiGateway();

	const [response, request] = useCancelableRequest(api.v2.districtAdmins.modules.specialistGroups.remove);

	const [isRemoving, setIsRemoving] = useState(false);
	const [requestParameters, setRequestParameters] = useState<OnRemoveParameters | null>(null);

	useEffect(() => {
		if (isAsyncSucceed(response) && !isNull(requestParameters)) {
			specialistGroupsStore().removeById?.(requestParameters.id);

			studentsStore().update?.((item: Student): Student => {
				if (item.specialistGroupsIDs.includes(requestParameters.id)) {
					return {
						...item,
						specialistGroupsIDs: item.specialistGroupsIDs.filter((id) => id !== requestParameters.id),
					};
				}

				return item;
			});

			dispatchAppEvent(
				RemoveSpecialistGroupEvent,
				new RemoveSpecialistGroupEvent({
					id: requestParameters.id,
					name: requestParameters.name,
				}),
			);

			requestParameters.onRemoved();

			setRequestParameters(null);
			setIsRemoving(false);
		}
	}, [response, requestParameters]);

	const onRemove = useCallback(
		({id, name, onRemoved}: OnRemoveParameters) => {
			setIsRemoving(true);

			request({
				specialistGroupID: id,
			});

			setRequestParameters({
				id,
				name,
				onRemoved,
			});
		},
		[request],
	);

	const isDataLoaded = isStudentsLoaded && isSpecialistsGroupsLoaded;

	return [isDataLoaded, onRemove, isAsyncPending(response) || isRemoving];
}
