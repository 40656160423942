import * as React from 'react';
import type {SVGProps} from 'react';

export function Cast(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
			<path
				fill='#333'
				d='M10.4 10C9.074 10 8 11.027 8 12.294v6.117c0 1.267 1.074 2.293 2.4 2.293h.85c.442 0 .8-.342.8-.764s-.358-.765-.8-.765h-.85c-.442 0-.8-.342-.8-.764v-6.117c0-.422.358-.765.8-.765h11.2c.442 0 .8.343.8.765v6.117c0 .422-.358.764-.8.764h-.8c-.442 0-.8.343-.8.765s.358.764.8.764h.8c1.326 0 2.4-1.026 2.4-2.293v-6.117C24 11.027 22.926 10 21.6 10H10.4Z'
			/>
			<path
				fill='#333'
				d='M15.989 18.412a.83.83 0 0 0-.574.215l-3.193 3.059c-.503.481-.138 1.314.574 1.314h6.386c.71 0 1.077-.833.574-1.314l-3.194-3.059a.83.83 0 0 0-.573-.215Z'
			/>
		</svg>
	);
}
