import {ColDef} from 'ag-grid-community';
import {useMemo} from 'react';
import {RowDef} from './types';
import {CellType, EntitiesFilter, NumberFilter, numberFilterParams} from '@esgi/ui/ag-grid';
import {School} from '@esgi/main/libs/admin-store';

export function useColumnDefinitions(rows: RowDef[], schools: School[]) {
	return useMemo<ColDef<RowDef>[]>(
		() => [
			{
				field: 'firstName',
				headerName: 'First Name',
				lockVisible: true,
				headerComponentParams: {
					description: 'Displays the first name of a teacher (Required)',
				},
				filter: false,
				sortable: true,
				type: CellType.Primary,
			},
			{
				field: 'lastName',
				headerName: 'Last Name',
				lockVisible: true,
				filter: false,
				headerComponentParams: {
					description: 'Displays the last name of a teacher (Required)',
				},
				sortable: true,
				type: CellType.Primary,
				sort: 'asc',
			},
			{
				field: 'userName',
				headerName: 'User Name',
				filter: false,
				headerComponentParams: {
					description: 'Displays the user name of a teacher',
				},
				sortable: true,
				sort: 'asc',
			},
			{
				field: 'email',
				headerName: 'Email',
				filter: false,
				headerComponentParams: {
					description: 'Displays the email of a teacher',
				},
				sortable: true,
				sort: 'asc',
			},
			{
				field: 'school',
				headerName: 'School',
				filter: EntitiesFilter,
				filterParams: {
					entities: schools,
				},
				headerComponentParams: {
					description: `Displays a school's name`,
				},
				sortable: true,
			},
			{
				field: 'classes',
				headerName: 'Classes',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows.map((r) => r.classes)),
				headerComponentParams: {
					description: 'Displays the number of classes associated with a teacher',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
			{
				field: 'groups',
				headerName: 'Groups',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows.map((r) => r.groups)),
				headerComponentParams: {
					description: 'Displays the number of groups associated with a teacher',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
			{
				field: 'students',
				headerName: 'Students',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows.map((r) => r.students)),
				headerComponentParams: {
					description: 'Displays the number of students associated with a teacher',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
		],
		[rows, schools],
	);
}
