import * as React from 'react';
import type {SVGProps} from 'react';

export function Download(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			data-cy='download-icon'
			{...props}
		>
			<path
				d='M8.88889 18C8.39822 18 8 18.384 8 18.8571V20.5714C8 22.4649 9.592 24 11.5556 24H20.4444C22.408 24 24 22.4649 24 20.5714V18.8571C24 18.384 23.6018 18 23.1111 18C22.6204 18 22.2222 18.384 22.2222 18.8571V20.5714C22.2222 21.5186 21.4267 22.2857 20.4444 22.2857H11.5556C10.5733 22.2857 9.77778 21.5186 9.77778 20.5714V18.8571C9.77778 18.384 9.37956 18 8.88889 18Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.5947 18.414C16.2662 18.7509 15.7338 18.7509 15.4053 18.414L12.7463 15.695C12.4179 15.3581 12.4179 14.8118 12.7463 14.4749C13.0747 14.138 13.6072 14.138 13.9357 14.4749L15.125 15.5556L15.125 8.88889C15.125 8.39797 15.5168 8 16 8C16.4832 8 16.875 8.39797 16.875 8.88889V15.5556L18.0643 14.4749C18.3928 14.138 18.9253 14.138 19.2537 14.4749C19.5821 14.8118 19.5821 15.3581 19.2537 15.695L16.5947 18.414Z'
				fill='#333333'
			/>
		</svg>
	);
}
