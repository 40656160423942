import {useMemo} from 'react';
import {RowDef} from '../../../../../types';
import {ExpandedPanel} from './expanded-panel';
import {QuickPanel} from './quick-panel';
import {UserType} from '@esgi/core/authentication';
import {UserType as UserTypeStorage, useStudents} from '@esgi/main/libs/admin-store';
import {isNull} from 'underscore';
import {Hierarchy, SpecialistType as HierarchySpecialistType} from '@esgi/main/features/admins/utils';
import {TestResultsStudent} from '@esgi/main/kits/admin';
import {useTestResultsSubject} from '../../../../../../_kit';
import {mapToEnum} from 'shared/utils';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	deselectedAllItems: VoidFunction;
};

export function ContentWrapper({selectedRow, deselectedAllItems, isPanelExpanded}: Props) {
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({specialistGroupsIDs}) => specialistGroupsIDs.includes(selectedRow.id))
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {
		isReady: isTestResultsSubjectReady,
		subjects: testResultsSubjects,
		reportSubject,
	} = useTestResultsSubject({
		userID: selectedRow.specialist.id,
		schoolID: selectedRow.school?.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter:
			mapToEnum(selectedRow.type.id, UserType) === UserType.PA ? null : 'userIDAsSpecialists',
	});

	const hierarchy = useMemo<Hierarchy>(() => {
		const specialistType = selectedRow.specialist.userType;

		const hierarchySpecialistType: HierarchySpecialistType | null =
			(specialistType === UserTypeStorage.ISD && UserType.ISD) ||
			(specialistType === UserTypeStorage.ISS && UserType.ISS) ||
			null;

		const isPreAssess = specialistType === UserTypeStorage.PA;

		const h: Hierarchy = {
			schoolID: selectedRow.specialist.schoolID,
			...(isPreAssess && {
				preAssessID: selectedRow.specialist.id,
				preAssessGroupID: selectedRow.id,
			}),
			...(!isNull(hierarchySpecialistType) && {
				specialistsID: selectedRow.specialist.id,
				specialistGroup: {
					id: selectedRow.id,
					name: selectedRow.name,
					specialistID: selectedRow.specialist.id,
					type: hierarchySpecialistType,
				},
				specialistType: hierarchySpecialistType,
			}),
		};

		return h;
	}, [selectedRow]);

	const skeleton = !isTestResultsSubjectReady || !isStudentsLoaded;

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				deselectedAllItems={deselectedAllItems}
				hierarchy={hierarchy}
				skeleton={skeleton}
				reportSubject={reportSubject}
			/>

			<ExpandedPanel
				selectedRow={selectedRow}
				hierarchy={hierarchy}
				skeleton={skeleton}
				students={filteredStudents}
				subjects={testResultsSubjects}
			/>
		</>
	);
}
