import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {SubjectType} from '@esgi/core/enums';
import {ReportType} from '../../types';
import {useFlag} from '@esgi/ui';
import {ParentLetter} from './parent-letter';
import {FlashCards} from './flash-cards';
import {useEffect} from 'react';

type Props = {
	baseOpendReport: ReportType.FlashCards | ReportType.ParentLetter;
	hierarchy: HierarchyInstance;
	subjectID: number;
	subjectType: SubjectType;
	onClose: VoidFunction;
};

export function ParentLetterAndFlashCards({baseOpendReport, hierarchy, subjectID, subjectType, onClose}: Props) {
	const [isParentLetterOpened, openParentLetter, closeParentLetter] = useFlag(
		baseOpendReport === ReportType.ParentLetter,
	);
	const [isFlashCardsOpened, openFlashCards, closeFlashCards] = useFlag(baseOpendReport === ReportType.FlashCards);

	useEffect(() => {
		if (!isParentLetterOpened && !isFlashCardsOpened) {
			onClose();
		}
	}, [isFlashCardsOpened, isParentLetterOpened, onClose]);

	return (
		<>
			<ParentLetter
				open={isParentLetterOpened}
				hierarchy={hierarchy}
				subjectID={subjectID}
				subjectType={subjectType}
				onOpen={openParentLetter}
				onClose={closeParentLetter}
			/>

			<FlashCards
				open={isFlashCardsOpened}
				hierarchy={hierarchy}
				subjectID={subjectID}
				subjectType={subjectType}
				onOpen={openFlashCards}
				onClose={closeFlashCards}
			/>
		</>
	);
}
