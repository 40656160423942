import {useMemo} from 'react';

import {
	useSchools,
	useTeachers,
	usePreAssessSpecialists,
	useClasses,
	useGroups,
	useSchoolSpecialists,
	useDistrictSpecialists,
	useSpecialistGroups,
	useStudents,
	School,
	User,
} from '@esgi/main/libs/admin-store';

import {FiltersData, RowDef} from './types';
import {isEmpty, unique} from 'underscore';

type Out = FiltersData & {
	ready: boolean;
	rows: RowDef[];
};

export function useData(): Out {
	const [{data: allSchools, loaded: allSchoolsLoaded}] = useSchools();
	const [{data: allTeachers, loaded: allTeachersLoaded}] = useTeachers();
	const [{data: allPreAssess, loaded: allPreAssessLoaded}] = usePreAssessSpecialists();
	const [{data: allClasses, loaded: allClassesLoaded}] = useClasses();
	const [{data: allGroups, loaded: allGroupsLoaded}] = useGroups();
	const [{data: schoolSpecialists, loaded: schoolSpecialistsLoaded}] = useSchoolSpecialists();
	const [{data: districtSpecialists, loaded: districtSpecialistsLoaded}] = useDistrictSpecialists();
	const [{data: allSpecialistGroups, loaded: allSpecialistGroupsLoaded}] = useSpecialistGroups();
	const [{data: students, loaded: studentsLoaded}] = useStudents();

	return useMemo<Out>(() => {
		const isDataLoaded =
			allSchoolsLoaded &&
			allTeachersLoaded &&
			allPreAssessLoaded &&
			allClassesLoaded &&
			allGroupsLoaded &&
			schoolSpecialistsLoaded &&
			districtSpecialistsLoaded &&
			allSpecialistGroupsLoaded &&
			studentsLoaded;

		if (!isDataLoaded) {
			return {
				ready: false,
				rows: [],
				availableSchools: [],
				availableTeachers: [],
				availableClasses: [],
				availableGroups: [],
				availableSpecialists: [],
				availableSpecialistGroups: [],
			};
		}

		const allSpecialists = [...schoolSpecialists, ...districtSpecialists, ...allPreAssess];
		const rows = students.filter((student) => !!student.teacherID || !isEmpty(student.specialistGroupsIDs)).map((student) => {
			const school = allSchools.find((school) => school.id === student.schoolID);
			const teacher = allTeachers.find((teacher) => teacher.id === student.teacherID);
			const classes = allClasses.filter((c) => student.classesIDs.includes(c.id));
			const groups = allGroups.filter((group) => student.groupsIDs.includes(group.id));

			const specialists = allSpecialists.filter((specialist) => student.specialistsIDs.includes(specialist.id));
			const specialistGroups = allSpecialistGroups.filter((group) => student.specialistGroupsIDs.includes(group.id));

			return {
				id: student.id,
				firstName: student.firstName,
				lastName: student.lastName,
				school: school ?? null,
				teacher: teacher ?? null,
				class: classes,
				group: groups,
				specialist: specialists,
				specialistGroups: specialistGroups,
				gradeLevelID: student.gradeLevelID,
			};
		});

		return {
			ready: true,
			rows,
			availableSchools: unique(rows.map((r) => r.school)).filter((item): item is School => !!item),
			availableTeachers: unique(rows.map((r) => r.teacher)).filter((item): item is User => !!item),
			availableClasses: unique(rows.map((r) => r.class).flat()),
			availableGroups: unique(rows.map((r) => r.group).flat()),
			availableSpecialists: unique(rows.map((r) => r.specialist).flat()),
			availableSpecialistGroups: unique(rows.map((r) => r.specialistGroups).flat()),
		};
	}, [
		allClasses,
		allClassesLoaded,
		allGroups,
		allGroupsLoaded,
		allPreAssess,
		allPreAssessLoaded,
		allSchools,
		allSchoolsLoaded,
		allSpecialistGroups,
		allSpecialistGroupsLoaded,
		allTeachers,
		allTeachersLoaded,
		districtSpecialists,
		districtSpecialistsLoaded,
		schoolSpecialists,
		schoolSpecialistsLoaded,
		students,
		studentsLoaded,
	]);
}
