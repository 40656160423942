import {AgGrid as AgGridBase} from '@esgi/ui/ag-grid';
import {ComponentProps, useCallback, useEffect, useState} from 'react';
import {usePageContentContext} from '../../context';
import {GridReadyEvent} from 'ag-grid-community';
import {isNull} from 'underscore';
import {getAdaptedColDefs} from './utils/get-adapted-col-defs';

type Props<T extends Record<string, unknown>> = ComponentProps<typeof AgGridBase.Table<T>>;

export function AgGrid<T extends Record<string, unknown>>({onGridReady, ...props}: Props<T>) {
	const {setApi, api, columnDefs, initialState, tableRows, isDataLoaded} = usePageContentContext<T>();

	const [adaptedColumnDefs, setAdaptedColumnDefs] = useState(columnDefs);

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		setAdaptedColumnDefs(getAdaptedColDefs({api, columns: columnDefs}));
	}, [api, columnDefs]);

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const onColumnVisibleChanged = () => {
			setAdaptedColumnDefs(getAdaptedColDefs({api, columns: columnDefs}));
		};

		api.addEventListener('columnVisible', onColumnVisibleChanged);

		return () => {
			api.removeEventListener('columnVisible', onColumnVisibleChanged);
		};
	}, [api, columnDefs]);

	const handleGridReadyHandler = useCallback(
		(params: GridReadyEvent) => {
			setApi(params.api);

			setAdaptedColumnDefs(getAdaptedColDefs({api: params.api, columns: columnDefs}));

			onGridReady?.(params);
		},
		[columnDefs, onGridReady, setApi],
	);

	return (
		<AgGridBase withoutLastColumnPadding fullRowHover>
			<AgGridBase.Table
				rowSelection='multiple'
				loading={!isDataLoaded}
				columnDefs={adaptedColumnDefs}
				rowData={tableRows}
				gridOptions={{initialState: initialState ?? undefined}}
				onGridReady={handleGridReadyHandler}
				pagination
				paginationAutoPageSize={false}
				paginationPageSize={15}
				rowMultiSelectWithClick
				suppressPaginationPanel
				suppressMovableColumns
				{...props}
			/>
		</AgGridBase>
	);
}
