import {useEffect, useState} from 'react';
import {
	PublishForSpecialistsTabID,
	PublishForTeacherTabID,
	RetestSubjectType,
	SubjectSettingData,
} from '../../../../types';
import {
	ExpandableSelectableBlockTab,
	GradeLevel,
	PublishToSpecialistsNumerable,
	PublishToTeachersNumerable,
	SessionTypeNumerable,
	SubjectPublishType,
	SubjectPublishValue,
} from '@esgi/main/features/admins/subject-management';
import {getPublishForTeacherInit} from './helpers/get-publish-for-teacher-init';
import {getPublishForSpecialistsInit} from './helpers/get-publish-for-specialists-init';
import {retestSubjectFromNumerable, retestSubjectToNumerable} from '../../../mapped-enums';
import {isNull} from 'underscore';
import {mapToEnum} from 'shared/utils';
import {getPublishPeriodInitValue} from './helpers/get-publish-period-init-value';
import {useDisposable} from '@esgi/core/service';
import {SubjectsDistrictController} from '@esgi/contracts/esgi';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {getPublishForTeachersSaveData} from '../../../helpers/get-publish-for-teachers-save-data';
import {getPublishForSpecialistsSaveData} from '../../../helpers/get-publish-for-specialists-save-data';
import {getPublishDatesSaveData} from '../../../helpers/get-publish-dates-save-data';
import {Subject as SubjectStore, isDeployedSubject, subjectsStore} from '@esgi/main/libs/admin-store';
import {getPublishForTeachersStoreData} from '../../../helpers/get-publish-for-teachers-store-data';
import {getPublishForSpecialistsStoreData} from '../../../helpers/get-publish-for-specialists-store-data';
import {Parameters, UpdatedSubjectData} from './types';
import {useFormTouched} from '../../../hooks/use-form-touched';

export function useSubjectData({
	isDataLoaded,
	teachersSchools,
	districtSpecialists,
	schoolSpecialists,
	schoolSpecialistsSchools,
	initSubjectData,
	allTrackDates,
	trackID,
	subjectID,
}: Parameters) {
	const controller = useDisposable(SubjectsDistrictController);
	const [updatedData, updateSubject] = useCancelableRequest(controller.update);

	const [initData, setInitData] = useState<SubjectSettingData | null>(null);

	const [subjectName, setSubjectName] = useState('');

	const [retestSubjectType, setRetestSubjectType] = useState(RetestSubjectType.Any);
	const [isShuffleQuestions, setIsShuffleQuestions] = useState(false);
	const [selectedGradeLevels, setSelectedGradeLevels] = useState<GradeLevel['id'][]>([]);

	const [publishPeriodValue, setPublishPeriodValue] = useState<SubjectPublishValue>({
		type: SubjectPublishType.Indefinitely,
		dateFrom: null,
		dateTo: null,
		trackDatesIDs: [],
	});

	const [isPublishForTeacherActive, setIsPublishForTeacherActive] = useState(true);
	const [publishForTeacherData, setPublishForTeacherData] = useState<
		ExpandableSelectableBlockTab<PublishForTeacherTabID>[]
	>([]);

	const [isPublishForSpecialistsActive, setIsPublishForSpecialistsActive] = useState(true);
	const [publishForSpecialistsData, setPublishForSpecialistsData] = useState<
		ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[]
	>([]);

	const [updatedSubjectData, setUpdatedSubjectData] = useState<UpdatedSubjectData | null>(null);

	const [isActive, setIsActive] = useState(false);

	const getCurrentSubjectSettingData = (): SubjectSettingData => ({
		subjectName,
		retestSubjectType,
		isShuffleQuestions,
		publishPeriodValue,
		selectedGradeLevels,
		isPublishForTeacherActive,
		publishForTeacherData,
		isPublishForSpecialistsActive,
		publishForSpecialistsData,
		isActive,
	});

	const isFormTouched = useFormTouched({
		initData,
		currentData: getCurrentSubjectSettingData(),
	});

	useEffect(() => {
		if (isDataLoaded && !isNull(initSubjectData)) {
			const initSubjectName = initSubjectData.name;

			const sessionTypeMapped = mapToEnum(initSubjectData.forceTestType, SessionTypeNumerable);
			const initRetestSubjectType = isNull(initSubjectData.forceTestType)
				? RetestSubjectType.Any
				: retestSubjectFromNumerable[sessionTypeMapped];

			const initIsShuffleQuestions = initSubjectData.shuffleQuestions;

			const initSelectedGradeLevels = initSubjectData.selectedGradeLevels;
			const initPublishPeriodValue = getPublishPeriodInitValue({allTrackDates, initSubjectData});

			const initIsPublishForTeacherActive =
				mapToEnum(initSubjectData.publishToTeachers, PublishToTeachersNumerable) !== PublishToTeachersNumerable.None;
			const initPublishForTeacherData = getPublishForTeacherInit({teachersSchools, initSubjectData});

			const initIsPublishForSpecialistsActive =
				mapToEnum(initSubjectData.publishToSpecialists, PublishToSpecialistsNumerable) !==
				PublishToSpecialistsNumerable.None;
			const initPublishForSpecialistsData = getPublishForSpecialistsInit({
				districtSpecialists,
				schoolSpecialistsSchools,
				initSubjectData,
			});

			setSubjectName(initSubjectName);

			setRetestSubjectType(initRetestSubjectType);
			setIsShuffleQuestions(initIsShuffleQuestions);

			setPublishPeriodValue(initPublishPeriodValue);
			setSelectedGradeLevels(initSelectedGradeLevels);

			setIsPublishForTeacherActive(initIsPublishForTeacherActive);
			setPublishForTeacherData(initPublishForTeacherData);

			setIsPublishForSpecialistsActive(initIsPublishForSpecialistsActive);
			setPublishForSpecialistsData(initPublishForSpecialistsData);

			setInitData({
				subjectName: initSubjectName,
				retestSubjectType: initRetestSubjectType,
				isShuffleQuestions: initIsShuffleQuestions,
				publishPeriodValue: initPublishPeriodValue,
				selectedGradeLevels: initSelectedGradeLevels,
				isPublishForTeacherActive: initIsPublishForTeacherActive,
				publishForTeacherData: initPublishForTeacherData,
				isPublishForSpecialistsActive: initIsPublishForSpecialistsActive,
				publishForSpecialistsData: initPublishForSpecialistsData,
				isActive: !initSubjectData.isHidden,
			});

			setIsActive(!initSubjectData.isHidden);
		}
	}, [isDataLoaded, initSubjectData]);

	useEffect(() => {
		if (isAsyncSucceed(updatedData)) {
			const {id, published, subjectName} = updatedData.data;

			subjectsStore().update?.((item: SubjectStore) => {
				if (item.id === subjectID && isDeployedSubject(item) && !isNull(updatedSubjectData)) {
					const allowedSchools = getPublishForTeachersStoreData({
						publishToTeachers: updatedSubjectData.publishToTeachers,
						selectedSchoolsIDs: updatedSubjectData.selectedSchoolsIDs,
						allSchoolsIDs: teachersSchools.map(({id}) => id),
					});

					const allowedSpecialists = getPublishForSpecialistsStoreData({
						publishToSpecialists: updatedSubjectData.publishToSpecialists,
						allSpecialistsIDs: [...districtSpecialists.map(({id}) => id), ...schoolSpecialists.map(({id}) => id)],
						selectedDistrictSpecialistsIDs: updatedSubjectData.selectedDistrictSpecialistsIDs,
						selectedSchoolSpecialistsSchoolsIDs: updatedSubjectData.selectedSchoolSpecialistsSchoolsIDs,
						allSchoolSpecialists: schoolSpecialists,
					});

					return {
						...item,
						id,
						gradeLevels: selectedGradeLevels,
						isPublished: published,
						name: subjectName,
						allowedSchools,
						allowedSpecialists,
						hidden: !isActive,
					};
				}

				return item;
			});

			setUpdatedSubjectData(null);
		}
	}, [updatedData]);

	const onUpdateSubject = () => {
		if (isNull(trackID)) {
			throw new Error('trackID is null');
		}

		const publishForTeachersSaveData = getPublishForTeachersSaveData({
			publishForTeacherData,
			isPublishForTeacherActive,
		});

		const publishForSpecialistsSaveData = getPublishForSpecialistsSaveData({
			isPublishForSpecialistsActive,
			publishForSpecialistsData,
		});

		const {publishFromDate, publishToDate, publishType} = getPublishDatesSaveData({allTrackDates, publishPeriodValue});

		updateSubject({
			districtID: undefined as unknown as number,
			id: subjectID,
			name: subjectName,
			forceTestType: (retestSubjectType === RetestSubjectType.Any
				? null
				: retestSubjectToNumerable[retestSubjectType]) as number,
			shuffleQuestions: isShuffleQuestions,
			gradeLevels: selectedGradeLevels,
			publishToTeachers: publishForTeachersSaveData.publishToTeachers,
			schools: publishForTeachersSaveData.schools,
			publishToSpecialists: publishForSpecialistsSaveData.publishToSpecialists,
			schoolSpecialists: publishForSpecialistsSaveData.schoolSpecialistsSchools,
			districtSpecialists: publishForSpecialistsSaveData.districtSpecialists,
			publishType,
			publishTrackID: trackID,
			publishFromDate,
			publishToDate,
			isHidden: !isActive,
		});

		setUpdatedSubjectData({
			publishToTeachers: publishForTeachersSaveData.publishToTeachers,
			selectedSchoolsIDs: publishForTeachersSaveData.schools ?? [],
			publishToSpecialists: publishForSpecialistsSaveData.publishToSpecialists,
			selectedDistrictSpecialistsIDs: publishForSpecialistsSaveData.districtSpecialists,
			selectedSchoolSpecialistsSchoolsIDs: publishForSpecialistsSaveData.schoolSpecialistsSchools,
			isHidden: !isActive,
		});

		setInitData(getCurrentSubjectSettingData());
	};

	return {
		subjectName,
		setSubjectName,
		retestSubjectType,
		setRetestSubjectType,
		isShuffleQuestions,
		setIsShuffleQuestions,
		publishPeriodValue,
		setPublishPeriodValue,
		setSelectedGradeLevels,
		selectedGradeLevels,
		publishForTeacherData,
		setPublishForTeacherData,
		isPublishForTeacherActive,
		setIsPublishForTeacherActive,
		publishForSpecialistsData,
		setPublishForSpecialistsData,
		isPublishForSpecialistsActive,
		setIsPublishForSpecialistsActive,
		onUpdateSubject,
		isDataSaving: isAsyncPending(updatedData),
		isFormTouched,
		isActive,
		setIsActive,
	};
}
