import {useSubjectsByUserID} from './hooks/use-subjects-by-user-id';
import {useSubjectsFromStore} from './hooks/use-subjects-from-store';
import {useSubjectsByDistrictID} from './hooks/use-subjects-by-district-id';

type Parameters = {
	teacherID: number | null;
};

export function useSubjects({teacherID}: Parameters) {
	const [isSubjectsFromStoreReady, schoolSubjects] = useSubjectsFromStore();
	const [isSubjectsByUserIDReady, teacherSubjects] = useSubjectsByUserID({teacherID});
	const [isDistrictSubjectsByIDReady, districtSubjects] = useSubjectsByDistrictID();

	return {
		isSubjectsLoaded: isSubjectsFromStoreReady && isDistrictSubjectsByIDReady && isSubjectsByUserIDReady,
		districtSubjects,
		schoolSubjects,
		teacherSubjects,
	};
}
