import {ReportDialog, Reports} from '@esgi/main/features/admins/data';
import {Hierarchy, HierarchyMode} from '@esgi/main/features/admins/utils';
import {useMemo} from 'react';

export function PreAssess() {
	const hierarchy = useMemo(() => {
		const h: Hierarchy = {
			mode: HierarchyMode.PreAssess,
		};

		return h;
	}, []);

	return (
		<ReportDialog.Section title='District Reports'>
			<Reports.GradeScale
				name='District Grades'
				description='Apply customizable grade scales to school scores'
				hierarchy={hierarchy}
			/>

			<Reports.TotalStudents
				name='District Students'
				description='Review and compare student scores for a subject tab at the District level'
				hierarchy={hierarchy}
			/>

			<Reports.Totals
				name='District Totals'
				description='Review and compare school scores for a subject tab'
				hierarchy={hierarchy}
			/>

			<Reports.ItemAnalysis name='Item Analysis' hierarchy={hierarchy} />

			<Reports.PieCharts hierarchy={hierarchy} />

			<Reports.PreAssessActivityReport
				name='Pre-assess activity'
				description='View Pre-Assess usage of ESGI'
				hierarchy={hierarchy}
			/>
		</ReportDialog.Section>
	);
}
