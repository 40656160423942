import {Class} from '@esgi/main/libs/admin-store';
import {Alert} from '@esgi/ui/alert';
import {useCallback} from 'react';
import {useRemoveTeacherClass} from '../../../utils';
import {RemoveSingleEntityAlert} from '@esgi/main/features/admins/data';

type Props = {
	onRemoved: VoidFunction;
	onClose: VoidFunction;
	potentialRemovedTeacherClass: Class;
};

export function DeleteTeacherClassAlert({onClose, onRemoved, potentialRemovedTeacherClass}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isTeacherClassesDataLoaded, removeTeacherClass, isTeacherClassRemoving] = useRemoveTeacherClass();

	const onRemoveTeacherClass = useCallback(() => {
		removeTeacherClass({
			id: potentialRemovedTeacherClass.id,
			name: potentialRemovedTeacherClass.name,
			onRemoved: () => {
				onClose();
				onRemoved();
			},
		});
	}, [onClose, onRemoved, potentialRemovedTeacherClass, removeTeacherClass]);

	return (
		<RemoveSingleEntityAlert
			skeleton={!isTeacherClassesDataLoaded}
			alertRef={alertRef}
			closeAlert={closeAlert}
			title='Delete Class?'
			entityName={potentialRemovedTeacherClass.name}
			bodyMessage={[
				'Are you sure you want to delete the following class?',
				'This will NOT delete students and their data. The students still will be available and can be added to another class.',
			]}
			isRemoving={isTeacherClassRemoving}
			onRemove={onRemoveTeacherClass}
		/>
	);
}
