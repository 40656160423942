import {useCallback, useState} from 'react';
import {useData} from './use-data';
import {Base} from './variant/base';
import {useColumnDefinitions} from './use-column-definitions';
import {AddEditStudent} from '@esgi/main/features/district-admin/student-profile-drawer';

export default function () {
	const {ready, rows, ...filterData} = useData();

	const colDefs = useColumnDefinitions(filterData);

	const [isOpenAddDrawer, setIsOpenAddDrawer] = useState(false);

	const onOpenAddDrawer = useCallback(() => {
		setIsOpenAddDrawer(true);
	}, []);

	const onCloseAddDrawer = useCallback(() => {
		setIsOpenAddDrawer(false);
	}, []);

	return (
		<>
			<Base columnDefs={colDefs} allTableRows={rows} isDataLoaded={ready} onOpenAddDrawer={onOpenAddDrawer} />
			{isOpenAddDrawer && <AddEditStudent studentID={null} onClose={onCloseAddDrawer} />}
		</>
	);
}
