import {useMemo} from 'react';
import {isUndefined} from '@esgi/ui';
import {DatePickerData, PeriodType as PeriodTypeForDatePicker} from '@esgi/main/kits/admin';
import {CommonInitialFormData, StudentsTwoPeriodsInitialFormData} from './types';
import {SchoolStudentsNeedingSupportWidgetViewType} from '../../../../../types/students-needing-support';
import {AdaptedWidgetOptions, Period, PeriodsVariant} from '../types';
import {datePickerDataForInitialization} from './constants';
import {mapToEnum} from 'shared/utils';
import {Color, Widget} from '@esgi/main/features/admins/dashboard';

export function useInitialCommonForm({widgetOptions}: {widgetOptions: AdaptedWidgetOptions}) {
	return useMemo<CommonInitialFormData>(() => {
		if (
			widgetOptions.viewType === SchoolStudentsNeedingSupportWidgetViewType.Classes &&
			!widgetOptions.classIDs.length
		) {
			return {
				periodsVariant: PeriodsVariant.None,
			};
		}

		if (
			widgetOptions.viewType === SchoolStudentsNeedingSupportWidgetViewType.Students &&
			widgetOptions.periods.length === 1
		) {
			return {
				periodsVariant: PeriodsVariant.SinglePeriod,
			};
		}

		if (
			widgetOptions.viewType === SchoolStudentsNeedingSupportWidgetViewType.Students &&
			widgetOptions.periods.length === 2
		) {
			return {
				periodsVariant: PeriodsVariant.TwoPeriods,
			};
		}

		return {
			periodsVariant: PeriodsVariant.None,
		};
	}, [widgetOptions]);
}

export function useInitialStudentsSinglePeriodForm({widgetOptions}: {widgetOptions: AdaptedWidgetOptions}) {
	return useMemo<DatePickerData>(() => {
		const period = widgetOptions.periods[0];

		return isUndefined(period)
			? datePickerDataForInitialization
			: {
					datePeriod: mapToEnum(period.datePeriod, PeriodTypeForDatePicker),
					trackDatesID: period.trackDatesID,
					dateRange: period.dateRange,
				};
	}, [widgetOptions]);
}

export function useInitialStudentsTwoPeriodsForm({widgetOptions}: {widgetOptions: AdaptedWidgetOptions}) {
	return useMemo<StudentsTwoPeriodsInitialFormData>(() => {
		const [firstPeriod, secondPeriod]: [Period, Period] = [
			widgetOptions.periods[0],
			widgetOptions.periods[1],
		].map<Period>((item, index) => {
			if (isUndefined(item)) {
				return {
					...datePickerDataForInitialization,
					color: Widget.colorItems[index]?.value ?? Color.None,
				};
			}

			return {
				color: item.color !== Color.None ? item.color : Widget.colorItems[index]?.value ?? Color.None,
				datePeriod: mapToEnum(item.datePeriod, PeriodTypeForDatePicker),
				trackDatesID: item.trackDatesID,
				dateRange: item.dateRange,
			};
		}) as [Period, Period];

		return {
			firstPeriod: {
				color: firstPeriod.color,
				datePeriod: firstPeriod.datePeriod,
				dateRange: firstPeriod.dateRange,
				trackDatesID: firstPeriod.trackDatesID,
			},
			secondPeriod: {
				color: secondPeriod.color,
				datePeriod: secondPeriod.datePeriod,
				dateRange: secondPeriod.dateRange,
				trackDatesID: secondPeriod.trackDatesID,
			},
		};
	}, [widgetOptions]);
}

export function useInitialClassesForm({widgetOptions}: {widgetOptions: AdaptedWidgetOptions}) {
	return useMemo<DatePickerData>(() => {
		const period = widgetOptions.periods[0];

		return isUndefined(period)
			? datePickerDataForInitialization
			: {
					datePeriod: mapToEnum(period.datePeriod, PeriodTypeForDatePicker),
					trackDatesID: period.trackDatesID,
					dateRange: period.dateRange,
				};
	}, [widgetOptions]);
}
