import {forkJoin, map, Observable} from 'rxjs';
import {MutableRefObject, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {Edit, Plus} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';
import {Drawer} from '@esgi/main/kits/common';
import {useService} from 'libs/core/src/service';
import {useBehaviorSubject, useStreamEffect} from '@esgi/ui/utils';
import {ImageData, StudentProfileMode, StudentProfileTab, TabValidation} from './types';
import {SaveWithoutClassesAlert} from './components/save-without-classes-alert';
import {DeletePhotoAlert} from './components/delete-photo-alert';
import {UploadPhotoAlert} from './components/upload-photo-alert';
import {SaveAnywayAlert} from './components/save-anyway-alert';
import {StudentDetails} from './components/student-details';
import {TabsController} from './components/tabs-controller';
import {Information} from './components/tabs/information';
import {MoreOptions} from './components/more-options';
import {Location} from './components/tabs/location';
import {Profile} from './components/tabs/profile';
import {StudentProfileService} from './service';
import {FormTouchedRef} from '../../types';
import {DrawerBody} from './index.styled';
import {tabList} from './constants';
import {RemoveStudent} from '@esgi/main/features/school-admin/common';

interface Props {
	id: string;
	modalsOwner: RefObject<HTMLDivElement>;
	formTouchedRef: MutableRefObject<FormTouchedRef>;
	onOpenTransferStudent?: VoidFunction;
	onDrawerClose: VoidFunction;
	onDrawerForceClose: VoidFunction;
}

export function StudentProfile({id: studentID, modalsOwner, formTouchedRef, onOpenTransferStudent, onDrawerClose, onDrawerForceClose}: Props) {
	const service = useService(StudentProfileService);

	const school = useBehaviorSubject(service.school);
	const photo = useBehaviorSubject(service.newPhoto);
	const initData = useBehaviorSubject(service.initData);
	const profileMode = useBehaviorSubject(service.mode);
	const canViewExportID = useBehaviorSubject(service.canExportID);
	const duplicateError = useBehaviorSubject(service.duplicateError);
	const initDictionaryData = useBehaviorSubject(service.initDictionaryData);

	const isBusy = useBehaviorSubject(service.isBusy);

	const removeStudentManager = RemoveStudent.useManagerRef();

	const [isInitialized, setInitialized] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [showEmptyClassError, setShowEmptyClassError] = useState(false);
	const [selectedTab, setSelectedTab] = useState<StudentProfileTab>(StudentProfileTab.Profile);
	const [isDeletePhotoAlertOpen, setDeletePhotoAlertOpen] = useState(false);
	const [isUploadPhotoAlertOpen, setUploadPhotoAlertOpen] = useState(false);
	const [invalidTabIDs, setInvalidTabIDs] = useState<StudentProfileTab[]>([]);

	const icon = useMemo(() => {
		if (profileMode === StudentProfileMode.add) {
			return Plus;
		}

		return Edit;
	}, [profileMode]);

	const sectionName = useMemo(() => {
		if (profileMode === StudentProfileMode.add) {
			return 'Add New Student';
		}

		if (!initData) {
			return null;
		}

		return `${initData?.profile?.firstName} ${initData?.profile?.lastName}`;
	}, [initData, profileMode]);

	const profileForm = service.profile;
	const locationForm = service.location;
	const informationForm = service.information;

	const checkIsFormTouched = useCallback(() => {
		const result = service.checkFormIsTouched();

		setIsFormTouched(result);
		service.setStudentProfileFormStatuses();
	}, [service]);

	useStreamEffect(profileForm.onChanged, checkIsFormTouched);
	useStreamEffect(locationForm.onChanged, checkIsFormTouched);
	useStreamEffect(informationForm.onChanged, checkIsFormTouched);
	useEffect(checkIsFormTouched, [photo]);

	const saveStudentData = useCallback((saveAnyway: boolean) => {
		service.save(saveAnyway).subscribe(res => {
			if (saveAnyway || (res.isSuccess && !res.errors?.length)) {
				setInitialized(false);
				onDrawerForceClose();
			}
		});
	}, [onDrawerForceClose, service]);

	const handleSaveStudent = useCallback(() => {
		forkJoin(tabList.map((tab) => (
			(service[tab].validate() as Observable<TabValidation>).pipe(map((item) => ({...item, tab})))
		))).subscribe((response) => {
			service.setStudentProfileFormStatuses();
			const invalidTabs = response.filter((item) => !item.valid).map((item) => item.tab);
			if (invalidTabs.length > 0) {
				setInvalidTabIDs(invalidTabs);
				return;
			}

			setInvalidTabIDs([]);

			if (service.location.controls.teacherID.value && service.location.controls.teacherEntityIDs.value.classIDs.length === 0) {
				setShowEmptyClassError(true);
				return;
			}

			saveStudentData(false);
		});
	}, [saveStudentData, service]);

	const handleSaveStudentImage = useCallback((data: ImageData) => {
		service.saveImage(data);
		setUploadPhotoAlertOpen(false);
	}, [service]);

	const handleDeleteStudentImage = useCallback(() => {
		service.deletePhoto();
		setUploadPhotoAlertOpen(false);
	}, [service]);

	const handelChangeCurrentTab = useCallback((tab: StudentProfileTab) => {
		if (tab === selectedTab) {
			return;
		}

		service[selectedTab as string].validate().subscribe((value) => {
			service.setStudentProfileFormStatuses();
			if (!value.valid) {
				return;
			}

			setSelectedTab(tab);
		});
	}, [selectedTab, service]);

	const cancelWithoutClassesModal = useCallback(() => {
		setShowEmptyClassError(false);
		setSelectedTab(StudentProfileTab.Location);
	}, []);

	const saveWithoutClasses = useCallback(() => {
		setShowEmptyClassError(false);
		saveStudentData(false);
	}, [saveStudentData]);

	const onOpenRemoveStudent = useCallback(() => {
		removeStudentManager.current?.runRemove({
			studentID: Number(studentID),
		});
	}, [removeStudentManager, studentID]);

	useImperativeHandle(formTouchedRef, () => ({
		isFormTouched,
		isInitialized,
	}), [isFormTouched, isInitialized]);

	useEffect(() => {
		service.init(studentID).subscribe(() => {
			setInitialized(true);
		});
	}, [service, studentID]);

	return (
		<>
			<Drawer.Header
				Icon={icon}
				sectionName={sectionName}
				withActionButton
				closeDrawer={onDrawerClose}
				actionButtonDisabled={!isFormTouched || isBusy}
				actionButtonText='Save'
				onActionButtonClick={handleSaveStudent}
			>
				{profileMode === StudentProfileMode.edit && (
					<MoreOptions
						isInitialized={isInitialized}
						onDeleteStudent={onOpenRemoveStudent}
					/>
				)}
			</Drawer.Header>
			<DrawerBody>
				<FlexBox direction='column'>
					<StudentDetails
						school={school}
						studentId={studentID}
						imageURL={photo?.imageCropUrl}
						fullName={`${initData?.profile?.firstName} ${initData?.profile?.lastName}`}
						isAddMode={profileMode === StudentProfileMode.add}
						onDeleteImage={() => setDeletePhotoAlertOpen(true)}
						onUploadPhotoOpen={() => setUploadPhotoAlertOpen(true)}
						isInitialized={isInitialized}
					/>
					<TabsController
						selectedTab={selectedTab}
						isDisabled={!isInitialized}
						onChange={handelChangeCurrentTab}
						isInitialized={isInitialized}
						invalidTabIDs={invalidTabIDs}
					/>
				</FlexBox>

				{selectedTab === StudentProfileTab.Profile && (
					<Profile
						initDictionaryData={initDictionaryData}
						form={profileForm}
						canViewExportID={canViewExportID}
						isInitialized={isInitialized}
					/>
				)}

				{selectedTab === StudentProfileTab.Location && (
					<Location service={service} form={locationForm} onOpenTransfer={onOpenTransferStudent} />
				)}

				{selectedTab === StudentProfileTab.Information && (
					<Information initDictionaryData={initDictionaryData} form={informationForm} />
				)}

				<RemoveStudent managerRef={removeStudentManager} onRemoved={() => {
					setInitialized(false);
					onDrawerForceClose();
				}}/>

				{isUploadPhotoAlertOpen && (
					<UploadPhotoAlert
						portalProps={{container: modalsOwner.current}}
						onClose={() => setUploadPhotoAlertOpen(false)}
						imageData={photo}
						onSave={handleSaveStudentImage}
					/>
				)}

				{isDeletePhotoAlertOpen && (
					<DeletePhotoAlert
						portalProps={{container: modalsOwner.current}}
						onDelete={handleDeleteStudentImage}
						onCancel={() => setDeletePhotoAlertOpen(false)}
					/>
				)}

				{duplicateError?.length && (
					<SaveAnywayAlert
						portalProps={{container: modalsOwner.current}}
						onSaveAnyway={() => saveStudentData(true)}
						onCancel={() => service.duplicateError.next(null)}
						name={`${service.profile.value.firstName} ${service.profile.value.lastName}`}
					/>
				)}

				{showEmptyClassError && (
					<SaveWithoutClassesAlert
						onCancel={cancelWithoutClassesModal}
						onSave={saveWithoutClasses}
					/>
				)}
			</DrawerBody>
		</>
	);
}
