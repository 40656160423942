import React, {Suspense} from 'react';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {lazyComponent} from '@esgi/core/react';

const BingoModal = lazyComponent(() => import('shared/modules/bingo/bingo'));

type Props = {
	hierarchy: HierarchyInstance;
	subjectID: number;
	onClose: VoidFunction;
};

export function BingoLauncher({subjectID, hierarchy, onClose}: Props) {
	return (
		<Suspense fallback={<></>}>
			<BingoModal selectedSubjectTabID={subjectID} hierarchy={hierarchy.snapshot} onClose={onClose} />
		</Suspense>
	);
}
