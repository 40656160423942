import {BaseComponentProps} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {ComponentProps} from 'react';
import {useReportsDialogContext} from '../../../context';
import {TabsTitle} from './index.styled';

type Props = BaseComponentProps & ComponentProps<typeof Text>;

export function Title({dataCy = 'tabs-title', css = {}, ...textProps}: Props) {
	const {skeleton} = useReportsDialogContext();

	return <TabsTitle data-cy={dataCy} css={css} skeleton={skeleton} {...textProps} />;
}
