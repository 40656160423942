import {useMemo} from 'react';
import {ColDef} from 'ag-grid-community';

import {CellType, EntitiesFilter, entitiesFilterParams, NumberFilter, numberFilterParams} from '@esgi/ui/ag-grid';

import {RowDef} from './types';
import {School, User, Group} from '@esgi/main/libs/admin-store';
import {isNull} from 'underscore';

export function useColumnDefinitions(
	rows: RowDef[],
	schools: (School | null)[],
	teachers: (User | null)[],
	groups: Group[],
) {
	return useMemo<ColDef<RowDef>[]>(
		() => [
			{
				field: 'name',
				headerName: 'Class',
				lockVisible: true,
				headerComponentParams: {
					description: 'Displays the name of a class (Required)',
				},
				filter: false,
				sortable: true,
				type: CellType.Primary,
				sort: 'asc',
			},
			{
				field: 'school',
				headerName: 'School',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(schools.filter((item) => !isNull(item))),
				headerComponentParams: {
					description: `Displays a school's name`,
				},
				sortable: true,
			},
			{
				field: 'teacher',
				headerName: 'Teacher',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(teachers.filter((item) => !isNull(item))),
				headerComponentParams: {
					description: 'Displays the name of a teacher',
				},
				sortable: true,
			},
			{
				field: 'groups',
				headerName: 'Groups',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(groups),
				headerComponentParams: {
					description: 'Displays the name of any groups associated with a class',
				},
				sortable: true,
			},
			{
				field: 'students',
				headerName: 'Students',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows, 'students'),
				headerComponentParams: {
					description: 'Displays the number of students associated with a class',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
		],
		[rows, schools, teachers, groups],
	);
}
