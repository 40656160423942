import {useCallback, useEffect} from 'react';
import {DatePickerData, formValueValidation as datePickerFormValueValidation} from '@esgi/main/kits/admin';
import {ElementStatus, FormControl, FormGroup, useForm} from '@esgi/ui/form';
import {
	useInitialCommonForm,
	useInitialClassesForm,
	useInitialStudentsSinglePeriodForm,
	useInitialStudentsTwoPeriodsForm,
} from './use-initial-form-data';
import {
	CommonForm,
	DistrictCutoffItem,
	StudentsTwoPeriodsForm,
	StudentsTwoPeriodsFormColorItem,
	DatePickerSingleForm,
} from './types';
import {setPeriodsValueForStudentsTwoPeriodsForm} from './helpers/set-periods-value-for-students-two-periods-form';
import {SchoolStudentsNeedingSupportWidgetViewType} from '../../../../../types/students-needing-support';
import {setFormControlsValidStatus} from './helpers/set-form-controls-valid-status';
import {AdaptedWidgetOptions, PeriodsVariant} from '../types';
import {datePickerDataForInitialization} from './constants';

export function useFormsInit({widgetOptions}: {widgetOptions: AdaptedWidgetOptions}) {
	const initialCommonForm = useInitialCommonForm({widgetOptions});

	const initialStudentsSinglePeriodForm = useInitialStudentsSinglePeriodForm({widgetOptions});
	const initialStudentsTwoPeriodsForm = useInitialStudentsTwoPeriodsForm({widgetOptions});
	const initialClassesForm = useInitialClassesForm({widgetOptions});

	const commonForm: CommonForm = useForm(
		() =>
			new FormGroup({
				viewType: new FormControl(widgetOptions.viewType),
				periodsVariant: new FormControl<PeriodsVariant>(PeriodsVariant.None, {
					initialValidateStatus:
						widgetOptions.viewType === SchoolStudentsNeedingSupportWidgetViewType.Classes
							? ElementStatus.disabled
							: ElementStatus.valid,
				}),
				districtCutoff: new FormControl<DistrictCutoffItem>([widgetOptions.districtCutoff]),
			}),
	);

	const studentsSinglePeriodForm: DatePickerSingleForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization, {
					validators: [datePickerFormValueValidation()],
				}),
			}),
	);

	const studentsTwoPeriodsForm: StudentsTwoPeriodsForm = useForm(
		() =>
			new FormGroup({
				firstPeriodColor: new FormControl<StudentsTwoPeriodsFormColorItem>([null]),
				firstPeriodDate: new FormControl<DatePickerData>(datePickerDataForInitialization, {
					validators: [datePickerFormValueValidation()],
				}),
				secondPeriodColor: new FormControl<StudentsTwoPeriodsFormColorItem>([null]),
				secondPeriodDate: new FormControl<DatePickerData>(datePickerDataForInitialization, {
					validators: [datePickerFormValueValidation()],
				}),
			}),
	);

	const classesForm: DatePickerSingleForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization, {
					validators: [datePickerFormValueValidation()],
				}),
			}),
	);

	const setStudentsSinglePeriodFormInitialData = useCallback(() => {
		setFormControlsValidStatus(studentsSinglePeriodForm);

		const {date} = studentsSinglePeriodForm.controls;

		date.value = initialStudentsSinglePeriodForm;
	}, [initialStudentsSinglePeriodForm, studentsSinglePeriodForm]);

	const setStudentsTwoPeriodsFormInitialData = useCallback(() => {
		setFormControlsValidStatus(studentsTwoPeriodsForm);

		const {firstPeriodColor, firstPeriodDate, secondPeriodColor, secondPeriodDate} = studentsTwoPeriodsForm.controls;
		const {firstPeriod, secondPeriod} = initialStudentsTwoPeriodsForm;

		setPeriodsValueForStudentsTwoPeriodsForm({
			periodValue: firstPeriod,
			colorControl: firstPeriodColor,
			dateRangeControl: firstPeriodDate,
			defaultColorItemsIndex: 0,
		});

		setPeriodsValueForStudentsTwoPeriodsForm({
			periodValue: secondPeriod,
			colorControl: secondPeriodColor,
			dateRangeControl: secondPeriodDate,
			defaultColorItemsIndex: 1,
		});
	}, [initialStudentsTwoPeriodsForm, studentsTwoPeriodsForm]);

	const setClassesFormInitialData = useCallback(() => {
		setFormControlsValidStatus(classesForm);

		const {date} = classesForm.controls;

		date.value = initialClassesForm;
	}, [initialClassesForm, classesForm]);

	useEffect(() => {
		const {periodsVariant} = commonForm.controls;

		periodsVariant.value = initialCommonForm.periodsVariant;
	}, [initialCommonForm]);

	useEffect(setStudentsSinglePeriodFormInitialData, [initialStudentsSinglePeriodForm]);
	useEffect(setStudentsTwoPeriodsFormInitialData, [initialStudentsTwoPeriodsForm]);
	useEffect(setClassesFormInitialData, [initialClassesForm]);

	return {
		commonForm,
		studentsSinglePeriodForm,
		studentsTwoPeriodsForm,
		classesForm,
		initialCommonForm,
		initialStudentsTwoPeriodsForm,
		setStudentsSinglePeriodFormInitialData,
		setStudentsTwoPeriodsFormInitialData,
		setClassesFormInitialData,
	};
}
