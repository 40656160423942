import {useMemo} from 'react';
import {isNull, unique} from 'underscore';

import {useSchools, useTeachers, useGroups, useClasses} from '@esgi/main/libs/admin-store';
import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {V2DistrictAdminsPagesDataAnalyticsController} from '@esgi/contracts/esgi';

import {RowDef} from './types';
import {isUndefined} from '@esgi/ui';

export function useData() {
	const [{data: allSchools}] = useSchools();
	const [{data: allTeachers}] = useTeachers();
	const [{data: allGroups}] = useGroups();
	const [{data: classes, loaded: classesLoaded}] = useClasses();

	const analyticController = useDisposable(V2DistrictAdminsPagesDataAnalyticsController);
	const analyticsData = useEndpointResult(() => {
		if (classesLoaded) {
			return analyticController.classes({classIDs: classes.map((c) => c.id)});
		}
	}, [classes, classesLoaded]);

	const {rows, schools, groups, teachers} = useMemo(() => {
		const rows = classes
			.map((classEntity) => {
				const teacher = allTeachers.find((t) => t.id === classEntity.teacherID);
				const school = allSchools.find((s) => s.id === classEntity.schoolID);
				const groups = allGroups.filter((g) => g.classID === classEntity.id) || [];
				const analytic = analyticsData?.value?.classAnalytics?.find((a) => a.classID === classEntity.id);

				if (isUndefined(teacher) || isUndefined(school)) {
					return null;
				}

				const rowDef: RowDef = {
					id: classEntity.id,
					name: classEntity.name,
					school,
					teacher,
					groups,
					students: analytic?.studentsCount ?? 0,
				};

				return rowDef;
			})
			.filter((item) => !isNull(item));
		return {
			rows,
			groups: unique(rows.map((r) => r.groups).flat()).filter(Boolean),
			schools: unique(rows.map((r) => r.school)).filter(Boolean),
			teachers: unique(rows.map((r) => r.teacher)).filter(Boolean),
		};
	}, [classes, allSchools, allGroups, allTeachers, analyticsData]);

	return {
		ready: classesLoaded && !!analyticsData,
		rows,
		schools,
		teachers,
		groups,
	};
}
