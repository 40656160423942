import {TestResultsStudent} from '@esgi/main/kits/admin';
import {useSpecialistGroups, useStudents} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {UserType} from '@esgi/core/authentication';
import {mapToEnum} from 'shared/utils';
import {RowDef} from '../types';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useTestResultsSubject} from '../../_kit';
import {Folder} from '@esgi/main/features/admins/data';
import {isUndefined} from '@esgi/ui';

type Parameters = {
	selectedRow: RowDef;
	activeFolder: Folder | null;
};

export function useSelectedSpecialistData({selectedRow, activeFolder}: Parameters) {
	const [{data: specialistGroup}] = useSpecialistGroups();
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const specialistType = selectedRow.type.id;
	const isPreAssess = specialistType === UserType.PA;
	const isSpecialist = specialistType === UserType.ISS || specialistType === UserType.ISD;

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({specialistsIDs}) => specialistsIDs.includes(selectedRow.id))
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {
		isReady: isTestResultsSubjectReady,
		subjects: testResultsSubjects,
		reportSubject,
	} = useTestResultsSubject({
		userID: selectedRow.id,
		schoolID: selectedRow.schools[0]?.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter:
			mapToEnum(selectedRow.type.id, UserType) === UserType.PA ? null : 'userIDAsSpecialists',
	});

	const firstGroup = useMemo(
		() => specialistGroup.find((s) => s.specialistID === selectedRow.id),
		[selectedRow, specialistGroup],
	);

	const {hierarchy, activitiesHierarchy} = useMemo<Record<'hierarchy' | 'activitiesHierarchy', Hierarchy>>(() => {
		if (isPreAssess) {
			const {preAssessID, ...restHierarchy}: Hierarchy = {
				preAssessID: selectedRow.id,
				preAssessGroupID: firstGroup?.id ?? 0,
			};

			return {
				hierarchy: {
					preAssessID,
					...restHierarchy,
				},
				activitiesHierarchy: {
					preAssessID,
				},
			};
		}

		if (isSpecialist) {
			const {specialistsID, ...restHierarchy}: Hierarchy = {
				schoolID: selectedRow.schools[0]?.id ?? 0,
				specialistsID: selectedRow.id,
				...(!isUndefined(firstGroup) && {
					specialistGroup: {
						id: firstGroup.id,
						name: firstGroup.name,
						specialistID: firstGroup.specialistID,
						type: specialistType,
					},
				}),
				specialistsFolderID: activeFolder?.id ?? 0,
				specialistType,
			};

			return {
				hierarchy: {
					specialistsID,
					...restHierarchy,
				},
				activitiesHierarchy: {
					specialistsID,
				},
			};
		}

		return {
			activitiesHierarchy: {},
			hierarchy: {},
		};
	}, [activeFolder, firstGroup, isPreAssess, isSpecialist, selectedRow, specialistType]);

	return {
		subjects: testResultsSubjects,
		students: filteredStudents,
		skeleton: !isTestResultsSubjectReady || !isStudentsLoaded,
		hierarchy,
		activitiesHierarchy,
		reportSubject,
	};
}
