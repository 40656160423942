import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {ScreenSize} from '@esgi/main/kits/common';

export const sectionContainerInlinePadding = 24;

export const Container = styled(Box, {
	width: '100%',
	height: '100%',
	paddingTop: 16,
	paddingLeft: sectionContainerInlinePadding,
	paddingRight: sectionContainerInlinePadding,
	backgroundColor: '$border',
	backgroundImage: 'linear-gradient(126deg, #F0F0F0 0.11%, #EBEBEB 71.15%)',
	variants: {
		screenSize: {
			[ScreenSize.Small]: {
				paddingLeft: 20,
				paddingRight: 20,
			},
			[ScreenSize.Tablet]: {
				paddingLeft: 12,
				paddingRight: 12,
			},
			[ScreenSize.PortraitTablet]: {
				paddingLeft: 12,
				paddingRight: 12,
			},
		},
	},
});
