import {Dispatch, useCallback, useState} from 'react';
import {AddEntitiesIntoFolder, FolderType} from '@esgi/main/features/school-admin/data';
import {useDataPageContext} from '../../../../layout/context';
import {RowDef} from '../../../types';
import {BottomPanel} from './bottom-panel';
import {addButtonDisabledTooltipMessage} from '../../constants';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';
import {SchoolReportPanel} from '../../../../_kit/school-reports-panel';
import {useData} from '../../../use-data';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {Page} from '@esgi/main/features/admins/data';

type Props = {
	userCanAddAndDeleteTeachers: boolean;
	editButtonDisabled: boolean;
	onOpenAddDrawer: VoidFunction;
	onEditClick: Dispatch<RowDef>;
};

export function Content({userCanAddAndDeleteTeachers, editButtonDisabled, onOpenAddDrawer, onEditClick}: Props) {
	const {entityFolders} = useDataPageContext();
	const {selectedItems, api} = Page.useContext<RowDef>();
	const {schoolName} = useData();
	const [{data: students}] = useStudents();

	const [showAddEntitiesIntoFolder, setShowAddEntitiesIntoFolder] = useState(false);

	const openAddEntitiesIntoFolder = useCallback(() => {
		setShowAddEntitiesIntoFolder(true);
	}, []);

	const closeAddEntitiesIntoFolder = useCallback(() => {
		setShowAddEntitiesIntoFolder(false);
	}, []);

	return (
		<>
			<Page.Header.Base
				onAddButtonClick={onOpenAddDrawer}
				addButtonDisabled={!userCanAddAndDeleteTeachers}
				addButtonDisabledTooltipMessage={addButtonDisabledTooltipMessage}
			/>
			<Page.Body.Base />

			<SingleItemExpandablePanel
				editButtonDisabled={editButtonDisabled}
				onFolderAddClick={openAddEntitiesIntoFolder}
				onEditClick={onEditClick}
				userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
				gridApi={api}
			/>
			<SchoolReportPanel api={api} schoolName={schoolName ?? ''} />

			<BottomPanel onFolderAddClick={openAddEntitiesIntoFolder} />

			{showAddEntitiesIntoFolder && (
				<AddEntitiesIntoFolder
					addEntityIDs={selectedItems.map(({id}) => id)}
					folders={(entityFolders ?? []).map(({id, name}) => ({id, name}))}
					initialFolderID={null}
					onCloseAlert={closeAddEntitiesIntoFolder}
					folderType={FolderType.Teacher}
				/>
			)}
		</>
	);
}
