import {Form, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {FormElement} from '../../components/form-element';
import {Input} from '@esgi/ui/form-controls';
import {AlertBody} from './index.styled';
import {Dispatch, useEffect, useState} from 'react';
import {useStreamEffect} from '@esgi/ui';
import {AlertFooter} from '../../components/alert-footer';
import {Folder} from '../../types';
import {nameFolderErrors} from './constants';
import {ErrorTooltip} from '../../components/error-tooltip';
import {FolderManagement, FolderType} from '../../../kit';

type Props = {
	folderType: FolderType;
	folders: Folder[];
	onFormTouchChanged: Dispatch<boolean>;
	onAlertClose: VoidFunction;
	onCheckFormCloseAlert: VoidFunction;
	addEntityIDs: number[];
};

export function NewFolder({
	folders,
	onFormTouchChanged,
	onAlertClose,
	onCheckFormCloseAlert,
	addEntityIDs,
	folderType,
}: Props) {
	const [isFolderCreating, setIsFolderCreating] = useState(false);
	const createFolder = FolderManagement.useCreateFolder({type: folderType, onFolderCreated: onAlertClose});

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);

	useEffect(() => {
		onFormTouchChanged(isFormTouched);
	}, [isFormTouched]);

	const form = useForm(
		() =>
			new FormGroup({
				name: new FormControl('', {
					validators: [
						Validators.required(),
						Validators.length.max(FolderManagement.folderNameMaxLength),
						Validators.isDublicateValue(folders.map(({name}) => name)),
					],
				}),
			}),
	);

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {name},
			},
		}) => {
			const isFormTouched = name !== '';

			setIsFormTouched(isFormTouched);

			form.validate(true).subscribe((validators) => {
				setIsFormValid(!validators.length);
			});
		},
	);

	const isAddDisable = !isFormTouched || !isFormValid || isFolderCreating;

	const onFolderCreate = () => {
		if (isAddDisable) {
			return;
		}

		setIsFolderCreating(true);

		createFolder({
			entityIDs: addEntityIDs,
			name: form.value.name,
		});
	};

	return (
		<>
			<AlertBody>
				<Form controller={form}>
					<FormElement control={form.controls.name}>
						<Input.Base placeholder='Folder Name' dataCy='folder-name' />

						{nameFolderErrors.map(({showOnError, message}) => (
							<ErrorTooltip showOnError={showOnError} message={message} key={showOnError} />
						))}
					</FormElement>
				</Form>
			</AlertBody>

			<AlertFooter isAddDisable={isAddDisable} onCancel={onCheckFormCloseAlert} onAddClick={onFolderCreate} />
		</>
	);
}
