import React, {Suspense} from 'react';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {lazyComponent} from '@esgi/core/react';

const WritingPractice = lazyComponent(() => import('modules/reports/writing-practice/writing-practice'));

type Props = {
	hierarchy: HierarchyInstance;
	onClose: VoidFunction;
};

export function WritingPracticeLauncher({hierarchy, onClose}: Props) {
	return (
		<Suspense fallback={<></>}>
			<WritingPractice hierarchy={hierarchy} onCloseClicked={onClose} />
		</Suspense>
	);
}
