import {SinglePeriod} from './variant/single-period';
import {ViewTableStudentsOnePeriodInfo, ViewTableStudentsTwoPeriodsInfo, ViewTableType} from '../../../../types';
import {TwoPeriods} from './variant/two-periods';
import {tableRowHeight, tableRowsGap} from '../table.styled';
import {WidgetCard, WidgetPeriod} from '@esgi/main/features/admins/dashboard';
import {useCallback, useState} from 'react';
import {AddEditStudent, TransferStudent} from '@esgi/main/features/district-admin/student-profile-drawer';
import {isNull} from 'underscore';
import {Portal} from '@esgi/ui';

type Props = {
	tableInfo: ViewTableStudentsOnePeriodInfo | ViewTableStudentsTwoPeriodsInfo;
	periods: WidgetPeriod[];
	rootPageContainer: Element | DocumentFragment | null;
};

export function StudentsTable({tableInfo, periods, rootPageContainer}: Props) {
	const {visibleData, widgetDataPageSize} = WidgetCard.useWidgetCardContext();

	const [editStudentID, setEditStudentID] = useState<number | null>(null);
	const [transferStudentID, setTransferStudentID] = useState<number | null>(null);

	const handleOpenTransferStudent = useCallback(() => {
		if (!editStudentID) {
			return;
		}

		setEditStudentID(null);
		setTransferStudentID(editStudentID);
	}, [editStudentID]);

	const notVisibleItems = widgetDataPageSize - visibleData.length;
	const emptyBoxHeight = tableRowHeight * notVisibleItems + tableRowsGap * (notVisibleItems - 1);

	const showEmptyContentBox = widgetDataPageSize !== visibleData.length;

	return (
		<>
			{tableInfo.type === ViewTableType.StudentsOnePeriod && (
				<SinglePeriod
					setSortableState={tableInfo.setSortableState}
					sortableState={tableInfo.sortableState}
					disabledSortableKeys={tableInfo.disabledSortableKeys}
					showEmptyContentBox={showEmptyContentBox}
					emptyBoxHeight={emptyBoxHeight}
					onStudentClick={setEditStudentID}
				/>
			)}

			{tableInfo.type === ViewTableType.StudentsTwoPeriods && (
				<TwoPeriods
					setSortableState={tableInfo.setSortableState}
					sortableState={tableInfo.sortableState}
					disabledSortableKeys={tableInfo.disabledSortableKeys}
					periods={periods}
					showEmptyContentBox={showEmptyContentBox}
					emptyBoxHeight={emptyBoxHeight}
					onStudentClick={setEditStudentID}
				/>
			)}

			{!isNull(editStudentID) && (
				<Portal container={rootPageContainer}>
					<AddEditStudent
						studentID={String(editStudentID)}
						onOpenTransferStudent={handleOpenTransferStudent}
						onClose={() => setEditStudentID(null)}
					/>
				</Portal>
			)}

			{!isNull(transferStudentID) && (
				<Portal container={rootPageContainer}>
					<TransferStudent
						studentID={transferStudentID}
						onClose={() => setTransferStudentID(null)}
						onTransfered={() => setEditStudentID(transferStudentID)}
					/>
				</Portal>
			)}
		</>
	);
}
