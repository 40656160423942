import {Dispatch, createContext, useContext} from 'react';
import {isNull} from 'underscore';
import {TabInfo, UpdateTab} from './types';

export type TabsContextValue<Tab extends string> = {
	activeTab: Tab;
	setActiveTab: Dispatch<Tab>;
	availableTabs: TabInfo<Tab>[];
	addTab: Dispatch<TabInfo<Tab>>;
	removeTab: Dispatch<Tab>;
	updateTab: UpdateTab<Tab>;
};

export const TabsContext = createContext<TabsContextValue<any> | null>(null);

export function useTabsContext<Tab extends string>() {
	const context = useContext(TabsContext);

	if (isNull(context)) {
		throw new Error('useTabsContext is null');
	}

	return context as TabsContextValue<Tab>;
}
