import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../../types';
import {getFullName} from '@esgi/main/kits/common';
import {ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';

type Props = {
	student: RowDef;
	onClose: VoidFunction;
	hierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function ReportsFeature({student, onClose, hierarchy, subject}: Props) {
	return (
		<ReportDialog
			onClose={onClose}
			header={getFullName({
				firstName: student.firstName,
				lastName: student.lastName,
			})}
			subject={subject}
		>
			<ReportDialog.Section title='Student Reports'>
				<Reports.ParentLetter hierarchy={hierarchy} />
				<Reports.StudentDetail hierarchy={hierarchy} />
				<Reports.StudentProgress hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
