import {StudentSort} from '@esgi/core/enums';

export class StudentSorter {
	private static thenBySort(sort: StudentSort) {
		if (this.sortBy(sort) === 'lastName') {
			return 'firstName';
		}

		return 'lastName';
	}

	private static sortBy(sort: StudentSort) {
		return sort === StudentSort.LastName ? 'lastName' : 'firstName';
	}

	public static sortStudents<T>(
		students: T[],
		sort: StudentSort) {
		function sortBy(sortBy: string, thenBy) {
			return (a: T, b: T) => {
				const aSortBy = removeDiacritics(a[sortBy]).toLowerCase();
				const bSortBy = removeDiacritics(b[sortBy]).toLowerCase();
				if (aSortBy < bSortBy) {
					return -1;
				}

				if (aSortBy > bSortBy) {
					return 1;
				}

				const aThenBy = removeDiacritics(a[thenBy]).toLowerCase();
				const bThenBy = removeDiacritics(b[thenBy]).toLowerCase();
				if (aThenBy < bThenBy) {
					return -1;
				}

				if (aThenBy > bThenBy) {
					return 1;
				}

				return 0;
			};
		}

		const sortFunction = sortBy(this.sortBy(sort), this.thenBySort(sort));

		return students.sort(sortFunction);
	}
}

function removeDiacritics(str) {
	str = str.normalize('NFD');
	return str.replace(/[\u0300-\u036f]/g, '');
}