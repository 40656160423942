import {Folder} from '@esgi/main/features/school-admin/data';
import {RowDef} from '../../../../../types';
import {GridApi} from 'ag-grid-community';
import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useUser} from '@esgi/core/authentication';
import {QuickPanel} from './quick-panel';
import {TestResults} from '@esgi/main/kits/admin';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {useSelectedTeacherData} from '../../../../use-selected-teacher-data';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	activeFolder: Folder;
	onDeselectFolder: VoidFunction;
	userCanAddAndDeleteTeachers: boolean;
	isPanelExpanded: boolean;
	gridApi: GridApi<Readonly<RowDef>> | null;
};

export function ContentWrapper({
	activeFolder,
	gridApi,
	isPanelExpanded,
	onDeselectFolder,
	onFolderAddClick,
	onRemoveFromFolderClick,
	selectedRow,
	userCanAddAndDeleteTeachers,
}: Props) {
	const user = useUser();

	const {isSelectedTeacherDataReady, students, subjects, testResultsAdditionalInfo, reportSubject} =
		useSelectedTeacherData({selectedRow});

	const teacherLevelHierarchy = useMemo<Hierarchy>(() => {
		const schoolID = user?.schoolID ?? 0;

		const h: Hierarchy = {
			schoolID,
			teacher: {
				id: selectedRow.id,
				firstName: selectedRow.firstName,
				lastName: selectedRow.lastName,
				schoolID,
			},
			teachersFolderID: activeFolder.id,
		};

		return h;
	}, [user, selectedRow, activeFolder]);

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				gridApi={gridApi}
				userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
				activeFolder={activeFolder}
				onDeselectFolder={onDeselectFolder}
				onRemoveFromFolderClick={onRemoveFromFolderClick}
				reportSubject={reportSubject}
				skeleton={!isSelectedTeacherDataReady}
				teacherLevelHierarchy={teacherLevelHierarchy}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={!isSelectedTeacherDataReady}
					allSubjects={subjects}
					students={students}
					hierarchy={teacherLevelHierarchy}
					additionalInfo={testResultsAdditionalInfo}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
