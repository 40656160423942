import {RowDef} from '../../../../../types';
import {ReportDialog, Reports, ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {getFullName} from '@esgi/main/kits/common';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	teacher: RowDef;
	onClose: VoidFunction;
	hierarchy: Hierarchy;
	subject: ReportsSubjectInfo;
};

export function ReportsFeature({teacher, onClose, hierarchy, subject}: Props) {
	return (
		<ReportDialog
			header={getFullName({firstName: teacher.firstName, lastName: teacher.lastName})}
			onClose={onClose}
			subject={subject}
		>
			<ReportDialog.Section title='Class Reports'>
				<Reports.ItemAnalysis hierarchy={hierarchy} />
				<Reports.PieCharts hierarchy={hierarchy} />
				<Reports.UntestedStudents hierarchy={hierarchy} />
			</ReportDialog.Section>

			<ReportDialog.Section title='Student Activities'>
				<Reports.Bingo hierarchy={hierarchy} />
				<Reports.WritingPractice hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
