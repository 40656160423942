import {Folder} from '@esgi/main/features/admins/data';
import {RowDef} from '../../../../../types';
import {GridApi} from 'ag-grid-community';
import {QuickPanel} from './quick-panel';
import {TestResults} from '@esgi/main/kits/admin';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useSelectedTeacherData} from '../../../../use-selected-teacher-data';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	activeFolder: Folder;
	onDeselectFolder: VoidFunction;
	userCanAddAndDeleteTeachers: boolean;
	isPanelExpanded: boolean;
	gridApi: GridApi<Readonly<RowDef>> | null;
};

export function ContentWrapper({
	isPanelExpanded,
	activeFolder,
	gridApi,
	onDeselectFolder,
	onFolderAddClick,
	onRemoveFromFolderClick,
	selectedRow,
	userCanAddAndDeleteTeachers,
}: Props) {
	const {skeleton, students, subjects, testResultsAdditionalInfo, reportSubject} = useSelectedTeacherData({
		selectedRow,
	});

	const teacherLevelHierarchy = useMemo<Hierarchy>(() => {
		const h: Hierarchy = {
			schoolID: selectedRow.school.id,
			teacher: {
				id: selectedRow.id,
				firstName: selectedRow.firstName,
				lastName: selectedRow.lastName,
				schoolID: selectedRow.school.id,
			},
			teachersFolderID: activeFolder.id,
		};

		return h;
	}, [selectedRow, activeFolder]);

	return (
		<>
			<QuickPanel
				selectedRow={selectedRow}
				isPanelExpanded={isPanelExpanded}
				onFolderAddClick={onFolderAddClick}
				gridApi={gridApi}
				userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
				activeFolder={activeFolder}
				onDeselectFolder={onDeselectFolder}
				onRemoveFromFolderClick={onRemoveFromFolderClick}
				reportSubject={reportSubject}
				skeleton={skeleton}
				teacherLevelHierarchy={teacherLevelHierarchy}
			/>

			<SingleSelectedExpandablePanel.ExpandedPanel>
				<TestResults
					skeleton={skeleton}
					allSubjects={subjects}
					students={students}
					hierarchy={teacherLevelHierarchy}
					additionalInfo={testResultsAdditionalInfo}
					withoutVariantByEachStudent
				/>
			</SingleSelectedExpandablePanel.ExpandedPanel>
		</>
	);
}
