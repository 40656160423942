import {ComponentPropsWithoutRef, forwardRef, useCallback} from 'react';
import {ActionButtonsBox, AddButton, Header} from './index.styled';
import {Download, Plus} from '@esgi/ui/icons';
import {Button, isUndefined} from '@esgi/ui';
import {TooltipedButton} from './components/tooltiped-button';
import {usePageContentContext} from '../../../../context';
import {isNull} from 'underscore';
import moment from 'moment';
import {AgGrid} from '@esgi/ui/ag-grid';

type Props = Omit<ComponentPropsWithoutRef<typeof Header>, 'children'> & {
	onAddButtonClick?: VoidFunction;
	addButtonDisabled?: boolean;
	addButtonDisabledTooltipMessage?: string;
};

export const Base = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'data-page-header-base',
			css = {},
			onAddButtonClick,
			addButtonDisabled,
			addButtonDisabledTooltipMessage,
			...props
		},
		forwardedRef,
	) => {
		const {api, entityName} = usePageContentContext();

		const onBtnExport = useCallback(() => {
			if (isNull(api)) {
				return;
			}

			const currentDateTime = moment().format('D.M.YYYY hh.mm A');

			const columns = api.getColumns();
			const filteredColumns =
				columns?.filter((item) => {
					const colDef = item.getColDef();

					return !isUndefined(colDef.field);
				}) ?? null;

			api.exportDataAsCsv({
				allColumns: false,
				fileName: `${entityName} ${currentDateTime}.csv`,
				onlySelected: false,
				onlySelectedAllPages: false,
				skipColumnGroupHeaders: false,
				skipColumnHeaders: false,
				...(!isNull(filteredColumns) && {
					columnKeys: filteredColumns,
				}),
			});
		}, [api, entityName]);

		return (
			<Header dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				<AgGrid.SearchInput gridApi={api} css={{width: 400}} />

				<ActionButtonsBox>
					<TooltipedButton tooltipLabel='Download'>
						<Button.Icon onClick={onBtnExport}>
							<Download />
						</Button.Icon>
					</TooltipedButton>

					{!isUndefined(onAddButtonClick) && (
						<TooltipedButton
							tooltipLabel={
								addButtonDisabled && !isUndefined(addButtonDisabledTooltipMessage)
									? addButtonDisabledTooltipMessage
									: 'Add'
							}
						>
							<AddButton color='secondary' disabled={addButtonDisabled} onClick={onAddButtonClick} dataCy='add-button'>
								<Plus />
							</AddButton>
						</TooltipedButton>
					)}
				</ActionButtonsBox>
			</Header>
		);
	},
);
