import {Container} from './index.styled';
import {BaseComponentProps, isUndefined} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {OneLinedText, TestColorLineIndicator, TestContentArea, TestType, TestTypeLabel} from '@esgi/main/kits/common';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof Container>, 'skeleton' | 'children'> & {
		testName: string;
		testType: TestType;
		testColor: string;
		testContentArea: TestContentArea;
	};

export const TestInfoLine = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'tests-panel-test-info-line', css = {}, testName, testType, testColor, testContentArea, ...props},
		forwardedRef,
	) => {
		return (
			<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props} clicable={!isUndefined(props.onClick)}>
				<TestColorLineIndicator color={testColor} contentArea={testContentArea} />
				<OneLinedText size='medium' bold color='currentColor' dataCy='test-name'>
					{testName}
				</OneLinedText>
				<TestTypeLabel testType={testType} />
			</Container>
		);
	},
);
