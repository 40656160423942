import {Suspense, useEffect, useState} from 'react';
import {HierarchySnapshot} from 'modules/hierarchy/core/models';
import {SubjectType} from '@esgi/core/enums';
import {Models} from 'shared/modules/reports/flash-cards/settings-form/core/models';
import {DataService} from 'shared/modules/reports/flash-cards/settings-form/services/data-service';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';

const FlashCardsSettings = lazyComponent(() => import('shared/modules/reports/flash-cards/settings-form/component'));

type FlashCardsWrapperProps = {
	hierarchySnapshot: HierarchySnapshot,
	changesCollector: ChangesCollector,
	onCloseClicked: () => void;
	parentLetterOnClosed: (emit: boolean, changesCollector: ChangesCollector) => any;
	subjectID: number;
	subjectType: SubjectType;
	initModel?: Models.InitModel;
};

export function FlashCardsWrapper(props: FlashCardsWrapperProps): JSX.Element {
	const [service] = useState<DataService>(() => {
		const service = new DataService(props.hierarchySnapshot);
		const selectedItems = new Models.InitModel();
		selectedItems.subjectID = props.subjectID;
		selectedItems.subjectType = props.subjectType;
		service.settingsService.setSelectedItems(props.initModel || selectedItems);
		return service;
	});

	useEffect(() => {
		return () => service?.destroy();
	}, []);

	function load() {
		SsoTracker.trackEvent({
			trackingEvent: 'FlashCardReport',
		});
	}

	useEffect(() => load(), [props.initModel]);

	return <Suspense fallback={<></>}>
		<FlashCardsSettings
			hierarchy={props.hierarchySnapshot}
			dataService={service}
			onCloseClicked={props.onCloseClicked}
			parentLetterOnClosed={props.parentLetterOnClosed}
			changesCollector={props.changesCollector}
		/>
	</Suspense>;
}
