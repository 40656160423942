import {Button, ContextMenu as ContextMenuUI} from '@esgi/ui';
import {Delete, More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useCallback, useState} from 'react';
import {Group} from '@esgi/main/libs/school-admin-store';
import {RemoveTeacherGroup} from '@esgi/main/features/school-admin/common';

type Props = {
	closeDrawer: VoidFunction;
	currentGroup: Group;
};

export function ContextMenu({closeDrawer, currentGroup}: Props) {
	const removeTeacherGroupManager = RemoveTeacherGroup.useManagerRef();

	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

	const openDeleteClassAlert = useCallback(() => {
		removeTeacherGroupManager.current?.runRemove({
			teacherGroupID: currentGroup.id,
		});

		setIsContextMenuOpen(false);
	}, [currentGroup, removeTeacherGroupManager]);

	return (
		<>
			<ContextMenuUI.Root open={isContextMenuOpen} onOpenChange={setIsContextMenuOpen}>
				<ContextMenuUI.Trigger>
					<Button.Icon withBackgroundHover>
						<More />
					</Button.Icon>
				</ContextMenuUI.Trigger>
				<ContextMenuUI.Content>
					<ContextMenuUI.Group>
						<ContextMenuUI.Item onClick={openDeleteClassAlert}>
							<Delete />
							<Text size='medium'>Delete</Text>
						</ContextMenuUI.Item>
					</ContextMenuUI.Group>
				</ContextMenuUI.Content>
			</ContextMenuUI.Root>

			<RemoveTeacherGroup managerRef={removeTeacherGroupManager} onRemoved={closeDrawer} />
		</>
	);
}
