import {ThemeProvider} from '@esgi/ui/theme';
import {TooltipProvider} from '@esgi/ui/tooltip';
import {ContentContainer, PageContainer} from './index.styled';
import {Outlet, useNavigate} from 'react-router-dom';
import {useMatchMedia} from '@esgi/ui';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useUser} from '@esgi/core/authentication';
import {ContactUs, useContactUs} from '@esgi/features/support';
import {isNull} from 'underscore';
import {ScreenSize, RootPageContext, NotificationAlert} from '@esgi/main/kits/common';
import {NavigationBarOrientation} from '@esgi/ui/layout';
import {NavigationPanel} from './components/navigation-panel';
import DistrictAdminDrawers from './drawers';
import {useInitAppSync} from './use-init-app-sync';
import {Provider} from 'react-redux';
import {ClearStorageEvent, reduxStore} from '@esgi/main/libs/admin-store';
import {dispatchAppEvent} from '@esgillc/events';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';

export default function PageWithNavigation() {
	const navigate = useNavigate();
	const currentUser = useUser();
	const {isVisible, handleClose} = useContactUs();

	const isPortraitTablet = useMatchMedia('portrait-tablet');
	const isLandscapeTablet = useMatchMedia('landscape-tablet');
	const isSmallLaptop = useMatchMedia('small-laptop');

	const rootPageContentContainerRef = useRef<HTMLDivElement>(null);

	const {isAppSyncInitialized} = useInitAppSync();

	useEffect(() => {
		if (isNull(currentUser)) {
			navigate('/login', {
				replace: true,
			});
		}
	}, [currentUser, navigate]);

	const {screenSize, navigationBarOrientation} = useMemo(() => {
		if (isPortraitTablet) {
			return {
				screenSize: ScreenSize.PortraitTablet,
				navigationBarOrientation: NavigationBarOrientation.Horizontal,
			};
		}

		if (isLandscapeTablet) {
			return {
				screenSize: ScreenSize.Tablet,
				navigationBarOrientation: NavigationBarOrientation.Horizontal,
			};
		}

		if (isSmallLaptop) {
			return {
				screenSize: ScreenSize.Small,
				navigationBarOrientation: NavigationBarOrientation.Vertical,
			};
		}

		return {
			screenSize: null,
			navigationBarOrientation: NavigationBarOrientation.Vertical,
		};
	}, [isLandscapeTablet, isPortraitTablet, isSmallLaptop]);

	const outletContext = useMemo<RootPageContext>(
		() => ({screenSize, rootPageContentContainerRef}),
		[screenSize, rootPageContentContainerRef],
	);

	const onSchoolYearChanged = useCallback(() => {
		dispatchAppEvent(ClearStorageEvent);
	}, []);

	return (
		<ThemeProvider>
			<TooltipProvider>
				<Provider store={reduxStore}>
					{currentUser && isAppSyncInitialized && (
						<>
							{isVisible && <ContactUs ticketSubmitted={handleClose} onClose={handleClose} />}
							<PageContainer navigationBarOrientation={navigationBarOrientation}>
								<NavigationPanel
									orientation={navigationBarOrientation}
									onSchoolYearChanged={onSchoolYearChanged}
									userFirstName={currentUser.firstName}
									userLastName={currentUser.lastName}
									userAvatarSrc={currentUser.avatarUrl}
								/>

								<ContentContainer ref={rootPageContentContainerRef} key={currentUser.globalSchoolYearID}>
									<Outlet context={outletContext} />
									<DistrictAdminDrawers screenSize={screenSize} />
								</ContentContainer>
							</PageContainer>

							<NotificationAlert />
							<BackgroundDownloadManager />
						</>
					)}
				</Provider>
			</TooltipProvider>
		</ThemeProvider>
	);
}
