import * as React from 'react';
import type {SVGProps} from 'react';

export function File(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 32 32' {...props}>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M11.556 7C9.592 7 8 8.535 8 10.429V21.57C8 23.465 9.592 25 11.556 25h8.888C22.408 25 24 23.465 24 21.571V10.43C24 8.535 22.408 7 20.444 7h-8.888Zm0 1.714h8.888c.982 0 1.778.768 1.778 1.715V21.57c0 .947-.796 1.715-1.778 1.715h-8.888c-.982 0-1.778-.768-1.778-1.715V10.43c0-.947.796-1.715 1.778-1.715Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M13.335 11.286c-.49 0-.889.383-.889.857 0 .473.398.857.89.857h5.333c.49 0 .889-.384.889-.857 0-.474-.398-.857-.89-.857h-5.333Zm0 3.428c-.49 0-.889.384-.889.857 0 .474.398.858.89.858h5.333c.49 0 .889-.384.889-.858 0-.473-.398-.857-.89-.857h-5.333Zm0 3.429c-.49 0-.889.384-.889.857s.398.857.89.857h2.666c.49 0 .889-.384.889-.857s-.398-.857-.889-.857h-2.667Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
