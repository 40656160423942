import {PropsWithChildren} from 'react';
import {Body, Footer} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {D} from '@esgi/ui/icons';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = PropsWithChildren<{
	title: string;
	count: number;
	onClick: VoidFunction;
}>;

export function Card({title, count, onClick, children}: Props) {
	return (
		<Body onClick={onClick} data-cy='navigation-card'>
			<OneLinedText size='medium' bold color='currentColor' data-cy='navigation-card-title'>
				{title}
			</OneLinedText>

			{children}

			<Footer>
				<Text size='xxLarge' color='currentColor' data-cy='navigation-card-count'>
					{count.toLocaleString('en', {useGrouping: true})}
				</Text>
				<D />
			</Footer>
		</Body>
	);
}
