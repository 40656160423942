import {FolderAdd, FolderRemove, Teacher} from '@esgi/ui/icons';
import {ActionPanel, NegativeButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button} from '@esgi/ui';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {getFullName} from '@esgi/main/kits/common';
import {ReportsFeature} from '../../../../../../components/reports';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';
import {ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	isPanelExpanded: boolean;
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	activitiesHierarchy: Hierarchy;
	reportsHierarchy: Hierarchy;
	skeleton: boolean;
	reportSubject: ReportsSubjectInfo | null;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	onRemoveFromFolderClick,
	activitiesHierarchy,
	reportSubject,
	reportsHierarchy,
	skeleton,
}: Props) {
	return (
		<SingleSelectedExpandablePanel.QuickPanel>
			<SingleSelectedExpandablePanel.QuickPanel.Header>
				<HeaderContent>
					<ActionPanel.MainBox>
						<Teacher />
						<Text size='large' color='highContrast'>
							{getFullName({
								firstName: selectedRow.firstName,
								lastName: selectedRow.lastName,
							})}
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<Button.Icon withBackgroundHover onClick={onFolderAddClick}>
							<FolderAdd />
						</Button.Icon>

						<NegativeButton onClick={onRemoveFromFolderClick}>
							<FolderRemove />
						</NegativeButton>

						<RunReportButton subject={reportSubject} skeleton={skeleton}>
							{({onReportsClose, subject}) => (
								<ReportsFeature
									onClose={onReportsClose}
									specialist={selectedRow}
									activitiesHierarchy={activitiesHierarchy}
									reportsHierarchy={reportsHierarchy}
									subject={subject}
								/>
							)}
						</RunReportButton>
					</ActionPanel.ActionButtonsBox>
				</HeaderContent>
			</SingleSelectedExpandablePanel.QuickPanel.Header>

			{!isPanelExpanded && (
				<AdditionalHeaderContent>
					<OpenPanelButton canExpandPanel={!!selectedRow.students} />
				</AdditionalHeaderContent>
			)}
		</SingleSelectedExpandablePanel.QuickPanel>
	);
}
