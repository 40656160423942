import {useColumnDefinitions} from './use-column-definitions';
import {useData} from './use-data';
import {BaseContent} from './variant/base';
import {useCallback, useState} from 'react';
import {GroupDrawer} from '@esgi/main/features/district-admin/data';
import {useDataPageState, Page} from '@esgi/main/features/admins/data';
import {entityName} from '../specialists/variant/constants';

export default function () {
	const {ready, rows, schools, classes, teachers} = useData();

	const colDefs = useColumnDefinitions(rows, schools, teachers, classes);
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	const [isOpenAddGroupDrawer, setIsOpenAddGroupDrawer] = useState(false);

	const closeAddGroupDrawer = useCallback(() => {
		setIsOpenAddGroupDrawer(false);
	}, []);

	const openAddGroupDrawer = useCallback(() => {
		setIsOpenAddGroupDrawer(true);
	}, []);

	return (
		<Page.Root
			columnDefs={colDefs}
			entityName='Groups'
			tableRows={rows}
			isDataLoaded={ready && isInitialStateReady}
			initialState={initialState}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<BaseContent onOpenAddDrawer={openAddGroupDrawer} />
			{isOpenAddGroupDrawer && <GroupDrawer.Add onClose={closeAddGroupDrawer} />}
		</Page.Root>
	);
}
