import {useMemo, useRef, useState} from 'react';
import {PeriodTabID, TabID} from '../types';
import {SubjectPublishType, SubjectPublishValue, TrackDate} from '../../types';
import {getInitialTabID} from '../helpers/get-initial-tab-id';
import moment from 'moment';

type Parameters = {
	initialValue: SubjectPublishValue;
	trackID: number | null;
	allTrackDates: TrackDate[];
};

export function useDrawerData({initialValue, trackID, allTrackDates}: Parameters) {
	const {current: initialTabID} = useRef(getInitialTabID(initialValue));
	const {current: initialPeriodTabID} = useRef(
		initialValue.type === SubjectPublishType.SchoolYear ? PeriodTabID.SchoolYear : PeriodTabID.MarkingPeriod,
	);

	const [tabID, setTabID] = useState<TabID>(initialTabID);
	const [periodTabID, setPeriodTabID] = useState<PeriodTabID>(initialPeriodTabID);
	const [selectedTrackDatesIDs, setSelectedTrackDatesIDs] = useState(initialValue.trackDatesIDs);

	const [customDateRange, setCustomDateRange] = useState<Date[]>(() => {
		if (initialValue.dateFrom && initialValue.dateTo) {
			const dateFrom = moment(`${initialValue.dateFrom}Z`).toDate();
			const dateTo = moment(`${initialValue.dateTo}Z`).toDate();
			return [dateFrom, dateTo];
		}

		return [];
	});

	const filteredTrackDates = useMemo(
		() => allTrackDates.filter((item) => item.trackID === trackID),
		[allTrackDates, trackID],
	);

	const isDrawerValueValid = ((): boolean => {
		if (tabID === TabID.Indefinitely) {
			return true;
		}

		if (tabID === TabID.Period) {
			if (periodTabID === PeriodTabID.SchoolYear) {
				return true;
			}

			if (periodTabID === PeriodTabID.MarkingPeriod) {
				return !!selectedTrackDatesIDs.length;
			}
		}

		if (tabID === TabID.CustomDateRange) {
			return customDateRange.length === 2;
		}

		return false;
	})();

	const isDrawerValueChanged = ((): boolean => {
		if (tabID !== initialTabID) {
			return true;
		}

		if (tabID === TabID.Period) {
			if (periodTabID !== initialPeriodTabID) {
				return true;
			}

			if (periodTabID === PeriodTabID.MarkingPeriod) {
				return (
					initialValue.trackDatesIDs.length !== selectedTrackDatesIDs.length ||
					selectedTrackDatesIDs.some((id) => !initialValue.trackDatesIDs.includes(id))
				);
			}
		}

		if (tabID === TabID.CustomDateRange) {
			return customDateRange.length === 2;
		}

		return false;
	})();

	const isActionButtonActive = isDrawerValueValid && isDrawerValueChanged;

	return {
		tabID,
		setTabID,
		periodTabID,
		setPeriodTabID,
		selectedTrackDatesIDs,
		setSelectedTrackDatesIDs,
		customDateRange,
		setCustomDateRange,
		filteredTrackDates,
		isActionButtonActive,
	};
}
