import {TestResultsAdditionalEntity, TestResultsStudent} from '@esgi/main/kits/admin';
import {useStudents} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {RowDef} from '../types';
import {getFullName} from '@esgi/main/kits/common';
import {useTestResultsSubject} from '../../_kit';
import {ReportsSubjectInfo} from '@esgi/main/features/admins/data';
import {isUndefined} from 'underscore';

type Parameters = {
	selectedRow: RowDef;
};

export function useSelectedTeacherData({selectedRow}: Parameters) {
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({teacherID}) => selectedRow.id === teacherID)
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {
		isReady: isTestResultsSubjectReady,
		subjects: testResultsSubjects,
		reportSubject,
	} = useTestResultsSubject({
		userID: selectedRow.id,
		schoolID: selectedRow.school.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter: 'schools',
	});

	const testResultsAdditionalInfo = useMemo<TestResultsAdditionalEntity>(
		() => ({
			school: {
				id: selectedRow.school.id,
				name: selectedRow.school.name,
			},
			teacher: {
				id: selectedRow.id,
				name: getFullName({
					firstName: selectedRow.firstName,
					lastName: selectedRow.lastName,
				}),
			},
		}),
		[selectedRow],
	);

	return {
		subjects: testResultsSubjects,
		students: filteredStudents,
		skeleton: !isTestResultsSubjectReady || !isStudentsLoaded,
		testResultsAdditionalInfo,
		reportSubject,
	};
}
