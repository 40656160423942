import {ColDef} from 'ag-grid-community';
import {useMemo} from 'react';
import {RowDef} from './types';
import {CellType, EntitiesFilter, entitiesFilterParams, NumberFilter, numberFilterParams} from '@esgi/ui/ag-grid';
import {School} from '@esgi/main/libs/admin-store';
import {userTypeOptions} from './constants';
import {isUndefined} from '@esgi/ui';

export function useColumnDefinitions(rows: RowDef[], schools: School[]) {
	return useMemo<ColDef<RowDef>[]>(
		() => [
			{
				field: 'firstName',
				headerName: 'First Name',
				lockVisible: true,
				headerComponentParams: {
					description: 'Displays the first name of a District Specialist (Required)',
				},
				sortable: true,
				type: CellType.Primary,
			},
			{
				field: 'lastName',
				headerName: 'Last Name',
				lockVisible: true,
				headerComponentParams: {
					description: 'Displays the last name of a District Specialist (Required)',
				},
				sortable: true,
				type: CellType.Primary,
				sort: 'asc',
			},
			{
				field: 'type',
				headerName: 'Type',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(Object.values(userTypeOptions)),
				headerComponentParams: {
					description: 'Displays the type of a Specialist: School or District',
				},
				sortable: true,
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					return data.type.name;
				},
			},
			{
				field: 'userName',
				headerName: 'User Name',
				filter: false,
				headerComponentParams: {
					description: 'Displays the user name of a specialist',
				},
				sortable: true,
				sort: 'asc',
			},
			{
				field: 'email',
				headerName: 'Email',
				filter: false,
				headerComponentParams: {
					description: 'Displays the email of a specialist',
				},
				sortable: true,
				sort: 'asc',
			},
			{
				field: 'schools',
				headerName: 'Schools',
				filter: EntitiesFilter,
				filterParams: entitiesFilterParams(schools),
				headerComponentParams: {
					description: `Displays a school's name`,
				},
				sortable: true,
			},
			{
				field: 'specialistGroups',
				headerName: 'Specialist Groups',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows, 'specialistGroups'),
				headerComponentParams: {
					description: 'Displays the number of Specialist Groups associated with a District Specialist',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
			{
				field: 'students',
				headerName: 'Students',
				filter: NumberFilter,
				filterParams: numberFilterParams(rows, 'students'),
				headerComponentParams: {
					description: 'Displays the number of students associated with a District Specialist',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
		],
		[rows, schools],
	);
}
