import {Edit, PieChartIcon} from '@esgi/ui/icons';
import {ActionPanel, DeleteButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {RowDef} from '../../../../../../types';
import {Button, Portal, useFlag} from '@esgi/ui';
import {SpecialistGroupDrawer, useNavigateToStudentsPage} from '@esgi/main/features/school-admin/data';
import {AdditionalHeaderContent, AdditionalInfo, HeaderContent, InfoBox} from './index.styled';
import {useDataPageContext} from '../../../../../../../layout/context';
import {getFullName, OneLinedText} from '@esgi/main/kits/common';
import {TitleWithTranscription} from '../title-with-transcription';
import {ReportsFeature} from '../reports';
import {useCallback} from 'react';
import {ReportsSubjectInfo, RunReportButton} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RemoveSpecialistGroup} from '@esgi/main/features/school-admin/common';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	deselectedAllItems: VoidFunction;
	reportSubject: ReportsSubjectInfo | null;
	skeleton: boolean;
	hierarchy: Hierarchy;
};

export function QuickPanel({
	selectedRow,
	isPanelExpanded,
	deselectedAllItems,
	hierarchy,
	reportSubject,
	skeleton,
}: Props) {
	const removeSpecialistGroupManagerRef = RemoveSpecialistGroup.useManagerRef();
	const {rootPageContentContainerRef} = useDataPageContext();

	const [showSpecialistGroupDrawer, openSpecialistGroupDrawer, closeSpecialistGroupDrawer] = useFlag();

	const goToStudentsPage = useNavigateToStudentsPage();

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			specialistGroups: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	const runRemoveItem = useCallback(() => {
		removeSpecialistGroupManagerRef.current?.runRemove({specialistGroupID: selectedRow.id});
	}, [removeSpecialistGroupManagerRef, selectedRow]);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<InfoBox>
							<OneLinedText size='large' color='highContrast'>
								{selectedRow.name}
							</OneLinedText>

							<AdditionalInfo>
								<TitleWithTranscription
									title='Specialist'
									transcript={getFullName({
										firstName: selectedRow.specialist.firstName,
										lastName: selectedRow.specialist.lastName,
									})}
								/>
							</AdditionalInfo>
						</InfoBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteButton onClick={runRemoveItem} />
							<ActionPanel.ActionButtonDivider />

							<Button.Icon onClick={openSpecialistGroupDrawer}>
								<Edit />
							</Button.Icon>

							<RunReportButton
								disabled={!selectedRow.students}
								disabledMessage={
									selectedRow.students
										? null
										: 'No students have been added to your class. Please add some before running the report.'
								}
								skeleton={skeleton}
								subject={reportSubject}
							>
								{({onReportsClose, subject}) => (
									<ReportsFeature
										onClose={onReportsClose}
										group={selectedRow}
										hierarchy={hierarchy}
										subject={subject}
									/>
								)}
							</RunReportButton>
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<ActionPanel.Navigation.Container>
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
						</ActionPanel.Navigation.Container>

						<SingleSelectedExpandablePanel.OpenPanelButton
							Icon={PieChartIcon}
							transcript='View Tests Results'
							warningDescription='No students have been added to your specialist group. Please add some before running the report.'
							canExpandPanel={!!selectedRow.students}
						/>
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showSpecialistGroupDrawer && (
				<Portal container={rootPageContentContainerRef.current}>
					<SpecialistGroupDrawer.Edit onClose={closeSpecialistGroupDrawer} specialistGroupID={selectedRow.id} />
				</Portal>
			)}

			<RemoveSpecialistGroup managerRef={removeSpecialistGroupManagerRef} onRemoved={deselectedAllItems} />
		</>
	);
}
