import {BasePageProps} from '../types';
import {entityName} from '../constants';
import {Content} from './components/content';
import {useDataPageState} from '@esgi/main/features/school-admin/data';
import {Page} from '@esgi/main/features/admins/data';

export function Base({allTableRows, isDataLoaded, columnDefs, addButtonDisabled, onOpenAddDrawer}: BasePageProps) {
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	return (
		<Page.Root
			columnDefs={columnDefs}
			entityName={entityName}
			tableRows={allTableRows}
			isDataLoaded={isDataLoaded && isInitialStateReady}
			initialState={initialState}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<Content addButtonDisabled={addButtonDisabled} onOpenAddDrawer={onOpenAddDrawer} />
		</Page.Root>
	);
}
