import {Group, useGroups} from '@esgi/main/libs/school-admin-store';
import {RemoveTeacherGroupManagerRef} from './types';
import {useCallback, useImperativeHandle, useState} from 'react';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {DeleteTeacherGroupAlert} from './components/delete-teacher-group-alert';

type Props = {
	managerRef: RemoveTeacherGroupManagerRef;
	onRemoved: VoidFunction;
};

export function RemoveTeacherGroupImpl({managerRef, onRemoved}: Props) {
	const [{data: teachersGroupsList}] = useGroups();

	const [potentialRemovedTeacherGroup, setPotentialRemovedTeacherGroup] = useState<Group | null>(null);

	const closeDeleteAlert = useCallback(() => {
		setPotentialRemovedTeacherGroup(null);
	}, []);

	useImperativeHandle(managerRef, () => ({
		runRemove: ({teacherGroupID}) => {
			const teacherGroup = teachersGroupsList.find(({id}) => id === teacherGroupID);

			if (isUndefined(teacherGroup)) {
				throw new Error('teacherGroup is not found in admin store');
			}

			setPotentialRemovedTeacherGroup(teacherGroup);
		},
	}));

	if (isNull(potentialRemovedTeacherGroup)) {
		return null;
	}

	return (
		<DeleteTeacherGroupAlert
			onClose={closeDeleteAlert}
			onRemoved={onRemoved}
			potentialRemovedTeacherGroup={potentialRemovedTeacherGroup}
		/>
	);
}
