import {Text} from '@esgi/ui/typography';
import {Button, TooltipContent} from './index.styled';
import {ComponentPropsWithoutRef, forwardRef, ReactNode, useLayoutEffect, useMemo} from 'react';
import {isUndefined, Tooltip, useFlag} from '@esgi/ui';
import {ReportsSubjectInfo} from '../../reports';
import {isNull} from 'underscore';

type ChildrenProps = {
	subject: ReportsSubjectInfo;
	onReportsClose: VoidFunction;
};

export type RunReportButtonProps = Omit<ComponentPropsWithoutRef<typeof Button>, 'children' | 'onClick'> & {
	title?: string;
	disabledMessage?: string | null;

	/**
	 * The children prop uses only for reports modal
	 */
	children?: (props: ChildrenProps) => ReactNode;
	subject?: ReportsSubjectInfo | null;
};

export const RunReportButton = forwardRef<HTMLButtonElement, RunReportButtonProps>(
	(
		{
			dataCy = 'run-report-button',
			css = {},
			color = 'primary',
			title = 'Run Reports',
			disabledMessage,
			disabled,
			skeleton,
			children,
			subject,
			...props
		},
		forwardedRef,
	) => {
		const [showReports, openReports, closeReports] = useFlag();

		useLayoutEffect(() => {
			if (isUndefined(children) && !isUndefined(subject)) {
				throw new Error('children should be passed');
			}

			if (!isUndefined(children) && isUndefined(subject)) {
				throw new Error('subject should be passed');
			}
		}, [children, subject]);

		const disabledMessageTooltip = useMemo<string | null>(() => {
			if (typeof disabledMessage === 'string') {
				return disabledMessage;
			}

			if (isNull(subject) && typeof children === 'function') {
				return 'You have no subject tabs available to run reports. Please create at least one subject tab by clicking Subject Tabs from the main navigation on the left, then share it with users.';
			}

			return null;
		}, [children, disabledMessage, subject]);

		const withDisabledMessage = !isNull(disabledMessageTooltip);

		return (
			<>
				<Tooltip open={withDisabledMessage ? undefined : false}>
					<Tooltip.Trigger>
						<Button
							dataCy={dataCy}
							css={css}
							color={color}
							onClick={openReports}
							disabled={withDisabledMessage || Boolean(disabled)}
							skeleton={skeleton}
							ref={forwardedRef}
							{...props}
						>
							<Text size='large' color='surface'>
								{title}
							</Text>
						</Button>
					</Tooltip.Trigger>

					<TooltipContent variant='default'>
						<Text size='small'>{disabledMessageTooltip}</Text>
					</TooltipContent>
				</Tooltip>

				{showReports &&
					subject &&
					children?.({
						onReportsClose: closeReports,
						subject,
					})}
			</>
		);
	},
);
