import {Text} from '@esgi/ui/typography';
import {ActionPanel, DeleteButton, SchoolReportsButton} from '../../../../_kit';
import {RowDef} from '../../../types';
import {RunReportButton, Page} from '@esgi/main/features/admins/data';

export function BottomPanel() {
	const {api, entityName} = Page.useContext<RowDef>();
	const totalRows = api?.getDisplayedRowCount();
	const selectedRows = api?.getSelectedRows();

	return (
		<ActionPanel.Root api={api} showOnMinSelectedItems={2}>
			<ActionPanel.Header>
				<ActionPanel.ContentBox>
					<ActionPanel.CloseButton />

					<ActionPanel.MainBox>
						<ActionPanel.CounterBox />

						<Text size='xSmall' font='mono' color='primary'>
							{entityName} Selected
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<ActionPanel.ShowOnlySelectedCheckbox />
						<ActionPanel.ActionButtonDivider />

						<DeleteButton disabled tooltipMessage='Select only one item for deletion.' whenShowTooltip='disabled' />

						{selectedRows?.length === totalRows ? (
							<SchoolReportsButton />
						) : (
							<RunReportButton title='Run Reports' disabled />
						)}
					</ActionPanel.ActionButtonsBox>
				</ActionPanel.ContentBox>
			</ActionPanel.Header>
		</ActionPanel.Root>
	);
}
