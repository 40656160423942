import {Alert} from '@esgi/ui/alert';
import {AlertBody, alertRootCss, Divider} from './index.styled';
import {isUndefined, Skeletonable} from '@esgi/ui';
import {GridApi, IRowNode, FirstDataRenderedEvent, ColDef, GridReadyEvent} from 'ag-grid-community';
import {Dispatch, useCallback, useEffect, useMemo, useState} from 'react';
import {isNull} from 'underscore';
import {Entity} from './types';
import {agRowSelectedBeforeCSS, cellHorizontalPadding} from './constants';
import {AgGrid} from '@esgi/ui/ag-grid';
import {Header} from './components/header';
import {Footer} from './components/footer';
import {TableControl} from './components/table-control';
import {useUser} from '@esgi/core/authentication';
import {getStudentsTableColumnDef, StudentRowModel} from '../students-table-utils';

type Props<T extends StudentRowModel> = Skeletonable & {
	entity: Entity | null;
	columnDefs: ColDef<T>[];
	rowsData: T[];
	selectedItemsIDs: StudentRowModel['id'][];
	disabledRowsAsSelectedIDs: StudentRowModel['id'][];
	onAdd: Dispatch<StudentRowModel['id'][]>;
	onClose: VoidFunction;
};

export function AddStudentsAlert<T extends StudentRowModel>({
	onAdd,
	onClose,
	columnDefs,
	selectedItemsIDs,
	skeleton,
	rowsData,
	entity,
	disabledRowsAsSelectedIDs,
}: Props<T>) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [api, setApi] = useState<GridApi<T> | null>(null);

	const [selectedAsDisabledNodes, setSelectedAsDisabledNodes] = useState<IRowNode<T>[]>([]);
	const [selectedRows, setSelectedRows] = useState<T[]>([]);

	const currentUser = useUser();

	const extendedColDefs = useMemo<ColDef<T>[]>(() => {
		return [
			getStudentsTableColumnDef.firstName({currentUser}),
			getStudentsTableColumnDef.lastName({currentUser}),
			...columnDefs,
		];
	}, [columnDefs, currentUser]);

	const onGridReady = useCallback(({api}: GridReadyEvent) => {
		setApi(api);
	}, []);

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const modelUpdatedHandler = () => {
			api.paginationGoToFirstPage();
		};

		const selectionChangedHandler = () => {
			const selectedRows: T[] = api.getSelectedRows();

			setSelectedRows(selectedRows);
		};

		api.addEventListener('modelUpdated', modelUpdatedHandler);
		api.addEventListener('selectionChanged', selectionChangedHandler);

		return () => {
			api.removeEventListener('modelUpdated', modelUpdatedHandler);
			api.removeEventListener('selectionChanged', selectionChangedHandler);
		};
	}, [api]);

	const isRowSelectable = useCallback(
		({data}: IRowNode<T>) => !(isUndefined(data) || disabledRowsAsSelectedIDs.includes(data.id)),
		[disabledRowsAsSelectedIDs],
	);

	const onFirstDataRendered = useCallback(
		({api}: FirstDataRenderedEvent<T>) => {
			const nodesToSelect: IRowNode<T>[] = [];
			const nodesAsSelectedAsDisabled: IRowNode<T>[] = [];

			api.forEachNode((node: IRowNode<T>) => {
				const data = node.data;

				if (!isUndefined(data) && selectedItemsIDs.includes(data.id)) {
					nodesToSelect.push(node);
				}

				if (!isUndefined(data) && disabledRowsAsSelectedIDs.includes(data.id)) {
					nodesAsSelectedAsDisabled.push(node);
				}
			});

			api.setNodesSelected({nodes: nodesToSelect, newValue: true});

			setSelectedRows(api.getSelectedRows());
			setSelectedAsDisabledNodes(nodesAsSelectedAsDisabled);
		},
		[disabledRowsAsSelectedIDs, selectedItemsIDs],
	);

	return (
		<Alert modalManagerRef={alertRef} css={alertRootCss}>
			<Header closeAlert={closeAlert} entity={entity} />

			<AlertBody>
				<TableControl api={api} />

				<AgGrid
					agSelectedRowBackgroundColor='primaryBackground'
					agRowSelectedBeforeCSS={agRowSelectedBeforeCSS}
					withoutFirstColumnPadding
					withoutLastColumnPadding
					cellHorizontalPadding={cellHorizontalPadding}
				>
					<AgGrid.Table
						rowData={rowsData}
						columnDefs={extendedColDefs}
						suppressRowClickSelection={false}
						rowMultiSelectWithClick
						rowSelection='multiple'
						pagination
						suppressPaginationPanel
						paginationAutoPageSize={false}
						paginationPageSize={10}
						suppressRowHoverHighlight={false}
						loading={Boolean(skeleton)}
						isRowSelectable={isRowSelectable}
						onFirstDataRendered={onFirstDataRendered}
						onGridReady={onGridReady}
						withoutCustomizationColumn
						selectedAsDisabledNodes={selectedAsDisabledNodes}
						suppressMovableColumns
					/>
				</AgGrid>
			</AlertBody>

			<Divider />

			<Footer
				api={api}
				closeAlert={closeAlert}
				disabledRowsAsSelectedIDs={disabledRowsAsSelectedIDs}
				onAdd={onAdd}
				selectedRows={selectedRows}
			/>
		</Alert>
	);
}
