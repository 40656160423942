import {BottomPanel} from './components/bottom-panel';
import {SingleItemExpandablePanel} from './components/single-item-expandable-panel';
import {SchoolReportPanel} from '../../../_kit/school-reports-panel';
import {RowDef} from '../../../teachers/types';
import {useData} from '../../../students/use-data';
import {Page} from '@esgi/main/features/admins/data';

type Props = {
	onOpenAddDrawer: VoidFunction;
};

export function Base({onOpenAddDrawer}: Props) {
	const {api} = Page.useContext<RowDef>();
	const {schoolName} = useData();

	return (
		<>
			<Page.Header.Base onAddButtonClick={onOpenAddDrawer} />
			<Page.Body.Base />

			<SingleItemExpandablePanel />
			<SchoolReportPanel api={api} schoolName={schoolName} />
			<BottomPanel />
		</>
	);
}
